import { createAction, props, union } from '@ngrx/store';

import { RGBA } from 'ngx-color';

import { Content }                                           from '../../declarations/content';
import { Layout }                                            from '../../declarations/layout';
import { OuterRenderingProperties, SizeRenderingProperties } from '../../declarations/rendering-properties';

export const addLayout = createAction(
  '[TEMPLATER][LAYOUTS] Add One',
  props<{ layout: Layout }>()
);

export const deleteLayouts = createAction(
  '[TEMPLATER][LAYOUTS] Delete Many',
  props<{ ids: string[] }>()
);

export const addContentToLayout = createAction(
  '[TEMPLATER][LAYOUTS] ADD Content',
  props<{ layoutId: string; placeholderId: string; content: Content }>()
);

export const resetContentOfLayout = createAction(
  '[TEMPLATER][LAYOUTS] RESET Content',
  props<{ layoutId: string; contentId: string }>()
);

export const updateLayoutCustomBlockRef = createAction(
  '[TEMPLATER][LAYOUTS] Update Custom Block Ref',
  props<{ id: string; blockRefId: string; blockRefChildrenId: string }>()
);

export const setLayoutRenderingCondition = createAction(
  '[TEMPLATER][LAYOUTS] Set Rendering Condition',
  props<{ id: string; conditions: string[] }>()
);

export const unsetLayoutRenderingCondition = createAction(
  '[TEMPLATER][LAYOUTS] Unset Rendering Condition',
  props<{ id: string }>()
);

export const updateLayoutSizeProps = createAction(
  '[TEMPLATER][LAYOUTS] Update Size Properties',
  props<{ id: string; partial: Partial<SizeRenderingProperties> }>()
);

export const updateLayoutRenderingProps = createAction(
  '[TEMPLATER][LAYOUTS] Update Rendering Properties',
  props<{ id: string; partial: Partial<OuterRenderingProperties> }>()
);

export const updateLayoutBackgroundColor = createAction(
  '[TEMPLATER][LAYOUTS] Update Rendering Property Background Color',
  props<{ id: string; previousColor: RGBA; nextColor: RGBA }>()
);

export const setLayoutHighlight = createAction(
  '[TEMPLATER][LAYOUTS] Set Highlight',
  props<{ id: string; highlighted: boolean }>()
);


export const undoLayout = createAction('[TEMPLATER][LAYOUTS] UNDO');
export const redoLayout = createAction('[TEMPLATER][LAYOUTS] REDO');

export const layoutUserActionsCollection = {
  addLayout,
  deleteLayouts,
  addContentToLayout,
  resetContentOfLayout,
  updateLayoutSizeProps,
  updateLayoutRenderingProps,
  updateLayoutBackgroundColor,
  setLayoutRenderingCondition,
  unsetLayoutRenderingCondition
};

export const layoutUserActions = union(layoutUserActionsCollection);
const layoutNonUserActions = union({
  setLayoutHighlight,
  updateLayoutCustomBlockRef
});

export type LayoutAction = typeof layoutUserActions | typeof layoutNonUserActions;
