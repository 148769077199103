import { TemplateModelUtils } from '../../utils/template-model.utils';
import { TemplateTrackingUtils } from '../../utils/template-tracking.utils';
import { CompilationContext, CompilationResult } from '../compilation';
import { ConstraintPattern, ContentType } from '../enums';
import { applyStyles } from '../rendering-properties';

import { Content, ContentParametersInfo, DEFAULT_ARTICLE_LINK } from './';

export interface TitleContentParams {
  titleContent: string;
  customTracking: string;
  disableEncoding: boolean;
}

export const TITLE_PARAMETERS_INFO: ContentParametersInfo<TitleContentParams> = {
  titleContent: {
    hiddenInSidebar: true,
    valueType: 'string'
  },
  customTracking: {
    valueType: 'string',
    enabledWithConstraints: [ConstraintPattern.HeatmapUTM, ConstraintPattern.SelligentTracking]
  },
  disableEncoding: {
    valueType: 'boolean',
    hint: true
  }
};

export const DEFAULT_TITLE_PARAMETERS: Content<TitleContentParams>['params'] = {
  static: {
    titleContent: '<span style="font-size: 24px;">Cliquez ici pour écrire votre titre</span>',
    customTracking: '',
    disableEncoding: false
  },
  dynamic: {
    titleContent: '<span style="font-size: 24px;">{{ metadata.title }}</span>',
    articleLink: DEFAULT_ARTICLE_LINK,
    customTracking: '',
    disableEncoding: false
  }
};

export type TitleContent = Content<TitleContentParams>;

export const compileTitleToHtmlNodes = (content: TitleContent, articleIndex: number, spaceBetweenParagraphs: number, context: CompilationContext): CompilationResult => {

  const result: CompilationResult = {
    nodes: [],
    context
  };

  const div_tag = document.createElement('div') as HTMLDivElement;
  applyStyles(div_tag, content.innerRenderingProperties);

  if (content.unlockedForEditorial) {
    const table_tag = document.createElement('table') as HTMLTableElement;
    applyStyles(table_tag, content.innerRenderingProperties);

    table_tag.append(document.createTextNode(TemplateModelUtils.compileContentUnlockedForEditorial(content.id)));
    table_tag.classList.add('ql');
    result.nodes = [table_tag];
  } else if (content.articleRef) {
    // using wrappers to transmit context and check existence
    const startingWrapper: Text = document.createTextNode(`{{ #reco_${articleIndex} }}`);
    const endingWrapper: Text = document.createTextNode(`{{ /reco_${articleIndex} }}`);

    div_tag.append(
      ...TemplateModelUtils.compileQuillOutputToHtmlNodes(
        content.params.dynamic.titleContent,
        spaceBetweenParagraphs !== undefined ? spaceBetweenParagraphs : content.outerRenderingProperties.spaceBetweenParagraphs,
        content.params.dynamic.disableEncoding
      )
    );

    let a_tag: HTMLAnchorElement;
    if (content.params.dynamic.articleLink) {

      let titleLink = content.params.dynamic.articleLink;
      titleLink += TemplateTrackingUtils.getMediegoTrackingParameter(context, content.params.dynamic, ContentType.Title, true, articleIndex);

      a_tag = document.createElement('a');
      a_tag.style.textDecoration = 'none';
      a_tag.style.color = 'black';
      a_tag.style.margin = '0';
      a_tag.target = '_blank';
      a_tag.href = titleLink;

      if (content.params.dynamic.disableEncoding) a_tag.setAttribute('data-disable-tracking', 'true');

      TemplateTrackingUtils.setSelligentTrackingParameter(a_tag, context, content.params.dynamic, true, articleIndex);

      a_tag.appendChild(div_tag);
      result.nodes = [startingWrapper, a_tag, endingWrapper];
    } else {
      result.nodes = [startingWrapper, div_tag, endingWrapper];
    }
  } else {
    div_tag.append(
      ...TemplateModelUtils.compileQuillOutputToHtmlNodes(
        content.params.static.titleContent,
        spaceBetweenParagraphs !== undefined ? spaceBetweenParagraphs : content.outerRenderingProperties.spaceBetweenParagraphs,
        content.params.static.disableEncoding
      )
    );
    div_tag.classList.add('ql');
    result.nodes = [div_tag];
  }

  return result;
};
