import { TemporalModifier } from './temporal-modifier';

export class PeriodFilterModifier extends TemporalModifier {

  readonly type: string = 'period-filter';

  n: number;
  period: string;


  constructor(n: number = 30, period: string = 'days') {
    super();
    this.n = n;
    this.period = period;
  }

  parametersToJson(): any {
    return {
      n: this.n,
      temporalUnit: this.period
    };
  }


  isValid(): boolean {
    return this.n > 0;
  }
}
