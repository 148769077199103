import { Action }   from '@ngrx/store';

import { AppState } from '../index';

export class EmptyAction implements Action {
  readonly type = 'EMPTY_ACTION';
}

export const HYDRATE_ROOT_STATE_ACTION_TYPE = 'HYDRATE_ROOT_STATE';

export class HydrateRootStateAction implements Action {
  readonly type = HYDRATE_ROOT_STATE_ACTION_TYPE;

  constructor(public payload: { rootState: AppState }) {}
}
