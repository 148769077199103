import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule }     from '@angular/common/http';
import { ApplicationRef, DoBootstrap, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule }                                       from '@angular/platform-browser';
import { BrowserAnimationsModule }                             from '@angular/platform-browser/animations';
import { ServiceWorkerModule }                                 from '@angular/service-worker';
import { createInputTransfer, createNewHosts, removeNgStyles } from '@angularclass/hmr';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';

import { EffectsModule }                                      from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  NavigationActionTiming,
  RouterStateSerializer,
  StoreRouterConnectingModule } from '@ngrx/router-store';
import { Action, ActionReducer, Store, StoreModule }          from '@ngrx/store';

import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { finalize, take, takeLast, takeWhile } from 'rxjs/operators';

import { JwtModule }                        from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader }              from '@ngx-translate/http-loader';
// import * as Sentry from '@sentry/angular-ivy';
import { CookieModule }                     from 'ngx-cookie';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { QuillModule }                      from 'ngx-quill';

import { AppRoutingModule }    from './app-routing.module';
import { MediegoCommonModule } from './mediego-common-module/mediego-common.module';

import { APP_EFFECTS, APP_REDUCERS, AppState, CustomRouterStateSerializer } from './main-module/state';
import { HYDRATE_ROOT_STATE_ACTION_TYPE, HydrateRootStateAction }           from './main-module/state/actions';

import { AppService }  from './services/app.service';
import { AuthService } from './services/auth.service';

import { AcceptInviteComponent }   from './main-module/views/accept-invite/accept-invite.component';
import { FirstSetupComponent }     from './main-module/views/first-setup/first-setup.component';
import { ForgotPasswordComponent } from './main-module/views/forgot-password/forgot-password.component';
import { LoginComponent }          from './main-module/views/login/login.component';
import { AppComponent }            from './main-module/views/main/app.component';
import { NoPermissionsComponent }  from './main-module/views/no-permissions/no-permissions.component';
import { PermissionsErrorComponent } from './main-module/views/permissions-error/permissions-error.component';
import { PlatformErrorComponent } from './main-module/views/platform-error/platform-error.component';
import { SignupComponent }         from './main-module/views/signup/signup.component';

import { BackboardComponent } from './main-module/components/backboard/backboard.component';
import { IntercomComponent }        from './main-module/components/intercom/intercom.component';
import { LogoutComponent }          from './main-module/components/logout/logout.component';
import {
  MailerCompatibilityCardComponent
} from './main-module/components/mailer-compatibility-card/mailer-compatibility-card.component';
import { NotificationsCardComponent } from './main-module/components/notifications-card/notifications-card.component';
import { SidenavTabsComponent }     from './main-module/components/sidenav-tabs/sidenav-tabs.component';
import { SnackbarLoadingComponent } from './main-module/components/snackbar-loading/snackbar-loading.component';
import { ToolbarComponent }         from './main-module/components/toolbar/toolbar.component';

import { JWT_MODULE_CONFIG } from './main-module/declarations/mediego-jwt';

import { environment } from '../environments/environment';

import { UnauthorizedInterceptor } from './main-module/interceptors/unauthorized-interceptor';


@NgModule({
  declarations: [
    AppComponent,
    NoPermissionsComponent,
    ToolbarComponent,
    SidenavTabsComponent,
    SignupComponent,
    AcceptInviteComponent,
    LoginComponent,
    FirstSetupComponent,
    PermissionsErrorComponent,
    PlatformErrorComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    IntercomComponent,
    SnackbarLoadingComponent,
    BackboardComponent,
    NotificationsCardComponent,
    MailerCompatibilityCardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    HttpClientModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    JwtModule.forRoot(JWT_MODULE_CONFIG),
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    LayoutModule,
    MediegoCommonModule,
    QuillModule.forRoot(),
    StoreModule.forRoot(
      APP_REDUCERS,
      {
        metaReducers: [
          hmrStateHydrator
        ],
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false
        }
      }
    ),
    EffectsModule.forRoot(APP_EFFECTS),
    // Connects RouterModule with StoreModule
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation
    }),
    // StoreDevtoolsModule.instrument(STORE_DEV_TOOLS_OPTIONS),
    HighlightModule,
    OverlayModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml')
        }
      }
    }
    // SENTRY
    /*
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
    */
    // END OF SENTRY CONFIG
  ]
})
export class AppModule implements DoBootstrap {
  private listeningToHmrInit$$ = new BehaviorSubject<boolean>(false);
  private hmrData$$ = new BehaviorSubject<any>(undefined);

  constructor(
    private appRef: ApplicationRef,
    private store: Store<AppState>,
    private authService: AuthService,
    private appService: AppService
  ) {}

  async ngDoBootstrap(appRef: ApplicationRef) {
    const langInit$ = this.appService.initLanguage();

    if (environment.hmr) {
      const hmrData$ = firstValueFrom(this.hmrData$$.pipe(takeLast(1)));
      this.listeningToHmrInit$$.next(true);
      // now listening for hmr data

      const hmrData = await hmrData$;
      this.listeningToHmrInit$$.complete(); // not needed anymore

      if (hmrData === null) {
        const loggedIn = this.authService.init();
        if (loggedIn) {
          await this.appService.init();
        }
      }
    } else {
      this.listeningToHmrInit$$.complete(); // we don't need it in prod
      this.hmrData$$.complete(); // we don't need it in prod

      const loggedIn = this.authService.init();
      if (loggedIn) {
        await this.appService.init();
      }
    }

    await langInit$;

    appRef.bootstrap(AppComponent);
  }

  // noinspection JSUnusedGlobalSymbols
  hmrOnInit(data) {
    if (data !== undefined) {
      // restore state by dispatch a SET_ROOT_STATE action
      if ('rootState' in data) {
        this.store.dispatch(new HydrateRootStateAction({ rootState: data.rootState }));
      }

      if ('restoreInputValues' in data) {
        data.restoreInputValues();
      }
    }

    this.listeningToHmrInit$$.pipe(
      takeWhile((__) => !__),
      finalize(() => {
        // bootstrap listening. emitting now...
        this.hmrData$$.next(data ? { ...data } : null);
        this.hmrData$$.complete();
      })
    ).subscribe();

    // this.appRef.tick();  <<< REMOVE THIS LINE, or store will not work after HMR
    Object.keys(data || {}).forEach((prop) => delete data[prop]);
  }

  // noinspection JSUnusedGlobalSymbols
  hmrOnDestroy(data) {
    this.store.pipe(take(1)).subscribe((s) => data.rootState = s);
    const cmpLocation = this.appRef.components.map((cmp) => cmp.location.nativeElement);
    data.disposeOldHosts = createNewHosts(cmpLocation);
    data.restoreInputValues = createInputTransfer();
    removeNgStyles();
  }

  // noinspection JSUnusedGlobalSymbols,JSMethodCanBeStatic
  hmrAfterDestroy(data) {
    data.disposeOldHosts();
    delete data.disposeOldHosts;
  }

}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function hmrStateHydrator(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: Action): any => {
    if (action.type === HYDRATE_ROOT_STATE_ACTION_TYPE) {
      const stateFromHMR = (action as HydrateRootStateAction).payload.rootState;

      return reducer(stateFromHMR, action);
    }

    return reducer(state, action);
  };
}
