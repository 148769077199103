import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mediego-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent {

  @Input() deleteText: string;
  @Input() deleteIcon?: string = 'warning';
  @Input() withMediegoTooltip: boolean = true;

  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

}
