import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { firstValueFrom, Observable } from 'rxjs';

import { AppState } from '../../main-module/state';
import { selectNbOfArticles, selectSortedArticles } from '../state/selectors/template-articles.selectors';
import { createArticleFromSelection }               from '../state/actions/template-model.actions';

import { Article } from '../declarations/article';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  get nbOfArticles$(): Observable<number> {
    return this.store.select(selectNbOfArticles);
  }

  get sortedArticles$(): Observable<Article[]> {
    return this.store.select(selectSortedArticles);
  }

  isManipulatingArticles: boolean = false;

  constructor(private store: Store<AppState>) {}

  async getSortedArticles(): Promise<Article[]> {
    return firstValueFrom(this.sortedArticles$);
  }

  createArticleFromSelectedElements() {
    this.store.dispatch(createArticleFromSelection());
  }

}
