import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { hmrModule }     from '@angularclass/hmr';

// import * as Sentry from '@sentry/angular-ivy';
import dayjs             from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocalizedFormat   from 'dayjs/plugin/localizedFormat';
import weekOfYear        from 'dayjs/plugin/weekOfYear';

import { AppModule }   from './app/app.module';

import { environment } from './environments/environment';

/*
Sentry.init({
  dsn: 'https://bedb1d53490e1876f0d4e1f6302a235e@o4506305025015808.ingest.sentry.io/4506305026654208',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Sentry.Replay({
      networkDetailAllowUrls: ['/most-recent-items']
    })
  ],

  release: 'alpha',
  environment: environment.name,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.production ? 0.1 : 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://dashboard.mediego.com/',
    'https://dashboard.staging.mediego.com/',
    'https://api.staging.mediego.com/',
    'https://api.mediego.com/'
  ],

  replaysSessionSampleRate: 0, // Disabling replays
  replaysOnErrorSampleRate: 1.0 // Except for errors
});
*/


dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);
dayjs.extend(weekOfYear);

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule).then((module) => {

  if (!environment.production) {
    // ng.profiler.timeChangeDetection({record: true})
    setTimeout(() => {
      if (module && module.injector) {
        const applicationRef = module.injector.get(ApplicationRef);
        if (applicationRef && applicationRef.components && applicationRef.components.length) {
          const appComponent = applicationRef.components[0];
          enableDebugTools(appComponent);
        }
      }
    }, 1000);
  }

  return module;

});

if (environment.hmr) {
  if (module['hot']) {
    bootstrap()
      .then((moduleRef) => hmrModule(moduleRef, module))
    ;
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  bootstrap();
}
