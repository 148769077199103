import { TemplateHeader } from '../../templater-module/declarations/template-model';

import { ExternalElement }   from './external-element';
import { TemplateSubject } from './template-subject';
import { TemplateVariableFromAPI } from './template-variables';

export class Template {
  id: string;
  name: string;

  timestamp?: number;

  compiled: string;
  model?: any;
  subject?: TemplateSubject;
  variables?: TemplateVariableFromAPI[];
  externalElements: ExternalElement[];

  enableEditor: boolean;

  static fromJson(json: any): Template {
    return new Template(json as Template);
  }

  constructor(data: Template) {
    Object.assign(this, data);
  }

}


export function compileHeader(obj: TemplateHeader): boolean {
  if (obj && obj.valueNotCompiled !== undefined) {

    const recosQuery: any[] = (obj.valueNotCompiled as any).matchAll(/\#reco\_([0-9]+)/gi) || [];
    const recos: number[] = [];

    Array.from(recosQuery).forEach((reco) => {
      // reco[0] will be the whole match -> #reco_X
      // reco[1] will be the capturing group -> X
      if (reco && reco.length && reco.length >= 2) {
        recos.push(parseInt(reco[1]));
      }
    });

    // dummy behavior -> compiled value cloned from uncompiled value
    obj.valueCompiled = obj.valueNotCompiled;

    // updating number of recos (and their specific indexes)
    obj.recoIndexesNeeded = [...recos];

    const trustedAfterCompilation = canTrustHeader(obj);

    obj.isTrusted = trustedAfterCompilation;

    return trustedAfterCompilation;
  } else {
    return false;
  }
}


export function canTrustHeader(obj: TemplateHeader): boolean {

  const firstSimpleCheck: boolean = (obj && obj.valueNotCompiled !== undefined
    && obj.valueCompiled !== undefined && obj.staticValueForBackup !== undefined);

  if (firstSimpleCheck) {

    const openingCurlyBraces: string[] = (obj.valueCompiled.match(/\{\{/g) || []);
    const closingCurlyBraces: string[] = (obj.valueCompiled.match(/\}\}/g) || []);

    const secondCheck: boolean = (openingCurlyBraces.length === closingCurlyBraces.length);

    return secondCheck;

  }
}

// subject = from server, template header = used by frontend
export function subjectToTemplateHeader(subject: TemplateSubject): TemplateHeader {

  if (!subject || subject.main === undefined || subject.default === undefined) {
    throw new Error('subjectToTemplateHeader function cannot process an invalid subject');
  }

  const importedHeader = {
    valueCompiled: '',
    recoIndexesNeeded: [],

    valueNotCompiled: subject.main,
    staticValueForBackup: subject.default,

    isTrusted: false
  };

  compileHeader(importedHeader);

  return importedHeader;
}

export function templateHeaderToSubject(header: TemplateHeader): TemplateSubject {

  if (!header || header.valueNotCompiled === undefined || header.staticValueForBackup === undefined) {
    throw new Error('templateHeaderToSubject function cannot process an invalid header');
  }

  return {
    main: header.valueNotCompiled,
    default: header.staticValueForBackup
  }
}

export const DEFAULT_HEADER: TemplateHeader = {
  valueCompiled: '',
  valueNotCompiled: '',
  recoIndexesNeeded: [],
  staticValueForBackup: '',

  isTrusted: false
};
