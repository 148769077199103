import { SingleVisitCriterion } from './single-visit-criterion';

export class UrlContainsCriterion extends SingleVisitCriterion {
  readonly type = 'url-contains';
  readonly displayName = 'URL(s)';
  readonly icon = 'code';

  patterns: string[];

  constructor(patterns: string[] = []) {
    super();
    this.patterns = patterns;
  }

  isValid() {
    return this.patterns.length > 0;
  }

  getPatterns(): string[] {
    return this.patterns;
  }

  addPattern(pattern: string): void {
    this.patterns.push(pattern);
  }

  removePattern(index: number): string {
    return this.patterns.splice(index, 1)[0];
  }


  parametersToJson(): any {
    return {
      patterns: this.patterns
    };
  }

}


