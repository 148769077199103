import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mediego-button-card',
  templateUrl: './button-card.component.html',
  styleUrls: ['./button-card.component.scss']
})
export class ButtonCardComponent {

  @Input() cardRouterLink: any[] | string;
  @Input() cardQueryParams: { [k: string]: any };
  @Input() cardQueryParamsHandling: 'merge' | 'preserve' | '';

  @Output() cardClick = new EventEmitter<void>();

  @Input() cardHeight: number; // px
  @Input() cardIcon: string;
  @Input() cardText: string;

  onClick() {
    this.cardClick.emit();
  }

}
