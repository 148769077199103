import { createReducer, on } from '@ngrx/store';

import {
  loadNewslettersConfigColors, loadNewslettersConfigFonts,
  NewslettersConfigAction,
  setNewslettersConfig,
  updateNewslettersConfigColor, updateNewslettersConfigFont,
  updateNewslettersConfigPrimaryColor,
  updateNewslettersConfigSecondaryColor
} from '../actions/newsletters-config.actions';

import { NewslettersConfigState } from '../../declarations/newsletters-config';

export const INITIAL_STATE: NewslettersConfigState = {
  colorset: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
  primaryColor: '#FFFFFF',
  secondaryColor: '#FFFFFF',
  fonts: ['Arial, sans-serif', 'Times New Roman, serif']
};

const reducer = createReducer(
  INITIAL_STATE,
  on(setNewslettersConfig, (state, { config }) => {
    return { ...config };
  }),

  // COlORSET
  on(loadNewslettersConfigColors, (state, { colors }) => {
    return {
      ...state,
      colorset: [...colors]
    };
  }),
  on(updateNewslettersConfigColor, (state, { color, index }) => {
    if (index < 0 || index >= state.colorset.length || !color) {
      return state;
    }

    return {
      ...state,
      colorset: [
        ...state.colorset.slice(0, index),
        color,
        ...state.colorset.slice(index + 1)
      ]
    }
  }),

  // BACKGROUND COLORS
  on(updateNewslettersConfigPrimaryColor, (state, { color }) => {
    return {
      ...state,
      primaryColor: color
    }
  }),
  on(updateNewslettersConfigSecondaryColor, (state, { color }) => {
    return {
      ...state,
      secondaryColor: color
    }
  }),


  // FONTS
  on(loadNewslettersConfigFonts, (state, { fonts }) => {
    return {
      ...state,
      fonts: [...fonts]
    };
  }),
  on(updateNewslettersConfigFont, (state, { font, index }) => {
    if (index < 0 || index >= state.fonts.length || !font) {
      return state;
    }

    return {
      ...state,
      fonts: [
        ...state.fonts.slice(0, index),
        font,
        ...state.fonts.slice(index + 1)
      ]
    }
  })
);

export function newslettersConfigReducer(state: NewslettersConfigState, action: NewslettersConfigAction): NewslettersConfigState {
  return reducer(state, action);
}
