import { HttpClient } from '@angular/common/http';
import { Injectable }             from '@angular/core';

import { Store } from '@ngrx/store';

import { firstValueFrom, Observable } from 'rxjs';

import { AppState } from '../main-module/state';
import {
  selectAllNewsletterSourcesOfSelectedEngine,
  selectAllWebSourcesOfSelectedEngine,
  selectNewsletterSourcesFolderOfSelectedEngine,
  selectWebSourcesFolderOfSelectedEngine
} from '../main-module/state/selectors/engines-selectors';
import {
  DeleteEmailSourceFromSelectedEngineAction,
  DeleteNewsletterPermissionsInSelectedEngineAction,
  DeleteWebSourceFromSelectedEngineAction,
  UpsertEmailSourceInSelectedEngineAction,
  UpsertWebSourceInSelectedEngineAction
} from '../main-module/state/actions/engines.actions';

import { Folder }                   from '../main-module/declarations/folder';
import { NewsletterSource, SendingConfiguration, Source } from '../mediego-common-module/declarations/source';
import { SourceAction } from '../newsletters-module/declarations/api-actions';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SourceService {

  get allWebSourcesOfSelectedEngine$(): Observable<Source[]> {
    return this.store.select(selectAllWebSourcesOfSelectedEngine);
  }

  get webSourcesFolderOfSelectedEngine$(): Observable<Folder> {
    return this.store.select(selectWebSourcesFolderOfSelectedEngine);
  }

  get allNewsletterSourcesOfSelectedEngine$(): Observable<NewsletterSource[]> {
    return this.store.select(selectAllNewsletterSourcesOfSelectedEngine);
  }


  get newsletterSourcesFolderOfSelectedEngine$(): Observable<Folder> {
    return this.store.select(selectNewsletterSourcesFolderOfSelectedEngine);
  }

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  duplicateSource(currentEngineId: number, name: string, engineIdOfSource: number, sourceDuplicated: string): Promise<Source> {
    const source$ = this.http.post<Source>(
      `${environment.apiUrl2}/2.0/engines/${engineIdOfSource}/sources/${sourceDuplicated}/duplicate`,
      {
        name,
        toEngine: currentEngineId
      }
    );

    return firstValueFrom(source$)
      .then((res: Source) => {

        const sourceAfterProcessing: any = {
          ...res,
          name: undefined
        };

        // registering it inside store
        this.store.dispatch(new UpsertEmailSourceInSelectedEngineAction({ source: sourceAfterProcessing }));

        return sourceAfterProcessing;
      });
  }

  // Action is only used for API logging middleware
  createOrUpdateSource(engineId: number, createdSource: Source, action: SourceAction): Promise<Source> {
    const source$ = this.http.put<Source>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/sources?action=${action}`,
      {
        ...createdSource
      },
      {}
    );

    return firstValueFrom(source$)
      .then((source) => {
        if (source.sourceType === 'web') {
          this.store.dispatch(new UpsertWebSourceInSelectedEngineAction({ source }));
        } else {
          this.store.dispatch(new UpsertEmailSourceInSelectedEngineAction({ source }));
        }

        return {
          ...source
        };
      });
  }

  async createOrUpdateSourceSendingConfig(engineId: number, sourceId: string, sendingConfig: SendingConfiguration): Promise<SendingConfiguration> {
    return await firstValueFrom(this.http.put<SendingConfiguration>(
        `${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/sending-configuration`,
        { ...sendingConfig },
        {}
      )
    );
  }

  getSources(engineId: string|number): Promise<Source[]> {
    const sources$ = this.http.get<Source[]>(`${environment.apiUrl2}/2.0/engines/${engineId}/sources`);
    return firstValueFrom(sources$);
  }

  getSource(engineId: string|number, sourceName: string): Promise<Source> {
    const source$ = this.http.get<Source>(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceName}`);
    return firstValueFrom(source$);
  }

  deleteSource(engineId: number, source: Source): Promise<void> {
    const source$ = this.http.delete(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${source.id}`, {
      responseType: 'text'
    });

    return firstValueFrom(source$)
      .then(() => {
        if (source.sourceType === 'web') {
          this.store.dispatch(new DeleteWebSourceFromSelectedEngineAction({ id: source.id }));
        } else {
          this.store.dispatch(new DeleteEmailSourceFromSelectedEngineAction({ id: source.id }));
          this.store.dispatch(new DeleteNewsletterPermissionsInSelectedEngineAction({ id: source.id }));
        }
      });
  }
}
