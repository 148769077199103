import { createSelector } from '@ngrx/store';

import { CRMState } from '../reducers/crm.reducer';

import { selectCRMState } from './index';


export const selectContactsList = createSelector(
  selectCRMState,
  (state: CRMState) => state?.list
);
