<mat-form-field appearance="outline" fxFlex="0 0 100%">
  <mat-label >{{ label }}</mat-label>
  <mat-select multiple [formControl]="selectElement">

    <mat-checkbox class="mat-option" color="primary"
                  [checked]="isChecked"
                  [indeterminate]="isIndeterminate"
                  (change)="toggleAllElements($event.checked)"
                  *ngIf="withSelectAll">
      {{ 'COMMON.MULTI_SELECT.SELECT_ALL' | translate }}
    </mat-checkbox>

    <ng-container *ngIf="sortKey; else noSort">
      <mat-option *ngFor="let item of availableElements | orderByKey: sortKey; index as i" [value]="item">
        {{ displayWith ? displayWith(item, i) : item }}
      </mat-option>
    </ng-container>
    <ng-template #noSort>
      <mat-option *ngFor="let item of availableElements | orderByKey: sortKey; index as i" [value]="item">
        {{ displayWith ? displayWith(item, i) : item }}
      </mat-option>

    </ng-template>

  </mat-select>
</mat-form-field>



