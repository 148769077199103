<mat-form-field fxFlex="0 0 100%" [appearance]="appearance" subscriptSizing="dynamic">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row [removable]="true" (removed)="removePattern(i)"
              *ngFor="let pattern of patterns; let i = index" [attr.data-index]="i">
      {{ pattern }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>

    <input matInput [placeholder]="placeholder || ('COMMON.CHIP_MULTISELECT.PLACEHOLDER' | translate)"
           [disabled]="!multiple && patterns?.length > 0"
           [matChipInputFor]="chipGrid"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="true"
           (matChipInputTokenEnd)="addPattern($event)" />
  </mat-chip-grid>
  <mat-hint *ngIf="!multiple">{{ 'COMMON.CHIP_MULTISELECT.HINT.SINGLE_VALUE' | translate }}</mat-hint>
</mat-form-field>
