import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { QuicklinkStrategy } from 'ngx-quicklink';

import { AcceptInviteComponent }   from './main-module/views/accept-invite/accept-invite.component';
import { FirstSetupComponent }     from './main-module/views/first-setup/first-setup.component';
import { ForgotPasswordComponent } from './main-module/views/forgot-password/forgot-password.component';
import { LoginComponent }          from './main-module/views/login/login.component';
import { NoPermissionsComponent }  from './main-module/views/no-permissions/no-permissions.component';
import { PermissionsErrorComponent } from './main-module/views/permissions-error/permissions-error.component';
import { PlatformErrorComponent } from './main-module/views/platform-error/platform-error.component';

import { LogoutComponent } from './main-module/components/logout/logout.component';

import { AuthGuard, NotAuthGuard } from './main-module/guards/auth-guard.service';
import { CanCreateSegment }        from './main-module/guards/can-create-segment.guard';
import { CanDeactivateGuard }      from './main-module/guards/can-deactivate.guard';
import {
  HasEngines,
  HasNoEngines
}                                  from './main-module/guards/has-engines.guard';
import {
  HasNewsletterSources,
  HasWebSources
}                                  from './main-module/guards/has-sources.guard';
import { RedirectToHome }          from './main-module/guards/redirect-to-home.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard, RedirectToHome],
    component: NoPermissionsComponent
  },
  {
    path: 'user',
    loadChildren: () => import('./user-module/user.module').then(m => m.UserModule)
  },
  {
    path: 'monitoring',
    loadChildren: () => import('./monitoring-module/monitoring.module').then(m => m.MonitoringModule)
  },
  {
    path: 'accept-invite/:token',
    canActivate: [NotAuthGuard],
    component: AcceptInviteComponent
  },
  {
    path: 'login',
    canActivate: [NotAuthGuard],
    component: LoginComponent
  },
  {
    path: 'logout',
    canActivate: [AuthGuard],
    component: LogoutComponent
  },
  {
    path: 'password-reset',
    canActivate: [NotAuthGuard],
    component: ForgotPasswordComponent
  },
  {
    path: 'password-reset/:token',
    canActivate: [NotAuthGuard],
    component: ForgotPasswordComponent
  },
  {
    path: 'first-setup',
    canActivate: [AuthGuard, HasNoEngines],
    component: FirstSetupComponent,
    data: {
      breadcrumbs: ['MAIN.APP.SIDEBAR.FIRST_STEPS.TITLE']
    }
  },
  {
    path: 'platform-error',
    canActivate: [AuthGuard, HasNoEngines],
    component: PlatformErrorComponent,
    data: {
      breadcrumbs: ['MAIN.APP.SIDEBAR.PLATFORM_ERROR.TITLE']
    }
  },
  {
    path: 'permissions-error',
    canActivate: [AuthGuard, HasNoEngines],
    component: PermissionsErrorComponent,
    data: {
      breadcrumbs: ['MAIN.APP.SIDEBAR.PERMISSIONS_ERROR.TITLE']
    }
  },
  {
    path: 'web-personalisation',
    canActivate: [AuthGuard, HasEngines, HasWebSources],
    loadChildren: () => import('./web-personalisation-module/web-personalisation.module').then(m => m.WebPersonalisationModule),
    data: {
      breadcrumbs: ['MAIN.APP.SIDEBAR.WEB_PERSO.TITLE']
    }
  },
  {
    path: 'newsletters',
    canActivate: [AuthGuard, HasEngines, HasNewsletterSources],
    loadChildren: () => import('./newsletters-module/newsletters.module').then(m => m.NewslettersModule),
    data: {
      breadcrumbs: ['MAIN.APP.SIDEBAR.NEWSLETTERS.TITLE']
    }
  },
  {
    path: 'segments',
    canActivate: [AuthGuard, HasEngines],
    loadChildren: () => import('./segments-module/segments.module').then(m => m.SegmentsModule),
    data: {
      breadcrumbs: ['MAIN.APP.SIDEBAR.SEGMENTS.TITLE']
    }
  },
  {
    path: 'contents',
    canActivate: [AuthGuard, HasEngines],
    loadChildren: () => import('./configuration-contents-module/configuration-contents.module').then(m => m.ConfigurationContentsModule),
    data: {
      breadcrumbs: ['MAIN.APP.SIDEBAR.CONTENTS.TITLE']
    }
  },
  {
    path: 'configuration',
    canActivate: [AuthGuard, HasEngines],
    loadChildren: () => import('./configuration-module/configuration.module').then(m => m.ConfigurationModule),
    data: {
      breadcrumbs: ['MAIN.APP.SIDEBAR.CONFIGURATION.TITLE']
    }
  },
  {
    path: 'crm',
    canActivate: [AuthGuard, HasEngines],
    loadChildren: () => import('./crm-module/crm.module').then(m => m.CrmModule),
    data: {
      breadcrumbs: ['MAIN.APP.SIDEBAR.CRM.TITLE']
    }
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
    // preloadingStrategy: PreloadAllModules,
    // preloadingStrategy: NoPreloading,
    preloadingStrategy: QuicklinkStrategy,
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
    // enableTracing: true // leave this line here to conveniently set it to 'true' for debugging
}
    )
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    NotAuthGuard,
    HasEngines,
    HasNoEngines,
    HasWebSources,
    HasNewsletterSources,
    CanCreateSegment,
    CanDeactivateGuard,
    RedirectToHome
  ]
})
export class AppRoutingModule { }
