import { Injectable }                                                                from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AppService }                                                                from '../../services/app.service';

@Injectable()
export class RedirectToHome implements CanActivate {

  constructor(
    private router: Router,
    private appService: AppService
  ) { }

  private async redirectToFirstSetupIfNeeded(): Promise<UrlTree> {
    return this.appService.getEngines().then(engines => {
      if (engines?.length === 0) return this.router.parseUrl('/permissions-error');
    }).catch((err) => {
      console.error(err);
      return this.router.parseUrl('/platform-error');
    });
  }

  private async redirectToNewsletters(next: ActivatedRouteSnapshot): Promise<UrlTree | boolean> {

    const engines = await this.appService.getEngines();
    const engine = engines?.length === 1 && engines[0];

    const sourcesNewsletter = engine?.sources?.newsletters_all;

    if (sourcesNewsletter?.length > 0) {
      if (sourcesNewsletter?.length === 1 && sourcesNewsletter[0].id) {
        return this.router.createUrlTree(
          ['newsletters', sourcesNewsletter[0].id],
          { queryParams: next.queryParams }
          );
      } else {
        // homepage
        return this.router.createUrlTree(['newsletters'], { queryParams: next.queryParams });
      }
    }
  }

  private async redirectToBilling(next: ActivatedRouteSnapshot): Promise<UrlTree | boolean> {

    const engines = await this.appService.getEngines();
    const engine = engines?.length === 1 && engines[0];

    if (!!engine?.userPermissions?.configurations?.billing) {
      return this.router.createUrlTree(['monitoring', 'billing'], { queryParams: next.queryParams });
    }
  }

  private async redirectToWeb(next: ActivatedRouteSnapshot): Promise<UrlTree | boolean> {

    const engines = await this.appService.getEngines();
    const engine = engines?.length === 1 && engines[0];

    const sourcesWeb = engine?.sources?.web_all;

    if (sourcesWeb?.length > 0) {
      if (sourcesWeb?.length === 1 && sourcesWeb[0].id) {
        return this.router.createUrlTree(
          ['web-personalisation', sourcesWeb[0].id, 'stats'],
          { queryParams: next.queryParams }
        );
      } else {
        // homepage
        return this.router.createUrlTree(['web-personalisation'], { queryParams: next.queryParams });
      }
    }
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    const firstSetup = await this.redirectToFirstSetupIfNeeded();
    if (firstSetup) return firstSetup;

    const newsletters = await this.redirectToNewsletters(next);
    if (newsletters) return newsletters;

    const billing = await this.redirectToBilling(next);
    if (billing) return billing;

    const webs = await this.redirectToWeb(next);
    if (webs) return webs;

    return this.router.createUrlTree(['newsletters'], { queryParams: next.queryParams });
  }

}
