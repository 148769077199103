import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
}                                         from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { combineLatest, Subscription }     from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import dayjs, { Dayjs }                    from 'dayjs';

import { AppService }                      from 'app/services/app.service';
import { AuthService }                     from 'app/services/auth.service';
import { LayoutService }                   from 'app/services/layout.service';

import { DateSelectionInfo }               from 'app/mediego-common-module/declarations/date-selection-info';
import { IntervalType }                    from 'app/mediego-common-module/declarations/interval-type';

@Component({
  selector: 'app-mediego-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateSelectionComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes;

  minStartDate: Dayjs;
  maxStartDate: Dayjs;
  minEndDate: Dayjs;
  maxEndDate: Dayjs;

  mode: 'live' | 'period';
  @Input() allowLiveMode: boolean;

  startDate: Dayjs;
  endDate: Dayjs;

  availableIntervalTypes: string[] = [];
  @Input() intervalType: IntervalType;
  @Input() allowSwitchInterval: boolean = true;

  //////////////////////////// Events ////////////////////////////
  @Output() changed = new EventEmitter<DateSelectionInfo>();


  urlSubscription: Subscription;


  constructor(
    public appService: AppService,
    private authService: AuthService,
    public layoutService: LayoutService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      // recreate date objects to use new locale
      // .toDate() strips old object from locale
      this.startDate = dayjs(this.startDate.toDate());
      this.endDate = dayjs(this.endDate.toDate());
    });

    combineLatest(
      [
        this.layoutService.isMobile,
        this.layoutService.isLandscape
      ]
    )
      .pipe(
        map((__: boolean[]) => __[0] && !__[1]),
        distinctUntilChanged()
      )
      .subscribe((__: boolean) => {
        this.classes = __ ? 'mobile' : '';
      });

    this.mode = (this.allowLiveMode && this.startDate === undefined && this.endDate === undefined) ? 'live' : 'period';

    this.minStartDate = dayjs(new Date(2017, 3, 1));
    this.maxStartDate = dayjs(new Date());
    this.maxEndDate = this.maxStartDate;

    this.authService.getIsAdmin()
      .then((isAdmin) => {
        if (!isAdmin) {
          this.maxStartDate = this.maxStartDate.subtract(1, 'day');
        }
      });

    this.urlSubscription = this.route.queryParams
      .pipe(take(1))
      .subscribe((params: Params) => {
        if (params.mode) {
          if (params.mode === 'live' && this.allowLiveMode) {
            this.updateSelectedModeAndDates(params);
            this.reportChange();
          } else {
            this.updateSelectedModeAndDates(params);
            this.updateURL();
            this.reportChange();
          }
        } else {
          this.updateSelectedModeAndDates();
          this.updateURL();
          this.reportChange(); // FIXME: SHOULD REMOVE ???
        }
      });
  }

  ngOnDestroy() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
    }
  }

  private updateSelectedModeAndDates(params: Params = {}) {
    if (params.mode) {
      this.mode = this.allowLiveMode ? params.mode : 'period';
    }

    if (this.mode === 'live') {
      this.endDate = dayjs(new Date())
        .subtract(1, 'hour')
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0);
      this.startDate = this.endDate.subtract(24, 'hour');
    } else {
      const start: string[] = (params['startDate'] || '').split('-');
      const end: string[] = (params['endDate'] || '').split('-');
      if (start.length === 3 && end.length === 3) {
        this.startDate = dayjs(new Date(+start[0], +start[1] - 1, +start[2]))
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .set('millisecond', 0);
        this.endDate = dayjs(new Date(+end[0], +end[1] - 1, +end[2]))
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .set('millisecond', 0);
      } else {
        this.endDate = this.maxEndDate.clone();
        this.startDate = this.endDate.subtract(2, 'week');
      }
    }

    this.minEndDate = this.startDate.clone();

    this.updateIntervalTypeChoices();
  }

  private reportChange() {
    const info: DateSelectionInfo = {
      startDate: this.startDate,
      endDate: this.endDate,
      intervalType: this.intervalType
    };

    this.changed.next(info);
  }

  /* keep url params updated when user changes selections in UI */
  private updateURL() {
    const queryParams = {} as Params;

    if (this.mode === 'live') {
      queryParams['startDate'] = undefined;
      queryParams['endDate'] = undefined;
      queryParams['mode'] = 'live';
    } else {
      queryParams['startDate'] = this.startDate.format('YYYY-MM-DD');
      queryParams['endDate'] = this.endDate.format('YYYY-MM-DD');
      queryParams['mode'] = 'period';
    }

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.route,
      skipLocationChange: true
    });
  }

  onStartDateSelected() {
    this.minEndDate = this.startDate.clone();
    if (this.endDate.isBefore(this.minEndDate)) {
      this.endDate = this.startDate.clone();
    }

    this.updateIntervalTypeChoices();
    this.updateURL();
    this.reportChange();
  }

  onEndDateSelected() {
    this.updateIntervalTypeChoices();
    this.updateURL();
    this.reportChange();
  }

  onIntervalTypeChanged() {
    this.reportChange();
  }

  onModeChange() {
    this.updateSelectedModeAndDates();
    this.updateURL();
    this.reportChange();
  }

  /* restrict choices according to the selected duration */
  private updateIntervalTypeChoices() {
    if (this.mode === 'live') {
      this.intervalType = 'hour';
      return;
    }

    const diff = this.endDate.diff(this.startDate, 'day');

    if (!this.allowSwitchInterval) {
      this.availableIntervalTypes = [this.intervalType || 'day'];
    } else {


      if (diff >= 28 * 2) {
        this.availableIntervalTypes = ['day', 'week', 'month'];
        if (!['day', 'week', 'month'].includes(this.intervalType)) {
          this.intervalType = 'week';
        }
      } else if (diff >= 7 * 2) {
        this.availableIntervalTypes = ['day', 'week'];
        if (!['day', 'week'].includes(this.intervalType)) {
          this.intervalType = 'day';
        }
      } else {
        this.availableIntervalTypes = ['day'];
        this.intervalType = 'day';
      }
    }

    if (!this.intervalType) {
      this.intervalType = 'day';
    }
  }

}
