import { createAction, union } from '@ngrx/store';

export const resetNewsletter = createAction(
  '[NEWSLETTERS] Reset'
);

export const newsletterGlobalActions = union({
  resetNewsletter
});

export type NewsletterGlobalAction = typeof newsletterGlobalActions
