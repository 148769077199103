import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';

import { environment } from 'environments/environment';

export interface Mailer {
  senders?: string[];
  id: string;
  displayName: string;
  mailerType: string;
  engine: number;
}

const mailerTypesWithSpecificSendingConf: string[] = [
  'a7'
]

export function hasSpecificSendingConfiguration(mailer: Mailer): boolean {
  return mailerTypesWithSpecificSendingConf.indexOf(mailer.mailerType) !== -1
}

export interface FTPMailer extends Mailer {
  url: string;
  port: number;
  password: string;
  ftpContactLists: any[];
  emailToNotify: string;
}

export interface ContactList {
  id?: string;
  name: string;
  publicName?: string;
  description?: string;
}

export interface ContactImport {
  engineId: number;
  mailerId: string;
  filename: string;
  contactListId: string;
  nbContacts: number;
  dateBegin: number;
  dateEnd: number;
}

@Injectable({
  providedIn: 'root'
})
export class MailersProvider {

  static MANUAL_CONTACTS_MAILER_TYPES: string[] = [
    'a7'
  ];
  constructor(private http: HttpClient) {}

  getMailersForEngine(engineId: number): Promise<Mailer[]> {
    const mailers$ = this.http.get<Mailer[]>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/mailers`
    );
    return firstValueFrom(mailers$);
  }

  getContactsMailersForEngine(engineId: number): Promise<Mailer[]> {
    const mailers$ = this.http.get<Mailer[]>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/contacts-mailers`
    );
    return firstValueFrom(mailers$);
  }

  getSendingMailersForEngine(engineId: number): Promise<Mailer[]> {
    const mailers$ = this.http.get<Mailer[]>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/sending-mailers`
    );
    return firstValueFrom(mailers$);
  }

  deleteMailer(engineId: number, mailerId: string): Promise<void> {
    const mailer$ = this.http.delete<void>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/mailers/${mailerId}`
    );
    return firstValueFrom(mailer$);
  }

  getContactsListsForMailer(
    engineId: number,
    mailerId: string
  ): Promise<ContactList[]> {
    const lists$ = this.http.get<ContactList[]>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/mailers/${mailerId}/contacts/lists`
    );
    return firstValueFrom(lists$);
  }

  createOrUpdateMailer(engineId: number, mailer: Mailer): Promise<Mailer> {
    const mailers$ = this.http.put<Mailer>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/mailers`,
      mailer
    );
    return firstValueFrom(mailers$);
  }

  putContactListForFTPMailer(
    engineId: number,
    mailerId: string,
    contactList: ContactList
  ): Promise<ContactList> {
    const list$ = this.http.put<ContactList>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/ftp/${mailerId}/contact-list`,
      contactList
    );
    return firstValueFrom(list$);
  }

  importFtpFile(
    engineId: number,
    mailerId: string,
    contactListId: string,
    file: File
  ): Promise<void> {
    const file$ = this.http
      .post<void>(
        `${environment.apiUrl2}/2.0/engines/${engineId}/ftp/${mailerId}/import?fileName=${file.name}&contactListId=${contactListId}`,
        file,
        {
          headers: {
            'Content-Type': file.type
          },
          params: {
            clientFilename: file.name,
            mimeType: file.type
          }
        }
      );
    return firstValueFrom(file$);
  }

  getImportHistory(engineId: number, mailerId: string): Promise<ContactImport[]> {
    const history$ = this.http
      .get<ContactImport[]>(
        `${environment.apiUrl2}/2.0/engines/${engineId}/ftp/${mailerId}/import/history`
      );
    return firstValueFrom(history$);
  }
}
