import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ContactList } from '../../configuration-module/providers/mailers.provider';

import { environment } from '../../../environments/environment';

export interface ListConsent {
  timestamp: number;
}

export interface Contact {
  address: string;
  euid: string;
  metadata: {[key: string]: string|number|boolean};

  listsWithConsents?: {[list: string]: ListConsent};
  lists?: string[];
}

export interface ContactMetadataDescriptor {
  displayName: string;
  isOptional: boolean;
  metadataName: string;
  metadataType: {
    type: 'int' | 'boolean' | 'string' | 'select';
    choices?: {[choice: string]: unknown};
    min?: string;
    max?: string;
  };
}

export interface FiltersValue {
  filterType: string;
  metadata?: string;
  lists?: string[];
  value?: string;
  minValue?: number;
  maxValue?: number;
}

export type FiltersValues = FiltersValue[];

export interface ContactsQueryResponse {
  contacts: Contact[];
  contactsCount: number;
  metadataTypes: ContactMetadataDescriptor[];
}

@Injectable({
  providedIn: 'root'
})
export class ContactsProvider {
  constructor(private http: HttpClient) {
  }

  createList(engineId: number, name: string, filters: FiltersValues, publicName?: string, description?: string): Observable<{
    list: ContactList;
    nbContacst: number;
  }> {
    return this.http.post<{
      list: ContactList;
      nbContacst: number;
    }>(`${environment.apiUrl2}/2.0/engines/${engineId}/contacts/lists`, {
      list: {
        name,
        publicName,
        description
      },
      filters
    });
  }

  getAllLists(engineId: number): Observable<ContactList[]> {
    return this.http.get<ContactList[]>(`${environment.apiUrl2}/2.0/engines/${engineId}/contacts/lists`);
  }

  getMetadataDescriptors(engineId: number): Observable<ContactMetadataDescriptor[]> {
    return this.http.get<ContactMetadataDescriptor[]>(`${environment.apiUrl2}/2.0/engines/${engineId}/contacts/metadata`);
  }

  getAllContacts(engineId: number, page: number, size: number, filters?: FiltersValues): Observable<ContactsQueryResponse> {
    return this.http.post<ContactsQueryResponse>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/contacts?page=${page}&size=${size}`,
      filters
    );
  }

  getContactsOfList(engineId: number, listId: string, page: number, size: number, filters?: FiltersValues): Observable<ContactsQueryResponse> {

    if (!filters || filters.length === 0) {
      return this.http.get<ContactsQueryResponse>(
        `${environment.apiUrl2}/2.0/engines/${engineId}/contacts/lists/${listId}?page=${page}&size=${size}`
      );
    } else {

      const filtersWithListFilter: FiltersValues = [
        ...filters,
        {
          filterType: 'contacts-list',
          lists: [listId]
        } as FiltersValue
      ];

      return this.getAllContacts(engineId, page, size, filtersWithListFilter);

    }


  }

  createOrUpdateContact(engineId: number, contact: Contact): Observable<void> {

    const contactWithStringsMetadata = {
      ...contact,
      metadata: Object.entries(contact.metadata).reduce((res, [metadataName, metadataValue]) => {
        if (metadataValue !== null && metadataValue !== undefined) res[metadataName] = metadataValue + '';
        return res;
      }, {})
    };

    return this.http.put<void>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/contacts/`,
      contactWithStringsMetadata
    );
  }

  createOrUpdateManyContacts(engineId: number, bulkContacts: Contact[]): Observable<void> {

    const contactsWithoutConsent = bulkContacts.map((contact) => ({
      ...contact,
      lists: Object.keys(contact.listsWithConsents),
      listsWithConsents: undefined
    }));

    const contactsWithStringsMetadata = contactsWithoutConsent.map((contact) => ({
      ...contact,
      metadata: Object.entries(contact.metadata).reduce((res, [metadataName, metadataValue]) => {
        if (metadataValue !== null && metadataValue !== undefined) res[metadataName] = metadataValue + '';
        return res;
      }, {})
    }));

    return this.http.put<void>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/contacts/bulk`,
      contactsWithStringsMetadata
    );
  }

}
