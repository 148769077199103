import { TemplateModelUtils } from '../../utils/template-model.utils';
import { TemplateTrackingUtils } from '../../utils/template-tracking.utils';
import { CompilationContext, CompilationResult } from '../compilation';
import { ConstraintPattern, ContentType } from '../enums';

import { Content, ContentParameters, ContentParametersInfo, DEFAULT_ARTICLE_LINK } from './';

export interface TextContentParams extends ContentParameters {
  textContent: string;
  customTracking: string;
  disableEncoding: boolean;
}

export const TEXT_PARAMETERS_INFO: ContentParametersInfo<TextContentParams> = {
  textContent: {
    hiddenInSidebar: true,
    valueType: 'string'
  },
  customTracking: {
    valueType: 'string',
    enabledWithConstraints: [ConstraintPattern.HeatmapUTM, ConstraintPattern.SelligentTracking]
  },
  disableEncoding: {
    valueType: 'boolean',
    hint: true
  }
};

export const DEFAULT_TEXT_PARAMETERS: Content<TextContentParams>['params'] = {
  static: {
    textContent: `<p>Cliquez pour modifier le texte, et insérer <a href="https://example.org" target="_blank"><u>des liens</u></a> par exemple !</p>`,
    customTracking: '',
    disableEncoding: false
  },
  dynamic: {
    textContent: '{{ metadata.description }}',
    articleLink: DEFAULT_ARTICLE_LINK,
    customTracking: '',
    disableEncoding: false
  }
};

export type TextContent = Content<TextContentParams>;

export const compileToHtmlNodes = (content: TextContent, articleIndex: number, spaceBetweenParagraphs: number, context: CompilationContext): CompilationResult => {

  const result: CompilationResult = {
    nodes: [],
    context
  };

  if (content.unlockedForEditorial) {
    result.nodes = [document.createTextNode(TemplateModelUtils.compileContentUnlockedForEditorial(content.id))];
  } else if (content.articleRef) {
    // using wrappers to transmit context and check existence
    const startingWrapper: Text = document.createTextNode(`{{ #reco_${articleIndex} }}`);
    const endingWrapper: Text = document.createTextNode(`{{ /reco_${articleIndex} }}`);

    const htmlNodes = TemplateModelUtils.compileQuillOutputToHtmlNodes(
      content.params.dynamic.textContent,
      spaceBetweenParagraphs !== undefined ? spaceBetweenParagraphs : content.outerRenderingProperties.spaceBetweenParagraphs,
      content.params.dynamic.disableEncoding
    );

    let a_tag: HTMLAnchorElement;
    if (content.params.dynamic.articleLink) {

      let textLink = content.params.dynamic.articleLink;
      textLink += TemplateTrackingUtils.getMediegoTrackingParameter(context, content.params.dynamic, ContentType.Text, true, articleIndex);

      a_tag = document.createElement('a');
      a_tag.style.textDecoration = 'none';
      a_tag.style.color = 'black';
      a_tag.style.margin = '0';
      a_tag.target = '_blank';
      a_tag.href = textLink;

      if (content.params.dynamic.disableEncoding) a_tag.setAttribute('data-disable-tracking', 'true');

      TemplateTrackingUtils.setSelligentTrackingParameter(a_tag, context, content.params.dynamic, true, articleIndex);

      a_tag.append(...htmlNodes);
      result.nodes = [startingWrapper, a_tag, endingWrapper];
    } else {
      result.nodes = [startingWrapper, ...htmlNodes, endingWrapper];
    }

  } else {

    result.nodes = TemplateModelUtils.compileQuillOutputToHtmlNodes(
      content.params.static.textContent,
      spaceBetweenParagraphs !== undefined ? spaceBetweenParagraphs : content.outerRenderingProperties.spaceBetweenParagraphs,
      content.params.static.disableEncoding
    );
  }

  return result;
};
