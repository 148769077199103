import { CompilationContext, CompilationResult } from '../compilation';

import { Content, ContentParameters, ContentParametersInfo } from './';

export interface CodeContentParams extends ContentParameters {
  codeContent?: string;
}

export type CodeContent = Content & CodeContentParams;

export const CODE_PARAMETERS_INFO: ContentParametersInfo<CodeContentParams> = {
  codeContent: {
    valueType: 'html'
  }
};

export const DEFAULT_CODE_PARAMETERS: Content<CodeContentParams>['params'] = {
  static: { },
  dynamic: { },
  codeContent: ''
};

export const compileToHtmlNodes = (content: CodeContent, articleIndex: number, context: CompilationContext): CompilationResult => {

  const result: CompilationResult = {
    nodes: [],
    context
  };

  const div_tag: HTMLDivElement = document.createElement('div');

  // default placeholder allows CodeBlock to be visible for editing
  div_tag.innerHTML = content.params.codeContent || `<div style="min-height: 30px"></div>`;

  const mainNodes = Array.from(div_tag.childNodes);

  if (content.articleRef) {
    const startingWrapper: Text = document.createTextNode(`{{ #reco_${articleIndex} }}`);
    const endingWrapper: Text = document.createTextNode(`{{ /reco_${articleIndex} }}`);

    result.nodes = [startingWrapper, ...mainNodes, endingWrapper];
  } else {
    result.nodes = mainNodes;
  }

  return result;

};
