import { createAction, props, union } from '@ngrx/store';

import { TemplateHeader } from '../../../templater-module/declarations/template-model';

export const loadSubject = createAction(
  '[NEWSLETTERS][SUBJECT] Load',
  props<{ subject: TemplateHeader }>()
);

export const resetSubject = createAction(
  '[NEWSLETTERS][SUBJECT] Reset'
);

const templateSubjectActions = union({
  loadSubject,
  resetSubject
});

export type TemplateSubjectAction = typeof templateSubjectActions
