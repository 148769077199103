import { DecimalPipe }         from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const symbols = {
  '-8': 'y',
  '-7': 'z',
  '-6': 'a',
  '-5': 'f',
  '-4': 'p',
  '-3': 'n',
  '-2': 'µ',
  '-1': 'm',
  '0': '',
  '1': 'k',
  '2': 'M',
  '3': 'G',
  '4': 'T',
  '5': 'P',
  '6': 'E',
  '7': 'Z',
  '8': 'Y',
  '9': 'H' // Though not official, 'hella' is hella big → 10^(9*3) or 10^27
};

@Pipe({
  name: 'abbreviatedNumber'
})
export class AbbreviatedNumberPipe implements PipeTransform {

  /**
   * Converts big numbers into human-readable forms
   * @param {number} input
   * @param {string} locale
   * @returns {string}
   */
  transform(input: number, locale: string): string {
    // if we're working on a negative number, convert it to positive and then prefix the final result with a -
    const negative = input < 0;
    const num = Math.abs(input); // this also insures that we work on copy of input

    // SOURCE: https://jburrows.wordpress.com/2014/11/18/abbreviating-numbers/
    const exponent = Math.log(num) / Math.log(10);
    const magnitudeExp = Math.floor(exponent);
    const hasIntegerComponent = magnitudeExp >= 0;
    const sign = (hasIntegerComponent) ? -1 : 1;
    const adjustment = sign * (magnitudeExp % 3);
    const significand = num / Math.pow(10, magnitudeExp + adjustment);
    const symbolIndex = (hasIntegerComponent) ? Math.floor(magnitudeExp / 3).toString() : Math.ceil(magnitudeExp / 3).toString();
    // END SOURCE

    const numString = new DecimalPipe(locale).transform(significand, '1.0-1') + symbols[symbolIndex];

    if (negative) {
      return '-' + numString;
    } else {
      return numString;
    }
  }

}
