import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatStepper } from '@angular/material/stepper';

import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';

import { AppService } from '../../../../services/app.service';

import {
  Constraint,
  ConstraintFactory,
  ConstraintParameter, CONSTRAINTS_ITEMSET_ONLY
} from '../constraints';
import { constraintToString } from '../constraints-serdes';
import { AtLeastNItems, AtMostNItems } from '../constraints/global-constraints';
import { HasSourcesConstraintForItemset } from '../constraints/item-constraints';


interface MetadataFormUpdate {
  type: 'add' | 'delete';
  alterForm: boolean;
  controls?: any; // if not altering form, we will have a list of controls for update
  id?: string; // for dynamic metadata list, id is provided for convenience
}

@Component({
  selector: 'app-mediego-constraint',
  templateUrl: './constraint.component.html',
  styleUrls: ['./constraint.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConstraintComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;
  firstStepGroup: UntypedFormGroup;
  secondStepGroup: UntypedFormGroup;
  optConstraintGroup: UntypedFormGroup; // for constraint using another constraint
  dynamicallyCreatedFields: string[] = []; // each dynamic field will have an identifier

  selectedConstraint: Constraint = null;

  /** Whether constraints shown are global or not */
  @Input() constraintsFor: 'local' | 'global' | 'itemset' = 'local';

  @Input() existingConstraint: Constraint = null;
  @Input() index: number = -1;
  @Input() minimal: boolean = false;


  @Output() addConstraint: EventEmitter<Constraint> = new EventEmitter<Constraint>();
  @Output() updateConstraint: EventEmitter<Constraint> = new EventEmitter<Constraint>();
  @Output() removeConstraint: EventEmitter<void> = new EventEmitter<void>();

  @Input() expanded: boolean = false;

  @Input() constraintTypes: any[] = [];
  editingSubConstraint: boolean = false;

  constructor(private _fb: UntypedFormBuilder, private appService: AppService, private router: Router, private translate: TranslateService) { }

  ngOnInit() {

    this.init();

    if (this.existingConstraint) {
      this.populateForm(this.existingConstraint);
    }
  }

  private init() {
    this.firstStepGroup = this._fb.group({
      constraintType: ['', Validators.required]
    });

    this.secondStepGroup = this._fb.group({});

    this.selectedConstraint = null;
    if (this.existingConstraint) this.existingConstraint = { ...this.existingConstraint };
    this.dynamicallyCreatedFields = [];

    if (this.stepper) this.stepper.reset();

    // usually it's the CRUD which pass constraints to this component
    // (in order to filter unique constraint, maintain integrity)
    // for sub-constraints, as it's not the case, we're providing them here
    if (!this.constraintTypes || !this.constraintTypes.length) {
      switch (this.constraintsFor) {
        case 'itemset':
          this.constraintTypes = [...CONSTRAINTS_ITEMSET_ONLY.reduce((acc, constraint) => {
            return [...acc, { type: constraint.type, disabled: false }];
          }, [])];
          break;
        default:
          this.constraintTypes = [];
          break;
      }
    }


    // listening for user selection => triggering next step
    this.firstStepGroup.valueChanges.subscribe((value) => {
      if (value && value.constraintType && this.stepper) {

        const constraint: Constraint = ConstraintFactory.buildFromType(value.constraintType);

        if (constraint) {
          this.populateForm(constraint);
        } else {
          this.selectedConstraint = null;
          console.error('no constraint found for ', value);
          return;
        }

        // going to second step
        if (this.stepper) this.stepper.next();
      }
    });
  }

  private populateForm(constraint: Constraint) {

    let controls = {};

    // storing constraint for further uses
    this.selectedConstraint = constraint;
    this.dynamicallyCreatedFields = [];


    // default logic if there parameter matches an array
    const populateDynamicConstraintParameter = (name) => {
      const addMetadata = (key) => {
        const metadataAdd: MetadataFormUpdate = this.addField(name, constraint.parameters[name], false);
        controls = {
          ...controls,
          ...metadataAdd.controls
        };

        // assigning controls values
        controls[name + '-' + metadataAdd.id] = [key, Validators.required];
      };

      // adding a control for each metadata to check
      if (constraint.parameters[name].value && constraint.parameters[name].value.length) {
        for (const metadata of constraint.parameters[name].value) {
          addMetadata(metadata);
        }
      } else {
        // at least one field is shown (creating a new constraint probably)
        addMetadata('');
      }
    };

    // dynamically generating form group
    Object.entries(constraint.parameters).forEach(([name, parameter]: [string, ConstraintParameter]) => {
      if (parameter.type === 'custom') {
        // custom parameters are scalable
        if (name === 'withValue') {

          const metadataEntries = Object.entries(constraint.parameters['withValue'].value);

          const addMetadata = (key, value) => {
            const metadataAdd: MetadataFormUpdate = this.addField('withValue', constraint.parameters['withValue'], false);
            controls = {
              ...controls,
              ...metadataAdd.controls
            };

            // assigning controls values
            controls['withValue-name-' + metadataAdd.id] = [key, Validators.required];
            controls['withValue-val-' + metadataAdd.id] = [value, {}];
          };

          metadataEntries.forEach(([key, value]) => {
            addMetadata(key, value)
          });

          // if no metadata entry -> we're creating a new constraint
          if (metadataEntries.length === 0) {
            addMetadata('', '');
          }

        } else if (name === 'withCategory' || name === 'withMetadata' || name === 'withSource' || name === 'withURL') {

          populateDynamicConstraintParameter(name);

        } else if (name === 'unknown') {
          controls['unknown'] = [{ value: parameter.value, disabled: true }];
        } else {
          controls[name + '-0'] = [parameter.value, {}];
        }
      } else {
        // Standard parameter, not custom with a type (boolean, string, number, etc.)
        if (parameter.required) {
          controls[name] = [{ value: parameter.value, disabled: !!parameter.disabled }, Validators.required];
        } else {
          controls[name] = [{ value: parameter.value, disabled: !!parameter.disabled }];
        }
      }
    });

    this.secondStepGroup = this._fb.group(controls);
  }

  deleteField(parameterName: string, parameter: ConstraintParameter, field: string) {


    const removedFieldIndex = this.indexOfDynamicField(field);
    this.dynamicallyCreatedFields.splice(removedFieldIndex, 1);

    if (parameterName === 'exists-names') {
      setTimeout(() => {
        this.secondStepGroup.removeControl('exists-names-' + field);
        this.secondStepGroup.removeControl('exists-names-link-' + field);
      }, 100);
    } else if (parameterName === 'withValue') {
      setTimeout(() => {
        this.secondStepGroup.removeControl('with-value-name-' + field);
        this.secondStepGroup.removeControl('with-value-val-' + field);
      }, 100);
    } else if (parameterName === 'withCategory' || parameterName === 'withMetadata'
      || parameterName === 'withSource' || parameterName === 'withURL') {
      setTimeout(() => {
        this.secondStepGroup.removeControl(parameterName + '-' + field);
      }, 100);
    }

  }

  addField(parameterName: string, parameter: ConstraintParameter, alterForm: boolean = true): MetadataFormUpdate {

    const id = uuidv4();
    const controls = {};

    const add = (key, value) => {
      if (alterForm) {
        this.secondStepGroup.addControl(key, value);
      } else {
        controls[key] = value;
      }
    };

    if (parameterName === 'exists-names') {
      add(parameterName + '-' + id, new UntypedFormControl('', Validators.required));
      add(parameterName + '-link-' + id, new UntypedFormControl('AND', Validators.required));
    } else if (parameterName === 'withValue') {
      add(parameterName + '-name-' + id, new UntypedFormControl('', Validators.required));
      add(parameterName + '-val-' + id, new UntypedFormControl(''));
    } else if (parameterName === 'withCategory' || parameterName === 'withMetadata'
      || parameterName === 'withSource' || parameterName === 'withURL') {
      add(parameterName + '-' + id, new UntypedFormControl('', Validators.required));
    }

    this.dynamicallyCreatedFields = [...this.dynamicallyCreatedFields, id];

    return {
      type: 'add',
      alterForm,
      controls: alterForm ? undefined : controls,
      id
    };

  }

  fieldReady(name: string): boolean {
    return this.secondStepGroup && !!this.secondStepGroup.get(name);
  }

  indexOfDynamicField(field: string): number {
    if (this.dynamicallyCreatedFields) {
      return this.dynamicallyCreatedFields.indexOf(field);
    } else {
      return -1;
    }
  }

  constraintToText(constraint: Constraint): string {
    return constraintToString(constraint, this.translate);
  }

  constraintWithSources(constraint: Constraint): boolean {
    return constraint?.type === HasSourcesConstraintForItemset.type;
  }

  async openItemfeed(e: Event, itemfeedId: string) {

    const engineId = await this.appService.getSelectedEngine().then(_ => _.id);

    e.preventDefault();
    e.stopPropagation();
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/contents/itemfeeds`],
        {
          queryParams: {
            itemfeedId,
            engineId
          }
        })
    );
    window.open(url, '_blank');
  }


  containsEmptyDynamicField(): boolean {
    return this.secondStepGroup && Object.values(this.secondStepGroup.getRawValue()).includes('');
  }

  validNewConstraint(add: boolean = true) {

    const constraint = this.selectedConstraint;

    if (constraint && this.secondStepGroup) {

      // binding each parameter to user input
      // (omitting custom parameter type as it means we have a dynamic form)
      Object.entries(constraint.parameters).forEach(([paramName, param]: [string, ConstraintParameter]) => {
        if (param && param.type !== 'custom' && this.secondStepGroup.get(paramName)) {
          param.value = this.secondStepGroup.get(paramName).value;

          if (typeof param.value === 'string') param.value = param.value.trim();

          // cleaning input
          if (param.type === 'string' && param.value.includes(',')) {
            param.value = param.value.split(',').map(part => part && part.trim()).join(',');
          }

          // for parameters with select, we add negation inside select for ease-of-use
          // that means adding an exclamation mark in front of param.value (ex: !metadataContains)
          if (param.type === 'select' && param.viewChoicesNegated?.length) {
            const negationShortcutFound: boolean = param.value?.startsWith('!');
            constraint.negated = !!negationShortcutFound;
            if (negationShortcutFound) param.value = param.value.slice(1);
          }

        } else if (param && param.type === 'custom') {
          switch (paramName) {
            case 'withValue':
              if (this.dynamicallyCreatedFields && this.dynamicallyCreatedFields.length) {
                param.value = this.dynamicallyCreatedFields.reduce((res, field) => {

                  const fieldName = this.secondStepGroup.get('withValue-name-' + field);
                  const fieldValue = this.secondStepGroup.get('withValue-val-' + field);

                  if (fieldName && fieldValue && fieldName.value) {

                    // avoiding common user mistakes typing "" or ''
                    let fieldValueNormalized = fieldValue.value ? fieldValue.value?.trim() : '';
                    if (fieldValueNormalized === '""' || fieldValueNormalized === '\'\'') fieldValueNormalized = '';

                    return {
                      ...res,
                      [fieldName.value.trim()]: fieldValueNormalized
                    };
                  } else {
                    return res;
                  }
                }, {});
              }
              break;


            case 'withCategory':
              this.populateParameterValueWithDynamicFields(param, 'withCategory-');
              break;

            case 'withMetadata':
              this.populateParameterValueWithDynamicFields(param, 'withMetadata-');
              break;

            case 'withSource':
              this.populateParameterValueWithDynamicFields(param, 'withSource-');
              break;

            case 'withURL':
              this.populateParameterValueWithDynamicFields(param, 'withURL-');
              break;

            default:
              break;

          }
        }
      });

      if (add) {
        this.addConstraint.emit(constraint);
        this.expanded = false;
        this.init();
      } else {
        // update
        this.updateConstraint.emit(constraint);
        this.expanded = false;
      }
    }
  }

  private populateParameterValueWithDynamicFields(parameter, prefix: string): void {
    if (this.dynamicallyCreatedFields && this.dynamicallyCreatedFields.length) {
      parameter.value = this.dynamicallyCreatedFields.reduce((res, field) => {

        const fieldURL = this.secondStepGroup.get(prefix + field).value;

        if (fieldURL) {
          return [
            ...res,
            fieldURL
          ];
        } else {
          return res;
        }
      }, []);
    }
  }

  updateOldConstraint() {
    this.validNewConstraint(false);
  }

  deleteOldConstraint() {
    this.removeConstraint.emit();
  }

  attachSubConstraint(subConstraint: Constraint) {
    this.editingSubConstraint = false;

    if (this.selectedConstraint &&
      (this.selectedConstraint.type === AtLeastNItems.type || this.selectedConstraint.type === AtMostNItems.type)) {

      // attaching subconstraint
      this.selectedConstraint.parameters['withConstraint'].value = subConstraint;
    }
  }

  switchExpand() {
    if (!this.minimal) this.expanded = !this.expanded;
  }

  switchNegated(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.selectedConstraint.negated = !this.selectedConstraint.negated;
    this.updateOldConstraint();
  }

  get canValidConstraint(): boolean {
    return !this.selectedConstraint.configurable || (this.secondStepGroup && this.secondStepGroup.valid);
  }

}
