import { createSelector } from '@ngrx/store';

import { TemplateModelUtils }            from '../../utils/template-model.utils';
import { TemplaterState }                from '../index';
import { articlesAdapter, ArticleState } from '../reducers/template-articles.reducer';

import { selectContentEntities }         from './contents.selectors';
import { selectTemplater }               from './index';
import { selectLayoutEntities }          from './layouts.selectors';
import { selectSTemplateModel }          from './template-model.selectors';

const {
  // selectIds,
  selectEntities,
  // selectAll,
  selectTotal
} = articlesAdapter.getSelectors();

export const selectTemplateArticleState = createSelector(
  selectTemplater,
  (state: TemplaterState) => state.articles
);

export const selectArticleEntities = createSelector(
  selectTemplateArticleState,
  (state: ArticleState) => selectEntities(state)
);

export const selectContentsOfArticle = createSelector(
  selectSTemplateModel,
  selectLayoutEntities,
  selectContentEntities,
  (model, layouts, contents, { articleId }) => {
    if (model && layouts && contents) {

      const contentsMatchingArticle: string[] = []; // list of content ids
      Object.keys(contents).forEach((id) => {
        if (contents[id] && contents[id].articleRef === articleId) {
          contentsMatchingArticle.push(id);
        }
      });

      return contentsMatchingArticle;
    } else {
      return [];
    }
  }
);

export const selectSortedArticleIds = createSelector(
  selectSTemplateModel,
  selectLayoutEntities,
  selectContentEntities,
  (model, layouts, contents) =>
    model ? TemplateModelUtils.selectSortedArticleIdsInModel(layouts, contents, model) : []
);

export const selectSortedArticles = createSelector(
  selectArticleEntities,
  selectSortedArticleIds,
  (articles, ids) => {
    return ids.reduce((res, id) => {
      const optArticle = articles[id];

      if (optArticle) {
        return [...res, optArticle];
      } else {
        return res;
      }
    }, []);
  }
);

export const selectNbOfArticles = createSelector(
  selectTemplateArticleState,
  selectTotal
);
