<mat-card class="constraint-card" [class.add-constraint-card]="!existingConstraint" [class.expanded]="expanded">
  <mat-card-header *ngIf="!minimal" (click)="switchExpand()" fxFlex="100%">
    <mat-card-title *ngIf="!existingConstraint || expanded">
      <ng-container *ngIf="existingConstraint; else newConstraint">
        {{ 'COMMON.ENTITY.CONSTRAINT' | translate }} #{{ index + 1}}
      </ng-container>
      <ng-template #newConstraint>
        <mat-icon color="primary">playlist_add</mat-icon> {{ 'COMMON.CONSTRAINTS.NEW' | translate }}
      </ng-template>
    </mat-card-title>
    <mat-card-subtitle *ngIf="!expanded && existingConstraint" fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxFlex="0 0 80" class="constraint-text">
        <strong>{{ index + 1 }} -</strong>&nbsp;
        <span [innerHTML]="constraintToText(existingConstraint)"></span>
        <ul *ngIf="constraintWithSources(existingConstraint)" class="sources-list">
          <li *ngFor="let source of existingConstraint.parameters.withSource.value"
              (click)="openItemfeed($event, source)">
            {{ source }}
          </li>
        </ul>

        <!-- Icon to negate/un-negate constraint -->
        <ng-container *ngIf="existingConstraint && existingConstraint.supportsNegation">
          <mat-icon *ngIf="selectedConstraint.negated === false; else negatedStatus"
                    (click)="switchNegated($event)"
                    [matTooltip]="'COMMON.CONSTRAINTS.REVERT.TOOLTIP' | translate"
                    color="accent">sync</mat-icon>
          <ng-template #negatedStatus>
            <mat-icon (click)="switchNegated($event)"
                      [matTooltip]="'COMMON.CONSTRAINTS.NORMALIZE.TOOLTIP' | translate">sync_problem</mat-icon>
          </ng-template>
        </ng-container>

      </div>
      <div fxFlex="0 0 20" class="constraint-actions">
        <mat-icon *ngIf="!selectedConstraint.mandatory" (click)="deleteOldConstraint()" color="warn">delete</mat-icon>
      </div>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>


    <mat-stepper *ngIf="!existingConstraint && expanded; else constraintForm" #stepper orientation="vertical" linear>

      <!-- STEP 1: constraint type selection -->
      <!-- no need for first step if this component is linked to an existing constraint -->
      <mat-step [stepControl]="firstStepGroup" state="constraintChoice">
        <form [formGroup]="firstStepGroup">
          <ng-template matStepLabel>
            {{
            (selectedConstraint ?
              'COMMON.CONSTRAINTS.' + selectedConstraint.type : 'COMMON.CONSTRAINTS.CHOICE') | translate
            }}
          </ng-template>

          <mat-form-field>
            <mat-label>{{ 'COMMON.CONSTRAINTS.TYPE' | translate }}</mat-label>
            <mat-select formControlName="constraintType">
              <mat-option *ngFor="let constraint of constraintTypes" [disabled]="constraint.disabled" [value]="constraint.type">
                {{ 'COMMON.CONSTRAINTS.' + constraint.type | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </form>
      </mat-step>

      <!-- STEP 2: constraint settings -->
      <mat-step [stepControl]="secondStepGroup" state="constraintSettings">
        <form [formGroup]="secondStepGroup">
          <ng-template matStepLabel>{{ 'COMMON.CONSTRAINTS.PARAMETERS' | translate }}</ng-template>

          <ng-container *ngTemplateOutlet="constraintSettings;context:{form:secondStepGroup, constraint:selectedConstraint}"></ng-container>

        </form>
      </mat-step>
    </mat-stepper>

    <ng-template #constraintForm>
      <form *ngIf="expanded" [formGroup]="secondStepGroup">
        <ng-template matStepLabel>{{ 'COMMON.CONSTRAINTS.PARAMETERS' | translate }}</ng-template>

        <ng-container *ngTemplateOutlet="constraintSettings;context:{form:secondStepGroup, constraint:selectedConstraint}"></ng-container>

      </form>
    </ng-template>



  </mat-card-content>
  <mat-card-actions fxLayoutAlign="end center" *ngIf="expanded">
    <button mat-flat-button *ngIf="!existingConstraint && selectedConstraint" [disabled]="canValidConstraint === false" (click)="validNewConstraint(true)" color="primary">{{ 'SHARED.BUTTONS.ADD' | translate }}</button>

    <ng-container *ngIf="existingConstraint">
      <button mat-button *ngIf="!selectedConstraint.mandatory" (click)="deleteOldConstraint()" color="warn">{{ 'SHARED.BUTTONS.DELETE' | translate }}</button>
      <button mat-button *ngIf="selectedConstraint.type !== 'unknown'" (click)="updateOldConstraint()">{{ 'SHARED.BUTTONS.SAVE' | translate }}</button>
    </ng-container>
  </mat-card-actions>
</mat-card>




<ng-template #constraintSettings let-form="form" let-constraint="constraint">

  <div *ngIf="constraint?.parameters"
       [formGroup]="form"
       fxLayout="row wrap" fxLayoutAlign="center center"
       class="constraint"
       [class.constraint-inline]="constraint?.parametersInline">

    <p *ngIf="constraint.description">{{ 'COMMON.CONSTRAINTS.' + constraint.type + '.DESCRIPTION' | translate }}</p>

    <div *ngFor="let parameterKeyValue of constraint.parameters | keyvalue"
         [fxFlex]="constraint?.parametersInline ? 'none' : '0 0 100%'"
         class="constraint-parameter">

      <ng-container *ngVar="parameterKeyValue.value as parameter" [ngSwitch]="parameter.type">

        <!-- STRING -->
        <div *ngSwitchCase="'string'">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.CONSTRAINTS.' + constraint.type + '.PARAMETER.' + parameterKeyValue.key | translate }}</mat-label>
            <input matInput [formControlName]="parameterKeyValue.key" type="text">
            <mat-hint *ngIf="parameter.hint">{{ 'COMMON.CONSTRAINTS.' + constraint.type + '.PARAMETER.' + parameterKeyValue.key + '.HINT' | translate }}</mat-hint>
          </mat-form-field>
        </div>
        <!-- END STRING -->

        <!-- NUMBER -->
        <div *ngSwitchCase="'number'">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.CONSTRAINTS.' + constraint.type + '.PARAMETER.' + parameterKeyValue.key | translate }}</mat-label>
            <input matInput [formControlName]="parameterKeyValue.key" type="number" min="1" max="999" step="1">
            <mat-hint *ngIf="parameter.hint">{{ 'COMMON.CONSTRAINTS.' + constraint.type + '.PARAMETER.' + parameterKeyValue.key + '.HINT' | translate }}</mat-hint>
          </mat-form-field>
        </div>
        <!-- END NUMBER -->

        <!-- BOOLEAN -->
        <div *ngSwitchCase="'boolean'">
          <mat-checkbox [formControlName]="parameterKeyValue.key">
            {{ 'COMMON.CONSTRAINTS.' + constraint.type + '.PARAMETER.' + parameterKeyValue.key | translate }}
          </mat-checkbox>
        </div>
        <!-- END BOOLEAN -->

        <!-- TOGGLE -->
        <div *ngSwitchCase="'toggle'">
          <mat-button-toggle-group [formControlName]="parameterKeyValue.key" [value]="parameter.value">
            <mat-button-toggle *ngFor="let choice of parameter.choices; let k = index" selected [value]="choice">
              {{ 'COMMON.CONSTRAINTS.' + constraint.type + '.PARAMETER.' + parameterKeyValue.key + '.' + parameter.viewChoices[k] | translate }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div *ngSwitchCase="'select'">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.CONSTRAINTS.' + constraint.type + '.PARAMETER.' + parameterKeyValue.key | translate }}</mat-label>
            <mat-select [formControlName]="parameterKeyValue.key">

              <ng-container *ngIf="parameter?.viewChoicesNegated?.length; else noNegations">
                <ng-container *ngFor="let opt of parameter.choices; let i = index">
                  <mat-option [value]="opt">{{ 'COMMON.CONSTRAINTS.' + constraint.type + '.PARAMETER.' + parameterKeyValue.key + '.' + parameter.viewChoices[i] | translate }}</mat-option>
                  <mat-option [value]="'!' + opt">{{ 'COMMON.CONSTRAINTS.' + constraint.type + '.PARAMETER.' + parameterKeyValue.key + '.' + parameter.viewChoicesNegated[i] | translate }}</mat-option>
                </ng-container>
              </ng-container>

              <ng-template #noNegations>
                <mat-option *ngFor="let opt of parameter.choices; let i = index" [value]="opt">
                  {{ 'COMMON.CONSTRAINTS.' + constraint.type + '.PARAMETER.' + parameterKeyValue.key + '.' + parameter.viewChoices[i] | translate }}
                </mat-option>
              </ng-template>

            </mat-select>
          </mat-form-field>
        </div>

        <!-- CUSTOM -->
        <div *ngSwitchCase="'custom'">

          <ng-container [ngSwitch]="parameterKeyValue.key">

            <ng-container *ngSwitchCase="'unknown'">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'COMMON.CONSTRAINTS.' + constraint.type + '.PARAMETER.' + parameterKeyValue.key | translate }}</mat-label>
                <input matInput [formControlName]="parameterKeyValue.key" type="text">
                <mat-hint *ngIf="parameter.hint">{{ parameter.hint }}</mat-hint>
              </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'withValue'">

              <div *ngFor="let field of dynamicallyCreatedFields; let k = index" fxLayout="row" fxLayoutAlign="center center">

                <mat-form-field *ngIf="fieldReady('withValue-name-' + field)" fxFlex="0 0 40%" appearance="outline">
                  <mat-label>{{ 'COMMON.CONSTRAINTS.METADATA.NAME' | translate }}</mat-label>
                  <input matInput [formControlName]="'withValue-name-' + field" type="text">
                </mat-form-field>

                <mat-form-field *ngIf="fieldReady('withValue-val-' + field)" fxFlex="0 0 40%" appearance="outline">
                  <mat-label>{{ 'COMMON.CONSTRAINTS.METADATA.VALUE' | translate }}</mat-label>
                  <input matInput [formControlName]="'withValue-val-' + field" type="text">
                </mat-form-field>

              </div>

            </ng-container>

            <ng-container *ngSwitchCase="'withMetadata'">

              <div *ngFor="let field of dynamicallyCreatedFields; let k = index" fxLayout="row" fxLayoutAlign="center center">

                <div fxFlex="0 0 10%" class="dynamic-field-index">
                  #{{ k + 1 }}
                </div>

                <mat-form-field fxFlex="0 0 60%" appearance="outline">
                  <mat-label>{{ 'COMMON.ENTITY.METADATA' | translate }}</mat-label>
                  <input matInput [formControlName]="'withMetadata-' + field" type="text">
                </mat-form-field>

                <!-- <div fxFlex="0 0 10%" class="dynamic-field-index">
                  <button *ngIf="dynamicallyCreatedFields.length > 1" mat-icon-button color="warn"
                          (click)="deleteField(parameterKeyValue.key, parameter, field)"
                          [matTooltip]="'COMMON.CONSTRAINTS.METADATA.DELETE' | translate">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>-->

              </div>

              <!--<button [disabled]="containsEmptyDynamicField()"
                      (click)="addField(parameterKeyValue.key, parameter)"
                      mat-flat-button>
                {{ 'COMMON.CONSTRAINTS.METADATA.ADD' | translate }}
              </button>-->

            </ng-container>

            <ng-container *ngSwitchCase="'withCategory'">

              <div *ngFor="let field of dynamicallyCreatedFields; let k = index" fxLayout="row" fxLayoutAlign="center center">

                <div fxFlex="0 0 10%" class="dynamic-field-index">
                  #{{ k + 1 }}
                </div>

                <mat-form-field fxFlex="0 0 60%" appearance="outline">
                  <mat-label>{{ 'COMMON.ENTITY.CATEGORY' | translate }}</mat-label>
                  <input matInput [formControlName]="'withCategory-' + field" type="text">
                </mat-form-field>

                <div fxFlex="0 0 10%" class="dynamic-field-index">
                  <button *ngIf="dynamicallyCreatedFields.length > 1"
                          (click)="deleteField(parameterKeyValue.key, parameter, field)"
                          mat-icon-button color="warn"
                          [matTooltip]="'COMMON.CONSTRAINTS.CATEGORY.DELETE' | translate">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

              </div>

              <button mat-flat-button
                      [disabled]="containsEmptyDynamicField()"
                      (click)="addField(parameterKeyValue.key, parameter)">
                {{ 'COMMON.CONSTRAINTS.CATEGORY.ADD' | translate }}
              </button>

            </ng-container>

            <ng-container *ngSwitchCase="'withURL'">

              <div *ngFor="let field of dynamicallyCreatedFields; let k = index" fxLayout="row" fxLayoutAlign="center center">

                <div fxFlex="0 0 10%" class="dynamic-field-index">
                  #{{ k + 1 }}
                </div>

                <mat-form-field fxFlex="0 0 60%" appearance="outline">
                  <mat-label>{{ 'COMMON.ENTITY.URL' | translate }}</mat-label>
                  <input matInput [formControlName]="'withURL-' + field" type="text">
                </mat-form-field>

                <div fxFlex="0 0 10%" class="dynamic-field-index">
                  <button *ngIf="dynamicallyCreatedFields.length > 1"
                          (click)="deleteField(parameterKeyValue.key, parameter, field)"
                          mat-icon-button color="warn" [matTooltip]="'COMMON.CONSTRAINTS.URL.DELETE' | translate">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

              </div>

              <button mat-flat-button
                      [disabled]="containsEmptyDynamicField()"
                      (click)="addField(parameterKeyValue.key, parameter)">
                {{ 'COMMON.CONSTRAINTS.URL.ADD' | translate }}
              </button>

            </ng-container>


            <ng-container *ngSwitchCase="'withSource'">

              <div *ngFor="let field of dynamicallyCreatedFields; let k = index" fxLayout="row" fxLayoutAlign="center center">

                <div fxFlex="0 0 10%" class="dynamic-field-index">
                  #{{ k + 1 }}
                </div>

                <mat-form-field fxFlex="0 0 60%" appearance="outline">
                  <mat-label>{{ 'COMMON.ENTITY.ITEMFEED' | translate }}</mat-label>
                  <input matInput [formControlName]="'withSource-' + field" type="text">
                </mat-form-field>

                <div fxFlex="0 0 10%" class="dynamic-field-index">
                  <button *ngIf="dynamicallyCreatedFields.length > 1"
                          (click)="deleteField(parameterKeyValue.key, parameter, field)"
                          mat-icon-button color="warn" [matTooltip]="'COMMON.CONSTRAINTS.ITEMFEED.DELETE' | translate">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

              </div>

              <button [disabled]="containsEmptyDynamicField()"
                      (click)="addField(parameterKeyValue.key, parameter)"
                      mat-flat-button>
                {{ 'COMMON.CONSTRAINTS.ITEMFEED.ADD' | translate }}
              </button>

            </ng-container>

            <ng-container *ngSwitchCase="'exists-names'">


              <div fxLayout="row">

                <mat-form-field fxFlexOffset="25%" fxFlex="0 0 50%" appearance="outline">
                  <mat-label>{{ 'COMMON.ENTITY.METADATA' | translate }} n°1</mat-label>
                  <input matInput formControlName="exists-names-0" type="text">
                </mat-form-field>

              </div>

              <div *ngFor="let field of dynamicallyCreatedFields; let k = index" fxLayout="row" fxLayoutAlign="center center">

                <mat-form-field fxFlex="0 0 10%" appearance="outline">
                  <mat-label>{{ 'COMMON.WORDS.LINK' | translate }}</mat-label>
                  <mat-select [formControlName]="'exists-names-link-' + field">
                    <mat-option value="AND">{{ 'COMMON.WORDS.AND' | translate }}</mat-option>
                    <mat-option value="OR">{{ 'COMMON.WORDS.OR' | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="0 0 50%" appearance="outline">
                  <mat-label>{{ 'COMMON.ENTITY.METADATA' | translate }} n°{{ k + 2 }}</mat-label>
                  <input matInput [formControlName]="'exists-names-' + field" type="text">
                </mat-form-field>

                <div fxFlex="0 0 10%">
                  <button *ngIf="dynamicallyCreatedFields.length > 1"
                          (click)="deleteField(parameterKeyValue.key, parameter, field)"
                          mat-icon-button color="warn" [matTooltip]="'COMMON.CONSTRAINTS.METADATA.DELETE' | translate">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

              </div>

              <button mat-flat-button (click)="addField(parameterKeyValue.key, parameter)">
                {{ 'COMMON.CONSTRAINTS.METADATA.ADD.MANDATORY' | translate }}
              </button>

            </ng-container>

            <ng-container *ngSwitchCase="'withConstraint'">

              <!-- no sub constraint yet, CREATE -->
              <app-mediego-constraint *ngIf="!constraint.parameters['withConstraint'].value; else readOrUpdateSubConstraint"
                                      constraintsFor="itemset"
                                      (addConstraint)="attachSubConstraint($event)"
                                      [expanded]="true"
                                      [minimal]="true">
              </app-mediego-constraint>

              <ng-template #readOrUpdateSubConstraint>
                <!-- UPDATE -->
                <ng-container *ngIf="editingSubConstraint; else readSubConstraint">
                  <app-mediego-constraint [existingConstraint]="constraint.parameters['withConstraint'].value"
                                          constraintsFor="itemset"
                                          (updateConstraint)="attachSubConstraint($event)"
                                          [expanded]="true"
                                          [minimal]="true"
                                          [index]="0">
                  </app-mediego-constraint>
                </ng-container>

                <!-- READ -->
                <ng-template #readSubConstraint>
                  <app-mediego-constraint-readonly [constraint]="constraint.parameters['withConstraint'].value">
                  </app-mediego-constraint-readonly>

                  <!-- button allowing for UPDATE -->
                  <button mat-icon-button (click)="editingSubConstraint = true" color="primary">
                    <mat-icon>settings</mat-icon>
                  </button>
                </ng-template>
              </ng-template>

              <!-- End of custom parameter 'withConstraint' -->
            </ng-container>

          </ng-container>

        </div>
        <!-- END CUSTOM -->
        <div *ngSwitchDefault>
          {{ 'COMMON.CONSTRAINTS.UNRECOGNIZED' | translate }}
        </div>

      </ng-container>

    </div> <!-- End .constraint-parameter -->

  </div>

</ng-template>
