import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { TooltipPosition } from '@angular/material/tooltip';


@Component({
  selector: 'app-mediego-fab-cta-button',
  templateUrl: './fab-call-to-action-button.component.html',
  styleUrls: ['./fab-call-to-action-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FabCallToActionButtonComponent {

  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() icon: string = '';
  @Input() tooltip?: string = '';
  @Input() tooltipPosition?: TooltipPosition;
  @Input() disabled?: boolean = false;
  @Input() loading?: boolean = false;
  @Input() externalPosition?: boolean = false;

  @Input() disabledDelay?: number = 0;

  @Input() badge?: string | number = '';

  @HostBinding('class.externalPosition') get isExternalPosition() {
    return !!this.externalPosition;
  }
}
