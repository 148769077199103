import { RGB, RGBA } from 'ngx-color';

export const rgbArrayToObject = (array: number[]): RGB => ({ r: array[0], g: array[1], b: array[2] });

export const rgbArrayToHexString = (rgb_array: number[]): string => {
  return '#' + rgb_array.map((x) => {
    const hex = x.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }).join('');
};

export const rgbToHexString = ({ r, g, b }: RGB | RGBA): string => {
  return rgbArrayToHexString([r, g, b]);
};

export const rgbToString = (color: RGB): string => {
  if (color && color.r !== undefined && color.g !== undefined && color.b !== undefined) {
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
  } else {
    return '';
  }
};

export const hexStringToRgba = (hex: string): RGBA => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: 1
    } : null;
};

export const rgbaToString = (color: RGBA): string => {
  if (color && color.r !== undefined && color.g !== undefined && color.b !== undefined) {
    if (color.a !== undefined) {
      return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    } else {
      return `rgb(${color.r}, ${color.g}, ${color.b})`;
    }
  } else {
    return '';
  }
};

export const rgbArrayToString = (rgb: number[], a?: number): string => {
  if (a === undefined) {
    return rgbToString(rgbArrayToObject(rgb));
  } else {
    return rgbaToString({ ...rgbArrayToObject(rgb), a });
  }
};

/**
 * Converts an HSV color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes h, s, and v are contained in the range [0, 1] and
 * returns r, g, and b in the range [0, 255].
 *
 * @param h   hue
 * @param s   saturation
 * @param v   value
 * @return  Array   the RGB representation
 */
export const hsvToRgb = (h, s, v): number[] => {
  let r;
  let g;
  let b;

  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0: r = v; g = t; b = p; break;
    case 1: r = q; g = v; b = p; break;
    case 2: r = p; g = v; b = t; break;
    case 3: r = p; g = q; b = v; break;
    case 4: r = t; g = p; b = v; break;
    case 5: r = v; g = p; b = q; break;
    default: break;
  }

  return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
};

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSL representation
 */
export const rgbToHsl = (r, g, b) => {
  r /= 255; g /= 255; b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h; let s; const l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }

    h /= 6;
  }

  return [h, s, l];
};

function hue2rgb(p, q, t) {
  if (t < 0) { t += 1; }
  if (t > 1) { t -= 1; }
  if (t < 1 / 6) { return p + (q - p) * 6 * t; }
  if (t < 1 / 2) { return q; }
  if (t < 2 / 3) { return p + (q - p) * (2 / 3 - t) * 6; }
  return p;
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  l       The lightness
 * @return  Array           The RGB representation
 */
export const hslToRgb = (h, s, l) => {
  let r; let g; let b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r * 255, g * 255, b * 255];
};

export const RGB_ARRAY_MEDIEGO_TEAL: number[] = [93, 189, 172];
export const RGB_ARRAY_WHITE: number[] = [255, 255, 255];
export const RGB_WHITE: RGB = rgbArrayToObject(RGB_ARRAY_WHITE);
export const RGBA_BLACK: RGBA = { r: 0, g: 0, b: 0, a: 1 };
export const RGBA_TRANSPARENT: RGBA = { r: 255, g: 255, b: 255, a: 0 };
