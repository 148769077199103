import _ from 'lodash/fp';
import merge from 'lodash/merge';

import { TemplateModelUtils } from '../utils/template-model.utils';

import { CompilationContext, CompilationResult } from './compilation';
import { DEFAULT_OUTER_RENDERING_PROPERTIES } from './constants/outer-rendering-styles';
import { Content, duplicateContent, isContent } from './content';
import { ContentType, LayoutType, SizeUnit, VerticalAlignment } from './enums';
import {
  applyBorderRadius,
  applyStyles,
  OuterRenderingProperties,
  RenderingProperties
} from './rendering-properties';
import { ModelElement, ParentElement } from './structure';
import { BoxRule } from './styles';

export const DEFAULT_LAYOUT_RENDERING_PROPERTIES: RenderingProperties = {
  ...DEFAULT_OUTER_RENDERING_PROPERTIES,
  responsive: true,
  layoutGap: 0,
  verticalAlign: VerticalAlignment.Top,
  backgroundColor: { r: 255, g: 255, b: 255, a: 0 },
  borderRadius: {
    sameForAllCorners: true,
    topLeft: 0,
    topRight: 0,
    bottomLeft: 0,
    bottomRight: 0
  }
};

export interface Layout extends ModelElement, ParentElement {
  type: LayoutType;
  children: Array<Layout | Content>;
  renderingProperties: Partial<OuterRenderingProperties>;
  renderingCondition?: string[];
  customBlockRef?: CustomBlockRef;
}

export interface CustomBlockRef {
  id: string;
  childrenId: string | null;
  // immediate child of custom blocks will be mapped with a childrenId
  // non immediate child will have null as tracking changes on 2-level depth or more
  // starts to be more complicated than recreating a news structure
}

export const isLayout = (obj: any): obj is Layout => {
  return obj && obj.hasOwnProperty('type') && Object.values(LayoutType).includes(obj.type);
};

export const isSuperLayout = (layout: Layout): boolean => {
  return layout.size && layout.size.width && layout.size.width.unit === SizeUnit.PX;
};

export type ArticleMapping = { [index: string]: string }

// articleIds is a map with key = existing article id / value = new article id for duplicated layouts
export const duplicateLayout = (layout: Layout, parentRef?: string, articleIds: ArticleMapping = {}): {layout: Layout, articleIds: ArticleMapping } => {
  const newId = TemplateModelUtils.create_UUID();

  const duplicatedLayout: Layout = {
    ...layout,
    id: newId,
    parentRef: parentRef || layout.parentRef,
    renderingProperties: merge({}, DEFAULT_LAYOUT_RENDERING_PROPERTIES, layout.renderingProperties),
    children: layout.children.map((child) => {
      if (isLayout(child)) {
        return duplicateLayout(child, newId, articleIds).layout;
      } else if (isContent(child)) {
        return duplicateContent(child, newId, articleIds);
      } else {
        console.warn(`child ${child} is neither layout nor Content`);
        return child;
      }
    })
  } as Layout;

  return {
    layout: duplicatedLayout,
    articleIds: articleIds
  }
};

export const compileLayoutToHtmlNodes = (layout: Layout,
                                         context: CompilationContext): CompilationResult => {

  const result: CompilationResult = {
    nodes: [],
    context
  };

  const table = document.createElement('table') as HTMLTableElement;
  table.classList.add('mdg-lt');
  table.classList.add(`mdg-${layout.type}`);
  if (layout.renderingProperties.responsive) {
    table.classList.add('r');
  }

  table.width = '100%';
  table.style.width = '100%';
  table.style.borderCollapse = 'collapse';
  table.style.borderSpacing = '0';
  table.cellPadding = '0';
  table.cellSpacing = '0';
  // table.border = '0';
  applyBorderRadius(layout.renderingProperties.borderRadius, table);
  table.style.border = 'none';

  const verticalAlignOfLayout: VerticalAlignment = layout.renderingProperties.verticalAlign;

  switch (layout.type) {
    case LayoutType.Row:
      const only_tr = document.createElement('tr') as HTMLTableRowElement;

      layout.children.forEach((childElement: Layout | Content, index: number) => {
        const child_td = document.createElement('td') as HTMLTableCellElement; // We use <th> instead of <td> because iOS does not support display:block on <td>

        child_td.classList.add('mdg-row');
        applyStyles(child_td, childElement.size || {});
        child_td.style.boxSizing = 'border-box';

        // Add child element
        if (childElement.type !== ContentType.Empty) {
          if (isContent(childElement)) {
            const { backgroundColor, verticalAlign, textAlign, borderRadius } = childElement.outerRenderingProperties;
            applyStyles(child_td, { backgroundColor, textAlign, verticalAlign, borderRadius });
          } else if (isLayout(childElement)) {
            applyStyles(child_td, childElement.renderingProperties);
          } else {
            throw Error('childElement is neither layout nor content');
          }

          if (layout.renderingProperties.layoutGap !== undefined) {
            if (index !== 0) {
              child_td.style.paddingLeft = `${layout.renderingProperties.layoutGap / 2}px`;
            }
            if (index !== layout.children.length - 1) {
              child_td.style.paddingRight = `${layout.renderingProperties.layoutGap / 2}px`;
            }
          }

          TemplateModelUtils
            .compileElementToHtmlNodes(childElement, context)
            .nodes
            .forEach((node) =>
              child_td.appendChild(node)
            );
        }

        only_tr.appendChild(child_td);
      });

      if (verticalAlignOfLayout) only_tr.style.verticalAlign = verticalAlignOfLayout;
      table.appendChild(only_tr);
      break;

    case LayoutType.Column:
      layout.children.forEach((childElement: Layout | Content) => {
        const child_tr = document.createElement('tr') as HTMLTableRowElement;

        const only_td = document.createElement('td') as HTMLTableCellElement;

        applyStyles(child_tr, childElement.size || {});
        only_td.style.boxSizing = 'border-box';
        only_td.width = '100%';

        let tdReceivingChilElements = only_td;

        // Add child element
        if (childElement.type !== ContentType.Empty) {
          if (isContent(childElement)) {
            const { backgroundColor, verticalAlign, textAlign } = childElement.outerRenderingProperties;
            applyStyles(only_td, { backgroundColor, verticalAlign, textAlign });
          } else if (isLayout(childElement)) {

            // element margins are converted to paddings applied on an intermediate container
            if (childElement?.renderingProperties?.margins.left
                || childElement?.renderingProperties?.margins.right) {

              const subTableToApplyMargins = document.createElement('table') as HTMLTableElement;
              const subTrToApplyMargins = document.createElement('tr') as HTMLTableRowElement;
              const subTdToApplyMargins = document.createElement('td') as HTMLTableCellElement;

              const marginOfLayout: BoxRule = { ...childElement.renderingProperties.margins, top: 0, bottom: 0 };
              applyStyles(only_td, { paddings: marginOfLayout });

              const propertiesWithoutMargins = _.cloneDeep(childElement.renderingProperties);
              propertiesWithoutMargins.margins.left = 0;
              propertiesWithoutMargins.margins.right = 0;

              subTdToApplyMargins.style.boxSizing = 'border-box';
              subTdToApplyMargins.width = '100%';
              applyStyles(subTdToApplyMargins, propertiesWithoutMargins);

              subTableToApplyMargins.style.borderCollapse = 'collapse';
              subTableToApplyMargins.style.width = '100%';

              // attach the intermediate table to the main td representing our element
              subTrToApplyMargins.appendChild(subTdToApplyMargins);
              subTableToApplyMargins.appendChild(subTrToApplyMargins);
              only_td.appendChild(subTableToApplyMargins);

              // at the end, the sub td becomes the main td
              tdReceivingChilElements = subTdToApplyMargins;

            } else {
              applyStyles(only_td, childElement.renderingProperties);
            }


          } else {
            throw Error('childElement is neither layout nor content');
          }

          TemplateModelUtils
            .compileElementToHtmlNodes(childElement, context)
            .nodes
            .forEach((node) => {
              tdReceivingChilElements.appendChild(node)
            });
        }

        child_tr.appendChild(only_td);


        if (!isLayout(childElement) || !childElement || !childElement.renderingCondition || !childElement.renderingCondition.length) {
          table.appendChild(child_tr);
        } else {
          // considering rendering conditions as mutually exclusive

          /// rendering condition can be a single day 'isMonday' or an array of days ['isMonday', 'isTuesday']s
          if (typeof childElement.renderingCondition === 'string') {
            table.appendChild(document.createTextNode(`{{#${childElement.renderingCondition}}}`));
            table.appendChild(child_tr.cloneNode(true));
            table.appendChild(document.createTextNode(`{{/${childElement.renderingCondition}}}`));
          } else {
            childElement.renderingCondition.forEach(condition => {
              table.appendChild(document.createTextNode(`{{#${condition}}}`));
              table.appendChild(child_tr.cloneNode(true));
              table.appendChild(document.createTextNode(`{{/${condition}}}`));
            });
          }
        }
      });
      break;

    default:
      throw Error('Unknown layout type ' + layout.type);
  }

  if (isSuperLayout(layout)) {
    // Add table to support Outlook
    const beginOutlookTable = document.createComment(`[if gte mso 9]><table width="${layout.size.width.value}"><tr><td><![endif]`); // TODO : set margin
    // Close Outlook specific table
    const endOutlookTable = document.createComment(`[if gte mso 9]></td></tr></table><![endif]`);

    result.nodes = [beginOutlookTable, table, endOutlookTable];
  } else {
    result.nodes = [table];
  }

  return result;

};
