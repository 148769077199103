import { BlockBlueprint, ContentBlueprint }                  from '../blueprints';
import { Content, ContentParametersInfo }                    from '../content';
import { BUTTON_PARAMETERS_INFO, DEFAULT_BUTTON_PARAMETERS } from '../content/button.content';
import { CODE_PARAMETERS_INFO, DEFAULT_CODE_PARAMETERS } from '../content/code.content';
import { DEFAULT_IMAGE_PARAMETERS, IMAGE_PARAMETERS_INFO }   from '../content/image.content';
import { DEFAULT_POWERSPACE_PARAMETERS, POWERSPACE_PARAMETERS_INFO } from '../content/powerspace.content';
import { DEFAULT_TEXT_PARAMETERS, TEXT_PARAMETERS_INFO }     from '../content/text.content';
import { DEFAULT_TITLE_PARAMETERS, TITLE_PARAMETERS_INFO }   from '../content/title.content';
import { ContentType, LayoutType, SizeUnit }                 from '../enums';
import { RenderingProperties }                               from '../rendering-properties';
import { TemplateVersion }                                   from '../template-model';

import {
  DEFAULT_BUTTON_INNER_STYLES,
  DEFAULT_IMAGE_INNER_STYLES,
  DEFAULT_TEXT_INNER_STYLES,
  DEFAULT_TITLE_INNER_STYLES
} from './inner-rendering-styles';
import {
  DEFAULT_BUTTON_OUTER_STYLES,
  DEFAULT_EMPTY_OUTER_STYLES,
  DEFAULT_IMAGE_OUTER_STYLES,
  DEFAULT_OUTER_RENDERING_PROPERTIES,
  DEFAULT_TEXT_OUTER_STYLES,
  DEFAULT_TITLE_OUTER_STYLES
} from './outer-rendering-styles';

export const SUPPORTED_TEMPLATE_VERSION: TemplateVersion = {
  release: 1,
  structure: 3,
  style: 4
};

// export const NO_CONTENT: NoContentBlueprint = { type: 'no-content' };

export const CONTENTS: { [type: string]: ContentBlueprint } = {
  [ContentType.Empty]: {
    type: ContentType.Empty,
    name: 'Vide'
  },
  [ContentType.Image]: {
    type: ContentType.Image,
    name: 'Image',
    description: 'Image',
    icon: 'insert_photo',
    outerRenderingProperties: {
      borderRadius: { sameForAllCorners: true, topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 }
    }
  },
  [ContentType.Text]: {
    type: ContentType.Text,
    name: 'Text',
    description: 'Paragraphe',
    icon: 'notes'
  },
  [ContentType.Title]: {
    type: ContentType.Title,
    name: 'Titre',
    description: 'Titre',
    icon: 'title'
  },
  [ContentType.Button]: {
    type: ContentType.Button,
    name: 'Bouton',
    description: 'Bouton page externe',
    icon: 'touch_app'
  },
  [ContentType.Code]: {
    type: ContentType.Code,
    name: 'Code',
    description: 'HTML personnalisé',
    icon: 'code'
  },
  [ContentType.Spacer]: {
    type: ContentType.Spacer,
    name: 'Espace',
    description: 'Espace vide',
    icon: 'vertical_distribute',
    outerRenderingProperties: {
      paddings: { top: 15, bottom: 15, left: 0, right: 0 }
    }
  },
  [ContentType.Powerspace]: {
    type: ContentType.Powerspace,
    name: 'Powerspace',
    description: 'Powerspace Ad',
    icon: 'euro'
  }
};

export const DEFAULT_CONTENT_INNER_STYLES: { [type: string]: RenderingProperties } = {
  [ContentType.Empty]: {},
  [ContentType.Image]: DEFAULT_IMAGE_INNER_STYLES,
  [ContentType.Text]: DEFAULT_TEXT_INNER_STYLES,
  [ContentType.Title]: DEFAULT_TITLE_INNER_STYLES,
  [ContentType.Button]: DEFAULT_BUTTON_INNER_STYLES,
  [ContentType.Code]: {},
  [ContentType.Powerspace]: DEFAULT_IMAGE_INNER_STYLES,
  [ContentType.Spacer]: {}
};

export const DEFAULT_CONTENT_OUTER_STYLES: { [type: string]: RenderingProperties } = {
  [ContentType.Empty]: DEFAULT_EMPTY_OUTER_STYLES,
  [ContentType.Image]: DEFAULT_IMAGE_OUTER_STYLES,
  [ContentType.Text]: DEFAULT_TEXT_OUTER_STYLES,
  [ContentType.Title]: DEFAULT_TITLE_OUTER_STYLES,
  [ContentType.Button]: DEFAULT_BUTTON_OUTER_STYLES,
  [ContentType.Code]: DEFAULT_OUTER_RENDERING_PROPERTIES,
  [ContentType.Powerspace]: DEFAULT_IMAGE_OUTER_STYLES,
  [ContentType.Spacer]: DEFAULT_OUTER_RENDERING_PROPERTIES
};

export const DEFAULT_CONTENT_PARAMETERS: { [type: string]: Content['params'] } = {
  [ContentType.Title]: DEFAULT_TITLE_PARAMETERS,
  [ContentType.Text]: DEFAULT_TEXT_PARAMETERS,
  [ContentType.Image]: DEFAULT_IMAGE_PARAMETERS,
  [ContentType.Button]: DEFAULT_BUTTON_PARAMETERS,
  [ContentType.Code]: DEFAULT_CODE_PARAMETERS,
  [ContentType.Powerspace]: DEFAULT_POWERSPACE_PARAMETERS,
  [ContentType.Spacer]: { static: {}, dynamic: {} },
  [ContentType.Empty]: { static: {}, dynamic: {} }
};

export const CONTENT_PARAMETERS_INFO: { [type: string]: ContentParametersInfo } = {
  [ContentType.Title]: TITLE_PARAMETERS_INFO,
  [ContentType.Text]: TEXT_PARAMETERS_INFO,
  [ContentType.Image]: IMAGE_PARAMETERS_INFO,
  [ContentType.Button]: BUTTON_PARAMETERS_INFO,
  [ContentType.Code]: CODE_PARAMETERS_INFO,
  [ContentType.Powerspace]: POWERSPACE_PARAMETERS_INFO,
  [ContentType.Spacer]: {},
  [ContentType.Empty]: {}
};

const APPLY_LINK_PARAMETER = {
  valueType: 'url',
  debounced: true
};

export const CONTENT_PARAMETERS_INFO_WITH_GLOBALS: { [type: string]: ContentParametersInfo } =
  Object
    .entries(CONTENT_PARAMETERS_INFO)
    .reduce((res, [type, params]) => {
      res[type] = { ...params, articleLink: { ...APPLY_LINK_PARAMETER } };
      return res;
    }, {});

export const RAW_BLOCKS: BlockBlueprint[] = [
  {
    type: LayoutType.Row,
    description: '1col',
    children: [CONTENTS[ContentType.Empty]]
  },
  {
    type: LayoutType.Row,
    description: '2col',
    children: [
      { ...CONTENTS[ContentType.Empty], width: { value: 50, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Empty], width: { value: 50, unit: SizeUnit.PERCENT } }
    ]
  },
  {
    type: LayoutType.Row,
    description: '3col',
    children: [
      { ...CONTENTS[ContentType.Empty], width: { value: 33.33, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Empty], width: { value: 33.33, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Empty], width: { value: 33.34, unit: SizeUnit.PERCENT } }
    ]
  },
  {
    type: LayoutType.Row,
    description: '4col',
    children: [
      { ...CONTENTS[ContentType.Empty], width: { value: 25, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Empty], width: { value: 25, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Empty], width: { value: 25, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Empty], width: { value: 25, unit: SizeUnit.PERCENT } }
    ]
  },
  {
    type: LayoutType.Row,
    description: '2col2row',
    children: [
      {
        type: LayoutType.Column,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty]]
      },
      {
        type: LayoutType.Column,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty]]
      }
    ]
  },
  {
    type: LayoutType.Row,
    description: '3col2row',
    children: [
      {
        type: LayoutType.Column,
        width: { value: 33.33, unit: SizeUnit.PERCENT },
        children: [CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty]]
      },
      {
        type: LayoutType.Column,
        width: { value: 33.34, unit: SizeUnit.PERCENT },
        children: [CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty]]
      },
      {
        type: LayoutType.Column,
        width: { value: 33.33, unit: SizeUnit.PERCENT },
        children: [CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty]]
      }
    ]
  },
  {
    type: LayoutType.Row,
    description: '1col_1col2row',
    children: [
      { ...CONTENTS[ContentType.Empty], width: { value: 40, unit: SizeUnit.PERCENT } },
      {
        type: LayoutType.Column,
        width: { value: 60, unit: SizeUnit.PERCENT },
        children: [CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty]]
      }
    ]
  },
  {
    type: LayoutType.Row,
    description: '1col2row_1col',
    children: [
      {
        type: LayoutType.Column,
        width: { value: 60, unit: SizeUnit.PERCENT },
        children: [CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty]]
      },
      { ...CONTENTS[ContentType.Empty], width: { value: 40, unit: SizeUnit.PERCENT } }
    ]
  },
  {
    type: LayoutType.Row,
    description: '1col_1col3row',
    children: [
      { ...CONTENTS[ContentType.Empty], width: { value: 40, unit: SizeUnit.PERCENT } },
      {
        type: LayoutType.Column,
        width: { value: 60, unit: SizeUnit.PERCENT },
        children: [CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty]]
      }
    ]
  },
  {
    type: LayoutType.Row,
    description: '2col3row',
    children: [
      {
        type: LayoutType.Column,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty]]
      },
      {
        type: LayoutType.Column,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty], CONTENTS[ContentType.Empty]]
      }
    ]
  }
  /*{
    type: LayoutType.Row,
    description: 'Une colonne (1/3) une colonne (2/3)',
    children: [
      { ...CONTENTS[ContentType.Empty], width: { value: 33.33, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Empty], width: { value: 66.67, unit: SizeUnit.PERCENT } }
    ]
  },*/
  /*{
    type: LayoutType.Row,
    description: 'Une colonne (2/3) une colonne (1/3)',
    children: [
      { ...CONTENTS[ContentType.Empty], width: { value: 66.67, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Empty], width: { value: 33.33, unit: SizeUnit.PERCENT } }
    ]
  },*/
  /*{
    type: LayoutType.Row,
    description: 'Une colonne (1/4) une colonne (2/4) une colonne (1/4)',
    children: [
      { ...CONTENTS[ContentType.Empty], width: { value: 25, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Empty], width: { value: 50, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Empty], width: { value: 25, unit: SizeUnit.PERCENT } }
    ]
  }*/
];

export const ARTICLE_BLOCKS: BlockBlueprint[] = [
  {
    type: LayoutType.Row,
    articleIndex: 0,
    children: [
      { ...CONTENTS[ContentType.Image], width: { value: 40, unit: SizeUnit.PERCENT } },
      {
        type: LayoutType.Column,
        width: { value: 60, unit: SizeUnit.PERCENT },
        children: [
          { ...CONTENTS[ContentType.Title], height: { value: 40, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Text], height: { value: 60, unit: SizeUnit.PERCENT } }
        ]
      }
    ]
  },
  {
    type: LayoutType.Row,
    articleIndex: 0,
    children: [
      {
        type: LayoutType.Column,
        width: { value: 60, unit: SizeUnit.PERCENT },
        children: [
          { ...CONTENTS[ContentType.Title], height: { value: 40, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Text], height: { value: 60, unit: SizeUnit.PERCENT } }
        ]
      },
      { ...CONTENTS[ContentType.Image], width: { value: 40, unit: SizeUnit.PERCENT } }
    ]
  },
  {
    type: LayoutType.Column,
    articleIndex: 0,
    children: [
      { ...CONTENTS[ContentType.Title], height: { value: 60, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Text], height: { value: 40, unit: SizeUnit.PERCENT } }
    ]
  },
  {
    type: LayoutType.Row,
    children: [
      {
        type: LayoutType.Column,
        width: { value: 50, unit: SizeUnit.PERCENT },
        articleIndex: 0,
        children: [
          { ...CONTENTS[ContentType.Title], height: { value: 60, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Text], height: { value: 40, unit: SizeUnit.PERCENT } }
        ]
      },
      {
        type: LayoutType.Column,
        width: { value: 50, unit: SizeUnit.PERCENT },
        articleIndex: 1,
        children: [
          { ...CONTENTS[ContentType.Title], height: { value: 60, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Text], height: { value: 40, unit: SizeUnit.PERCENT } }
        ]
      }
    ]
  },
  {
    type: LayoutType.Row,
    children: [
      {
        type: LayoutType.Column,
        articleIndex: 0,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [
          { ...CONTENTS[ContentType.Image], height: { value: 70, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Title], height: { value: 30, unit: SizeUnit.PERCENT } }
        ]
      },
      {
        type: LayoutType.Column,
        articleIndex: 1,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [
          { ...CONTENTS[ContentType.Image], height: { value: 70, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Title], height: { value: 30, unit: SizeUnit.PERCENT } }
        ]
      }
    ]
  },
  {
    type: LayoutType.Row,
    children: [
      {
        type: LayoutType.Column,
        articleIndex: 0,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [
          { ...CONTENTS[ContentType.Title], height: { value: 30, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Image], height: { value: 70, unit: SizeUnit.PERCENT } }
        ]
      },
      {
        type: LayoutType.Column,
        articleIndex: 1,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [
          { ...CONTENTS[ContentType.Title], height: { value: 30, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Image], height: { value: 70, unit: SizeUnit.PERCENT } }
        ]
      }
    ]
  },
  {
    type: LayoutType.Column,
    articleIndex: 0,
    children: [
      { ...CONTENTS[ContentType.Image], height: { value: 50, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Title], height: { value: 20, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Text], height: { value: 30, unit: SizeUnit.PERCENT } }
    ]
  },
  {
    type: LayoutType.Column,
    articleIndex: 0,
    children: [
      { ...CONTENTS[ContentType.Title], height: { value: 20, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Image], height: { value: 50, unit: SizeUnit.PERCENT } },
      { ...CONTENTS[ContentType.Text], height: { value: 30, unit: SizeUnit.PERCENT } }
    ]
  },
  {
    type: LayoutType.Row,
    children: [
      {
        type: LayoutType.Column,
        articleIndex: 0,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [
          { ...CONTENTS[ContentType.Image], height: { value: 50, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Title], height: { value: 20, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Button], height: { value: 30, unit: SizeUnit.PERCENT } }
        ]
      },
      {
        type: LayoutType.Column,
        articleIndex: 1,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [
          { ...CONTENTS[ContentType.Image], height: { value: 50, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Title], height: { value: 20, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Button], height: { value: 30, unit: SizeUnit.PERCENT } }
        ]
      }
    ]
  },
  {
    type: LayoutType.Row,
    children: [
      {
        type: LayoutType.Column,
        articleIndex: 0,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [
          { ...CONTENTS[ContentType.Title], width: { value: 20, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Image], width: { value: 50, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Button], width: { value: 30, unit: SizeUnit.PERCENT } }
        ]
      },
      {
        type: LayoutType.Column,
        articleIndex: 1,
        width: { value: 50, unit: SizeUnit.PERCENT },
        children: [
          { ...CONTENTS[ContentType.Title], width: { value: 20, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Image], width: { value: 50, unit: SizeUnit.PERCENT } },
          { ...CONTENTS[ContentType.Button], width: { value: 30, unit: SizeUnit.PERCENT } }
        ]
      }
    ]
  }
];
