import { Constraint } from '../constraints';

export class CategoryInUserPreferencesConstraint implements Constraint {
  static type = 'categoryInUserPreferences';
  static disabled = false;
  static supportsMultiple = false;
  type = 'categoryInUserPreferences';
  disabled = false;
  configurable = false;
  supportsNegation = true;
  negated = false;
  priority = 3;
  parameters = {
    'augmentWithUserPreferences': {
      type: 'boolean',
      description: true,
      disabled: true,
      value: true
    }
  };
}

export class AlreadyRecommendedConstraint implements Constraint {
  static type = 'alreadyRecommended';
  static disabled = false;
  static supportsMultiple = false;
  type = 'alreadyRecommended';
  disabled = false;
  configurable = false;
  supportsNegation = true;
  negated = true;
  priority = 3;
  parameters = {
    'never-shown': {
      type: 'boolean',
      disabled: true,
      description: true,
      value: true
    }
  };
}

export class MandatoryArticleConstraint implements Constraint {
  static type = 'mandatory';
  static disabled = false;
  static supportsMultiple = false;
  type = 'mandatory';
  disabled = false;
  configurable = true;
  mandatory = true;
  supportsNegation = false;
  negated = false;
  priority = 1;
  parameters = {
    'isMandatory': {
      type: 'boolean',
      disabled: false,
      description: true,
      value: true
    }
  };
}
