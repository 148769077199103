import { Injectable }                                               from '@angular/core';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot }      from '@angular/router';

import { routerReducer, RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';
import { ActionReducerMap }               from '@ngrx/store';

import { ConfigurationState }             from '../../configuration-module/state';
import { NewslettersState }               from '../../newsletters-module/state';
import { TemplaterState }                 from '../../templater-module/state';

import { AuthEffects }                    from './effects/auth.effects';
import { EnginesEffects }                 from './effects/engines.effects';
import { authReducer, AuthState }                                   from './reducers/auth.reducer';
import { crmReducer, CRMState } from './reducers/crm.reducer';
import { defaultReducer }                 from './reducers/default.reducer';
import { enginesReducer, EnginesState }   from './reducers/engines.reducer';
import { settingsReducer, SettingsState } from './reducers/settings.reducer';

export const APP_REDUCERS: ActionReducerMap<AppState> = {
  router: routerReducer,
  auth: authReducer,
  engines: enginesReducer,
  settings: settingsReducer,
  crm: crmReducer,

  newsletters: defaultReducer,
  templater: defaultReducer,
  configuration: defaultReducer
};

export const APP_EFFECTS: any[] = [
  AuthEffects,
  EnginesEffects
];

export interface UndoableState<T> {
  past: T[];
  present: T;
  future: T[];
}

export interface AppState {
  router: RouterReducerState<CustomRouterState>;
  auth: AuthState;
  engines: EnginesState;
  settings: SettingsState;
  crm: CRMState;

  newsletters: NewslettersState;
  templater: TemplaterState;
  configuration: ConfigurationState;
}

export interface CustomRouterState {
  route: ActivatedRouteSnapshot;
  url: string;
  params: Params;
  queryParams: Params;
}

const KEY_SOURCES_NAVIGATION_HISTORY = 'mdg_history';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<CustomRouterState> {
  serialize(routerState: RouterStateSnapshot): CustomRouterState {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;

    // registering route accessed
    if (params && params.source) {

      const rawHistory = localStorage.getItem(KEY_SOURCES_NAVIGATION_HISTORY);
      let parsedHistory: string[] = (rawHistory && JSON.parse(rawHistory)) || [];

      if (parsedHistory.includes(params.source)) {
        parsedHistory = parsedHistory.filter((source) => source !== params.source);
      }

      parsedHistory.unshift(params.source); // adding current source
      // saving to localStorage
      localStorage.setItem(KEY_SOURCES_NAVIGATION_HISTORY, JSON.stringify(parsedHistory.slice(0, 100)));
    }

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { route, url, params, queryParams };
  }
}
