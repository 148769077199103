<mat-card [ngSwitch]="status | async" fxFlex="100%" fxLayout="column">
  <ng-container *ngSwitchCase="'ready'">
    <div class="title">{{ chartTitle }}</div>

    <div fxLayout="column" fxLayout.gt-lg="row" fxLayoutAlign="center center">
      <!--Chart section-->
      <div class="chartContainer">
        <canvas baseChart
                chartType="pie"
                [datasets]="datasets"
                [labels]="labels"
                [options]="_options"
                [colors]="colors">
        </canvas>
      </div>

      <!--Details section-->
      <div class="detailsSection" fxLayout="column" fxLayoutGap="25px">
        <div class="totalDiv" fxLayout="row" fxLayout.gt-lg="column" fxLayoutGap="5px">
          <span class="totalNumber">{{ _total | number }}</span>
          <span class="totalLabel">{{ totalLabel }}</span>
        </div>
        <mat-grid-list [cols]="nbOfCols" [rowHeight]="rowHeight" gutterSize="30px">
          <mat-grid-tile *ngFor="let dataPoint of _dataPoints; let i = index" [attr.data-index]="i"
                         [style.border-left-color]="dataPoint.color" [class.active]="i === _activeIndex"
                         matTooltip="{{ dataPoint.label }}: {{ dataPoint.value | number }} ({{ dataPoint.fraction | percent }})">
            <span class="dataPointValue">{{ dataPoint.value | abbreviatedNumber:(locale$ | async) }}</span>
            <span class="dataPointLabel">{{ dataPoint.label }}</span>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'loading'">
    <div class="title">
      <svg xmlns="http://www.w3.org/2000/svg" width="315px" height="10px" preserveAspectRatio="xMidYMid slice">
        <rect x="0" y="0" width="315" height="10" rx="3" ry="3" fill="#e5ebee"></rect>
      </svg>
    </div>

    <div fxLayout="column" fxLayout.gt-lg="row" fxLayoutAlign="center center">
      <!--Chart section-->
      <div class="chartContainer">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 472 472">
          <g>
            <path fill="#e5ebee" d="m236.49601,381a144.21,144.21 0 0 1 -111.5,-52.63l-70.8,55.63a234,234 0 0 0 182.3,87c7.5,0 14.91,-0.37 22.24,-1.06l-10.24,-89.44c-3.96,0.33 -7.96,0.5 -12,0.5z"></path>
            <path fill="#e5ebee" d="m91.99601,236.5a145.29,145.29 0 0 1 2.42,-26.41l-87.93,-19.4a234.88,234.88 0 0 0 42.78,187l70.73,-55.63a143.84,143.84 0 0 1 -28,-85.56z"></path>
            <path fill="#e5ebee" d="m256.44601,379.62l10.23,89.45c98.49,-12.66 177.84,-86.42 198.84,-182l-88.3,-17.6a144.63,144.63 0 0 1 -120.77,110.15z"></path>
            <path fill="#e5ebee" d="m233.65601,92l1.65,-90c-110.53,0.55 -203,77.56 -227.14,180.85l87.92,19.39a144.55,144.55 0 0 1 137.57,-110.24z"></path>
            <path fill="#e5ebee" d="m380.99601,236.5a145.39,145.39 0 0 1 -2.18,25.09l88.29,17.6a235.8,235.8 0 0 0 3.89,-42.69c0,-127.23 -101.33,-230.79 -227.69,-234.39l-1.65,90a144.5,144.5 0 0 1 139.34,144.39z"></path>
          </g>
        </svg>
      </div>

      <!--Details section-->
      <div class="detailsSection" fxLayout="column" fxLayoutGap="25px">
        <div class="totalDiv" fxLayout="row" fxLayout.gt-lg="column" fxLayoutGap="5px">
          <div class="totalNumber">
            <svg xmlns="http://www.w3.org/2000/svg" width="160px" height="38px" preserveAspectRatio="xMidYMid slice">
              <rect x="0" y="0" width="160" height="38" rx="3" ry="3" fill="#e5ebee"></rect>
            </svg>
          </div>
          <div class="totalLabel">
            <svg xmlns="http://www.w3.org/2000/svg" width="55px" height="28px" preserveAspectRatio="xMidYMid slice">
              <rect x="0" y="0" width="55" height="28" rx="3" ry="3" fill="#e5ebee"></rect>
            </svg>
          </div>
        </div>
        <mat-grid-list [cols]="nbOfCols" [rowHeight]="rowHeight" gutterSize="30px">
          <mat-grid-tile class="placeholder" *ngFor="let entry of [0, 1, 2, 3, 4, 5]; let i = index" [attr.data-index]="i">
            <div class="dataPointValue">
              <svg xmlns="http://www.w3.org/2000/svg" width="90px" height="25px" preserveAspectRatio="xMidYMid slice">
                <rect x="0" y="0" width="90" height="25" rx="3" ry="3" fill="#e5ebee"></rect>
              </svg>
            </div>
            <div class="dataPointLabel">
              <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="17px" preserveAspectRatio="xMidYMid slice">
                <rect x="0" y="0" width="40" height="17" rx="3" ry="3" fill="#e5ebee"></rect>
              </svg>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'error'">
    <div id="errorContainer" fxLayout="row" fxLayoutAlign="center center" fxFill>
      <mat-icon>error</mat-icon>
    </div>
  </ng-container>
</mat-card>
