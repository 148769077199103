import { AuthAction, AuthActionTypes } from '../actions/auth.actions';

import { AuthUtils }                   from '../../utils/auth.utils';

export interface AuthState {
  email: string | undefined;
  isLoggedIn: boolean;
  __isAdmin: boolean; // original real value
  isAdmin: boolean; // for display purposes
  isDemoUser: boolean;
  demoExpiryDate: number | undefined;
}

const INITIAL_STATE: AuthState = {
  email: undefined,
  isLoggedIn: false,
  __isAdmin: false,
  isAdmin: false,
  isDemoUser: false,
  demoExpiryDate: undefined
};

export function authReducer(state: AuthState = INITIAL_STATE, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOG_IN:
      return {
        ...state,
        email: action.payload.email,
        isLoggedIn: true,
        __isAdmin: action.payload.isAdmin,
        isAdmin: action.payload.isAdmin,
        isDemoUser: AuthUtils.isDemoAccount(action.payload.email),
        demoExpiryDate: action.payload.demoExpiryDate
      };

    case AuthActionTypes.LOG_OUT:
      return {
        ...state,
        email: undefined,
        isLoggedIn: false,
        __isAdmin: false,
        isAdmin: false,
        isDemoUser: false,
        demoExpiryDate: undefined
      };

    case AuthActionTypes.TOGGLE_ADMIN:
      // just an extra measure of security
      if (state.__isAdmin) {
        return {
          ...state,
          isAdmin: action.payload.displayAdmin
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}
