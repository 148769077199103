<ng-template #addNewsletterDialogTemplateRef>
  <app-mediego-confirmation-dialog [dialogTitle]="'MAIN.APP.DIALOGS.ADD_NEWSLETTER.TITLE' | translate"
                                   icon="add_circle_outline"
                                   class="add-newsletter-dialog"
                                   [acceptLabel]="'SHARED.BUTTONS.ADD' | translate"
                                   [declineLabel]="'SHARED.BUTTONS.CANCEL' | translate"
                                   [resultData]="addNewsletterForm.value">
    <form [formGroup]="addNewsletterForm">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'MAIN.APP.DIALOGS.ADD_WEBSOURCE.INPUT.NAME' | translate }}</mat-label>
        <input matInput type="text" formControlName="name">
      </mat-form-field>

      <hr/>

      <ng-container *ngIf="duplicateEmailSourceSelectShown; else buttonForEnablingDuplication">

        <mat-form-field  appearance="outline">
          <mat-label>{{ 'MAIN.APP.DIALOGS.DUPLICATE_NEWSLETTER.INPUT.ENGINE' | translate }}</mat-label>
          <mat-select formControlName="fromEngineId" (selectionChange)="updateSourceListForDuplication('email', $event.value)">
            <mat-option *ngFor="let engine of engineListForDuplication" [value]="engine[1]">{{ engine[0] }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field  appearance="outline">
          <mat-label>{{ 'MAIN.APP.DIALOGS.DUPLICATE_NEWSLETTER.INPUT.NAME' | translate }}</mat-label>
          <mat-select formControlName="duplicationId">
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let newsletter of newsletterListForDuplication" [value]="newsletter[1]">{{ newsletter[0] }}</mat-option>
          </mat-select>
        </mat-form-field>

      </ng-container>


      <ng-template #buttonForEnablingDuplication>
        <button mat-flat-button (click)="enableDuplicateField()">
          {{ 'MAIN.APP.DIALOGS.DUPLICATE_NEWSLETTER.BUTTON' | translate }}
        </button>
      </ng-template>
    </form>
  </app-mediego-confirmation-dialog>
</ng-template>

<ng-template #addWebSourceDialogTemplateRef>
  <app-mediego-confirmation-dialog [dialogTitle]="'MAIN.APP.DIALOGS.ADD_WEBSOURCE.TITLE' | translate"
                                   icon="add_circle_outline"
                                   [acceptLabel]="'SHARED.BUTTONS.ADD' | translate"
                                   [declineLabel]="'SHARED.BUTTONS.CANCEL' | translate"
                                   [resultData]="addWebSourceForm.value">
    <form [formGroup]="addWebSourceForm">
      <mat-form-field appearance="outline" mat-dialog-content>
        <mat-label>{{ 'MAIN.APP.DIALOGS.ADD_WEBSOURCE.INPUT.NAME' | translate }}</mat-label>
        <input matInput type="text" formControlName="name">
      </mat-form-field>
    </form>
  </app-mediego-confirmation-dialog>
</ng-template>

<ng-template #moveSourceDialogTemplateRef>
  <app-mediego-confirmation-dialog [dialogTitle]="'MAIN.APP.DIALOGS.MOVE_WEBSOURCE.TITLE' | translate"
                                   icon="add_circle_outline"
                                   [acceptLabel]="'SHARED.BUTTONS.SAVE' | translate"
                                   [declineLabel]="'SHARED.BUTTONS.CANCEL' | translate"
                                   [resultData]="nameInput.value">
    <mat-form-field appearance="outline" mat-dialog-content>
      <mat-label>{{ 'MAIN.APP.DIALOGS.MOVE_WEBSOURCE.INPUT.NAME' | translate }}</mat-label>
      <input matInput type="text" #nameInput>
    </mat-form-field>
  </app-mediego-confirmation-dialog>
</ng-template>

<div id="sideNavBreadcrumbs" fxLayout="row" fxLayoutAlign="start baseline" *ngIf="nbOfTabs > 0">
  <button id="home-tab-crumb" mat-button color="primary" tabindex="-1"
          (click)="goToTab(0)">
    <mat-icon>home</mat-icon>
  </button>

  <ng-container *ngTemplateOutlet="chevronTemplate"></ng-container>

  <ng-container *ngIf="nbOfTabs > 1">
    <button id="parent-folder-crumb" mat-button color="primary" tabindex="-1"
            (click)="goToTab(selectedTabIndex - 1)" matTooltipPosition="above"
            [matTooltip]="previousTab?.name | translate">
      <mat-icon>folder_outline</mat-icon>
    </button>

    <ng-container *ngTemplateOutlet="chevronTemplate"></ng-container>
  </ng-container>

  <button mat-button disabled id="current-folder-crumb" color="primary" tabindex="-1">
    {{ selectedTab?.name | translate }}
  </button>

  <ng-template #chevronTemplate>
    <button mat-icon-button class="crumb-chevron" disabled>
      <mat-icon>chevron_right</mat-icon>
    </button>
  </ng-template>
</div>

<div id="navigation-tabs" fxLayout="row" *ngIf="allTabs$ | async as allTabs">
  <mat-tab-group dynamicHeight [selectedIndex]="selectedTabIndex$$ | async" animationDuration="0">
    <mat-tab *ngFor="let tab of allTabs">
      <ng-template matTabContent>

        <!-- First Steps -->
        <mat-nav-list *ngIf="(selectedEngine$ | async) === null">
          <a mat-list-item routerLink="/first-setup" (click)="shouldCloseSidebar.emit()">
            <mat-icon>play_circle_filled</mat-icon>{{ 'MAIN.APP.SIDEBAR.FIRST_STEPS.TITLE' | translate }}
          </a>
        </mat-nav-list>

        <ng-container *ngIf="tab">
          <mat-nav-list *ngIf="selectedEngine$ | async as selectedEngine" fxLayout="column">
            <!-- STATIC ITEMS -->
            <!-- Newsletters -->
            <ng-container *ngIf="tab.name === 'MAIN.APP.SIDEBAR.NEWSLETTERS.TITLE'">

              <!-- drag and drop toggle -->
              <!--<a mat-list-item *ngIf="hasFolderRight$ | async"
                 [matTooltip]="(!canMoveFolder ? 'MAIN.APP.SIDEBAR.NEWSLETTERS.MOVE.TOOLTIP' : 'MAIN.APP.SIDEBAR.NEWSLETTERS.MOVE_END.TOOLTIP') | translate"
                 class="emphasis" (click)="toggleDragDrop()">
                <mat-icon>low_priority</mat-icon>
                <ng-container *ngIf="!canMoveFolder; else dragDropInstruction">
                  {{ 'MAIN.APP.SIDEBAR.NEWSLETTERS.MOVE' | translate }}
                </ng-container>
                <ng-template #dragDropInstruction>
                  {{ 'MAIN.APP.SIDEBAR.NEWSLETTERS.MOVE_END' | translate }}
                </ng-template>
              </a>-->

              <!-- ADD -->
              <!--<a mat-list-item class="emphasis" data-intercom-target="email_create_button" (click)="openDialog('email')" *ngIf="selectedEngine.userPermissions.createNewsletters">
                <mat-icon>add</mat-icon>
                {{ 'MAIN.APP.SIDEBAR.NEWSLETTERS.ADD' | translate }}
              </a>-->

              <!-- ALL -->
              <ng-container *ngIf="tab.name === 'MAIN.APP.SIDEBAR.NEWSLETTERS.TITLE' && (isEngineAdmin$ | async) === true">
                <a mat-list-item class="emphasis" data-intercom-target="email_stats_button" (click)="shouldCloseSidebar.emit()"
                   [routerLink]="['/newsletters', 'supervisor']" queryParamsHandling="preserve" routerLinkActive="active">
                  <mat-icon>table_view</mat-icon> {{ 'SHARED.SUPERVISOR' | translate }}
                </a>
              </ng-container>

              <!-- STATS -->
              <a mat-list-item class="emphasis" data-intercom-target="email_stats_button" (click)="shouldCloseSidebar.emit()"
                 [routerLink]="['/newsletters', ALL_EMAILING_SOURCES.id, 'stats']" queryParamsHandling="preserve" routerLinkActive="active">
                <mat-icon>bar_chart</mat-icon>{{ ALL_EMAILING_SOURCES.displayName | translate }}
              </a>

              <!-- HISTORY -->
              <a mat-list-item class="emphasis" data-intercom-target="email_history_button" (click)="shouldCloseSidebar.emit()"
                 [routerLink]="['/newsletters', ALL_EMAILING_HISTORY.id, 'history']" routerLinkActive="active">
                <mat-icon>timeline</mat-icon>{{ ALL_EMAILING_HISTORY.displayName | translate }}
              </a>
            </ng-container>

            <!-- Web Sources -->
            <ng-container *ngIf="tab.name === 'MAIN.APP.SIDEBAR.WEB_PERSO.TITLE'">
              <a mat-list-item class="emphasis" data-intercom-target="web_personalisation_create_button" (click)="openDialog('web')">
                <mat-icon>add</mat-icon>
                {{ 'MAIN.APP.SIDEBAR.WEB_PERSO.ADD' | translate }}
              </a>
              <a mat-list-item class="emphasis" data-intercom-target="web_personalisation_stats_button" (click)="shouldCloseSidebar.emit()"
                 [routerLink]="['/web-personalisation', ALL_PERSONALISATION_SOURCES.id, 'stats']" queryParamsHandling="preserve" routerLinkActive="active">
                <mat-icon>bar_chart</mat-icon>{{ ALL_PERSONALISATION_SOURCES.displayName | translate }}
              </a>
            </ng-container>

            <!-- Segments -->
            <ng-container *ngIf="tab.name === 'MAIN.APP.SIDEBAR.SEGMENTS.TITLE'">
              <a mat-list-item class="emphasis" data-intercom-target="segments_create_button" routerLink="/segments/new" routerLinkActive="active" (click)="shouldCloseSidebar.emit()" *ngIf="selectedEngine.userPermissions.createSegments">
                <mat-icon>add</mat-icon>{{ 'MAIN.APP.SIDEBAR.SEGMENTS.ADD' | translate }}
              </a>
              <a mat-list-item class="emphasis" data-intercom-target="analytics_panel" (click)="shouldCloseSidebar.emit()"
                 routerLink="/segments/analytics" queryParamsHandling="preserve" routerLinkActive="active">
                <mat-icon>bar_chart</mat-icon>{{ 'MAIN.APP.SIDEBAR.SEGMENTS.ANALYTICS' | translate }}
              </a>
            </ng-container>

            <!-- CRM -->
            <ng-container *ngIf="tab.name === 'MAIN.APP.SIDEBAR.CRM.TITLE'">
              <a mat-list-item routerLink="/crm/contacts/lists" (click)="shouldCloseSidebar.emit()">
                <mat-icon>groups</mat-icon>{{ 'MAIN.APP.SIDEBAR.CRM.CONTACTS.LISTS' | translate }}
              </a>
            </ng-container>

            <!-- Contents -->
            <ng-container *ngIf="tab.name === 'MAIN.APP.SIDEBAR.CONTENTS.TITLE'">

              <!-- Gestion globale -->
              <a mat-list-item class="emphasis" (click)="shouldCloseSidebar.emit()"
                 routerLink="/contents/supervisor" *ngIf="isAdmin$ | async" routerLinkActive="active">
                <mat-icon>table_view</mat-icon> {{ 'SHARED.SUPERVISOR' | translate }}
              </a>

              <a mat-list-item routerLink="/contents/itemfeeds" *ngIf="selectedEngine.userPermissions.configurations.itemSets" routerLinkActive="active" (click)="shouldCloseSidebar.emit()" >
                <mat-icon>feed</mat-icon>{{ 'MAIN.APP.SIDEBAR.CONTENTS.FLUX' | translate }}
              </a>
              <a mat-list-item routerLink="/contents/itemsets" *ngIf="selectedEngine.userPermissions.configurations.itemSets" routerLinkActive="active" (click)="shouldCloseSidebar.emit()" >
                <mat-icon>category</mat-icon>{{ 'MAIN.APP.SIDEBAR.CONTENTS.SOURCES' | translate }}
              </a>

            </ng-container>

            <!-- Configuration -->
            <ng-container *ngIf="tab.name === 'MAIN.APP.SIDEBAR.CONFIGURATION.TITLE'">
              <a mat-list-item routerLink="/configuration/engine" *ngIf="selectedEngine.userPermissions.configurations.integration" routerLinkActive="active" (click)="shouldCloseSidebar.emit()">
                <mat-icon>public</mat-icon>{{ 'MAIN.APP.SIDEBAR.CONFIGURATION.DOMAIN' | translate }}
              </a>
              <a mat-list-item routerLink="/configuration/access-management" *ngIf="selectedEngine.userPermissions.configurations.accessManagement" routerLinkActive="active" (click)="shouldCloseSidebar.emit()">
                <mat-icon>group</mat-icon>{{ 'MAIN.APP.SIDEBAR.CONFIGURATION.ACCESS_MANAGEMENT.TITLE' | translate }}
              </a>
              <a mat-list-item routerLink="/configuration/audience-management" *ngIf="selectedEngine.userPermissions.configurations.mailers" routerLinkActive="active" (click)="shouldCloseSidebar.emit()">
                <mat-icon>send</mat-icon>{{ 'MAIN.APP.SIDEBAR.CONFIGURATION.AUDIENCE' | translate }}
              </a>
              <a mat-list-item routerLink="/configuration/newsletters-config" *ngIf="selectedEngine.userPermissions.configurations.newsletters && (isEngineAdmin$ | async)" routerLinkActive="active" (click)="shouldCloseSidebar.emit()">
                <mat-icon>style</mat-icon>{{ 'MAIN.APP.SIDEBAR.CONFIGURATION.NEWSLETTERS' | translate }}
              </a>

              <a *ngIf="isAdmin$ | async" mat-list-item class="accent" routerLink="/monitoring" (click)="shouldCloseSidebar.emit()">
                <mat-icon color="accent">query_stats</mat-icon>{{ 'MAIN.APP.SIDEBAR.MONITORING' | translate }}
              </a>

              <a *ngIf="isAdmin$ | async" mat-list-item class="accent" routerLink="/monitoring/api_history" (click)="shouldCloseSidebar.emit()">
                <mat-icon color="accent">history_edu</mat-icon>{{ 'MAIN.APP.SIDEBAR.MONITORING.API' | translate }}
              </a>

              <a *ngIf="isAdmin$ | async" mat-list-item class="accent" routerLink="/monitoring/costs" (click)="shouldCloseSidebar.emit()">
                <mat-icon color="accent">credit_card_gear</mat-icon>{{ 'MAIN.APP.SIDEBAR.MONITORING.COSTS' | translate }}
              </a>

              <a *ngIf="selectedEngine.userPermissions.configurations.billing" mat-list-item routerLink="/monitoring/billing" routerLinkActive="active" (click)="shouldCloseSidebar.emit()">
                <mat-icon>receipt_long</mat-icon>{{ 'MAIN.APP.SIDEBAR.MONITORING.BILLING' | translate }}
              </a>

              <a *ngIf="isAdmin$ | async" mat-list-item class="accent" routerLink="/monitoring/messages" (click)="shouldCloseSidebar.emit()">
                <mat-icon color="accent">campaign</mat-icon>{{ 'MAIN.APP.SIDEBAR.MONITORING.MEDIEGO_MESSAGES' | translate }}
              </a>

              <a *ngIf="isAdmin$ | async" mat-list-item class="accent" routerLink="/monitoring/tracking" (click)="shouldCloseSidebar.emit()">
                <mat-icon color="accent">ads_click</mat-icon>{{ 'MAIN.APP.SIDEBAR.MONITORING.TRACKING' | translate }}
              </a>

              <!--<a mat-list-item routerLink="/configuration/scrapers" *ngIf="selectedEngine.userPermissions.configurations.scrapers" routerLinkActive="active" (click)="shouldCloseSidebar.emit()" >
                <mat-icon>track_changes</mat-icon>{{ 'MAIN.APP.SIDEBAR.CONFIGURATION.SCRAPERS' | translate }}
              </a>
              <a mat-list-item routerLink="/configuration/social-networks" *ngIf="selectedEngine.userPermissions.configurations.socialNetwork" routerLinkActive="active" (click)="shouldCloseSidebar.emit()">
                <mat-icon>share</mat-icon>{{ 'MAIN.APP.SIDEBAR.CONFIGURATION.SOCIAL_MEDIA' | translate }}
              </a>-->
            </ng-container>
            <!-- END: STATIC ITEMS -->

            <div *ngIf="canMoveFolder; else defaultLayoutWithoutDragDrop" cdkDropListGroup>

            <!-- FOLDERS -->
              <ng-container *ngFor="let folder of tab.folders | sortFolders:'asc'; let i = index; trackBy: folderTrackingFunction">
                <!-- each folder is a drop area -->
                <div cdkDropList
                     (cdkDropListDropped)="dropInFolder($event, folder)"
                     (cdkDropListEntered)="dropPreviewInFolder($event, folder)"
                     (cdkDropListExited)="dropPreviewOutFolder($event, folder)"
                     class="folder-drop-area">

                  <a mat-list-item class="folder-link"
                     [attr.data-intercom-target]="folder.intercomTarget"
                     (click)="selectFolder(folder)">
                      <span fxLayoutAlign="start center">
                        <mat-icon *ngIf="folderHovered === folder; else defaultIcon" class="folder-icon">folder_open</mat-icon>
                        <ng-template #defaultIcon>
                          <mat-icon class="folder-icon">{{ folder.icon || 'folder' }}</mat-icon>
                        </ng-template>
                        {{ folder.name | translate }}
                      </span>
                  </a>
                </div>
              </ng-container>

              <!-- ITEMS -->
              <div cdkDropList id="draggableItems"
                   *ngIf="getSortedFolderItemsWithRouterLinks(tab) | async as sortedItems">
                <ng-container *ngFor="let item of sortedItems; trackBy: itemTrackingFunction">
                  <div cdkDrag [cdkDragData]="item" (cdkDragStarted)="dragStart($event)" (cdkDragEnded)="dragEnd($event)">
                    <a mat-list-item>
                      {{ item.displayName ? item.displayName : item.name }}
                    </a>
                  </div>
                </ng-container>
              </div>
            </div> <!-- end of drag & drop -->

            <!-- TEMPLATE WITH NO DRAG AND DROP ENABLED -->
            <ng-template #defaultLayoutWithoutDragDrop>
              <!-- FOLDERS -->
              <ng-container *ngFor="let folder of tab.folders | orderByKey:'priority'">
                <a mat-list-item class="folder-link" [attr.data-intercom-target]="folder.intercomTarget" (click)="selectFolder(folder)">
                  <span fxLayoutAlign="start center">
                    <mat-icon class="folder-icon">{{ folder.icon || 'folder' }}</mat-icon>
                    {{ folder.name | translate }}
                  </span>
                  <mat-icon class="folder-arrow">chevron_right</mat-icon>
                </a>
              </ng-container>

              <!-- ITEMS -->
              <ng-container *ngIf="getSortedFolderItemsWithRouterLinks(tab) | async as sortedItems">
                <ng-container *ngFor="let item of sortedItems">
                  <a mat-list-item (click)="shouldCloseSidebar.emit()" [routerLink]="item.routerLink" queryParamsHandling="preserve">
                    {{ item.displayName ? item.displayName : item.name }}
                  </a>
                </ng-container>
              </ng-container>
            </ng-template>
            <!-- END OF NO DRAG AND DROP TEMPLATE -->

            <!-- LOGOUT -->
            <ng-container *ngIf="tab.name === 'SHARED.BUTTONS.HOME'">

              <a mat-list-item class="primary" routerLink="/user" (click)="shouldCloseSidebar.emit()">
                <mat-icon>account_circle</mat-icon>{{ 'MAIN.APP.SIDEBAR.ACCOUNT' | translate }}
              </a>

              <a mat-list-item class="warn" routerLink="/logout" (click)="shouldCloseSidebar.emit()">
                <mat-icon>exit_to_app</mat-icon>{{ 'MAIN.APP.SIDEBAR.LOGOUT' | translate }}
              </a>
            </ng-container>
          </mat-nav-list>
        </ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
