import { createReducer, on } from '@ngrx/store';

import { ListAction, loadContactsList, unloadContactsList } from '../actions/crm.actions';

import { ContactList } from '../../../configuration-module/providers/mailers.provider';


export interface CRMState {
  list: ContactList;
}

const INITIAL_STATE: CRMState = {
  list: undefined
};

const reducer = createReducer(
  INITIAL_STATE,
  on(loadContactsList, (state, { list }) => ({ list })),
  on(unloadContactsList, (_) => INITIAL_STATE)
);

export function crmReducer(state: CRMState, action: ListAction): CRMState {
  return reducer(state, action);
}

/* LEGACY REDUCER

export function crmReducer(state: CRMState = INITIAL_STATE, action: ListAction): CRMState {
  switch (action.type) {
    case loadContactsList.type:
      return {
        ...state,
        list: action.list
      };

    case unloadContactsList.type:
      return {
        ...state,
        list: undefined
      };

    default:
      return state;
  }
}
*/
