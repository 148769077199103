import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import merge from 'lodash/merge';

import { NewsletterGlobalAction, resetNewsletter } from '../../../newsletters-module/state/actions/newsletter.actions';
import {
  ContentAction,
  deleteContents,
  setContentDynamic,
  setContentHighlight,
  setContentStatic, updateContentDarkmodeParameter,
  updateContentDynamicParameter,
  updateContentInnerRenderingProps,
  updateContentOuterRenderingProps,
  updateContentParameter,
  updateContentProperties,
  updateContentSizeProps,
  updateContentStaticParameter
} from '../actions/content.actions';
import { addContentToLayout, resetContentOfLayout } from '../actions/layout.actions';
import {
  addRowsToTemplateModel,
  addRowToTemplateModel,
  importTemplateModel,
  loadTemplateModel
} from '../actions/template-model.actions';

import {
  DEFAULT_CONTENT_INNER_STYLES,
  DEFAULT_CONTENT_OUTER_STYLES,
  DEFAULT_CONTENT_PARAMETERS
} from '../../declarations/constants';
import { Content } from '../../declarations/content';
import { ContentType } from '../../declarations/enums';

import { TemplateModelUtils } from '../../utils/template-model.utils';

export type ContentState = EntityState<Content>;

export const contentsAdapter: EntityAdapter<Content> = createEntityAdapter<Content>();

export const initialState: ContentState = contentsAdapter.getInitialState();

const {
  // selectIds,
  // selectEntities
  // selectAll,
  // selectTotal
} = contentsAdapter.getSelectors();

const reducer = createReducer(
  initialState,
  on(resetNewsletter, (_) => initialState),
  on(loadTemplateModel,
    importTemplateModel,
    (state, { model }) =>
      contentsAdapter.setAll(
        TemplateModelUtils.extractContentsFromLayout(model),
        state
      )
  ),
  on(deleteContents, (state, { ids }) => {
    return contentsAdapter.removeMany(ids, state);
  }),
  on(addRowToTemplateModel, (state, { row }) =>
    contentsAdapter.addMany(
      TemplateModelUtils.extractContentsFromLayout(row),
      state
    )
  ),
  on(addRowsToTemplateModel, (state, { rows }) => {
      const contents = rows.reduce((res, row) => [...res, ...TemplateModelUtils.extractContentsFromLayout(row)], []);
      return contentsAdapter.addMany(
        contents,
        state
      )
    }
  ),
  on(setContentHighlight, (state, { ids, highlighted }) => {
    return contentsAdapter.updateMany(
      ids.map((id) => (
        { id,
          changes: {
            outerRenderingProperties: {
              ...state.entities[id].outerRenderingProperties,
              highlighted
            }
          }
        }
      )), state)
  }),
  on(addContentToLayout, (state, { placeholderId, content }) => {
    const emptyPlaceholder: Content = state.entities[placeholderId];

    const stepState = contentsAdapter.removeOne(placeholderId, state);

    return contentsAdapter.addOne({
      ...content,
      size: merge({}, emptyPlaceholder.size)
    }, stepState);
  }),
  on(resetContentOfLayout, (state, { layoutId, contentId }) => {

    // resetting content but keeping same id
    return contentsAdapter.updateOne({
      id: contentId,
      changes: {
        type: ContentType.Empty,
        name: 'Vide',
        outerRenderingProperties: { ...DEFAULT_CONTENT_OUTER_STYLES[ContentType.Empty] },
        innerRenderingProperties: { ...DEFAULT_CONTENT_INNER_STYLES[ContentType.Empty] },
        params: { ...DEFAULT_CONTENT_PARAMETERS[ContentType.Empty] }
      }
    }, state);

  }),
  on(setContentDynamic, (state, { id, articleRef }) =>
    contentsAdapter.updateOne(
      { id, changes: { articleRef } },
      state
    )
  ),
  on(setContentStatic, (state, { id }) =>
    contentsAdapter.updateOne(
      { id, changes: { articleRef: null } },
      state
    )
  ),
  on(updateContentSizeProps, (state, { id, partial }) => {
    const current = state.entities[id];
    return contentsAdapter.updateOne(
      { id, changes: { size: Object.assign({}, current.size || {}, partial) } },
      state
    )
  }),
  on(updateContentOuterRenderingProps, (state, { id, partial }) => {
    const current = state.entities[id];
    return contentsAdapter.updateOne(
      { id, changes: { outerRenderingProperties: Object.assign({}, current.outerRenderingProperties, partial) } },
      state
    )
  }),
  on(updateContentInnerRenderingProps, (state, { id, partial }) => {
    const current = state.entities[id];
    return contentsAdapter.updateOne(
      { id, changes: { innerRenderingProperties: Object.assign({}, current.innerRenderingProperties, partial) } },
      state
    )
  }),
  on(updateContentParameter, (state, { id, param, val }) => {
    const current = state.entities[id];
    const oldParams = current.params;
    const newParams = {
      ...oldParams,
      [param]: val
    };

    return contentsAdapter.updateOne(
      { id, changes: { params: newParams } },
      state
    )
  }),
  on(updateContentStaticParameter, (state, { id, param, val }) => {
    const current = state.entities[id];
    const oldParams = current.params;
    const newParams = { ...oldParams,
      static: { ...oldParams.static,
        [param]: val
      }
    };

    return contentsAdapter.updateOne(
      { id, changes: { params: newParams } },
      state
    )
  }),
  on(updateContentDynamicParameter, (state, { id, param, val }) => {
    const current = state.entities[id];
    const oldParams = current.params;
    const newParams = { ...oldParams,
      dynamic: { ...oldParams.dynamic,
        [param]: val
      }
    };

    return contentsAdapter.updateOne(
      { id, changes: { params: newParams } },
      state
    )
  }),
  on(updateContentDarkmodeParameter, (state, { id, param, val }) => {
    const current = state.entities[id];
    const oldParams = current.params;
    const newParams = { ...oldParams,
      darkmode: { ...oldParams.darkmode,
        [param]: val
      }
    };

    return contentsAdapter.updateOne(
      { id, changes: { params: newParams } },
      state
    )
  }),
  on(updateContentProperties, (state, { id, partial }) => {

      return contentsAdapter.updateOne(
        { id, changes: partial },
        state
      );
    }
  )
);

export function contentReducer(state: ContentState, action: ContentAction | NewsletterGlobalAction) {
  return reducer(state, action);
}
