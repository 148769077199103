import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Constraint } from '../constraints';
import { constraintToString } from '../constraints-serdes';


@Component({
  selector: 'app-mediego-constraint-readonly',
  templateUrl: './constraint-readonly.component.html',
  styleUrls: ['./constraint-readonly.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConstraintReadonlyComponent {

  @Input() constraint: Constraint;

  constructor(private translate: TranslateService) {}

  constraintToText(constraint: Constraint) {
    return constraintToString(constraint, this.translate);
  }


}
