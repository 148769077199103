import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-mediego-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SearchBarComponent {

  @HostBinding('class.large') get largeSearchBar() { return this.large }

  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() large?: boolean;
}
