import { RGBA_TRANSPARENT } from '../../../mediego-common-module/utils/color.utils';
import { TextAlignment, VerticalAlignment } from '../enums';
import { OuterRenderingProperties } from '../rendering-properties';

export const DEFAULT_OUTER_RENDERING_PROPERTIES: Partial<OuterRenderingProperties> = {
  paddings: { top: 0, right: 0, bottom: 0, left: 0 },
  margins: { top: 0, right: 0, bottom: 0, left: 0 },
  borders: {
    sameForAllSides: true,
    top: { style: 'solid', width: 0, color: '#000000' },
    right: { style: 'solid', width: 0, color: '#000000' },
    bottom: { style: 'solid', width: 0, color: '#000000' },
    left: { style: 'solid', width: 0, color: '#000000' }
  }
};

export const DEFAULT_EMPTY_OUTER_STYLES: Partial<OuterRenderingProperties> = {
  ...DEFAULT_OUTER_RENDERING_PROPERTIES,
  verticalAlign: VerticalAlignment.Middle
};

export const DEFAULT_BUTTON_OUTER_STYLES: Partial<OuterRenderingProperties> = {
  ...DEFAULT_OUTER_RENDERING_PROPERTIES,
  paddings: {
    top: 10,
    right: 0,
    bottom: 10,
    left: 0
  },
  verticalAlign: VerticalAlignment.Top,
  textAlign: TextAlignment.Center,
  backgroundColor: RGBA_TRANSPARENT
};

export const DEFAULT_IMAGE_OUTER_STYLES: Partial<OuterRenderingProperties> = {
  ...DEFAULT_OUTER_RENDERING_PROPERTIES,
  backgroundColor: { r: 255, g: 255, b: 255, a: 0 },
  textAlign: TextAlignment.Center,
  verticalAlign: VerticalAlignment.Middle
};

export const DEFAULT_TITLE_OUTER_STYLES: Partial<OuterRenderingProperties> = {
  ...DEFAULT_OUTER_RENDERING_PROPERTIES,
  // textAlign: TextAlignment.Left,
  paddings: { top: 15, right: 15, bottom: 15, left: 15 },
  backgroundColor: { r: 255, g: 255, b: 255, a: 0 }
  // spaceBetweenParagraphs: 12
};

export const DEFAULT_TEXT_OUTER_STYLES: Partial<OuterRenderingProperties> = {
  ...DEFAULT_OUTER_RENDERING_PROPERTIES,
  // textAlign: TextAlignment.Left,
  paddings: { top: 15, right: 15, bottom: 15, left: 15 },
  backgroundColor: { r: 255, g: 255, b: 255, a: 0 }
  // spaceBetweenParagraphs: 12
};
