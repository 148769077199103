import {
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';

import { ContactList, Mailer } from '../../../../configuration-module/providers/mailers.provider';



@Component({
  selector: 'app-mediego-setup-sending-config',
  templateUrl: './setup-sending-config.component.html',
  styleUrls: ['./setup-sending-config.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SetupSendingConfigComponent {

  @Input() sendingConfigForm: UntypedFormGroup;
  @Input() contactsMailers: Mailer[] = [];
  @Input() sendingMailers: Mailer[] = [];
  @Input() contactsListsForMailer: ContactList[] = [];
  @Input() senders: string[] = [];
  @Input() isManualContactsMailer: boolean = false;
  @Input() contactsMailerLoading: boolean = false;


  get selectedSendingMailerType(): string {
    const selectedMailer = this.contactsMailers.find(m => m?.id === this.sendingConfigForm.get('sendingMailer').value);
    return selectedMailer?.mailerType;
  }

  get specificSendingConf(): FormControl {
    return this.sendingConfigForm.get('specificSendingMailerConfiguration') as FormControl;
  }


  displayContactsListsName(contactsList: ContactList): string {
    return contactsList.name
  }

  updateManualContactLists(newContactLists: string[]): void {
    this.sendingConfigForm.get('contactsLists').setValue(newContactLists);
    this.sendingConfigForm.markAsTouched();
  }

  updateSelectedContactsLists(elements) {
    this.sendingConfigForm.markAsTouched(); // the multi-select component does not change the "touched" attribute natively.
    this.sendingConfigForm.get('contactsLists').setValue(elements);
  }

  restoreConfig() {
    this.sendingConfigForm.get('contactsMailer').setValue('');
    this.sendingConfigForm.get('contactsLists').setValue([]);
    this.sendingConfigForm.get('sendingMailer').setValue('');
    this.sendingConfigForm.get('senderName').setValue('');
    this.sendingConfigForm.get('senderAddress').setValue('');
    this.sendingConfigForm.get('replyToAddress').setValue('');
    this.sendingConfigForm.get('specificSendingMailerConfiguration').setValue(null);
    this.sendingConfigForm.markAsTouched();
  }


}
