import _ from 'lodash/fp';

import { rgbaToString } from '../../../mediego-common-module/utils/color.utils';
import { TemplateModelUtils } from '../../utils/template-model.utils';
import { TemplateTrackingUtils } from '../../utils/template-tracking.utils';
import { CompilationContext, CompilationResult } from '../compilation';
import { ConstraintPattern, ContentType, LinkTargetMode } from '../enums';
import { applyStyles } from '../rendering-properties';

import { Content, ContentParametersInfo, DEFAULT_ARTICLE_LINK } from './';

export interface ButtonContentParams {
  buttonLink: string;
  buttonContent: string;
  buttonRedirectionMode: LinkTargetMode;
  customTracking: string;
  disableEncoding: boolean;
}

export const BUTTON_PARAMETERS_INFO: ContentParametersInfo<ButtonContentParams> = {
  buttonContent: {
    valueType: 'string'
  },
  buttonLink: {
    valueType: 'url'
  },
  buttonRedirectionMode: {
    valueType: 'enum',
    availableOptions: [
      ['new', LinkTargetMode.NewTab],
      ['current', LinkTargetMode.CurrentTab]
    ]
  },
  customTracking: {
    valueType: 'string',
    enabledWithConstraints: [ConstraintPattern.HeatmapUTM, ConstraintPattern.SelligentTracking]
  },
  disableEncoding: {
    valueType: 'boolean',
    hint: true
  }
};

export const DEFAULT_BUTTON_PARAMETERS: Content<ButtonContentParams>['params'] = {
  static: {
    buttonLink: '',
    buttonContent: 'CLICK ME',
    buttonRedirectionMode: LinkTargetMode.NewTab,
    customTracking: '',
    disableEncoding: false
  },
  dynamic: {
    buttonLink: DEFAULT_ARTICLE_LINK,
    buttonContent: 'LIRE',
    buttonRedirectionMode: LinkTargetMode.NewTab,
    customTracking: '',
    disableEncoding: false
  }
};

export type ButtonContent = Content<ButtonContentParams>;


// :hover cannot be applied within inline styles, hence why we need css rules
export const computeHoverRulesForButton = (content: ButtonContent): string => {

  const { buttonColor, textColor, buttonColorHover, textColorHover } = content.innerRenderingProperties;

  const needsButtonColorHover = !_.isEqual(buttonColor, buttonColorHover) && buttonColorHover.a !== 0;
  const needsTextColorHover = textColor !== textColorHover;
  const needsHover = needsButtonColorHover || needsTextColorHover;

  if (!needsHover) return '';
  else {
    const idHash = TemplateModelUtils.contentIdHash(content);
    const ruleForButtonColor = needsButtonColorHover ? `.${idHash}:hover { background-color: ${rgbaToString(buttonColorHover)} !important; }` : '';
    const ruleForTextColor = needsTextColorHover ? `.${idHash}:hover a { color: ${textColorHover} !important; }` : '';

    if (needsButtonColorHover && needsTextColorHover) return ruleForButtonColor + '\n' + ruleForTextColor;
    else if (needsButtonColorHover) return ruleForButtonColor;
    else return ruleForTextColor;
  }
}

export const compileToHtmlNodes = (content: ButtonContent, articleIndex: number, context: CompilationContext): CompilationResult => {

  const result: CompilationResult = {
    nodes: [],
    context
  };


  // styles definition

  const {
    buttonPaddings, buttonColor, buttonBorderRadius, buttonTextAlign,
    textColor, textFont, fontSize, fontWeight, minWidth
  } = content.innerRenderingProperties;

  const { buttonColorHover, textColorHover } = content.innerRenderingProperties;

  let needsButtonColorHover = false, needsTextColorHover = false, needsHover = false;

  if (buttonColorHover && textColorHover) {
    needsButtonColorHover = _.isEqual(buttonColor, buttonColorHover) && buttonColorHover.a !== 0;
    needsTextColorHover = textColor !== textColorHover;
    needsHover = needsButtonColorHover || needsTextColorHover;

    if (needsHover && result.context.hoverContents) result.context.hoverContents = [...result.context.hoverContents, content];
  }

  const table_tag = document.createElement('table') as HTMLTableElement;
  table_tag.classList.add('mediego-button-table');
  if (needsHover) table_tag.classList.add(TemplateModelUtils.contentIdHash(content));
  table_tag.style.display = 'inline-block';
  table_tag.style.borderCollapse = 'collapse';
  table_tag.cellPadding = '0';
  table_tag.cellSpacing = '0';
  table_tag.style.border = 'none';
  const tr_tag = document.createElement('tr') as HTMLTableRowElement;
  const td_tag = document.createElement('td') as HTMLTableCellElement;
  const a_tag = document.createElement('a') as HTMLAnchorElement;
  a_tag.style.textDecoration = 'none';
  a_tag.style.color = 'black';
  a_tag.style.margin = '0';

  if (minWidth?.value) {
    a_tag.style.display = 'inline-block';
    a_tag.style.width = '100%';
  }

  table_tag.appendChild(tr_tag);
  tr_tag.appendChild(td_tag);
  td_tag.appendChild(a_tag);

  applyStyles(table_tag, {
    buttonColor,
    buttonBorderRadius
  });
  applyStyles(td_tag, { buttonPaddings, textColor, textFont, fontSize, minWidth, buttonTextAlign });
  applyStyles(a_tag, { textColor, fontWeight });


  // generating HTML link tag with its inner HTML

  const linkVariable = `variables.${TemplateModelUtils.sanitizeContentIdForMustacheLink(content.id)}`;
  const textVariable = `variables.${TemplateModelUtils.sanitizeContentIdForMustache(content.id)}`;

  const relevantParams = content.articleRef ? content.params.dynamic : content.params.static;
  TemplateTrackingUtils.setSelligentTrackingParameter(a_tag, context, relevantParams, !!content.articleRef, articleIndex);

  if (relevantParams.disableEncoding) a_tag.setAttribute('data-disable-tracking', 'true');

  if (content.unlockedForEditorial) {
    a_tag.href = `{{${linkVariable}}}`;
    a_tag.target = '_blank';
    a_tag.innerHTML = `{{${textVariable}}}`;
  } else if (content.articleRef) {

    let buttonLink = relevantParams.buttonLink;
    buttonLink += TemplateTrackingUtils.getMediegoTrackingParameter(context, relevantParams, ContentType.Button, !!content.articleRef, articleIndex);

    a_tag.href = buttonLink;
    a_tag.target = relevantParams.buttonRedirectionMode;
    a_tag.innerHTML = relevantParams.buttonContent;
  } else {
    a_tag.href = relevantParams.buttonLink;
    a_tag.target = relevantParams.buttonRedirectionMode;
    a_tag.innerHTML = relevantParams.buttonContent;
  }


  // mustache if/fi

  const startIfLink: Text = document.createTextNode(`{{ #${linkVariable} }}`);
  const endIfLink: Text = document.createTextNode(`{{ /${linkVariable} }}`);
  const startIfContent: Text = document.createTextNode(`{{ #${textVariable} }}`);
  const endIfContent: Text = document.createTextNode(`{{ /${textVariable} }}`);
  const startIfReco: Text = document.createTextNode(`{{ #reco_${articleIndex} }}`);
  const endIfReco: Text = document.createTextNode(`{{ /reco_${articleIndex} }}`);


  if (content.unlockedForEditorial && content.articleRef) {
    result.nodes = [startIfReco, startIfLink, startIfContent, table_tag, endIfContent, endIfLink, endIfReco];
  } else if (content.unlockedForEditorial) {
    result.nodes = [startIfLink, startIfContent, table_tag, endIfContent, endIfLink];
  } else if (content.articleRef) {
    result.nodes = [startIfReco, table_tag, endIfReco];
  } else {
    result.nodes = [table_tag];
  }

  return result;
};
