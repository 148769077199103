<b *ngIf="bold; else noBold">{{ prettyId }}</b>
<ng-template #noBold>
  {{ prettyId }}
</ng-template>

<button *ngIf="allowCopy" (click)="copyId($event)"
        class="copy-id-btn"
        matTooltip="Cliquer pour copier l'ID" mat-icon-button color="accent">
  <mat-icon>content_paste</mat-icon>
</button>
