import { createSelector } from '@ngrx/store';

import { TemplaterState }  from '../index';

import { selectTemplater } from './';

export const selectCustomBlocks = createSelector(
  selectTemplater,
  (state: TemplaterState) => state.customBlocks
);

export const selectCustomBlock = createSelector(
  selectCustomBlocks,
  (customBlocks, { id }) =>
    customBlocks.find((block) => block.id === id)
);
