import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { concatMap } from 'rxjs/operators';

import { AuthActionTypes }        from '../actions/auth.actions';
import { DeleteAllEnginesAction } from '../actions/engines.actions';

@Injectable()
export class AuthEffects {

  loggedOut$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthActionTypes.LOG_OUT),
      concatMap(() => [
      // TODO: SHOULD CLEAR ENTIRE APP STATE ON LOG OUT
        new DeleteAllEnginesAction()
      ])
    ));

  constructor(
    private actions: Actions
  ) {}

}
