import { HttpClient } from '@angular/common/http';
import { Injectable }             from '@angular/core';

import { Store } from '@ngrx/store';

import { firstValueFrom, Observable, of, throwError } from 'rxjs';
import { map }                  from 'rxjs/operators';

import { AppState }              from '../main-module/state';
import {
  selectSegmentsFolderOfSelectedEngine,
  selectSegmentsOfSelectedEngine
}                                from '../main-module/state/selectors/engines-selectors';
import { selectSelectedSegment } from '../main-module/state/selectors/router-selectors';
import { SegmentAction }         from 'app/segments-module/declarations/actions/segment-action';
import {
  DeleteSegmentFromSelectedEngineAction,
  InsertSegmentPermissionsInSelectedEngineAction,
  UpsertSegmentInSelectedEngineAction
}                                from '../main-module/state/actions/engines.actions';

import { LastNVisitsModifier }    from 'app/segments-module/declarations/criteria/modifiers/last-n-visits-modifier';
import { NumberOfVisitsModifier } from 'app/segments-module/declarations/criteria/modifiers/number-of-visits-modifier';
import { PeriodFilterModifier }   from 'app/segments-module/declarations/criteria/modifiers/period-filter-modifier';
import { QuantitativeModifier }   from 'app/segments-module/declarations/criteria/modifiers/quantitative-modifier';
import { TemporalModifier }       from 'app/segments-module/declarations/criteria/modifiers/temporal-modifier';
import { SegmentCriterion }       from 'app/segments-module/declarations/criteria/segment-criterion';
import { CategoryCriterion }      from 'app/segments-module/declarations/criteria/single-visit/category-criterion';
import { SingleVisitCriterion }   from 'app/segments-module/declarations/criteria/single-visit/single-visit-criterion';
import { UrlContainsCriterion }   from 'app/segments-module/declarations/criteria/single-visit/url-contains-criterion';
import { VisitCriterion }         from 'app/segments-module/declarations/criteria/visit-criterion';
import { Segment }                from 'app/segments-module/declarations/segment';
import { SegmentPermissions }     from '../main-module/declarations/engine';
import { Folder }                 from '../main-module/declarations/folder';

import { environment } from '../../environments/environment';

// Sub-interfaces

interface TemporalModifierParameters {
  n?: number;
  temporalUnit?: string;
}

interface QuantitativeModifierParameters {
  n?: number;
  operator?: string;
}

interface SingleVisitCriterionParameters {
  patterns?: string[];
  categories?: string[];
}

export enum SingleVisitCriterionType { URL_CONTAINS = 'url-contains', CATEGORY = 'category' }
export enum TemporalModifierType { LAST_N_VISITS = 'last-n-visits-filter', PERIOD = 'period-filter' }
export enum QuantitativeModifierType { NUMBER_OF_VISITS = 'number-of-visits' }

export type SingleVisitCriterionJsonType = { type: SingleVisitCriterionType; parameters: SingleVisitCriterionParameters }
export type TemporalModifierJsonType = { type: TemporalModifierType; parameters: TemporalModifierParameters }
export type QuantitativeModifierJsonType = { type: QuantitativeModifierType; parameters: QuantitativeModifierParameters }

// Main interface gathering all the sub-interfaces

interface SegmentCriterionParameters {
  criteria: SingleVisitCriterionJsonType[];
  temporalModifier: TemporalModifierJsonType;
  quantitativeModifier: QuantitativeModifierJsonType;
  useAndOperator: boolean;
}

export enum SegmentCriterionType { VISIT = 'visit' }

export type SegmentCriterionJsonType = { type: SegmentCriterionType; parameters: SegmentCriterionParameters }

@Injectable()
export class SegmentsService {
  /////////////////////////////////////
  // FOR DEMO ONLY
  /////////////////////////////////////
  private static getDummySegment(personaId: string): Observable<Segment> {
    switch (personaId) {
      case 'fashionista':
        return of({
          id: 'fashionista',
          name: 'Fashionista',
          demo: true
        });
      case 'follower':
        return of({
          id: 'followers',
          name: 'Followers',
          demo: true
        });
      case 'classique':
        return of({
          id: 'classique',
          name: 'Classique',
          demo: true
        });
      default:
        return throwError(new Error('Dummy segment not found'));
    }
  }


  private static deserializeSegmentCriterionFromJson(jsonCriterion: SegmentCriterionJsonType): SegmentCriterion {
    switch (jsonCriterion.type) {
      case SegmentCriterionType.VISIT:
        return new VisitCriterion(
          jsonCriterion.parameters?.criteria?.map(SegmentsService.deserializeSingleVisitCriterion),
          SegmentsService.deserializeTemporalModifier(jsonCriterion.parameters?.temporalModifier),
          SegmentsService.deserializeQuantitativeModifier(jsonCriterion.parameters?.quantitativeModifier),
          jsonCriterion.parameters.useAndOperator
        );
      default:
        throw Error('Cannot parse segment criterion. Unknown type: ' + jsonCriterion.type);
    }
  }

  private static deserializeSingleVisitCriterion(jsonCriterion: SingleVisitCriterionJsonType): SingleVisitCriterion {
    switch (jsonCriterion.type) {
      case SingleVisitCriterionType.URL_CONTAINS:
        return new UrlContainsCriterion(jsonCriterion.parameters.patterns);
      case SingleVisitCriterionType.CATEGORY:
        return new CategoryCriterion(jsonCriterion.parameters.categories);
      default:
        throw Error('Cannot parse single visit criterion. Unknown type: ' + jsonCriterion.type);
    }
  }

  private static deserializeTemporalModifier(jsonCriterion: TemporalModifierJsonType): TemporalModifier {
    switch (jsonCriterion.type) {
      case TemporalModifierType.LAST_N_VISITS:
        return new LastNVisitsModifier(jsonCriterion.parameters.n);
      case TemporalModifierType.PERIOD:
        return new PeriodFilterModifier(jsonCriterion.parameters.n, jsonCriterion.parameters.temporalUnit);
      default:
        throw Error('Cannot parse temporal modifier. Unknown type: ' + jsonCriterion.type);
    }
  }

  private static deserializeQuantitativeModifier(jsonCriterion: QuantitativeModifierJsonType): QuantitativeModifier {
    switch (jsonCriterion.type) {
      case QuantitativeModifierType.NUMBER_OF_VISITS:
        return new NumberOfVisitsModifier(jsonCriterion.parameters.n, jsonCriterion.parameters.operator);
      default:
        throw Error('Cannot parse quantitative modifier. Unknown type: ' + jsonCriterion.type);
    }
  }

  get segmentsOfSelectedEngine$(): Observable<Segment[]> {
    return this.store.select(selectSegmentsOfSelectedEngine);
  }

  get segmentsFolderOfSelectedEngine$(): Observable<Folder> {
    return this.store.select(selectSegmentsFolderOfSelectedEngine);
  }

  get selectedSegment$(): Observable<Segment | undefined> {
    return this.store.select(selectSelectedSegment);
  }

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  getSelectedSegment(): Promise<Segment | undefined> {
    return firstValueFrom(this.selectedSegment$);
  }

  /////////////////////////////////////
  ////////////////// Segments
  /////////////////////////////////////
  createOrUpdateSegment(engineId: number, name: string, criteria: SegmentCriterion[], segmentId?: string): Promise<Segment> {
    let payload = {};

    if (segmentId) {
      payload = {
        engineId,
        id: segmentId,
        name,
        criteria: criteria.map((criterion) => criterion.toJson())
      }
    } else {
      payload = {
        engineId,
        name,
        criteria: criteria.map((criterion) => criterion.toJson())
      }
    }

    const segments$ = this.http.put<Segment>(`${environment.apiUrl2}/2.0/engines/${engineId}/segments`, payload);

    return firstValueFrom(segments$)
      .then((segment: Segment) => {
        const segmentPermissions: SegmentPermissions = {
          segmentId: segment.id,
          configuration: true,
          statistics: true,
          createActions: true
        };

        this.store.dispatch(new UpsertSegmentInSelectedEngineAction({ segment }));

        this.store.dispatch(new InsertSegmentPermissionsInSelectedEngineAction({ segmentPermissions }));
        return segment;
      });
  }

  getSegmentById(engineId: number, segmentId: string): Observable<Segment> {
    if (engineId === -1) {
      return SegmentsService.getDummySegment(segmentId);
    } else {
      return this.http.get<Segment>(`${environment.apiUrl2}/2.0/engines/${engineId}/segments/${segmentId}`)
        .pipe(
          map((segment: Segment) => {
            segment.criteria = (segment.criteria as unknown as SegmentCriterionJsonType[]).map(
              (jsonCriterion) => SegmentsService.deserializeSegmentCriterionFromJson(jsonCriterion)
            );
            return segment;
          })
        );
    }
  }

  getCategories(engineId: number): Promise<string[]> {
    const categories$ = this.http.get<string[]>(`${environment.apiUrl2}/2.0/engines/${engineId}/categories`);
    return firstValueFrom(categories$);
  }

  deleteSegment(engineId: number, segment: Segment): Promise<void> {
    const segments$ = this.http.delete(`${environment.apiUrl2}/2.0/engines/${engineId}/segments/${segment.id}`);
    return firstValueFrom(segments$)
      .then(() => {
        this.store.dispatch(new DeleteSegmentFromSelectedEngineAction({ id: segment.id }));
    });
  }


  /////////////////////////////////////
  ////////////////// Actions
  /////////////////////////////////////

  getActionById(engineId: number, segmentId, actionId: string): Promise<SegmentAction> {
    const actions$ = this.http
      .get<SegmentAction>(`${environment.apiUrl2}/2.0/engines/${engineId}/segments/${segmentId}/actions/${actionId}`);
    return firstValueFrom(actions$);
  }

  createOrUpdateAction(engineId: number, action: SegmentAction): Promise<string> {
    const action$ = this.http
      .put<SegmentAction>(`${environment.apiUrl2}/2.0/engines/${engineId}/segments/${action.segmentId}/actions`, JSON.stringify(action), {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      });
    return firstValueFrom(action$)
      .then((action) => {
        return action.id
      });
  }

  deleteAction(engineId: number, segmentId: string, actionId: string): Promise<void> {
    const action$ = this.http
      .delete<void>(`${environment.apiUrl2}/2.0/engines/${engineId}/segments/${segmentId}/actions/${actionId}`);
    return firstValueFrom(action$);
  }

}
