import { createAction, props, union } from '@ngrx/store';

import { RecommendationPipeline } from '../../declarations/pipeline/recommendation-pipeline';

export const loadTemplatePipeline = createAction(
  '[NEWSLETTERS][PIPELINE] Load',
  props<{ pipeline: RecommendationPipeline }>()
);

export const clearTemplatePipeline = createAction(
  '[NEWSLETTERS][PIPELINE] Clear'
);

export const syncRecommendationSourcesWithArticles = createAction(
  '[NEWSLETTERS][PIPELINE] Sync sources of recos and articles',
  props<{ newIndexes: number[] }>()
);

export const clearAllRecommendationSources = createAction(
  '[NEWSLETTERS][PIPELINE] Clear sources of recos'
);

export const pipelineActions = union({
  loadTemplatePipeline,
  clearTemplatePipeline,
  syncRecommendationSourcesWithArticles,
  clearAllRecommendationSources
});

export type PipelineAction = typeof pipelineActions
