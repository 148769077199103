import {
  RGB_WHITE,
  RGBA_BLACK,
  rgbToHexString
} from '../../../mediego-common-module/utils/color.utils';
import { SizeUnit } from '../enums';
import { InnerRenderingProperties } from '../rendering-properties';

export const DEFAULT_BUTTON_INNER_STYLES: Partial<InnerRenderingProperties> = {
  buttonPaddings: {
    top: 10,
    right: 20,
    bottom: 10,
    left: 20
  },
  buttonColor: RGBA_BLACK,
  buttonBorderRadius: 10,
  textColor: rgbToHexString(RGB_WHITE),
  textFont: ['Verdana', 'Latin Modern Sans', 'sans-serif'],
  fontWeight: 'normal',
  fontSize: {
    value: 12,
    unit: SizeUnit.PX
  },
  minWidth: {
    value: 0,
    unit: SizeUnit.PX
  },

  // on hover (won't be applied if equals to their default counterpart)
  buttonColorHover: RGBA_BLACK,
  textColorHover: rgbToHexString(RGB_WHITE)
};

export const DEFAULT_IMAGE_INNER_STYLES: Partial<InnerRenderingProperties> = {
  imageWidth: {
    value: 100,
    unit: SizeUnit.PERCENT,
    responsive: false
  }
};

export const DEFAULT_TITLE_INNER_STYLES: Partial<InnerRenderingProperties> = {
  margins: { top: 0, right: 0, bottom: 0, left: 0 }
};

export const DEFAULT_TEXT_INNER_STYLES: Partial<InnerRenderingProperties> = {
  margins: { top: 0, right: 0, bottom: 0, left: 0 }
};
