import { DecimalPipe, PercentPipe } from '@angular/common';
import { PipeTransform } from '@angular/core';

import dayjs, { Dayjs }  from 'dayjs';

import { hsvToRgb } from './color.utils';

export class ChartsUtils {

  static fillArrayWithValueUntilLength(array: any[], value: any, length: number): void {
    if (!Array.isArray(array)) {
      console.warn('__ChartsUtils.fillArray(): provided array was not valid. had to initialize a new empty one.');
      array = [];
    }

    while (array.length < length) {
      array.push(value);
    }
  }

  static tooltipLabelPercent(dataAxis: 'x' | 'y' = 'y', alreadyPercent: boolean = false): (tooltipItem) => string {
    return (tooltipItem): string => {
      const value = tooltipItem.raw;
      const dataset_label = tooltipItem.dataset.label;
      let tooltip = (alreadyPercent ? value : value * 100).toFixed(2) + '%';
      if (dataset_label) {
        tooltip = dataset_label + ': ' + tooltip;
      }
      return tooltip;
    };
  }

  static tooltipLabelWithoutDatasetLabel(tooltipItem): string {
    return tooltipItem.raw;
  }

  static tooltipLabelForTriDimensionalChart(resourceY: string, relationBetweenResources: string, resourceZ: string): (tooltipItem) => string {
    return (tooltipItem): string => {
      return `${tooltipItem.raw} ${resourceY} ${relationBetweenResources} ${tooltipItem.dataset.label} ${resourceZ}`;
    };
  }

  static tooltipLabelTransform(pipe?: PipeTransform, prefix?: string, suffix?: string, dataAxis: 'x' | 'y' | 'radial' = 'y'): (tooltipItem, data) => string {
    return (tooltipItem, data): string => {
      let value;

      if (dataAxis === 'radial') {
        value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
      } else {
        value = tooltipItem.formattedValue;
      }

      if (pipe) {
        value = pipe.transform(value);
      }

      if (prefix) {
        value = prefix + value;
      }

      if (suffix) {
        value = value + suffix;
      }

      return value;
    };
  }

  static tooltipTitlePrefixSuffix(prefix?: string, suffix?: string, labelAxis: 'x' | 'y' = 'y'): (tooltipItem, data) => string {
    return (tooltipItem, _): string => {
      let label = labelAxis === 'x' ? tooltipItem[0].label : tooltipItem[0].label;

      if (prefix) {
        label = prefix + label;
      }

      if (suffix) {
        label = label + suffix;
      }

      return label;
    };
  }

  static tickPercent(locale: string): (value, index, values) => string {
    return (value, _, __): string => new PercentPipe(locale).transform(value, '1.0-2');
  }

  static tickDecimal(locale: string): (value, index, values) => string {
    return (value, _, __): string => new DecimalPipe(locale).transform(value);
  }

  static generateHeatColorsFromValues(values: number[]): number[][] {
    const max = Math.max(...values);

    return values.map((value) => {
      const fraction = value / max;
      const hue = (Math.floor(50 * (1 - fraction)) + 10) / 360;
      return hsvToRgb(hue, 1, 1);
    });
  }

  // static generateColorHuesFromValues(values: number[], seedRgbArray: number[] = RGB_ARRAY_MEDIEGO_TEAL): number[][] {
  //   let seedHSL = rgbToHsl(seedRgbArray[0], seedRgbArray[1], seedRgbArray[2]);
  //   let max = Math.max(...values);
  //
  //   return values.map((value) => {
  //     let fraction = value / max;
  //     let hue = (Math.floor(50 * (1 - fraction)) + 10) / 360;
  //     return hsvToRgb(hue, 1, 1);
  //   });
  // }

  static displayPercentagesPlugin(chartInstance: any) {
    const ctx = chartInstance.ctx;

    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';

    chartInstance.config.data.datasets.forEach((dataset, datasetIndex) => {
      const total = dataset.data.reduce((acc, num) => acc + num, 0);

      if (total !== 0) {
        const meta = chartInstance.controller.getDatasetMeta(datasetIndex);

        meta.data.forEach((bar, barIndex) => {
          const data = dataset.data[barIndex];
          const model = bar._model;
          const scaleMax = bar._yScale.maxHeight;
          const barHeight = model.base - model.y;

          const yPos = barHeight / scaleMax >= 0.95 ? model.y + 20 : model.y - 5;

          ctx.fillText(Math.round(data / total * 100) + '%', model.x, yPos);
        });
      }
    });
  }

  static chunk<T>(arr: T[], chunkSize: number) {
    const _arr = [...arr];
    const chunks: T[][] = [];

    while (_arr.length) {
      chunks.push(_arr.splice(0, chunkSize));
    }
    return chunks;
  }

  static decimateArray(array: any[], decimationRatio: number) {
    const totalItems = array.length;

    if (totalItems === 0) {
      return array;
    }

    const newArray = [];
    const maxLength = Math.round(array.length * (1 - decimationRatio));
    const delta = Math.floor(array.length / maxLength);

    for (let i = 0; i < array.length; i = i + delta) {
      newArray.push(array[i]);
    }

    return newArray;
  }

  static isDate(value: any): value is Dayjs {
    return dayjs.isDayjs(value);
  }

  static labelsAreEqual<T = number | string | Dayjs>(label1: T, label2: T): boolean {
    if (ChartsUtils.isDate(label1) && ChartsUtils.isDate(label2)) {
      return label1.isSame(label2);
    } else {
      return label1 === label2;
    }
  }

}
