import { Pipe, PipeTransform } from '@angular/core';

import { Folder } from '../../main-module/declarations/folder';

@Pipe({
  name: 'sortFolders'
})
export class SortFoldersPipe implements PipeTransform {

  transform(array: Folder[], args: 'asc' | 'desc' = 'asc'): Folder[] {
    switch (args) {
      case 'asc':
        return array.sort((a, b) => a?.name?.localeCompare(b?.name));
      case 'desc':
        return array.sort((a, b) => b?.name?.localeCompare(a?.name));
    }
  }

}
