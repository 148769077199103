import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { NewsletterGlobalAction, resetNewsletter } from '../../../newsletters-module/state/actions/newsletter.actions';
import {
  addContentToLayout,
  addLayout,
  deleteLayouts,
  LayoutAction,
  resetContentOfLayout,
  setLayoutHighlight, setLayoutRenderingCondition, unsetLayoutRenderingCondition, updateLayoutCustomBlockRef,
  updateLayoutRenderingProps,
  updateLayoutSizeProps
} from '../actions/layout.actions';
import {
  addRowsToTemplateModel,
  addRowToTemplateModel,
  clearTemplateModel,
  importTemplateModel,
  loadTemplateModel
} from '../actions/template-model.actions';

import { Content } from '../../declarations/content';
import { ContentType, LayoutType } from '../../declarations/enums';
import { Layout } from '../../declarations/layout';
import { SizeRenderingProperties } from '../../declarations/rendering-properties';

import { TemplateModelUtils } from '../../utils/template-model.utils';
// Omit type will be included in Typescript 3.5
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export interface SLayout extends Omit<Layout, 'children'> {
  children: Array<{ id: string; type: LayoutType | ContentType; size?: Partial<SizeRenderingProperties> }>;
}

export type LayoutState = EntityState<SLayout>;

export const layoutsAdapter: EntityAdapter<SLayout> = createEntityAdapter<SLayout>();

export const initialState: LayoutState = layoutsAdapter.getInitialState();

const {
  // selectIds,
  // selectEntities,
  // selectAll,
  // selectTotal
} = layoutsAdapter.getSelectors();

const generateLayoutsFromRow = (row): SLayout[] => {
  return [row, ...TemplateModelUtils.extractSLayoutsFromParentElement(row)]
    .map((layout) => ({
      ...layout,
      children: layout.children.map((__) => ({
        id: __.id,
        type: __.type
      }))
    }))
}

const reducer = createReducer(
  initialState,
  on(resetNewsletter, (_) => initialState),
  on(
    loadTemplateModel,
    importTemplateModel,
    (state, { model }) =>
      layoutsAdapter.setAll(
        TemplateModelUtils.extractSLayoutsFromParentElement(model),
        state
      )
  ),
  on(clearTemplateModel, (state) => {
    return layoutsAdapter.removeAll(state);
  }),
  on(addLayout, (state, { layout }) => {
    return layoutsAdapter.addOne(layout, state);
  }),
  on(deleteLayouts, (state, { ids }) => {
    return layoutsAdapter.removeMany(ids, state);
  }),
  on(
    addRowToTemplateModel,
    (state, { row }) =>
      layoutsAdapter.addMany(
        generateLayoutsFromRow(row),
        state
      )
  ),
  on(
    addRowsToTemplateModel,
    (state, { rows }) => {
      const allLayouts: SLayout[] = rows.reduce((res, row) => [
          ...res,
          ...generateLayoutsFromRow(row)
        ], []);

      return layoutsAdapter.addMany(
        allLayouts,
        state
      );
    }
  ),
  on(setLayoutHighlight, (state, { id, highlighted }) => {
    const current = state.entities[id];
    return layoutsAdapter.updateOne({
      id, changes: {
        renderingProperties: {
          ...current.renderingProperties,
          highlighted
        }
      }
    }, state)
  }),
  on(addContentToLayout, (state, { layoutId, placeholderId, content }) => {
    const current = state.entities[layoutId];
    const childIndex = current.children.findIndex((__) => __.id === placeholderId);

    return layoutsAdapter.updateOne({
      id: layoutId,
      changes: {
        children: [
          ...current.children.slice(0, childIndex),
          { id: content.id, type: content.type },
          ...current.children.slice(childIndex + 1)
        ]
      }
    }, state);
  }),
  on(resetContentOfLayout, (state, { layoutId, contentId }) => {

    const current = state.entities[layoutId];
    const childIndex = current.children.findIndex((__) => __.id === contentId);
    const oldSize: SizeRenderingProperties = (current.children[childIndex] as Content).size;

    return layoutsAdapter.updateOne({
      id: layoutId,
      changes: {
        children: [
          ...current.children.slice(0, childIndex),
          {
            id: contentId,
            type: ContentType.Empty,
            size: { ...oldSize }
          },
          ...current.children.slice(childIndex + 1)
        ]
      }
    }, state);

  }),
  on(updateLayoutSizeProps, (state, { id, partial }) => {
    const current = state.entities[id];
    return layoutsAdapter.updateOne(
      { id, changes: { size: Object.assign({}, current.size || {}, partial) } },
      state
    )
  }),
  on(updateLayoutRenderingProps, (state, { id, partial }) => {
    const current = state.entities[id];
    return layoutsAdapter.updateOne(
      { id, changes: { renderingProperties: Object.assign({}, current.renderingProperties, partial) } },
      state
    )
  }),
  on(updateLayoutCustomBlockRef, (state, { id, blockRefId, blockRefChildrenId }) => {
    return layoutsAdapter.updateOne(
      {
        id,
        changes: {
          customBlockRef: {
              id: blockRefId,
              childrenId: blockRefChildrenId
            }
          }
        },
      state
    );
  }),
  on(setLayoutRenderingCondition, (state, { id, conditions }) => {
    return layoutsAdapter.updateOne(
      {
        id,
        changes: {
          renderingCondition: conditions
        }
      },
      state
    )
  }),
  on(unsetLayoutRenderingCondition, (state, { id }) => {
    return layoutsAdapter.updateOne(
      {
        id,
        changes: {
          renderingCondition: undefined
        }
      },
      state
    )
  })
);

export function layoutReducer(state: LayoutState, action: LayoutAction | NewsletterGlobalAction): LayoutState {
  return reducer(state, action);
}
