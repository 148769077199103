import { Action }   from '@ngrx/store';

import { Language } from '../../declarations/lang';

export enum SettingsActionTypes {
  SET_LANG = 'SET_LANGUAGE'
}

export class SetLanguageAction implements Action {
  readonly type = SettingsActionTypes.SET_LANG;

  constructor(public payload: { lang: Language }) {}
}

export type SettingsAction =
  SetLanguageAction;
