<div fxLayout="column" fxFlex="0 0 100%" class="header" fxLayoutAlign="center center">

  <mat-icon class="error-icon" color="warn">warning</mat-icon>

	<span class="error-headline">
	  Impossible de charger les données...
	</span>

  <span>
	  Cette page indique un problème de réseau rendant impossible l'accès à nos serveurs
	</span>
  <span (click)="openSupport()" class="join-support">
    Si le problème persiste et ne semble pas provenir de votre connexion Internet,<br/>nous vous invitons à joindre le support technique en cliquant ici
  </span>
</div>
