import { createAction, props, union } from '@ngrx/store';

import { NewslettersConfig } from '../../declarations/newsletters-config';


export const setNewslettersConfig = createAction(
  '[CONFIG][NEWSLETTERS_CONFIG] Set Config',
  props<{ config: NewslettersConfig }>()
);

export const loadNewslettersConfigColors = createAction(
  '[CONFIG][NEWSLETTERS_CONFIG] Load Colors',
  props<{ colors: string[] }>()
);

export const updateNewslettersConfigColor = createAction(
  '[CONFIG][NEWSLETTERS_CONFIG] Set One Color',
  props<{ color: string; index: number }>()
);

export const updateNewslettersConfigPrimaryColor = createAction(
  '[CONFIG][NEWSLETTERS_CONFIG] Set Primary Color',
  props<{ color: string }>()
);

export const updateNewslettersConfigSecondaryColor = createAction(
  '[CONFIG][NEWSLETTERS_CONFIG] Set Secondary Color',
  props<{ color: string }>()
);

export const loadNewslettersConfigFonts = createAction(
  '[CONFIG][NEWSLETTERS_CONFIG] Load Fonts',
  props<{ fonts: string[] }>()
);

export const updateNewslettersConfigFont = createAction(
  '[CONFIG][NEWSLETTERS_CONFIG] Set One Font',
  props<{ font: string; index: number }>()
);

const newslettersConfigActions = union({
  loadNewslettersConfigColors,
  updateNewslettersConfigColor,
  updateNewslettersConfigPrimaryColor,
  updateNewslettersConfigSecondaryColor,
  loadNewslettersConfigFonts,
  updateNewslettersConfigFont,
  setNewslettersConfig
});

export type NewslettersConfigAction = typeof newslettersConfigActions;
