import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable }             from '@angular/core';

import { Store } from '@ngrx/store';

import { firstValueFrom, Observable } from 'rxjs';
import { take }  from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService }    from 'ngx-cookie';

import { AppState }  from '../main-module/state';
import { AuthState }                 from '../main-module/state/reducers/auth.reducer';
import { selectAuthState }           from '../main-module/state/selectors';
import {
  select__IsAdmin,
  selectEmail,
  selectIsAdmin,
  selectIsDemoUser,
  selectIsLoggedIn
}                                    from '../main-module/state/selectors/auth-selectors';
import { LogInAction, LogOutAction } from '../main-module/state/actions/auth.actions';

import { JWT_TOKEN_STORAGE_KEY, MediegoJwt } from '../main-module/declarations/mediego-jwt';

import { environment } from '../../environments/environment';
import { AuthUtils } from '../main-module/utils/auth.utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static HEADER_TEST_AUTHENTICATION = 'X-test-authentication';

  get authState$(): Observable<AuthState> {
    return this.store.select(selectAuthState);
  }
  get isLoggedIn$(): Observable<boolean> {
    return this.store.select(selectIsLoggedIn);
  }
  get email$(): Observable<string | undefined> {
    return this.store.select(selectEmail);
  }
  // real original value
  get __isAdmin$(): Observable<boolean> {
    return this.store.select(select__IsAdmin);
  }
  // for display purposes
  get isAdmin$(): Observable<boolean> {
    return this.store.select(selectIsAdmin);
  }
  get isDemoUser$(): Observable<boolean> {
    return this.store.select(selectIsDemoUser);
  }

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  private jwtHelper = new JwtHelperService();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private store: Store<AppState>
  ) {}

  init(): boolean {
    const rawJwt = localStorage.getItem(JWT_TOKEN_STORAGE_KEY);

    if (rawJwt) {
      const demoExpiryDateString = this.cookieService.get('MEDIEGO_DEMO');
      const demoExpiryDate: number | undefined = demoExpiryDateString ? +demoExpiryDateString : undefined;

      if (demoExpiryDate === undefined || !AuthUtils.isDemoExpired(demoExpiryDate)) {
        const jwt: MediegoJwt = this.jwtHelper.decodeToken(rawJwt);
        this.store.dispatch(new LogInAction({ email: jwt.email, isAdmin: jwt.superAdmin, demoExpiryDate }));
        return true;
      }
    }

    // ELSE
    this.store.dispatch(new LogOutAction());
    return false;
  }

  getIsAdmin(): Promise<boolean> {
    const admin$ = this.isAdmin$.pipe(take(1));
    return firstValueFrom(admin$);
  }

  getEmail(): Promise<string> {
    const email$ = this.email$.pipe(take(1));
    return firstValueFrom(email$);
  }

  verifySignupInviteToken(token: string): Promise<string> {
    const token$ = this.http.get(`${environment.apiUrl2}/2.0/user/verify-token`, {
      params: new HttpParams().set('token', token),
      responseType: 'text'
    });
    return firstValueFrom(token$);
  }


  acceptInvite(token: string, password: string): Promise<string> {
    const invite$ = this.http.post(`${environment.apiUrl2}/2.0/user/accept-invite`, {
      token,
      password
    }, { responseType: 'text' });
    return firstValueFrom(invite$);
  }

  login(email: string, password: string): Promise<void> {
    const loggedIn$ = this.http.post(environment.apiUrl2 + '/2.0/signIn', {
      email,
      password
    }, { responseType: 'text' });

    return firstValueFrom(loggedIn$)
      .then((rawJwt: string) => {
        const jwt: MediegoJwt = this.jwtHelper.decodeToken(rawJwt);
        localStorage.setItem(JWT_TOKEN_STORAGE_KEY, rawJwt);
        this.store.dispatch(new LogInAction({ email: jwt.email, isAdmin: jwt.superAdmin }));
      });
  }

  authenticate(email: string, password: string): Promise<boolean> {
    const authentication$ = this.http.post(environment.apiUrl2 + '/2.0/signIn', {
      email,
      password
    }, { responseType: 'text', headers: { [AuthService.HEADER_TEST_AUTHENTICATION]: 'true' } });

    return firstValueFrom(authentication$)
      .then(() => {
        return true;
      }).catch(() => {
        return false;
      });
  }

  logout(): void {
    this.store.dispatch(new LogOutAction());
    localStorage.removeItem('mediego_jwt');
    (window as any).Intercom('shutdown');
  }

  impersonate(email: string): Promise<void> {
    const impersonate$ = this.http.post(`${environment.apiUrl2}/2.0/impersonate?email=${email}`, undefined, { responseType: 'text' });

    return firstValueFrom(impersonate$)
      .then((rawJwt: string) => {
        const jwt: MediegoJwt = this.jwtHelper.decodeToken(rawJwt);
        localStorage.setItem(JWT_TOKEN_STORAGE_KEY, rawJwt);
        this.store.dispatch(new LogInAction({ email: jwt.email, isAdmin: jwt.superAdmin }));
        document.location.reload();
      });
  }


  requestPasswordReset(email: string): Promise<string> {
    const reset$ = this.http
      .post(`${environment.apiUrl2}/2.0/user/password-reset/request`, { email }, { responseType: 'text' });

    return firstValueFrom(reset$);
  }

  // returns email associated with password reset token
  verifyToken(token: string): Promise<string> {
    const token$ = this.http.get(`${environment.apiUrl2}/2.0/user/password-reset/verify-token`,
      {
        params: new HttpParams().set('token', token),
        responseType: 'text'
      }
    );
    return firstValueFrom(token$);
  }

  setNewPassword(token: string, newPassword: string): Promise<string> {
    const password$ = this.http
      .post(`${environment.apiUrl2}/2.0/user/password-reset/set-new`, { token, newPassword }, { responseType: 'text' });
    return firstValueFrom(password$);
  }

  changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const password$ = this.http.post<void>(`${environment.apiUrl2}/2.0/user/change-password`, {
      oldPassword,
      newPassword
    });
    return firstValueFrom(password$);
  }

  deleteMyAccount(): Promise<void> {
    const account$ = this.http.post<void>(`${environment.apiUrl2}/2.0/user/delete-account`, {});
    return firstValueFrom(account$);
  }
}
