<div id="container">
  <mat-card [ngSwitch]="status | async" fxLayout="column" fxLayoutAlign="center">
    <ng-container *ngSwitchCase="'ready'">
      <div id="main" fxLayout="row wrap" fxLayoutGap="5px">
        <div id="value" fxFlexAlign="baseline">{{ formatNumber(value) }}</div>
        <div id="title" fxFlexAlign="baseline">{{ cardTitle }}</div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'loading'">
      <div id="mainLoading">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 280 40" preserveAspectRatio="xMidYMid slice">
          <rect x="0" y="4" width="130" height="32" rx="3" ry="3" fill="#e5ebee"></rect>
          <rect x="140" y="11" width="125" height="25" rx="3" ry="3" fill="#e5ebee"></rect>
        </svg>
      </div>
    </ng-container>

    <!-- ERROR PLACEHOLDER -->
    <ng-container *ngSwitchCase="'error'">
      <div id="errorContainer" fxLayout="row" fxLayoutAlign="center center" fxFill>
        <mat-icon>error</mat-icon>
      </div>
    </ng-container>

    <div id="sidebar"></div>
  </mat-card>
</div>
