import { SingleVisitCriterion } from './single-visit-criterion';

export class CategoryCriterion extends SingleVisitCriterion {
  readonly type = 'category';
  readonly displayName = 'Catégorie(s)';
  readonly icon = 'label';

  categories: string[];

  constructor(categories: string[] = []) {
    super();
    this.categories = categories;
  }

  isValid(): boolean {
    return this.categories.length > 0;
  }

  getCategories(): string[] {
    return this.categories;
  }

  addCategory(category: string): void {
    this.categories.push(category);
  }

  removeCategory(index: number): string {
    return this.categories.splice(index, 1)[0];
  }


  parametersToJson(): any {
    return {
      categories: this.categories
    };
  }

}
