import { RGBA }                                       from 'ngx-color';

import { rgbToHexString } from '../../mediego-common-module/utils/color.utils';
import { boxRuleToString, sizeRuleToString }          from '../utils/rendering-properties.utils';

import { SizeUnit, TextAlignment, VerticalAlignment }         from './enums';
import { BorderRadiusRules, BorderRules, BoxRule, ResponsiveSizeRule, SizeRule } from './styles';

// export type RenderingProperties = Partial<SizeRenderingProperties & OuterRenderingProperties & InnerRenderingProperties>;
export type RenderingProperties =
  Partial<SizeRenderingProperties> &
  Partial<OuterRenderingProperties> &
  Partial<InnerRenderingProperties>;

export interface SizeRenderingProperties {
  width?: SizeRule;
  height?: SizeRule;
}

export interface OuterRenderingProperties {
  highlighted: boolean;

  backgroundColor: RGBA; //
  primaryColor: RGBA; //
  secondaryColor: RGBA; //

  borders: BorderRules; //
  borderRadius: BorderRadiusRules; //
  margins: BoxRule; //
  paddings: BoxRule; //

  responsive: boolean;
  layoutGap: number;

  width?: SizeRule; //
  height?: SizeRule; //

  textAlign: TextAlignment; //
  verticalAlign: VerticalAlignment; //
  spaceBetweenParagraphs: number;
}

export interface InnerRenderingProperties {
  margins: BoxRule; //

  textFont: string[]; //
  textColor: string; //
  textColorHover?: string; // optional
  fontSize: SizeRule; //
  fontWeight?: string; //
  lineHeight?: string; //

  // titleLevel: number;

  imageWidth: ResponsiveSizeRule; //
  minWidth: SizeRule;

  buttonPaddings: BoxRule; //
  buttonColor: RGBA; //
  buttonColorHover?: RGBA; // optional
  buttonBorderRadius: number;
  buttonTextAlign?: TextAlignment;
}

export interface SizeConstraints {
  widthUnit: SizeUnit;
  minWidth: number;
  maxWidth: number;
}

export const borderRadiusIsRelevant = (borderRadius: BorderRadiusRules): boolean => {
  return !!(borderRadius && (borderRadius.topLeft || borderRadius.topRight || borderRadius.bottomRight || borderRadius.bottomLeft));
}
/*
export const convertBorderRadiusToCSS = (props: BorderRadiusRules) => {
  if (props && (props.bottomLeft || props.bottomRight || props.topLeft || props.topRight)) {
    return props.topLeft + 'px';
  } else {
    return '';
  }
}*/

// https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius
export const applyBorderRadius = (props: BorderRadiusRules, element: HTMLElement) => {
  if (props && (props.bottomLeft || props.bottomRight || props.topLeft || props.topRight)) {
    if (props.sameForAllCorners) {
      element.style.borderRadius = props.topLeft + 'px';
    } else {
      /* top-left | top-right | bottom-right | bottom-left */
      element.style.borderRadius = `${props.topLeft}px ${props.topRight}px ${props.bottomRight}px ${props.bottomLeft}px`;
    }
  }
}

export const isTransparentRGBA = (color: RGBA): boolean => {
  return color.a === 0;
}

export const computeStyles = (props: RenderingProperties, forDashboard: boolean = false): { [p: string]: string } => {
  const styles = {};

  // ordered by increasing priority
  if (props.buttonColor && !isTransparentRGBA(props.buttonColor)) { styles['background-color'] = rgbToHexString(props.buttonColor); }
  if (props.secondaryColor && !isTransparentRGBA(props.secondaryColor)) { styles['background-color'] = rgbToHexString(props.secondaryColor); }
  if (props.primaryColor && !isTransparentRGBA(props.primaryColor)) { styles['background-color'] = rgbToHexString(props.primaryColor); }
  if (props.backgroundColor && !isTransparentRGBA(props.backgroundColor)) { styles['background-color'] = rgbToHexString(props.backgroundColor); }
  // HEX colors for optimizing mailer compatibility

  // Hover styles
  if (props.buttonColorHover && !isTransparentRGBA(props.buttonColorHover)) { styles['background-color'] = rgbToHexString(props.buttonColorHover); }
  if (props.textColorHover) { styles['color'] = props.textColorHover; }
  // Hover styles

  if (props.width || props.imageWidth) {
    styles['width'] = sizeRuleToString((props.width || props.imageWidth)!);
  }

  if (props.minWidth?.value) {
    styles['min-width'] = sizeRuleToString(props.minWidth);
  }

  if (props.height) {
    const heightVal = sizeRuleToString(props.height);
    styles['height'] = heightVal;
    styles['min-height'] = heightVal;
    styles['max-height'] = heightVal;
  }

  if (props.paddings) { styles['padding'] = boxRuleToString(props.paddings); }
  if (props.buttonPaddings) { styles['padding'] = boxRuleToString(props.buttonPaddings); }

  if (props.margins) {
    styles['margin'] = boxRuleToString(props.margins);
    if (Object.values(props.margins).find((margin) => !!margin)) {
      styles['display'] = 'block'; // margin won't be efficient on a table element
    }
  }

  if (props.verticalAlign) {
    styles['vertical-align'] = props.verticalAlign;
  }

  if (props.textAlign) {
    styles['text-align'] = props.textAlign;
  }

  if (props.buttonTextAlign) {
    styles['text-align'] = props.buttonTextAlign;
  }

  if (props.borderRadius) {
    let appliedBorderRadius: boolean = false;
    if (props.borderRadius.sameForAllCorners) {
      if (props.borderRadius.topLeft) {
        styles['border-radius'] = `${props.borderRadius.topLeft}px`;
        appliedBorderRadius = true;
      }
    } else {
      styles['border-radius'] = `${props.borderRadius.topLeft}px ${props.borderRadius.topRight}px ${props.borderRadius.bottomRight}px ${props.borderRadius.bottomLeft}px`
      /*if (props.borderRadius.topLeft) styles['border-top-left-radius'] = props.borderRadius.topLeft + 'px';
      if (props.borderRadius.topRight) styles['border-top-right-radius'] = props.borderRadius.topRight + 'px';
      if (props.borderRadius.bottomLeft) styles['border-bottom-left-radius'] = props.borderRadius.bottomLeft + 'px';
      if (props.borderRadius.bottomRight) styles['border-bottom-right-radius'] = props.borderRadius.bottomRight + 'px';*/
      appliedBorderRadius = !!(props.borderRadius.topLeft || props.borderRadius.topRight || props.borderRadius.bottomLeft || props.borderRadius.bottomRight);
    }

    if (appliedBorderRadius && !forDashboard) styles['overflow'] = 'hidden';
  }

  if (props.borders) {
    if (props.borders.sameForAllSides) {
      if (props.borders.top.width) {
        styles['border'] = `${props.borders.top.width}px ${props.borders.top.style} ${props.borders.top.color}`;
      }
    } else {

      if (props.borders.top.width || props.borders.right.width || props.borders.bottom.width || props.borders.left.width) {

        Object
          .entries(props.borders)
          .forEach(([side, border]) => {
            if (border.width) {
              styles[`border-${side}`] = `
             ${border.style} ${border.width}px ${border.color}
           `;
            }
          });

      }
    }
  }

  if (props.buttonBorderRadius !== undefined) {
    styles['border-radius'] = `${props.buttonBorderRadius}px`;
  }

  if (props.fontSize) {
    styles['font-size'] = sizeRuleToString(props.fontSize);
  }

  if (props.fontWeight) {
    styles['font-weight'] = props.fontWeight;
  }

  if (props.textColor) {
    styles['color'] = props.textColor;
  }

  if (props.textFont && props.textFont.length > 0) {
    styles['font-family'] = '';

    const appendFontToRule = (font, rule) => {
      if (font.split(',').length === 0) {
        if (font.includes(' ')) return `${rule}'${font}', `;
        else return `${rule}${font}, `;
      } else {
        font.split(',').forEach((subFont) => {
          if (subFont.includes(' ')) rule = `${rule}'${subFont.trim()}', `;
          else rule = `${rule}${subFont.trim()}, `;
        });
        return rule;
      }
    };

    for (const font of props.textFont) {
      if (font && font.length > 0) {
        styles['font-family'] = appendFontToRule(font, styles['font-family']);
      }
    }
    // removing last comma
    if (styles['font-family'].length) styles['font-family'] = styles['font-family'].slice(0, -2);

    // generic font if needed
    if (!styles['font-family'].includes('serif')) styles['font-family'] += ', sans-serif';
  }

  if (props.lineHeight) {
    styles['line-height'] = props.lineHeight;
  }

  if (props.width && props.margins && props.margins.left > 0 && props.margins.left === props.margins?.right) {
    const marginsForLayout = { ...props.margins, left: 0, right: 0 };
    styles['margin'] = boxRuleToString(marginsForLayout);

    const widthWithMarginsIncluded = { ...props.width };
    widthWithMarginsIncluded.value -= (props.margins.left + props.margins.right);
    styles['width'] = sizeRuleToString(widthWithMarginsIncluded);
  }

  return styles;
};

export const alterSpecificStylesForTemplate = (props: RenderingProperties = {}, styles: { [p: string]: string }): { [p: string]: string } => {

  // Deleting borders as it will be placed on ::before block in stead
  if (styles['border']) {
    delete styles['border'];
  }

  const directions = ['top', 'right', 'bottom', 'left'];
  for (const direction of directions) {
    if (styles['border-' + direction]) {
      delete styles['border-' + direction];
    }
  }

  if (styles['padding']) {
    delete styles['padding'];
  }

  // Applying borders thanks to CSS variables
  if (props.borders) {
    if (props.borders.sameForAllSides && props.borders.top) {
      for (const direction of directions) {
        styles['--model-border-' + direction + '-color'] = props.borders.top.color ? props.borders.top.color : '';
        styles['--model-border-' + direction + '-style'] = props.borders.top.style ? props.borders.top.style : 'solid';
        styles['--model-border-' + direction + '-width'] = (props.borders.top.width ? props.borders.top.width : '0') + 'px';
      }
    } else {
      for (const direction of directions) {
        styles['--model-border-' + direction + '-color'] = props.borders[direction].color ? props.borders[direction].color : '';
        styles['--model-border-' + direction + '-style'] = props.borders[direction].style ? props.borders[direction].style : 'solid';
        styles['--model-border-' + direction + '-width'] = (props.borders[direction].width ? props.borders[direction].width : '0') + 'px';
      }
    }
  }

  if (props.paddings) {
    styles['--model-padding-left'] = (props.paddings.left ? props.paddings.left : '0') + 'px';
    styles['--model-padding-right'] = (props.paddings.right ? props.paddings.right : '0') + 'px';
    styles['--model-padding-top'] = (props.paddings.top ? props.paddings.top : '0') + 'px';
    styles['--model-padding-bottom'] = (props.paddings.bottom ? props.paddings.bottom : '0') + 'px';
  }

  return styles;
};

export const applyStyles = (element: HTMLElement, props: RenderingProperties): void => {

  // if more than one fallback font is specified
  if (props?.textFont?.length) {

    if (props?.textFont?.find((font) => font?.includes(','))) {
      // client can specify multiple fonts at once, we normalize these fonts inside our array
      props.textFont = props.textFont.reduce((allFonts, font) => {
        if (font?.includes(',')) {
          const subFonts = font.split(',').map(subFont => subFont?.trim());
          return [...allFonts, ...subFonts];
        } else {
          return [...allFonts, font];
        }
      }, []);
    }

    // eliminating same fonts
    props.textFont = props.textFont.filter((font, index) => props.textFont.indexOf(font) === index);

  }


  const styles = computeStyles(props);
  Object.assign(element.style, styles);

  // if a style has been removed, we delete its key
  for (const key of Object.getOwnPropertyNames(element.style)) {
    if (key && key.length > 2 && !styles[key] && element.style[key] !== undefined) {

      try {

        delete element.style[key];
        // element.style.removeProperty(key); do not support camelCase

      } catch (error) {
        console.error('Error while trying to delete key "', key, '" of ', element.style);
        console.error(error);
      }
    }
  }
};
