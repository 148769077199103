import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Observable }                                         from 'rxjs';

import { ChartDataset, ChartOptions, Color, TooltipItem } from 'chart.js';
import { Tick } from 'chart.js/dist/types';
import dayjs, { isDayjs } from 'dayjs';
import capitalize           from 'lodash/capitalize';
import _ from 'lodash/fp';
import merge                from 'lodash/merge';
import set                  from 'lodash/set';

import { AppService }   from 'app/services/app.service';

import { IntervalType } from 'app/mediego-common-module/declarations/interval-type';
import { LoadingStatus }    from '../../../../main-module/declarations/loading-status';

import { ChartsUtils }  from 'app/mediego-common-module/utils/charts.utils';
import { rgbArrayToString } from '../../../utils/color.utils';

import 'chartjs-adapter-moment';

interface ColorStyles {
  backgroundColor: Color | Color[];
  borderColor: Color | Color[];
  hoverBackgroundColor: Color | Color[];
  hoverBorderColor: Color | Color[];
  pointBackgroundColor?: Color | Color[];
}

@Component({
  selector: 'app-mediego-chart-box',
  templateUrl: './chart-box.component.html',
  styleUrls: ['./chart-box.component.scss']
})
export class ChartBoxComponent implements OnInit, OnChanges {
  @Input() admin: boolean;
  @Input() type = 'line';
  @Input() orientation?: 'horizontal' | 'vertical';
  @Input() chartTitle: string;
  @Input() datasets: ChartDataset[];
  @Input() labels: any[];
  @Input() legend: boolean;
  @Input('colors') colorsRaw: number[][];
  colorsForChart: ColorStyles[];
  @Input() singleColor: boolean;
  @Input() heatMapColors: boolean;
  @Input() options: any;
  @Input() description: string;
  @Input() displayPercentOnBars: boolean;
  @Input() intervalType: IntervalType = 'day';
  @Input() cardWrapper: boolean = true;

  _defaultOptions = { plugins: [] } as any;
  _options: any;

  get status(): Observable<LoadingStatus> {
    return this.appService.loadingStatus$;
  }

  rgbColors = [
    [80, 192, 161], // teal
    [54, 162, 235], // blue
    [255, 99, 132], // red
    [153, 102, 255], // purple
    [151, 187, 205], // grey
    [255, 159, 64] // orange
  ];

  constructor(public appService: AppService) { }

  ngOnInit() {

    // _.set(this._defaultOptions, 'title.display', true);
    set(this._defaultOptions, 'maintainAspectRatio', false);
    set(this._defaultOptions, 'animation.duration', 0);

    if (this.type === 'line') {

      const intervalType = this.intervalType;

      const tickCallback: ChartOptions<'line'>['scales']['x']['ticks']['callback'] = function(tickInternalValue: number, index: number, ticks: Tick[]) {
        const value = this.getLabelForValue(tickInternalValue);

        if (ChartsUtils.isDate(value)) {
          switch (intervalType) {
            case 'month':
              return value.format('MMM YYYY');
            case 'week':
              return `Semaine ${ value.week() }`;
            case 'day':
              return value.format('ddd DD MMM');
            case 'hour':
              return value.format('HH[h]');
            default:
              return value.toISOString();
          }
        } else {
          return value;
        }
      }

      set(this._defaultOptions, 'tooltips.mode', 'index');
      set(this._defaultOptions, 'elements.line.fill', true);
      set(this._defaultOptions, 'elements.line.tension', 0);
      set(this._defaultOptions, 'elements.line.borderWidth', 2);
      set(this._defaultOptions, 'elements.point.hitRadius', 10);
      set(this._defaultOptions, 'elements.point.radius', 3);
      set(this._defaultOptions, 'elements.point.hoverRadius', 4);
      set(this._defaultOptions, 'elements.point.borderWidth', 2);
      set(this._defaultOptions, 'elements.point.hoverBorderWidth', 2);
      set(this._defaultOptions, 'scales.x', {});
      set(this._defaultOptions, 'scales.x.ticks.callback', tickCallback);
      set(this._defaultOptions, 'scales.y.beginAtZero', true);
      set(this._defaultOptions, 'plugins.tooltip.callbacks.title', function(values: Array<TooltipItem<'line'>>) {
        const labelRaw = values?.length && values[0]?.label;

        if (labelRaw) {
          if (isDayjs(labelRaw)) return labelRaw.format('ddd DD MMM');
          else {
            try {
              const timestamp = parseInt(values[0].label, 10);
              return dayjs.unix(timestamp / 1000).format('ddd DD MMM');
            } catch (e) {
              console.error('Error while trying to parse tooltip title for formatting with dayjs');
              return labelRaw;
            }
          }
        }

        return '';
      });
    } else if (this.type === 'bar' && this.orientation === 'horizontal') {

      set(this._defaultOptions, 'dataset.categoryPercentage', 0.5);
      set(this._defaultOptions, 'elements.rectangle.borderWidth', 2);
      set(this._defaultOptions, 'scales.x.beginAtZero', true);
      set(this._defaultOptions, 'indexAxis', 'y');

    } else if (this.type === 'bar') {
      // For some reason currently unknown, bar charts stop handling
      // the displayPercentagesPlugin correctly when the title is disabled.
      set(this._defaultOptions, 'title.display', true); // IMPORTANT. DO NOT REMOVE THIS.
      set(this._defaultOptions, 'legend.display', false);

      set(this._defaultOptions, 'dataset.categoryPercentage', 0.5);
      set(this._defaultOptions, 'elements.rectangle.borderWidth', 2);
      set(this._defaultOptions, 'scales.y.beginAtZero', true);

      if (this.displayPercentOnBars) {
        this._defaultOptions.plugins.push({
          afterDatasetsDraw: ChartsUtils.displayPercentagesPlugin
        });
      }
    }

    // merge this.options into default options and put in this._options
    this._options = merge(this._defaultOptions, this.options);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes?.datasets?.currentValue
      && !changes.datasets.firstChange
      && !_.isEqual(changes.datasets.currentValue, changes.datasets.previousValue)) {
      this.ngOnInit(); // for ease of use, re-init chart
    }

    if (changes.hasOwnProperty('datasets') && changes.datasets.currentValue.length > 0) {
      if (this.type === 'bar') {
        if (this.singleColor) {
          this.colorsForChart = [{ // mediego teal
            backgroundColor: Array(this.labels.length).fill('rgba(80, 192, 161, 0.1)'),
            borderColor: Array(this.labels.length).fill('rgba(80, 192, 161, 1)'),
            hoverBackgroundColor: Array(this.labels.length).fill('rgba(80, 192, 161, 0.6)'),
            hoverBorderColor: Array(this.labels.length).fill('rgba(80, 192, 161, 1)')
          }];
        } else if (this.heatMapColors) {
          const rgbColors = ChartsUtils.generateHeatColorsFromValues(changes.datasets.currentValue[0].data);

          this.colorsForChart = [{ // heatmap colors
            backgroundColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 0.5)),
            borderColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 0.8)),
            hoverBackgroundColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 0.8)),
            hoverBorderColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 1))
          }];
        } else if (this.colorsRaw) { // fixed colors
          const rgbColors = this.colorsRaw;
          this.colorsForChart = Array(changes.datasets.currentValue.length).fill({
            backgroundColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 0.1)),
            borderColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 1)),
            hoverBackgroundColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 0.6)),
            hoverBorderColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 1))
          });
        }
      } else if (this.type === 'pie' || this.type === 'doughnut') {
        this.colorsForChart = Array(changes.datasets.currentValue.length).fill({
          backgroundColor: this.rgbColors.map((rgb) => rgbArrayToString(rgb, 0.8)),
          hoverBackgroundColor: this.rgbColors.map((rgb) => rgbArrayToString(rgb, 1)),
          hoverBorderColor: this.rgbColors.map(() => 'rgba(255, 255, 255, 1)')
        });
      } else if (this.type === 'line') {
        if (this.singleColor) {
          this.colorsForChart = [{
            backgroundColor: Array(this.labels.length).fill('rgba(80, 192, 161, 0.1)'),
            borderColor: Array(this.labels.length).fill('rgba(80, 192, 161, 1)'),
            hoverBackgroundColor: Array(this.labels.length).fill('rgba(80, 192, 161, 0.6)'),
            hoverBorderColor: Array(this.labels.length).fill('rgba(80, 192, 161, 1)'),
            pointBackgroundColor: Array(this.labels.length).fill('rgba(80, 192, 161, 1)')
          }];
        } else if (this.colorsRaw) {
          const rgbColors = this.colorsRaw;
          this.colorsForChart = Array(changes.datasets.currentValue.length).fill({
            backgroundColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 0.1)),
            borderColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 1)),
            hoverBackgroundColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 0.6)),
            hoverBorderColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 1)),
            pointBackgroundColor: rgbColors.map((rgb) => rgbArrayToString(rgb, 1))
          });
        }
      } else {
        const mediegoDatasetIndex = changes.datasets.currentValue.findIndex((dataset: any) => dataset.label === 'mediego');
        // if exists and not at beginning
        if (mediegoDatasetIndex > 0) {
          // remove and get item
          const mediegoDataset = changes.datasets.currentValue.splice(mediegoDatasetIndex, 1)[0];
          // re-place item at beginning
          changes.datasets.currentValue.unshift(mediegoDataset);
        }

        this.datasets.forEach((dataset) => {
          dataset.label = capitalize(dataset.label);
        });
      }


      if (this.type === 'line') {
        const nbOfDatasets = changes.datasets.currentValue.length;
        this.legend = nbOfDatasets !== 1;
      }

      this.updateColorsInsideOptions();
    }
  }

  updateColorsInsideOptions() {
    if (!this.colorsForChart) return;

    let datasetsWithColors = [];

    this.colorsForChart.map(({ backgroundColor, borderColor, hoverBorderColor, hoverBackgroundColor, pointBackgroundColor }) => {
      datasetsWithColors = [
        ...this.datasets.map((dataset) => ({
          ...dataset,
          borderColor,
          backgroundColor,
          hoverBorderColor,
          hoverBackgroundColor,
          pointBackgroundColor,
          borderWidth: 2
        }))
      ];
    });

    this.datasets = datasetsWithColors;

  }
}
