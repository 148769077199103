import { Component, HostBinding, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-mediego-permissions-error',
  templateUrl: './permissions-error.component.html',
  styleUrls: ['./permissions-error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PermissionsErrorComponent {
  @HostBinding('class') classes = 'mainContent';


  openSupport() {
    (window as any).Intercom('show');
  }
}
