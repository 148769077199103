import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-mediego-section-title',
  template: `
    <h2 [class.title--primary]="color === 'primary'"
        [class.title--accent]="color === 'accent'"
        [class.title--warn]="color === 'warn'">
      <mat-icon *ngIf="icon" [color]="iconColor">{{ icon }}</mat-icon>&nbsp;<b [innerHTML]="title | safeHtml"></b>
    </h2>
    <h4 *ngIf="subtitle" class="subtitle">{{ subtitle }}</h4>
  `,
  styleUrls: ['./section-title.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SectionTitleComponent {
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() icon?: string;
  @Input() iconColor?: 'primary' | 'accent' | 'warn';
  @Input() color?: 'primary' | 'accent' | 'warn';
}
