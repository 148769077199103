import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], searchText: string, searchedProperty?: string): any[] {

    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter((it) => {

      if (searchedProperty) {
        if (it.collection?.length) {

          let parentFolderMatch;
          if (searchText.includes('/')) {
            parentFolderMatch = it.collection.join('/').toLowerCase().includes(searchText);
          } else {
            parentFolderMatch = it.collection.find(folder => folder.toLowerCase().includes(searchText));
          }

          return it[searchedProperty] && it[searchedProperty].toLowerCase().includes(searchText)
            || parentFolderMatch;
        }
        else return it[searchedProperty] && it[searchedProperty].toLowerCase().includes(searchText);
      } else {
        it.toLowerCase().includes(searchText);
      }

    });

  }
}
