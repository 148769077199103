import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Observable }                                                          from 'rxjs';

import { AppService }                                               from '../../../../services/app.service';

import { Language }                                                 from '../../../../main-module/declarations/lang';
import { LoadingStatus }                                            from '../../../../main-module/declarations/loading-status';
import { TopItem, TopItemsStats }                                   from '../../../declarations/stats/top-items-stats';

interface TopDisplayTab {
  tabTitle: string;
  tabData: TopItem[];
}

@Component({
  selector: 'app-mediego-top-display',
  templateUrl: './top-display.component.html',
  styleUrls: ['./top-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopDisplayComponent implements OnChanges {

  @Input() data: TopItemsStats;

  tabs: TopDisplayTab[] = [];

  get status$(): Observable<LoadingStatus> {
    return this.appService.loadingStatus$;
  }

  get locale$(): Observable<Language> {
    return this.appService.selectedLanguage$;
  }

  constructor(private appService: AppService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('data') && changes['data'].currentValue !== null) {
      this.tabs = [
        { tabTitle: 'SHARED.STATISTICS.TOP_ITEMS.BY_CLICKS', tabData: changes['data'].currentValue.topClicks },
        { tabTitle: 'SHARED.STATISTICS.TOP_ITEMS.BY_DISPLAYS', tabData: changes['data'].currentValue.topDisplays },
        { tabTitle: 'SHARED.STATISTICS.TOP_ITEMS.BY_CLICK_RATE', tabData: changes['data'].currentValue.topClickRate }
      ];
    } else {
      this.tabs = [];
    }
  }

}
