import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { AppService }                                from '../../../../services/app.service';
import { LayoutService }                             from '../../../../services/layout.service';

import { LoadingStatus }                             from '../../../../main-module/declarations/loading-status';

import { ChartsUtils }                               from '../../../utils/charts.utils';
import { rgbArrayToString }                          from '../../../utils/color.utils';

interface Entry {
  label: string;
  value: number;
  fraction: number;
  color: string;
}

@Component({
  selector: 'app-mediego-pie-grid',
  templateUrl: './pie-grid.component.html',
  styleUrls: ['./pie-grid.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieGridComponent implements OnInit, OnChanges, OnDestroy {

  @Input() cardTitle: string;
  @Input() data: number[];
  @Input() labels: string[];
  @Input() total: number;
  @Input() nbOfCols: number;
  @Input() colors: string[]; // in HEX

  rowHeight = 170;
  gutterSize = 15;


  rows = new BehaviorSubject<Entry[][]>([]);

  get status(): Observable<LoadingStatus> {
    return this.appService.loadingStatus$;
  }

  layoutSubscription: Subscription;

  constructor(
    private appService: AppService,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.layoutSubscription = this.layoutService.onBreakPointChange.subscribe((val) => {
      this.parseData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty('data') &&
      changes.hasOwnProperty('labels') &&
      changes.hasOwnProperty('total') &&
      changes.hasOwnProperty('nbOfCols')
    ) {
      this.parseData(changes);
    }
  }

  ngOnDestroy(): void {
    if (this.layoutSubscription) {
      this.layoutSubscription.unsubscribe();
    }
  }

  parseData(changes?: SimpleChanges) {
    const _data = (changes && changes['data'].currentValue as number[] || this.data);
    const _labels = (changes && changes['labels'].currentValue as string[] || this.labels);
    const _total = (changes && changes['total'].currentValue as number || this.total);
    const _nbOfCols = (changes && changes['nbOfCols'].currentValue as number || this.nbOfCols);

    const _chunkData = ChartsUtils.chunk(_data, _nbOfCols);
    const _chunkLabels = ChartsUtils.chunk(_labels, _nbOfCols);

    const rgbColors = ChartsUtils.generateHeatColorsFromValues(_data);

    this.rows.next(_chunkData.map((row, i) => {
      return row.map((value, j) => {
        const fraction = value / _total;
        const color = rgbArrayToString(rgbColors[i * _nbOfCols + j]);
        return {
          label: _chunkLabels[i][j],
          value,
          fraction,
          color
        };
      });
    }));
  }

}
