import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mediego-newsletter-item',
  template: `
    <mat-list-item>
      <mat-icon color="primary">mail_outline</mat-icon>&nbsp;{{ name }}
    </mat-list-item>
  `,
  styleUrls: []
})
export class NewsletterItemComponent {
  @Input() name: string
}
