import { SLayout } from '../state/reducers/layout.reducer';

import { ContentType } from '../declarations/enums';
import { TemplateModel } from '../declarations/template-model';

import { TemplateModelUtils } from './template-model.utils';


export class TemplatePowerspaceUtils {

  static extractAdPositionsFromModel(model: TemplateModel): string[] {
    const contents = TemplateModelUtils.extractContentsFromLayout(model);
    return contents
      .filter((content) => content?.type === ContentType.Powerspace)
      .map((content) => content.id);
  }

  static extractAdPositionsFromSModel(model: SLayout[]): string[] {
    return model.reduce((ids, layout) => {
      return [...ids, ...TemplateModelUtils.extractContentIdFromSLayout(layout, ContentType.Powerspace)]
    }, [])
  }

}
