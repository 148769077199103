import { Injectable }                                       from '@angular/core';

import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { CdkScrollable, ScrollDispatcher }                                         from '@angular/cdk/overlay';
import { MatSidenavContent } from '@angular/material/sidenav';

import { combineLatest, Observable }                                               from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  scrollOffset$: Observable<number>;
  isDesktopLowRes: Observable<boolean>;
  isDesktopHighRes: Observable<boolean>;
  isDesktop: Observable<boolean>;
  isTablet: Observable<boolean>;
  isMobile: Observable<boolean>;
  isLandscape: Observable<boolean>;
  onBreakPointChange: Observable<any>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private scrollDispatcher: ScrollDispatcher
  ) {

    this.onBreakPointChange = this.breakpointObserver.observe([
      Breakpoints.Web,
      Breakpoints.Tablet,
      Breakpoints.Handset,
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
      '(orientation: landscape)'
    ]).pipe(
      debounceTime(100),
      shareReplay(1)
    );

    this.scrollOffset$ = this.scrollDispatcher
      .scrolled()
      .pipe(
        filter((scrollable: CdkScrollable) => scrollable instanceof MatSidenavContent),
        map((scrollable: CdkScrollable) => scrollable.measureScrollOffset('top')),
        shareReplay(1)
      );

    this.isDesktopLowRes = combineLatest(
      [
        this.breakpointObserver.observe(Breakpoints.Web),
        this.breakpointObserver.observe(Breakpoints.Large)
      ]
    ).pipe(
      debounceTime(100),
      map((results: [BreakpointState, BreakpointState]) => results[0].matches && results[1].matches),
      startWith(this.breakpointObserver.isMatched(Breakpoints.Web) && this.breakpointObserver.isMatched(Breakpoints.Large)),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.isDesktopHighRes = combineLatest(
      [
        this.breakpointObserver.observe(Breakpoints.Web),
        this.breakpointObserver.observe(Breakpoints.XLarge)
      ]
    ).pipe(
      debounceTime(100),
      map((results: [BreakpointState, BreakpointState]) => results[0].matches && results[1].matches),
      startWith(this.breakpointObserver.isMatched(Breakpoints.Web) && this.breakpointObserver.isMatched(Breakpoints.XLarge)),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.isDesktop = this.breakpointObserver.observe(Breakpoints.Web)
      .pipe(
        debounceTime(100),
        map((__) => __.matches),
        startWith(this.breakpointObserver.isMatched(Breakpoints.Web)),
        distinctUntilChanged(),
        shareReplay(1)
      );

    this.isTablet = this.breakpointObserver.observe(Breakpoints.Tablet)
      .pipe(
        debounceTime(100),
        map((__) => __.matches),
        startWith(this.breakpointObserver.isMatched(Breakpoints.Tablet)),
        distinctUntilChanged(),
        shareReplay(1)
      );

    this.isMobile = this.breakpointObserver.observe(Breakpoints.Handset)
      .pipe(
        debounceTime(100),
        map((__) => __.matches),
        startWith(this.breakpointObserver.isMatched(Breakpoints.Handset)),
        distinctUntilChanged(),
        shareReplay(1)
      );

    this.isLandscape = this.breakpointObserver.observe('(orientation: landscape)')
      .pipe(
        debounceTime(100),
        map((__) => __.matches),
        startWith(this.breakpointObserver.isMatched('(orientation: landscape)')),
        distinctUntilChanged(),
        shareReplay(1)
      );

  }

}
