import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestamp'
})
export class TimestampPipe implements PipeTransform {

  transform(timestamp: number): string {
    const minutes = Math.floor(timestamp / 60000);
    const seconds = Math.round((timestamp - (minutes * 60000)) / 1000);

    if (minutes) return `${minutes}min${seconds}s`;
    else return `${seconds}s`
  }

}
