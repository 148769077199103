import { QuantitativeModifier } from './quantitative-modifier';

export class NumberOfVisitsModifier extends QuantitativeModifier {

  readonly type: string = 'number-of-visits';

  n: number;
  operator: string;


  constructor(n: number = 3, operator: string = '>') {
    super();
    this.n = n;
    this.operator = operator;
  }

  parametersToJson(): any {
    return {
      n: this.n,
      operator: this.operator
    };
  }


  isValid(): boolean {
    return this.operator && this.n >= 0;
  }
}
