import { Injectable }                                                                from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Store }                                                                     from '@ngrx/store';

import { firstValueFrom } from 'rxjs';

import { AppState }                                                                  from '../state';
import { selectSelectedEngine }                                                      from '../state/selectors/engines-selectors';

import { AuthService }                                                               from '../../services/auth.service';

import { Engine }                                                                    from '../declarations/engine';

@Injectable()
export class CanCreateSegment implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (await this.authService.getIsAdmin()) {
      return true;
    }

    const engine: Engine = await firstValueFrom(this.store.select(selectSelectedEngine));

    const hasSegmentsPackage = engine.clientPermissions.segments;
    const hasSegmentsCreationPermission = engine.userPermissions.createSegments;

    if (hasSegmentsPackage && hasSegmentsCreationPermission) {
      return true;
    }

    const hasRemainingTrialSegments = engine.segments
      .filter((__) => !__.demo)
      .length < 2;

    if (hasRemainingTrialSegments && hasSegmentsCreationPermission) {
      return true;
    } else {
      return this.router.parseUrl('/segments/upgrade');
    }
  }
}
