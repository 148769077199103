<div class="section">
  <h2 [class.has-subtitle]="options.subtitle">{{options.title}}</h2>
  <div class="subtitle" *ngIf="options.subtitle">
    <mat-icon>info</mat-icon>
    <h5>{{ options.subtitle }}</h5>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between baseline">
    <mat-form-field appearance="outline" fxFlex="0 0 calc(100% - 90px)">
      <mat-label>{{ options.columnTitle }}</mat-label>
      <input matInput type="text" [formControl]="elementToAddForm">
    </mat-form-field>
    <button mat-button color="accent" [disabled]="elementToAddForm.invalid" (click)="addElement()">
      AJOUTER
    </button>
  </div>

  <table mat-table *ngIf="elements.length" [dataSource]="dataSource">
    <!-- Main Column -->
    <ng-container matColumnDef="main">
      <th mat-header-cell *matHeaderCellDef>{{ options.columnTitle }}</th>
      <td mat-cell *matCellDef="let elem">{{ elem }}</td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="text-align: right"></th>
      <td mat-cell *matCellDef="let elem" style="text-align: right">
        <button mat-icon-button color="warn" (click)="removeElement(elem)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let elem; columns: displayedColumns;"></tr>
  </table>
</div>
