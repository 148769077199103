import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable }             from '@angular/core';

import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import _ from 'lodash/fp';

import { RecommendationPipeline } from '../newsletters-module/declarations/pipeline/recommendation-pipeline';
import { RecommendationSource } from '../newsletters-module/declarations/pipeline/recommendation-source';
import { TemplateVariableFromAPI } from '../newsletters-module/declarations/template-variables';

import { environment } from '../../environments/environment';

import { ItemMetadata, TemplateContent } from './template.provider';

export interface RecoQuery {
  engineId: number;
  sourceId: string;
  host: string;
  recommendationPipeline: RecommendationPipeline;
  editorialRecommendations: any;
  variables: TemplateVariableFromAPI[];
}

export interface RecoItem {
  id: string;
  metadata: ItemMetadata;

  // API v2
  creationDate?: number;
  ancestorCategories?: string[];
  baseCategories?: string[];
  externalIds?: string[];
  itemSets?: string[];
  keywords?: {[key: string]: number};
  sources?: string[];
  url?: string;

  // Custom fields used by dashboard
  origin?: RecommendationSource;
  fromEditorial?: boolean;
}

export const sanitizeReco = (reco: RecoItem | any): RecoItem | null => {
  if (reco) {
    return {
      id: reco.id || reco.itemId || -1,
      creationDate: reco.creationDate || 0,
      metadata: reco.metadata || {},
      ancestorCategories: reco.ancestorCategories || [],
      baseCategories: reco.baseCategories || [],
      externalIds: reco.externalIds || [],
      itemSets: reco.itemSets || [],
      keywords: reco.keywords || {},
      sources: reco.sources || [],
      url: reco.url || (reco.metadata && reco.metadata.url) || ''
    };
  } else {
    return null;
  }
};

@Injectable({
  providedIn: 'root'
})
export class RecosProvider {

  private latestRecosQuery: RecoQuery | undefined = undefined;
  private recosFromAPI$$: Subject<RecoItem[]> = new BehaviorSubject<RecoItem[]>([]);

  constructor(private http: HttpClient) {
  }

  getMatchingRecosCount(engineId: number, sourceId: string, recommendationSource: RecommendationSource): Promise<number> {

    const count$ = this.http
      .post<number>(
        `${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/recommendations/recommendables/count`,
        recommendationSource,
        {
          responseType: 'json'
        }
      );

    return firstValueFrom(count$);
  }

  getRecos(engineId: number, sourceId: string, host: string,
           recommendationPipeline: RecommendationPipeline, editorialRecommendations: any, variables: TemplateVariableFromAPI[]): Observable<RecoItem[]> {

    const currentRecoQuery: RecoQuery = _.cloneDeep({
      engineId,
      sourceId,
      host,
      recommendationPipeline,
      editorialRecommendations,
      variables
    });

    if (_.isEqual(this.latestRecosQuery, currentRecoQuery)) {
      // Matched reco query of last request (finished or not, behavior subject or simple subject behind the scene)
      // This should prevent unnecessary HTTP POST calls
      return this.recosFromAPI$$.asObservable();
    }

    // Not matching reco query, starting standard process
    this.latestRecosQuery = currentRecoQuery;

    // Sanitizing editorial
    const convertTemplateContentV2 = (templateContent: TemplateContent) => {
      const templateContentV2 = _.cloneDeep(templateContent);
      Object.entries(templateContentV2).forEach(([key, val]) => {
        if (val) {
          const itemId = val.id || val.itemId;
          templateContentV2[key] = {
            itemId: itemId.toString(),
            metadataOverride: val.metadataOverride
          };
        } else {
          delete templateContentV2[key]; // removing NULL from editorial
        }
      });
      return templateContentV2;
    };

    return this.http
      .post<RecoItem[]>(
        `${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/recommendations/json`,
        {
          pipeline: recommendationPipeline ? recommendationPipeline : null,
          variables: variables ? variables : null,
          editorialRecommendations: editorialRecommendations ? convertTemplateContentV2(editorialRecommendations) : null
        },
        {
          params: new HttpParams().set('replacePipeline', 'true'),
          responseType: 'json'
        }
      ).pipe(
        map((response: RecoItem[]) => {
          if (response) {
            return response.map((reco, index) => {
              if (reco) {

                // setting reco origin (allowing further processing, knowing where the reco comes from)
                if (reco && recommendationPipeline
                  && recommendationPipeline.recommendationsSources
                  && recommendationPipeline.recommendationsSources.length
                  && recommendationPipeline.recommendationsSources[index]) {
                  reco.origin = recommendationPipeline.recommendationsSources[index];
                }

                return reco;

              } else {
                return null;
              }
            });

          } else {
            return [];
          }
        }),
        tap((recos) => {
          // emitting recos for components listening for this particular API call (started + or - at the same time)
          this.recosFromAPI$$.next(recos);
          // then switching to a behavior subject for future listening with same reco query
          this.recosFromAPI$$ = new BehaviorSubject<RecoItem[]>(recos);
          this.latestRecosQuery = currentRecoQuery; // ensuring we mark current reco query as the latest
        })
    );
  }
}
