import { ContactList } from '../../configuration-module/providers/mailers.provider';

import { CampaignConfig } from './campaign-config';

export const ALL_PERSONALISATION_SOURCES: Source = {
  id: 'all_personalisation_sources',
  sourceType: 'web',
  displayName: 'SHARED.AGGREGATED_STATS',
  collection: []
};

export const ALL_EMAILING_SOURCES: NewsletterSource = {
  id: 'all_emailing_sources',
  sourceType: 'email',
  displayName: 'SHARED.AGGREGATED_STATS',
  collection: []
};

export const ALL_EMAILING_HISTORY: NewsletterSource = {
  id: 'all_emailing_history',
  sourceType: 'email',
  displayName: 'SHARED.AGGREGATED_HISTORY',
  collection: []
};

export interface Source {
  id?: string;
  sourceType: 'web' | 'email';
  displayName: string;
  collection: string[];

  recommendationPipeline?: string;

  // multi armed bandit
  enableMultiArmedBandit?: boolean;
  multiArmedBanditEpsilon?: number;

  trackClicks?: boolean;
  enableRecommendationTracking?: boolean;
  recosSentTTL?: number;

  coldstartNbLastVisitors?: number;

  variants?: Variant[];

  headerTarget?: string;
  makeRedirections?: boolean;
  forceProtocol?: boolean;
  useHttps?: boolean;
  anchor?: string;
  trackingParams?: { [param: string]: string };
  rawTrackingParams?: { [param: string]: string };

  template?: string;
  enableTemplateEditor?: boolean;
}

export interface NewsletterSource extends Source {
  periodicity?: Periodicity; // TODO: SHOULD BE DEPRECATED
  contactsLists?: string[];
  subject?: string;
  headlineInSubject?: boolean;
  senderName?: string;
  senderAddress?: string;
  sendingConfiguration?: SendingConfiguration;

  campaigns?: CampaignConfig[];
  mailers?: { contact: string; sending: string; };
}

export interface SendingConfiguration {
  contactsLists?: string[];
  contactsMailer?: string;
  replyToAddress?: string;
  senderAddress?: string;
  senderName?: string;
  sendingMailer?: string;
  specificSendingMailerConfiguration?: A7SpecificSendingMailerConfiguration;
}

export type SendingConfigurationFromFront = Exclude<SendingConfiguration, 'contactsLists'> & { contactsLists: ContactList[] };

export interface A7SpecificSendingMailerConfiguration {
  type: 'a7';
  autoStart: boolean;
  htmlProcess?: string;
  interests: string[];
}


// TODO: SHOULD BE DEPRECATED
export interface Periodicity {
  days?: number[]; // If auto sending. 0 = Monday, 1 = Tuesday ....
  date?: string; // If manual sending.
  hours: number;
  minutes: number;
}

export interface Variant {
  id: string;
  name: string;
  enabled: boolean;
  mediegoVariant: boolean;
  ratio: number;
  elementQuery: string;
  recommendationPipeline: string;
  relevantItemSets: string[];
  template: string;
  utm: Map<string, string>;
}


export const isNewsletter = (obj: any): obj is NewsletterSource => {
  return obj?.sourceType === 'email';
};
