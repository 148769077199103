import merge from 'lodash/merge';

import { TemplateModelUtils }                                 from '../../utils/template-model.utils';
import { EditorialOverrides } from '../editorial-styles';
import { ConstraintPattern, ContentType } from '../enums';
import { InnerRenderingProperties, OuterRenderingProperties } from '../rendering-properties';
import { ModelElement }                                       from '../structure';


export type ContentParameters = any;

export type ContentParametersInfo<T = ContentParameters> = {
  [P in keyof T]: {
    hiddenInSidebar?: boolean;
    valueType: 'string' | 'number' | 'url' | 'enum' | 'html' | 'boolean';
    required?: boolean;
    debounced?: boolean;
    hint?: string | boolean;
    availableOptions?: Array<[string, T[P]]>;
    unit?: string; // utile s'il s'agit d'un nombre
    maxValue?: number; // max s'il s'agit d'un nombre
    minValue?: number; // min s'il s'agit d'un nombre
    enabledWithConstraints?: ConstraintPattern[];
  }
}


export interface Content<T = ContentParameters> extends ModelElement {
  type: ContentType;
  description?: string;
  articleRef?: string | null;

  unlockedForEditorial?: boolean;
  editorialOverrides?: EditorialOverrides;

  params: {
    static: T;
    dynamic: T & { articleLink?: string };
    darkmode?: Partial<T>;

    codeContent?: string;
    powerspaceId?: string;
    customDomain?: string;
  };

  outerRenderingProperties: Partial<OuterRenderingProperties>;
  innerRenderingProperties: Partial<InnerRenderingProperties>;
}

export const isContent = (obj: any): obj is Content => {
  return obj && obj.hasOwnProperty('type') && Object.values(ContentType).includes(obj.type);
};

// Content can only be duplicated as part of duplicating the entire row
// so we need the id of the duplicated row to be used as parentRef of child content
export const duplicateContent = (content: Content, newParentRef: string, articleIds: { [index: string]: string }): Content => {
  let articleRef = null;

  if (content.articleRef !== null) {
    articleIds[content.articleRef] = articleIds[content.articleRef] || TemplateModelUtils.create_UUID();
    articleRef = articleIds[content.articleRef];
  }

  return {
    ...content,
    id: TemplateModelUtils.create_UUID(),
    parentRef: newParentRef,
    articleRef,
    innerRenderingProperties: merge({}, content.innerRenderingProperties),
    outerRenderingProperties: merge({}, content.outerRenderingProperties)
  };
};


export const DEFAULT_ARTICLE_LINK = '{{{ metadata.mediegoLink }}}';
export const DEFAULT_ARTICLE_LINK_2_BRACES = '{{ metadata.mediegoLink }}';

