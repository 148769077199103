import { TranslateService } from '@ngx-translate/core';

import { LocalRecommendationConstraint } from '../../../newsletters-module/declarations/pipeline/constraints/local/LocalRecommendationConstraint';

import { Constraint, ConstraintFactory, CONSTRAINTS_ITEMSET, UnknownConstraint } from './constraints';
import { AtLeastNItems, AtMostNItems, AtMostNItemsInSameCategory } from './constraints/global-constraints';
import {
  HasCategoriesConstraint,
  HasMetadataConstraint,
  HasMetadataConstraintForItemset,
  HasSourcesConstraintForItemset,
  MetadataInsecableSpaceConstraint,
  MetadataValueConstraint,
  PublicationDateConstraint,
  URLValueConstraint
} from './constraints/item-constraints';
import {
  AlreadyRecommendedConstraint,
  CategoryInUserPreferencesConstraint,
  MandatoryArticleConstraint
} from './constraints/local-constraints';

export const constraintToString = (constraint, i18n: TranslateService): string => {
  if (constraint && constraint.type && constraint.parameters) {

    let checkbox = undefined, numberInput = -1, subConstraint = undefined;

    switch (constraint.type) {
      case HasCategoriesConstraint.type:
        const categories = constraint.parameters['withCategory']?.value || [];

        const categoriesPretty = categories.join(', ');

        const mandatoryOrNot: string = constraint.negated ? 'COMMON.WORDS.FORBIDDEN.F' : 'COMMON.WORDS.MANDATORY';

        const entity = i18n.instant(categories.length > 1 ? 'COMMON.ENTITY.CATEGORY.PLURAL' : 'COMMON.ENTITY.CATEGORY');
        const status = i18n.instant(categories.length > 1 ? mandatoryOrNot + '.PLURAL' : mandatoryOrNot);

        return i18n.instant('COMMON.CONSTRAINTS.hasCategories.STRING',
          {
            entity,
            categories: categoriesPretty,
            status
          });

      case HasSourcesConstraintForItemset.type:

        const sources = (constraint.parameters['withSource'] && constraint.parameters['withSource'].value) || [];
        const allMandatory = !!(constraint.parameters['all-mandatory'] && constraint.parameters['all-mandatory'].value);

        // const sourcesPretty = sources.join(', ');

        const pluralize = sources.length > 1;

        if (pluralize) {
          if (allMandatory) {
            return i18n.instant('COMMON.CONSTRAINTS.hasSources.STRING.MULTIPLE.MANDATORY');
          } else {
            return i18n.instant('COMMON.CONSTRAINTS.hasSources.STRING.MULTIPLE');
          }
        } else {
          return i18n.instant('COMMON.CONSTRAINTS.hasSources.STRING.SINGLE');
        }

      case AlreadyRecommendedConstraint.type:
        checkbox = constraint.parameters['never-shown'];
        if (checkbox && checkbox.value !== undefined) {
          if (!constraint.negated) {
            return i18n.instant('COMMON.CONSTRAINTS.alreadyRecommended.STRING');
          } else {
            return i18n.instant('COMMON.CONSTRAINTS.alreadyRecommended.STRING.NEGATED');
          }
        }
        break;


      case MetadataInsecableSpaceConstraint.type: // Alias
        if (constraint.parameters['withName'] && constraint.parameters['withName'].value) {
          const metadata = constraint.parameters['withName'].value;
          return i18n.instant('COMMON.CONSTRAINTS.metadataValue-nbsp.TOSTRING') + ` <b>${metadata}</b>`;
        }
        break;

      case MetadataValueConstraint.type:

        subConstraint = constraint.parameters['withCheck']?.value;
        if (subConstraint.startsWith('!')) subConstraint = subConstraint.slice(1);

        const indexTranslation = constraint.parameters['withCheck'].choices.indexOf(subConstraint);
        let operator = constraint.parameters['withCheck'].viewChoices[indexTranslation];
        if (constraint.negated) {
          operator = constraint.parameters['withCheck'].viewChoicesNegated[indexTranslation];
        }
        const operatorI18N = i18n.instant('COMMON.CONSTRAINTS.metadataValue.PARAMETER.withCheck.' + operator);

        if (constraint.parameters['withValue']?.value) {
          const assertions = Object.entries(constraint.parameters['withValue']?.value || {});
          return assertions.reduce((acc, [key, value]) => {
            return i18n.instant('COMMON.ENTITY.METADATA') + ` <b>${key}</b> ${operatorI18N} <b>"${value}"</b>`;
          }, '');
        }

        break;

      case HasMetadataConstraint.type:
        if (constraint.parameters['withName'] && constraint.parameters['withName'].value) {

          const metadata = constraint.parameters['withName'].value;
          const existingOrNonExisting = i18n.instant(constraint.negated ? 'COMMON.WORDS.UNAVAILABLE.F' : 'COMMON.WORDS.AVAILABLE.F');
          return i18n.instant('COMMON.ENTITY.METADATA') + ` ${metadata} ${existingOrNonExisting}`;
        }
        break;

      case HasMetadataConstraintForItemset.type:
        if (constraint.parameters['withMetadata'] && constraint.parameters['withMetadata'].value) {
          const metadatas = (constraint.parameters['withMetadata'] && constraint.parameters['withMetadata'].value) || [];
          const metadatasPretty = metadatas.join(', ');

          if (metadatas.length > 1) {
            return i18n.instant('COMMON.ENTITY.METADATA.PLURAL') + ` <b>${metadatasPretty}</b> ` + i18n.instant('COMMON.WORDS.MANDATORY.PLURAL');
          } else {
            return i18n.instant('COMMON.ENTITY.METADATA') + ` <b>${metadatasPretty}</b> ` + i18n.instant('COMMON.WORDS.MANDATORY');
          }
        }
        break;


      case PublicationDateConstraint.type:
        numberInput = (constraint.parameters['hours'] && constraint.parameters['hours'].value) || -1;
        if (numberInput > 0) {
          const hours = numberInput > 1 ? i18n.instant('COMMON.DATE_SELECTION.INTERVAL_SELECTION.HOUR.PLURAL')
            : i18n.instant('COMMON.DATE_SELECTION.INTERVAL_SELECTION.HOUR');

          if (constraint.negated) {
            return i18n.instant('COMMON.CONSTRAINTS.isRecent.STRING.LESS_THAN', { value: numberInput, entity: hours?.toLowerCase() });
          } else {
            return i18n.instant('COMMON.CONSTRAINTS.isRecent.STRING.MORE_THAN', { value: numberInput, entity: hours?.toLowerCase() });
          }
        }
        break;


      case CategoryInUserPreferencesConstraint.type:
        checkbox = constraint.parameters['augmentWithUserPreferences'];
        if (checkbox && checkbox.value !== undefined) {
          if (constraint.negated) {
            return i18n.instant('COMMON.CONSTRAINTS.categoryInUserPreferences.STRING.NEGATED');
          } else {
            return i18n.instant('COMMON.CONSTRAINTS.categoryInUserPreferences.STRING');
          }
        }
        break;

      case MandatoryArticleConstraint.type:
        checkbox = constraint.parameters['isMandatory'];
        if (checkbox && checkbox.value) {
          return i18n.instant('COMMON.CONSTRAINTS.mandatory.STRING');
        } else {
          return i18n.instant('COMMON.CONSTRAINTS.mandatory.STRING.NEGATED');
        }


      case AtMostNItemsInSameCategory.type:
        numberInput = (constraint.parameters['nArticles'] && constraint.parameters['nArticles'].value) || -1;
        if (numberInput > 0) {
          return i18n.instant('COMMON.CONSTRAINTS.atMostNItemsInSameCategory.STRING', { nArticles: numberInput })
        }
        break;

      case AtLeastNItems.type:
        numberInput = (constraint.parameters['nArticles'] && constraint.parameters['nArticles'].value) || -1;
        subConstraint = (constraint.parameters['withConstraint'] && constraint.parameters['withConstraint'].value) || null;
        if (subConstraint && numberInput > 0) {
          return i18n.instant('COMMON.CONSTRAINTS.atLeastNItems.STRING', { nArticles: numberInput })
            + constraintToString(subConstraint, i18n);
        }
        break;

      case AtMostNItems.type:
        numberInput = (constraint.parameters['nArticles'] && constraint.parameters['nArticles'].value) || -1;
        subConstraint = (constraint.parameters['withConstraint'] && constraint.parameters['withConstraint'].value) || null;
        if (subConstraint && numberInput > 0) {
          return i18n.instant('COMMON.CONSTRAINTS.atMostNItems.STRING', { nArticles: numberInput })
            + constraintToString(subConstraint, i18n);
        }
        break;

      case URLValueConstraint.type:
        subConstraint = constraint.parameters['withCheck']?.value;
        if (subConstraint.startsWith('!')) subConstraint = subConstraint.slice(1);

        const indexForTranslation = constraint.parameters['withCheck'].choices.indexOf(subConstraint);
        let urlOperator = constraint.parameters['withCheck'].viewChoices[indexForTranslation];
        if (constraint.negated) {
          urlOperator = constraint.parameters['withCheck'].viewChoicesNegated[indexForTranslation];
        }
        const urlOperatorI18N = i18n.instant('COMMON.CONSTRAINTS.urlValue.PARAMETER.withCheck.' + urlOperator);

        const urls = constraint.parameters['withURL']?.value || [];
        const urlsPretty = urls.join('<br/>');
        return `${i18n.instant('COMMON.ENTITY.URL')} ${urlOperatorI18N} <b>${urlsPretty}</b>`;


      case UnknownConstraint.type:
        return i18n.instant('COMMON.CONSTRAINTS.unknown.STRING');

    }
  }

  return '';
};


export const buildConstraint = (type, parameterValues: { [key: string]: any }, negated: boolean = false) => {


  if (['urlContains', 'urlEquals', 'urlStartsWith', 'urlEndsWith'].includes(type)) {
    type = URLValueConstraint.type;
  }

  if (['metadataContains', 'metadataEquals'].includes(type)) {
    type = MetadataValueConstraint.type;
  }

  const constraint = ConstraintFactory.buildFromType(type, parameterValues, negated);

  // assignation of parameters (ctrl + click on buildConstraint to see how parameters are provided)
  if (constraint && constraint.parameters) {
    Object.entries(parameterValues).forEach(([key, value]) => {
      if (constraint.parameters[key]) {
        constraint.parameters[key].value = value;
      }
    });
  }

  constraint.negated = negated;

  return constraint;

};

// return a constraint such as :
// {
//   value: 'hasCategories',
//   viewValue: 'Article - catégories',
//   disabled: false,
//   parameters: [
//     {
//       name: 'categories-needed',
//       type: 'string',
//       description: 'Catégories de l\'article',
//       value: '',
//       hint: 'Pour renseigner plusieurs catégories, il suffit de les séparer par une virgule'
//     },
//     {
//       name: 'all-mandatory',
//       type: 'boolean',
//       description: 'Toutes les catégories sont requises',
//       value: true
//     }
//   ]
// }
export const serializeConstraintForDashboard = (localeRecommendationConstraint: LocalRecommendationConstraint) => {

  if (localeRecommendationConstraint) {
    let constraint: any = localeRecommendationConstraint;
    if (localeRecommendationConstraint.type === 'recommendedItemConstraint') {
      constraint = (localeRecommendationConstraint as any).constraint;
    }

    const parameters = {};

    const negated: boolean = constraint.type === 'not';
    if (negated) {
      constraint = constraint.constraint;
    } // extracting real constraint (one level deeper)

    switch (constraint.type) {
      case HasCategoriesConstraint.type:
        parameters['withCategory'] = constraint.categories || [];
        parameters['all-mandatory'] = constraint.all;
        break;
      case AlreadyRecommendedConstraint.type:
        parameters['never-shown'] = !negated;
        break;
      case HasMetadataConstraint.type:
        parameters['withName'] = constraint.key;
        break;
      case HasSourcesConstraintForItemset.type:
        parameters['withSource'] = constraint.sources;
        parameters['all-mandatory'] = !!constraint.all;
        break;
      case AtMostNItemsInSameCategory.type:
        parameters['nArticles'] = constraint.n;
        parameters['ignoredCategories'] = (constraint.ignoredCategories && constraint.ignoredCategories.split(', ')) || '';
        break;
      case AtLeastNItems.type:
        parameters['nArticles'] = constraint.n;
        parameters['withConstraint'] = serializeConstraintForDashboard(constraint.constraint);
        break;
      case AtMostNItems.type:
        parameters['nArticles'] = constraint.n;
        parameters['withConstraint'] = serializeConstraintForDashboard(constraint.constraint);
        break;
      case CategoryInUserPreferencesConstraint.type:
        parameters['augmentWithUserPreferences'] = true;
        break;
      case MandatoryArticleConstraint.type:
        parameters['isMandatory'] = constraint.value;
        break;


        // FOR METADATA

      case 'metadataContains':

        if (negated && constraint.value === MetadataInsecableSpaceConstraint.unbreakableSpace) {
          parameters['withName'] = constraint.key;
        } else {
          parameters['withCheck'] = negated ? '!metadataContains' : 'metadataContains';
          parameters['withValue'] = { [constraint.key]: constraint.value };
        }
        break;

      case 'metadataEquals':
        parameters['withCheck'] = negated ? '!metadataEquals' : 'metadataEquals';
        parameters['withValue'] = { [constraint.key]: constraint.value };
        break;

        // FOR URLS

      case 'urlStartsWith':
        parameters['withCheck'] = negated ? '!urlStartsWith' : 'urlStartsWith';
        parameters['withURL'] = constraint.contents || [];
        break;

      case 'urlEndsWith':
        parameters['withCheck'] = negated ? '!urlEndsWith' : 'urlEndsWith';
        parameters['withURL'] = constraint.contents || [];
        break;

      case 'urlContains':
        parameters['withCheck'] = negated ? '!urlContains' : 'urlContains';
        parameters['withURL'] = constraint.contents || [];
        break;

      case 'urlEquals':
        parameters['withCheck'] = negated ? '!urlEquals' : 'urlEquals';
        parameters['withURL'] = constraint.contents || [];
        break;

        // END URLS



      case PublicationDateConstraint.type:
        parameters['hours'] = constraint.maxAge / 1000 / 60 / 60;
        break;
      default:
        parameters['unknown'] = JSON.stringify(localeRecommendationConstraint);
        break;
    }

    return buildConstraint(constraint.type, parameters, negated);

  }
};

// return a constraint such as :
// { 'type': 'recommendedItemConstraint', 'constraint': { 'type': 'hasCategories', 'categories': ['Coronavirus'], 'all': false } }
export const deserializeConstraintForBack = (constraint: Constraint, recommendedItemConstraintWrapper?: boolean) => {

  const wrapper = {
    type: 'recommendedItemConstraint',
    constraint: null
  };

  // deduce recommendedItemConstraintWrapper from constraint type (only item constraints)
  let needWrapper: boolean = false;
  if (recommendedItemConstraintWrapper) {
    needWrapper = CONSTRAINTS_ITEMSET.some(item => item.type === constraint.type) || constraint.type === PublicationDateConstraint.type;
  }

  let constraintDeserialized = {}, constraintType = '';

  switch (constraint.type) {
    case HasCategoriesConstraint.type:

      // safety check on categories type (must be array at the end)
      const categories = constraint.parameters['withCategory']?.value || [];

      constraintDeserialized = {
        type: 'hasCategories',
        categories,
        all: constraint.parameters['all-mandatory'].value
      };
      break;

    case AlreadyRecommendedConstraint.type:
      constraintDeserialized = {
        type: 'alreadyRecommended'
      };
      break;

    case MetadataInsecableSpaceConstraint.type:
      const metadata = constraint.parameters['withName']?.value || 'title';

      constraintDeserialized = {
        type: 'metadataContains',
        key: metadata,
        value: MetadataInsecableSpaceConstraint.unbreakableSpace
      };
      break;

    case MetadataValueConstraint.type:

      if (constraint.parameters['withCheck']?.value && constraint.parameters['withValue']?.value) {

        const metadataMap = constraint.parameters['withValue'].value;
        const metadataEntries = Object.entries(metadataMap);

        constraintType = constraint.parameters['withCheck']?.value;
        constraintType = constraintType.startsWith('!') ? constraintType.slice(1) : constraintType;

        if (metadataEntries.length === 1) {
          // No need for a wrapper
          constraintDeserialized = {
            type: constraintType,
            key: metadataEntries[0][0],
            value: metadataEntries[0][1]
          };
        }
      }
      break;

    case HasMetadataConstraint.type:
      if (constraint.parameters['withName'] && constraint.parameters['withName'].value !== undefined) {
        const metadata = constraint.parameters['withName'].value;
        constraintDeserialized = {
          type: 'hasMetadata',
          key: metadata
        };
      }
      break;

    case HasMetadataConstraintForItemset.type:
      if (constraint.parameters['withMetadata'] && constraint.parameters['withMetadata'].value?.length) {
        const metadata = constraint.parameters['withMetadata'].value[0];
        constraintDeserialized = {
          type: 'hasMetadata',
          key: metadata
        };
      }
      break;

    case HasSourcesConstraintForItemset.type:
      if (constraint.parameters['withSource'] && constraint.parameters['all-mandatory'] !== undefined) {
        const sources = constraint.parameters['withSource'].value || [];
        const all = !!constraint.parameters['all-mandatory'].value;
        constraintDeserialized = {
          type: 'hasSources',
          sources,
          all
        };
      }
      break;

    case CategoryInUserPreferencesConstraint.type:
      if (constraint.parameters['augmentWithUserPreferences'] && constraint.parameters['augmentWithUserPreferences'].value) {
        constraintDeserialized = {
          type: 'categoryInUserPreferences'
        };
      }
      break;

    case MandatoryArticleConstraint.type:
      if (constraint.parameters['isMandatory']) {
        constraintDeserialized = {
          type: 'mandatory',
          value: !!constraint.parameters['isMandatory'].value
        };
      }
      break;

    case AtMostNItemsInSameCategory.type:
      if (constraint.parameters['nArticles'] && constraint.parameters['ignoredCategories']) {
        const categories = constraint.parameters['ignoredCategories'].value;
        const n = constraint.parameters['nArticles'].value;
        constraintDeserialized = {
          type: 'atMostNItemsInSameCategory',
          n,
          ignoredCategories: categories,
          hard: false
        };
      }
      break;

    case AtLeastNItems.type:
      if (constraint.parameters['nArticles'] && constraint.parameters['withConstraint']) {
        const n = constraint.parameters['nArticles'].value;
        const subConstraint = constraint.parameters['withConstraint'].value;

        constraintDeserialized = {
          type: 'atLeastNItems',
          n,
          constraint: subConstraint ? deserializeConstraintForBack(subConstraint) : null,
          hard: false
        };
      }
      break;

    case AtMostNItems.type:
      if (constraint.parameters['nArticles'] && constraint.parameters['withConstraint']) {
        const n = constraint.parameters['nArticles'].value;
        const subConstraint = constraint.parameters['withConstraint'].value;

        constraintDeserialized = {
          type: 'atMostNItems',
          n,
          constraint: subConstraint ? deserializeConstraintForBack(subConstraint) : null,
          hard: false
        };
      }
      break;

    case URLValueConstraint.type:
      if (constraint.parameters['withCheck'] && constraint.parameters['withURL']) {
        const contents = constraint.parameters['withURL'].value || [];

        constraintType = constraint.parameters['withCheck']?.value;
        constraintType = constraintType.startsWith('!') ? constraintType.slice(1) : constraintType;

        if (constraintType && contents) {
          constraintDeserialized = {
            type: constraintType,
            contents
          };
        }
      }
      break;


    case PublicationDateConstraint.type:
      if (constraint.parameters['hours']) {
        const hours = constraint.parameters['hours'].value;

        constraintDeserialized = {
          type: 'isRecent',
          timezone: 'Europe/Paris', // ECT timezone
          maxAge: hours * 60 * 60 * 1000
        };
      }
      break;

    case UnknownConstraint.type:
      if (constraint.parameters['unknown']) {
        const unknown = constraint.parameters['unknown'].value;
        constraintDeserialized = JSON.parse(unknown);
      }
      break;

    default:
  }

  if (constraint.negated) {
    constraintDeserialized = {
      type: 'not',
      constraint: constraintDeserialized
    };
  }

  if (needWrapper) {
    return {
      ...wrapper,
      constraint: {
        ...constraintDeserialized
      }
    };
  } else {
    return constraintDeserialized;
  }
};

