import { Dictionary }     from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Segment } from '../../../segments-module/declarations/segment';
import { Engine }  from '../../declarations/engine';

import { enginesAdapter, EnginesState } from '../reducers/engines.reducer';

import { selectEnginesState } from './index';

const {
  selectIds,
  selectEntities,
  selectAll
  // selectTotal
} = enginesAdapter.getSelectors();

export const selectEngineEntities = createSelector(
  selectEnginesState,
  selectEntities
);

export const selectEngines = createSelector(
  selectEnginesState,
  selectAll
);

export const selectEngineIds = createSelector(
  selectEnginesState,
  (state: EnginesState) => selectIds(state) as number[]
);

export const selectEngine = createSelector(
  selectEngineEntities,
  (state: Dictionary<Engine>, props: { engineId: number }) => state[props.engineId]
);

export const selectSelectedEngine = createSelector(
  selectEnginesState,
  (state: EnginesState) => selectEntities(state)[state.selectedEngineId]
);

export const selectSelectedEngineName = createSelector(
  selectSelectedEngine,
  (engine: Engine | undefined) => engine ? engine.displayName : ''
);

export const selectAllWebSourcesOfSelectedEngine = createSelector(
  selectSelectedEngine,
  (engine: Engine) =>
    engine?.sources?.web_all?.sort(
      (a, b) => a?.displayName?.localeCompare(b?.displayName)
    )
    || []
);

export const selectWebSourcesFolderOfSelectedEngine = createSelector(
  selectSelectedEngine,
  (engine: Engine) => {
    if (engine) {
      return engine.sources?.web;
    } else {
      return {
        name: 'MAIN.APP.SIDEBAR.WEB_PERSO.TITLE',
        folders: [],
        items: []
      };
    }
  }
);

export const selectAllNewsletterSourcesOfSelectedEngine = createSelector(
  selectSelectedEngine,
  (engine: Engine) => {
    return engine?.sources?.newsletters_all?.sort(
      (a, b) => a?.displayName?.localeCompare(b?.displayName)
    ) || []
  }
);

export const selectNewsletterSourcesFolderOfSelectedEngine = createSelector(
  selectSelectedEngine,
  (engine: Engine) => {
    if (engine) {
      return engine.sources?.newsletters;
    } else {
      return {
        name: 'MAIN.APP.SIDEBAR.NEWSLETTERS.TITLE',
        folders: [],
        items: []
      };
    }
  }
);

export const selectSegmentsOfSelectedEngine = createSelector(
  selectSelectedEngine,
  (engine: Engine) => {
    return engine?.segments?.sort((a, b) => a?.name?.localeCompare(b?.name)) || [];
  }
);

export const selectSegmentsFolderOfSelectedEngine = createSelector(
  selectSegmentsOfSelectedEngine,
  (segments: Segment[]) => {
    return {
      name: 'MAIN.APP.SIDEBAR.SEGMENTS.TITLE',
      folders: [],
      items: segments
    };
  }
);

export const selectAllNewsletterSourcesOfEngine = createSelector(
  selectEngines,
  (engines: Engine[], { engineId }) => {
    const engine = engines?.find((__) => __?.id === engineId);
    return engine?.sources?.newsletters_all?.sort(
      (a, b) => a?.displayName?.localeCompare(b?.displayName)
    ) || [];
  }
);

export const selectSegmentsOfEngine = createSelector(
  selectEngines,
  (engines: Engine[], { engineId }) => {
    const optEngine = engines?.find((__) => __?.id === engineId);
    return optEngine?.segments?.sort((a, b) => a?.name?.localeCompare(b?.name)) || [];
  }
);

export const selectNewsletterUserPermissions = createSelector(
  selectSelectedEngine,
  (engine: Engine, { sourceId }) =>
    engine?.userPermissions?.newsletters?.find((__) => __?.newsletterId === sourceId)
    || {
      newsletterId: sourceId,
      configuration: false,
      statistics: false,
      editTemplate: false,
      editContent: false,
      editVariables: false,
      editCampaigns: false
    }
);

export const selectSegmentUserPermissions = createSelector(
  selectSelectedEngine,
  (engine: Engine, { segmentId }) =>
    engine?.userPermissions?.segments?.find((__) => __?.segmentId === segmentId)
    || {
      segmentId,
      configuration: false,
      statistics: false,
      createActions: false
    }
);
