<div fxLayout="column" fxFlex="0 0 100%" class="header" fxLayoutAlign="center center">

  <mat-icon class="error-icon" color="accent">settings_account_box</mat-icon>

	<span class="error-headline">
	  Il semblerait que votre compte n'ait pas encore de permissions
	</span>

  <span>
	  En l'état vous n'avez accès à aucune ressource, nous vous invitons à contacter la personne en charge de votre compte afin qu'elle vous attribue les permissions nécessaires.
	</span>
  <span (click)="openSupport()" class="join-support">
    En cas de doute, vous pouvez nous contacter en cliquant ici
  </span>
</div>
