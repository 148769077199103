import { ActivatedRouteSnapshot, Data, Params, UrlSegment } from '@angular/router';

import { createSelector } from '@ngrx/store';

import { ALL_EMAILING_SOURCES, ALL_PERSONALISATION_SOURCES } from '../../../mediego-common-module/declarations/source';
import { Engine }                                            from '../../declarations/engine';

import { CustomRouterState }    from '../index';

import { selectSelectedEngine } from './engines-selectors';
import { selectRouterState }    from './index';
import { selectSelectedLanguage } from './settings-selectors';

export const selectRouterParams = createSelector(
  selectRouterState,
  (state: CustomRouterState | undefined) => state ? state.params : undefined
);

export const selectCurrentRoute = createSelector(
  selectRouterState,
  (state: CustomRouterState | undefined) => {
    if (!state) {
      return undefined;
    }

    return state.route;
  }
);

export const selectCurrentRouteData = createSelector(
  selectCurrentRoute,
  (route: ActivatedRouteSnapshot | undefined) => {
    if (!route) {
      return {};
    }

    return route.data || {};
  }
);

// Doc is language dependent
export const selectCurrentRouteDocHref = createSelector(
  selectCurrentRouteData,
  selectSelectedLanguage,
  (data: Data, lang: 'en' | 'fr') => {

    if (lang === 'en' || lang === 'fr') {

      if (data['docHrefId']) {
        return 'https://docs.mediego.com/' + lang + '/articles/' + data['docHrefId'];
      } else {
        return 'https://docs.mediego.com/' + lang + '/';
      }


    } else {
      // Fallback for french documentation
      return data['docHref'] as string || 'https://docs.mediego.com/fr/';
    }

  }
);

export const selectParentPath = createSelector(
  selectRouterState,
  (state: CustomRouterState | undefined) => {
    if (!state) {
      return undefined;
    }

    const pathFromRoot = state.route.pathFromRoot;
    if (pathFromRoot.length < 2) { // this means route is root route
      return undefined;
    }

    return (pathFromRoot[1].url[0] || {} as UrlSegment).path;
  }
);

export const selectBreadCrumbs = createSelector(
  selectRouterState,
  (state: CustomRouterState | undefined) => {
    if (!state) {
      return [];
    }
    const crumbsFromRouter = state.route
      .pathFromRoot
      .reduce(
        (res, __) => res.concat(__.data['breadcrumbs'] || []),
        []
      );

    return [...new Set(crumbsFromRouter)];
  }
);

export const selectTabCrumb = createSelector(
  selectCurrentRouteData,
  (data: Data) => {
    return data['tab'];
  }
);

export const selectSelectedTabInfo = createSelector(
  selectRouterState,
  (state: CustomRouterState | undefined) => {
    if (!state) {
      return undefined;
    }

    const pathFromRoot = state.route.pathFromRoot;
    if (pathFromRoot.length < 3) { // this means route does not have tab
      return undefined;
    }

    // return (pathFromRoot[2].url[1] || {} as UrlSegment).path;
    const [, ...info] = pathFromRoot[2].url;
    return info.map((__) => __.path);
  }
);

export const selectSelectedSource = createSelector(
  selectRouterParams,
  selectSelectedEngine,
  (
    params: Params | undefined,
    selectedEngine: Engine | undefined
  ) => {
    if (selectedEngine && params && params.hasOwnProperty('source')) {
      const sourceName = params['source'];

      switch (sourceName) {
        case ALL_PERSONALISATION_SOURCES.id:
          return ALL_PERSONALISATION_SOURCES;
        case ALL_EMAILING_SOURCES.id:
          return ALL_EMAILING_SOURCES;
        default:
          return selectedEngine.sources.web_all.find((__) => __.id === sourceName) ||
            selectedEngine.sources.newsletters_all.find((__) => __.id === sourceName);
      }
    } else {
      return undefined;
    }
  }
);

export const selectSource = createSelector(
  selectSelectedEngine,
  (
    selectedEngine: Engine | undefined,
    { sourceId }
  ) => {

    if (selectedEngine && sourceId) {
      switch (sourceId) {
        case ALL_PERSONALISATION_SOURCES.id:
          return ALL_PERSONALISATION_SOURCES;
        case ALL_EMAILING_SOURCES.id:
          return ALL_EMAILING_SOURCES;
        default:
          return selectedEngine.sources?.web_all?.find((__) => __?.id === sourceId) ||
            selectedEngine.sources?.newsletters_all?.find((__) => __?.id === sourceId);
      }
    } else {
      return undefined;
    }

  }
);

export const selectSelectedSegment = createSelector(
  selectRouterParams,
  selectSelectedEngine,
  (
    params: Params | undefined,
    selectedEngine: Engine | undefined
  ) => {
    if (selectedEngine && params && params.hasOwnProperty('segmentId')) {
      const segmentId = params['segmentId'];
      return selectedEngine.segments.find((__) => __.id === segmentId);
    } else {
      return undefined;
    }
  }
);
