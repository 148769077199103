<ng-template #cardTemplate>
  <mat-card fxLayout="column" fxLayoutAlign="center center" [ngStyle]="{ 'height.px': cardHeight || 150 }" (click)="onClick()">
    <mat-icon>{{ cardIcon }}</mat-icon>
    <span>{{ cardText }}</span>
  </mat-card>
</ng-template>

<div id="container">
  <ng-container *ngIf="cardRouterLink">
    <a id="link" [routerLink]="cardRouterLink" [queryParams]="cardQueryParams" [queryParamsHandling]="cardQueryParamsHandling">
      <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngTemplateOutlet="cardRouterLink ? undefined : cardTemplate"></ng-container>
</div>
