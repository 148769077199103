import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  LOG_IN = 'LOG_IN',
  LOG_OUT = 'LOG_OUT',
  TOGGLE_ADMIN = 'TOGGLE_ADMIN_DISPLAY'
}

export class LogInAction implements Action {
  readonly type = AuthActionTypes.LOG_IN;

  constructor(public payload: { email: string; isAdmin: boolean; demoExpiryDate?: number }) {}
}

export class LogOutAction implements Action {
  readonly type = AuthActionTypes.LOG_OUT;
}

export class ToggleAdminDisplayAction implements Action {
  readonly type = AuthActionTypes.TOGGLE_ADMIN;

  constructor(public payload: { displayAdmin: boolean }) {}
}

export type AuthAction =
  LogInAction |
  LogOutAction |
  ToggleAdminDisplayAction;
