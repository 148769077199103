import { Injectable } from '@angular/core';

import { select, Store }          from '@ngrx/store';

import { firstValueFrom, Observable } from 'rxjs';
import { take }                   from 'rxjs/operators';

import { AppState }               from '../main-module/state';
import { selectTemplatePipeline } from '../newsletters-module/state/selectors';

import { RecommendationPipeline } from '../newsletters-module/declarations/pipeline/recommendation-pipeline';

@Injectable({
  providedIn: 'root'
})
export class PipelineService {

  constructor(private store: Store<AppState>) { }

  get pipeline$(): Observable<RecommendationPipeline> {
    return this.store.pipe(select(selectTemplatePipeline));
  }

  getCurrentPipeline() {
    return firstValueFrom(this.pipeline$.pipe(take(1)));
  }
}
