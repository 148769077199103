import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
}                                       from '@angular/core';
import { UntypedFormControl }                  from '@angular/forms';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatInput }                     from '@angular/material/input';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import deburr from 'lodash/deburr';

@Component({
  selector: 'app-mediego-multi-autocomplete',
  templateUrl: './multi-autocomplete.component.html',
  styleUrls: ['./multi-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MultiAutocompleteComponent implements OnInit, OnChanges {

  @Input() elements: { [index: string]: string };
  @Input() placeholder: string;
  @ViewChild('chipCategoryInput', { static: true }) chipCategoryInput: MatInput;
  @Output() selectedChanges = new EventEmitter<any>();
  @Input() preselectedElements: { [index: string]: string };
  @Input() max: number = -1; // max items allowed in the selection
  @Input() dynamic: boolean = true; // whether items proposed change with selected items
  @Input() allowSameItem: boolean = true; // whether selection can be composed of several identical items or not

  filteredElements: string[] = [];
  selectedElement = new UntypedFormControl();
  selectedElements: string[] = [];

  constructor(
    private snackbar: MatSnackBar
  ) {

  }
  ngOnInit() {
    this.selectedElements = Object.values(this.preselectedElements);
    this.filteredElements = Object.values(this.elements).sort();
    this.selectedElement.valueChanges.subscribe((searchTerm) => {
      if (searchTerm && searchTerm.length > 1) {
        this.filteredElements = Object
          .values(this.elements)
          .filter((elementName) => {
            if (Object.values(this.selectedElement).includes(elementName)) {
              return false;
            }

            const _normalizedCategoryName = deburr(elementName).toLowerCase();
            const _normalizedSearchTerm = deburr(searchTerm).toLowerCase();

            return _normalizedCategoryName.includes(_normalizedSearchTerm);
          });
      } else {
        this.filteredElements = Object.values(this.elements).filter((__) => !this.selectedElements.includes(__));
      }

      this.filteredElements.sort();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.preselectedElements && changes['preselectedElements']) {
      if (this.max === -1 || Object.values(changes['preselectedElements'].currentValue).length <= this.max) {
        this.selectedElements = Object.values(changes['preselectedElements'].currentValue);
        this.emitElements();
      } else {
        console.error('Too many preselected elements (max reached)');
      }
    }
    if (this.elements && changes['elements']) {
      this.filteredElements = Object.values(this.elements).filter((__) => !this.selectedElements.includes(__));
    }
  }


  addElement(event: MatAutocompleteSelectedEvent): void {
    if (this.max === -1 || this.selectedElements.length < this.max) {

      if (this.allowSameItem || !this.selectedElements.includes(event.option.value)) {

        this.selectedElements.push(event.option.value);
        this.chipCategoryInput['nativeElement'].value = '';
        this.emitElements();
        if (this.dynamic) {
          this.filteredElements = Object.values(this.elements).filter((__) => !this.selectedElements.includes(__));
          this.filteredElements.sort();
        }
      } else {
        this.snackbar.open('L\'élément est déjà sélectionné.', null, {
          duration: 2000,
          panelClass: ['mediego-snack-bar', 'warn']
        });
        console.error('Unable to add element (same element already selected)');
      }

    } else {
      this.snackbar.open('Vous ne pouvez pas ajouter plus de ' + this.max + ' élement(s).', null, {
        duration: 2000,
        panelClass: ['mediego-snack-bar', 'warn']
      });
      console.error('Unable to add element (max reached)');
    }

  }

  removeElement(index: number) {
    if (this.dynamic) {
      this.filteredElements.push(this.selectedElements[index]);
      this.filteredElements.sort();
    }
    this.selectedElements.splice(index, 1);
    this.emitElements();
  }

  emitElements() {
    this.selectedChanges.emit(Object
      .keys(this.elements)
      .reduce((obj, key) => {
        const value = this.elements[key];
        if (this.selectedElements.includes(value)) {
          obj[key] = value;
        }
        return obj;
      }, {}));
  }
}
