import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable }                                                              from '@angular/core';
import { Router }                                                                  from '@angular/router';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(async(error: HttpErrorResponse) => {

          const testingAuthentication = req.headers.get(AuthService.HEADER_TEST_AUTHENTICATION);

          // Unauthorized
          if (!testingAuthentication && error.status === 401) {
            console.warn('Unauthorized 401');
            this.authService.logout();
            await this.router.navigate(['/login']);
          }

          throw error;
        })
      );
  }

}
