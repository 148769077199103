<mat-card id="date-selection-card">
  <mat-card-content [fxLayout]="(layoutService.isMobile | async) && (layoutService.isLandscape | async) === false ? 'column' : 'row'">

    <mat-button-toggle-group id="mode-selection-row" *ngIf="allowLiveMode" [(ngModel)]="mode" (change)="onModeChange()"
                             [vertical]="mode === 'period' && ((layoutService.isMobile | async) === true && (layoutService.isLandscape | async) === false) === false">
      <mat-button-toggle value="live">
        <mat-icon>rss_feed</mat-icon>{{ 'COMMON.DATE_SELECTION.MODE_SELECTION.LIVE' | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="period">
        <mat-icon>history</mat-icon>{{ 'COMMON.DATE_SELECTION.MODE_SELECTION.HISTORY' | translate }}
      </mat-button-toggle>
    </mat-button-toggle-group>


    <div id="date-selection-row" *ngIf="mode === 'period'" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field appearance="outline" fxFlex="0 0 calc(50% - 7.5px)" subscriptSizing="dynamic">
        <mat-label>{{ 'COMMON.DATE_SELECTION.START_DATE_INPUT_LABEL' | translate }}</mat-label>
        <input matInput type="text" [matDatepicker]="startDatePicker"
               [(ngModel)]="startDate" [min]="minStartDate" [max]="maxStartDate" (click)="startDatePicker.open()"
               (dateChange)="onStartDateSelected()" [disabled]="(layoutService.isMobile | async)">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker" color="primary"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker [disabled]="false" [touchUi]="(layoutService.isDesktop | async) === false"></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="0 0 calc(50% - 7.5px)" subscriptSizing="dynamic">
        <mat-label>{{ 'COMMON.DATE_SELECTION.END_DATE_INPUT_LABEL' | translate }}</mat-label>
        <input matInput type="text" [matDatepicker]="endDatePicker"
               [(ngModel)]="endDate" [min]="minEndDate" [max]="maxEndDate" (click)="endDatePicker.open()"
               (dateChange)="onEndDateSelected()" [disabled]="(layoutService.isMobile | async)">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker [disabled]="false" [touchUi]="(layoutService.isDesktop | async) === false"></mat-datepicker>
      </mat-form-field>
    </div>

    <mat-button-toggle-group id="interval-selection-row" [vertical]="((layoutService.isMobile | async) === true && (layoutService.isLandscape | async) === false) === false" *ngIf="mode === 'period' && availableIntervalTypes.length > 1"
                             [(ngModel)]="intervalType" (change)="onIntervalTypeChanged()">
      <mat-button-toggle *ngFor="let type of availableIntervalTypes" [value]="type">{{ type | intervalTypeTranslationId | translate }}</mat-button-toggle>
    </mat-button-toggle-group>

  </mat-card-content>
</mat-card>
