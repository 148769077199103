<mat-card fxFlex="100%" fxFlex.gt-xs="450px">
  <mat-progress-bar *ngIf="initStatus === 'loading' || isLoading" mode="indeterminate" color="accent"></mat-progress-bar>

  <div id="header" fxLayout="column" fxLayoutAlign="start center">
    <!-- <img src="/assets/img/logo_icon_only_green.svg" height="50px"> -->
    <mat-icon color="accent">assignment_ind</mat-icon>

    <h1>{{ 'MAIN.ACCEPT_INVITE.TITLE_A' | translate }}</h1>
    <h3>{{ 'MAIN.ACCEPT_INVITE.TITLE_B' | translate }}</h3>
  </div>

  <mat-card-content fxLayout="column" fxLayoutAlign="center">
    <div *ngIf="initStatus === 'error'" fxLayoutAlign="center">
      <mat-icon class="error-icon">warning</mat-icon>
    </div>

    <form *ngIf="initStatus === 'done'" [formGroup]="acceptInviteForm" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutGap="5px">
      <mat-form-field appearance="outline" subscriptSizing="fixed">
        <mat-label>{{ 'MAIN.FORMS.EMAIL' | translate }}</mat-label>
        <input matInput formControlName="email" required autocomplete="email">
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{ 'MAIN.FORMS.PASSWORD' | translate }}</mat-label>
        <input matInput [type]="passwordVisible ? 'text' : 'password'" pattern="[a-zA-Z0-9\[$&\+\,:;=\?@#\|'<>\.^\*\(\)%!\-\]€]{10,64}" formControlName="password" required autofocus autocomplete="new-password">
        <button mat-icon-button color="primary" (click)="passwordVisible = !passwordVisible" matSuffix><mat-icon>{{ passwordVisible ? 'visibility_off' : 'visibility' }}</mat-icon></button>
        <mat-hint class="password-conditions">
          <ul>
            <li>
              <mat-icon [color]="acceptInviteForm.get('password').getError('NotLongEnough') === true ? 'warn' : 'accent'">{{ acceptInviteForm.get('password').getError('NotLongEnough') === true ? 'error' : 'done' }}</mat-icon> 10 caractères ou plus
            </li>
            <li>
              <mat-icon [color]="acceptInviteForm.get('password').getError('NoDigit') === true ? 'warn' : 'accent'">{{ acceptInviteForm.get('password').getError('NoDigit') === true ? 'error' : 'done' }}</mat-icon> Chiffre
            </li>
            <li>
              <mat-icon [color]="acceptInviteForm.get('password').getError('NoLowerCase') === true ? 'warn' : 'accent'">{{ acceptInviteForm.get('password').getError('NoLowerCase') === true ? 'error' : 'done' }}</mat-icon> Minuscule
            </li>
            <li>
              <mat-icon [color]="acceptInviteForm.get('password').getError('NoUpperCase') === true ? 'warn' : 'accent'">{{ acceptInviteForm.get('password').getError('NoUpperCase') === true ? 'error' : 'done' }}</mat-icon> Majuscule
            </li>
            <li>
              <mat-icon [color]="acceptInviteForm.get('password').getError('NoSpecialChar') === true ? 'warn' : 'accent'">{{ acceptInviteForm.get('password').getError('NoSpecialChar') === true ? 'error' : 'done' }}</mat-icon> Caractère spécial
            </li>
          </ul>
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'MAIN.FORMS.CONFIRM_PASSWORD' | translate }}</mat-label>
        <input matInput type="password" formControlName="confirmPassword" required autocomplete="new-password">
        <mat-error *ngIf="acceptInviteForm.get('confirmPassword').getError('NotEqualTo') === 'password'">
          {{ 'MAIN.FORMS.NON_MATCHING_PASSWORDS' | translate }}
        </mat-error>
      </mat-form-field>
      <div id="buttons-row" fxFlex="row" fxLayoutAlign="end">
        <button mat-raised-button color="accent" [disabled]="isLoading || acceptInviteForm.invalid" type="submit">
          {{ 'SHARED.BUTTONS.NEXT' | translate }}
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
