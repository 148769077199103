import { Constraint } from '../constraints';

export class AtMostNItemsInSameCategory implements Constraint {
  static type = 'atMostNItemsInSameCategory';
  static disabled = false;
  static supportsMultiple = true;
  type = 'atMostNItemsInSameCategory';
  disabled = false;
  configurable = true;
  supportsNegation = false;
  negated = false;
  priority = 3;
  parameters = {
    'nArticles': {
      type: 'number',
      description: true,
      value: 3
    },
    'ignoredCategories': {
      type: 'string',
      description: true,
      value: '',
      required: true
    }
  };
}

export class AtLeastNItems implements Constraint {
  static type = 'atLeastNItems';
  static disabled = false;
  static supportsMultiple = true;
  type = 'atLeastNItems';
  disabled = false;
  configurable = true;
  supportsNegation = false;
  negated = false;
  priority = 3;
  parameters = {
    'nArticles': {
      type: 'number',
      description: true,
      value: 3
    },
    'withConstraint': {
      type: 'custom',
      description: true,
      value: null
    }
  };
}

export class AtMostNItems implements Constraint {
  static type = 'atMostNItems';
  static disabled = false;
  static supportsMultiple = true;
  type = 'atMostNItems';
  disabled = false;
  configurable = true;
  supportsNegation = false;
  negated = false;
  priority = 3;
  parameters = {
    'nArticles': {
      type: 'number',
      description: true,
      value: 3
    },
    'withConstraint': {
      type: 'custom',
      description: true,
      value: null
    }
  };
}

