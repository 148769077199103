import { CommonModule }    from '@angular/common';
import { NgModule }        from '@angular/core';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule }                          from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule }                           from '@angular/material/bottom-sheet';
import { MatButtonToggleModule }                          from '@angular/material/button-toggle';
import { MatCardModule }                                  from '@angular/material/card';
import { MatChipsModule }                                 from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule }                            from '@angular/material/datepicker';
import { MatExpansionModule }                             from '@angular/material/expansion';
import { MatFormFieldModule }                             from '@angular/material/form-field';
import { MatGridListModule }                              from '@angular/material/grid-list';
import { MatIconModule }                                  from '@angular/material/icon';
import { MatInputModule }                                 from '@angular/material/input';
import { MatLegacyButtonModule as MatButtonModule }                                from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule }                              from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule }                                from '@angular/material/legacy-dialog';
import { MatLegacyListModule as MatListModule }                                  from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule }                                  from '@angular/material/legacy-menu';
import { MatLegacySliderModule as MatSliderModule }                                from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule }                              from '@angular/material/legacy-snack-bar';
import { MatLegacyTabsModule as MatTabsModule }                                  from '@angular/material/legacy-tabs';
import { MatPaginatorModule }                             from '@angular/material/paginator';
import { MatProgressBarModule }                           from '@angular/material/progress-bar';
import { MatProgressSpinnerModule }                       from '@angular/material/progress-spinner';
import { MatRadioModule }                                 from '@angular/material/radio';
import { MatSelectModule }                                from '@angular/material/select';
import { MatSidenavModule }                               from '@angular/material/sidenav';
import { MatSlideToggleModule }                           from '@angular/material/slide-toggle';
import { MatSortModule }                                  from '@angular/material/sort';
import { MatStepperModule }                               from '@angular/material/stepper';
import { MatTableModule }                                 from '@angular/material/table';
import { MatToolbarModule }                               from '@angular/material/toolbar';
import { MatTooltipModule }                               from '@angular/material/tooltip';

import { CustomDateAdapter, MAT_DAYJS_DATE_FORMATS } from './mediego-common-module/utils/custom-date-adapter';

const MATERIAL_MODULES = [
  CommonModule,
  ScrollingModule,
  MatBadgeModule,
  MatDatepickerModule,
  MatButtonModule,
  MatSidenavModule,
  MatFormFieldModule,
  MatInputModule,
  MatToolbarModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatListModule,
  MatGridListModule,
  MatSelectModule,
  MatCardModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatChipsModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatButtonToggleModule,
  MatTableModule,
  MatSortModule,
  MatExpansionModule,
  MatTabsModule,
  MatCheckboxModule,
  MatStepperModule,
  MatIconModule,
  MatMenuModule,
  MatRadioModule,
  MatPaginatorModule,
  MatRippleModule,
  MatBottomSheetModule
];

@NgModule({
  imports: MATERIAL_MODULES,
  exports: MATERIAL_MODULES,
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS }
  ]
})
export class MaterialModule { }
