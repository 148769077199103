import { createAction, props, union } from '@ngrx/store';

import { ContactList } from '../../../configuration-module/providers/mailers.provider';

export const loadContactsList = createAction(
  '[CRM][LIST] Select',
  props<{ list: ContactList }>()
);

export const unloadContactsList = createAction(
  '[CRM][LIST] Unselect'
);

export const listActions = union({
  loadContactsList,
  unloadContactsList
});

export type ListAction = typeof listActions
