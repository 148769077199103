import _ from 'lodash/fp';

import { Article } from '../declarations/article';
import { DEFAULT_GLOBAL_CONSTRAINTS } from '../declarations/constraint';
import { Content, DEFAULT_ARTICLE_LINK, isContent } from '../declarations/content';
import { ButtonContent } from '../declarations/content/button.content';
import { CustomBlock, isCustomBlock } from '../declarations/custom-block';
import { ContentType, SizeUnit } from '../declarations/enums';
import { isLayout, Layout } from '../declarations/layout';
import { OuterRenderingProperties } from '../declarations/rendering-properties';
import { BorderRadiusRules } from '../declarations/styles';
import { TemplateModel } from '../declarations/template-model';

import { TemplateMetaModelUtils } from './template-meta-model.utils';
import { TemplateModelUtils } from './template-model.utils';


export class TemplateMigrationUtils {


  static migrate<T extends CustomBlock | TemplateModel>(model: T): T {

    const INNER_RENDERING = 'innerRenderingProperties';
    const OUTER_RENDERING = 'outerRenderingProperties';
    const LAYOUT_RENDERING = 'renderingProperties';

    // clean way of changing properties
    TemplateMigrationUtils.removePropertyOfContents(ContentType.Text, model, 'textCharLimit');
    TemplateMigrationUtils.removePropertyOfContents(ContentType.Title, model, 'titleCharLimit');
    TemplateMigrationUtils.addPropertyOfContents(ContentType.Button, model, 'customTracking', '');
    TemplateMigrationUtils.addPropertyOfContents(ContentType.Text, model, 'customTracking', '');
    TemplateMigrationUtils.addPropertyOfContents(ContentType.Title, model, 'customTracking', '');
    TemplateMigrationUtils.addPropertyOfContents(ContentType.Image, model, 'customTracking', '');
    TemplateMigrationUtils.addRenderingPropertyOfContents(ContentType.Button, model, INNER_RENDERING, 'fontWeight', 'normal');
    TemplateMigrationUtils.addRenderingPropertyOfContents(ContentType.Button, model, INNER_RENDERING, 'minWidth', {
      value: 0,
      unit: SizeUnit.PX
    });
    TemplateMigrationUtils.addRenderingPropertyOfContents(ContentType.Image, model, OUTER_RENDERING, 'borderRadius', {
      sameForAllCorners: true, topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0
    } as OuterRenderingProperties['borderRadius']);
    TemplateMigrationUtils.normalizeButtonFonts(model);
    TemplateMigrationUtils.addDarkmodePropertyOfContents(ContentType.Image, model, 'imageUrl', '');
    TemplateMigrationUtils.addPropertyOfContents(ContentType.Image, model, 'imageAlt', '');

    TemplateMigrationUtils.addPropertyOfContents(ContentType.Powerspace, model, 'user', 'email_md5');
    TemplateMigrationUtils.addPropertyOfContents(ContentType.Powerspace, model, 'nativeWidth', false);

    TemplateMigrationUtils.addDynamicPropertyOfContents(ContentType.Text, model, 'articleLink', DEFAULT_ARTICLE_LINK);
    TemplateMigrationUtils.addDynamicPropertyOfContents(ContentType.Title, model, 'articleLink', DEFAULT_ARTICLE_LINK);

    const defaultBorderRadius: BorderRadiusRules = { sameForAllCorners: true, topLeft: 0, topRight: 0, bottomLeft: 0, bottomRight: 0 };
    TemplateMigrationUtils.addRenderingPropertyOfLayouts(model, LAYOUT_RENDERING, 'borderRadius', { ...defaultBorderRadius })
    TemplateMigrationUtils.swapConditionRenderingPropertyOfLayoutsFromStringToArrayOfStrings(model)

    TemplateMigrationUtils.addRenderingPropertyOfContents(ContentType.Button, model, INNER_RENDERING, 'buttonTextAlign', '');

    const getButtonColorOfButton = (content: ButtonContent) => ({ ...content.innerRenderingProperties.buttonColor });
    const getTextColorOfButton = (content: ButtonContent) => content.innerRenderingProperties.textColor;
    TemplateMigrationUtils.addRenderingPropertyOfContents(ContentType.Button, model, INNER_RENDERING, 'buttonColorHover', getButtonColorOfButton);
    TemplateMigrationUtils.addRenderingPropertyOfContents(ContentType.Button, model, INNER_RENDERING, 'textColorHover', getTextColorOfButton);

    TemplateMigrationUtils.addPropertyOfContents(ContentType.Button, model, 'disableEncoding', false);
    TemplateMigrationUtils.addPropertyOfContents(ContentType.Text, model, 'disableEncoding', false);
    TemplateMigrationUtils.addPropertyOfContents(ContentType.Title, model, 'disableEncoding', false);
    TemplateMigrationUtils.addPropertyOfContents(ContentType.Image, model, 'disableEncoding', false);

    // template model specific
    if (isLayout(model)) {
      if (!model.renderingProperties.spaceBetweenParagraphs) {
        model.renderingProperties.spaceBetweenParagraphs = 0;
      }

      if (!model.metaModel) {
        const articles: Article[] = TemplateModelUtils.parseParentElementArticleEntries(model);
        model.metaModel = TemplateMetaModelUtils.extractMetaModel(articles, model);
      }

      // adding new factory constraints to model
      const factoryConstraintsToAdd = DEFAULT_GLOBAL_CONSTRAINTS.filter(factoryConstraint =>
        !model.constraints.find(existingConstraint => existingConstraint.pattern === factoryConstraint.pattern)
      ).map(factoryConstraint => _.cloneDeep(factoryConstraint));
      if (factoryConstraintsToAdd.length) {
        model.constraints = [
          ...model.constraints,
          ...factoryConstraintsToAdd
        ];
      }
    }

    return model;
  }

  static applyFunctionOnLayouts(fnToApply, item: TemplateModel | CustomBlock | Layout | Content, ...args) {
    if (item && isLayout(item)) {

      fnToApply(item, ...args);

    } else if (item && isCustomBlock(item)) {

      item.model.forEach((layout) => fnToApply(layout, ...args));

    }
  }

  static applyFunctionOnContents(fnToApply, contentType: ContentType, item: TemplateModel | CustomBlock | Layout | Content, ...args) {
    if (item && isContent(item) && item.type === contentType) {

      fnToApply(item, ...args);

    } else if (isLayout(item) && item.children?.length) {
      item.children.forEach(children => TemplateMigrationUtils.applyFunctionOnContents(fnToApply, contentType, children, ...args));
    } else if (isCustomBlock(item) && item.model?.length) {
      item.model.forEach(children => TemplateMigrationUtils.applyFunctionOnContents(fnToApply, contentType, children, ...args));
    }
  }

  static removePropertyOfContents(contentType: ContentType, item: Layout | Content | TemplateModel | CustomBlock, property: string) {

    const removePropertyFn = (content: Content, property: string) => {

      // finding if property must be removed on item
      const hasStaticPropertyToBeRemoved: boolean = content.params?.static && content.params.static[property] !== undefined;
      const hasDynamicPropertyToBeRemoved: boolean = content.params?.dynamic && content.params.dynamic[property] !== undefined;

      if (hasStaticPropertyToBeRemoved) {
        console.warn('deleted static property [' + property + ']');
        delete content.params.static[property];
      }
      if (hasDynamicPropertyToBeRemoved) {
        console.warn('deleted dynamic property [' + property + ']');
        delete content.params.dynamic[property];
      }
    };

    TemplateMigrationUtils.applyFunctionOnContents(removePropertyFn, contentType, item, property);
  }

  static addPropertyOfContents(contentType: ContentType, item: Layout | Content | CustomBlock | TemplateModel, property: string, defaultValue: any) {

    const addPropertyFn = (content: Content, property: string, defaultValue: string) => {

      // finding if property must be removed on item
      const alreadyHasStaticProperty: boolean = content.params?.static && content.params.static[property] !== undefined;
      const alreadyHasDynamicProperty: boolean = content.params?.dynamic && content.params.dynamic[property] !== undefined;

      if (!alreadyHasStaticProperty) {
        console.warn('added static property [' + property + ']');
        content.params.static[property] = defaultValue;
      }
      if (!alreadyHasDynamicProperty) {
        console.warn('added dynamic property [' + property + ']');
        content.params.dynamic[property] = defaultValue;
      }
    };

    TemplateMigrationUtils.applyFunctionOnContents(addPropertyFn, contentType, item, property, defaultValue);
  }

  static addDynamicPropertyOfContents(contentType: ContentType, item: Layout | Content | CustomBlock | TemplateModel, property: string, defaultValue: any) {

    const addPropertyFn = (content: Content, property: string, defaultValue: string) => {

      // finding if property must be removed on item
      const alreadyHasDynamicProperty: boolean = content.params?.dynamic && content.params.dynamic[property] !== undefined;
      if (!alreadyHasDynamicProperty) {
        console.warn('added dynamic property [' + property + ']');
        content.params.dynamic[property] = defaultValue;
      }
    };

    TemplateMigrationUtils.applyFunctionOnContents(addPropertyFn, contentType, item, property, defaultValue);
  }

  static addDarkmodePropertyOfContents(contentType: ContentType, item: Layout | Content | CustomBlock | TemplateModel, property: string, defaultValue: any) {

    const addDarkmodeFn = (content: Content, property: string, defaultValue: any) => {
      // finding if content already has darkmode parameters
      if (!content.params.darkmode) {
        content.params.darkmode = {};
      }

      // then if it is necessary to add specified property
      const hasDarkmodeProperty: boolean = content.params.darkmode[property] !== undefined;
      if (!hasDarkmodeProperty && defaultValue !== null && defaultValue !== undefined) {
        console.warn('set darkmode property [' + property + ']');
        content.params.darkmode[property] = defaultValue;
      }
    };

    TemplateMigrationUtils.applyFunctionOnContents(addDarkmodeFn, contentType, item, property, defaultValue);
  }

  static addRenderingPropertyOfLayouts(item: Layout | Content | CustomBlock | TemplateModel, targetRendering: string,
                                        property: string, defaultValue: any) {

    const addFn = (layout: Layout, targetRendering: string, property: string, defaultValue: any) => {
      const hasRenderingProperty: boolean = layout[targetRendering]
        && layout[targetRendering][property] !== undefined;

      if (!hasRenderingProperty && defaultValue !== null && defaultValue !== undefined) {
        console.warn('added rendering property [' + property + '] to layout');
        layout[targetRendering][property] = defaultValue;
      }
    };

    TemplateMigrationUtils.applyFunctionOnLayouts(addFn, item, targetRendering, property, defaultValue);
  }

  static swapConditionRenderingPropertyOfLayoutsFromStringToArrayOfStrings(item: Layout | Content | CustomBlock | TemplateModel) {

    if (isCustomBlock(item)) {
      item.model.forEach(layout => TemplateMigrationUtils.swapConditionRenderingPropertyOfLayoutsFromStringToArrayOfStrings(layout));
    } else {

      const hasConditionRenderingProperty: boolean = isLayout(item) && item.renderingCondition !== undefined && typeof item.renderingCondition === 'string';

      if (hasConditionRenderingProperty && isLayout(item)) {

        const nextRenderingCondition = (item.renderingCondition as any as string) === 'isWeekend' ? ['isSaturday', 'isSunday'] : [item.renderingCondition as any as string];

        console.warn('swapped rendering condition from ', item.renderingCondition, ' to ', nextRenderingCondition);
        item.renderingCondition = nextRenderingCondition;

        item.children.forEach(child => TemplateMigrationUtils.swapConditionRenderingPropertyOfLayoutsFromStringToArrayOfStrings(child));
      }

    }
  }

  static addRenderingPropertyOfContents(contentType: ContentType, item: Layout | Content | CustomBlock | TemplateModel, targetRendering: string,
                                        property: string, defaultValue: any) {

    const addFn = (content: Content, targetRendering: string, property: string, defaultValue: any) => {
      const hasRenderingProperty: boolean = content[targetRendering]
        && content[targetRendering][property] !== undefined;

      if (!hasRenderingProperty && defaultValue !== null && defaultValue !== undefined) {
        console.warn('added rendering property [' + property + ']');


        if (typeof defaultValue === 'function') {
          console.warn('dynamically set to ', defaultValue(content))
          content[targetRendering][property] = defaultValue(content);
        } else {
          content[targetRendering][property] = defaultValue;
        }

      }
    };

    TemplateMigrationUtils.applyFunctionOnContents(addFn, contentType, item, targetRendering, property, defaultValue);
  }

  static normalizeButtonFonts(item: Layout | Content | CustomBlock | TemplateModel) {

    const normalizeFn = (content: Content) => {
      const fonts = content?.innerRenderingProperties?.textFont;

      // removing Latin Modern Sans
      if (fonts?.includes('Latin Modern Sans')) {
        content.innerRenderingProperties.textFont = fonts.filter((font) => !!font && font !== 'Latin Modern Sans');
        console.warn('removed font Latin Modern Sans from Button Content');
      }
    };

    TemplateMigrationUtils.applyFunctionOnContents(normalizeFn, ContentType.Button, item);
  }

}
