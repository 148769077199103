import { CompilationContext } from '../declarations/compilation';
import { ContentParameters } from '../declarations/content';
import { ConstraintPattern, ContentType } from '../declarations/enums';


export class TemplateTrackingUtils {

  static contentTypeAbbreviations = {
    [ContentType.Button]: 'b',
    [ContentType.Image]: 'i',
    [ContentType.Text]: 'd',
    [ContentType.Title]: 't'
  };

  static setSelligentTrackingParameter(a_tag: HTMLAnchorElement, context: CompilationContext, params: ContentParameters, isArticle: boolean, articleIndex?: number): void {
    if (context.constraints?.find(constraint => constraint.pattern === ConstraintPattern.SelligentTracking && constraint.active)) {
      // finding parameter value
      let masensorValue;
      if (params.customTracking) masensorValue = params.customTracking;
      else if (isArticle) masensorValue = `article ${articleIndex + 1}`;

      // setting it if available inside <a masensor="..."
      if (masensorValue) a_tag.setAttribute('masensor', masensorValue);
    }
  }

  static getMediegoTrackingParameter(context: CompilationContext, params: ContentParameters, contentType: ContentType, isArticle: boolean, articleIndex?: number): string {
    if (context.constraints?.find(constraint => constraint.pattern === ConstraintPattern.HeatmapUTM && constraint.active)) {
      let mgo_hValue;
      if (params.customTracking) mgo_hValue = params.customTracking;
      else if (isArticle) mgo_hValue = `${articleIndex + 1}${TemplateTrackingUtils.contentTypeAbbreviations[contentType]}`;
      if (!!mgo_hValue) return `&mgo_h=${mgo_hValue}`;
    }
    return '';
  }
}
