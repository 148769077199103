<mat-card>

  <mat-card-title>
    <h3 class="content">
      <img src="/assets/img/logo/logo-compact-color.svg" class="logo" alt="" title="" />
      Notifications de l'équipe Mediego

      <button *ngIf="isAdmin$ | async"
              mat-icon-button color="accent" class="btn-add-notification"
              [routerLink]="['/monitoring/messages']"
              [matTooltip]="'COMMON.ADMINISTRATION.WARNING_MESSAGE' | translate">
        <mat-icon>notification_add</mat-icon>
      </button>

      <button (click)="dismissAllNotifications()"
              mat-icon-button color="primary" class="btn-clear-notifications"
              matTooltip="Marquer comme lues">
        <mat-icon>notifications_off</mat-icon>
      </button>
    </h3>
  </mat-card-title>

  <mat-divider></mat-divider>

  <mat-list>

    <ng-container *ngFor="let notification of notifications | orderByKey:'publicationDate':'desc'; let k = index">
      <mat-list-item (click)="dismissNotification(notification)"
                     [class.pending]="notification.pending"
                     [matTooltip]="notification.pending ? 'Cliquez pour marquer comme lu' : ''">

        <mat-icon mat-list-icon [color]="notification.tag === 'info' ? 'primary' : 'warn'">
          {{ notification.tag }}
        </mat-icon>

        <div>
          <span class="date">{{ notification.publicationDate | date:'EEEE d MMMM YYYY' }}</span>
          <span class="text">{{ notification.content }}</span>
        </div>
      </mat-list-item>
      <mat-divider *ngIf="k < notifications.length"></mat-divider>
    </ng-container>
  </mat-list>
</mat-card>
