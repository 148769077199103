<button mat-fab [color]="color" [disabled]="disabled || loading" [class.loading-container]="loading"
        [matBadge]="badge" [matBadgeHidden]="!badge" matBadgeOverlap="true" matBadgeColor="primary"
        [matTooltip]="tooltip" [matTooltipPosition]="tooltipPosition ? tooltipPosition : 'before'">
  <ng-container *ngIf="disabledDelay > 0 && disabled; else showIcon">
    {{ disabledDelay }}
  </ng-container>
  <ng-template #showIcon>
    <mat-icon>{{ icon }}</mat-icon>
  </ng-template>
</button>
