import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild, ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatTableDataSource } from '@angular/material/table';

import { combineLatest, firstValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';

import { AppService } from '../../../services/app.service';
import { AuthService } from '../../../services/auth.service';
import { SourceService } from '../../../services/source.service';

import { ContactList, hasSpecificSendingConfiguration, Mailer, MailersProvider } from 'app/configuration-module/providers/mailers.provider';

import { Engine } from 'app/main-module/declarations/engine';
import { isNewsletter, NewsletterSource, SendingConfiguration, Source } from '../../declarations/source';

import { environment } from '../../../../environments/environment';
import { TemplateModelUtils } from '../../../templater-module/utils/template-model.utils';
import { MediegoValidators } from '../../validators/mediego-validators';

interface TrackingParameter { name: string; value: string; encoded: boolean }



@Component({
  selector: 'app-mediego-source-setup',
  templateUrl: './source-setup.component.html',
  styleUrls: ['./source-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SourceSetupComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'mainContent';
  @ViewChild('deleteDialogTemplateRef', { static: true }) deleteDialogTemplateRef;


  saving = false;

  selectedEngine: Engine;

  source: Source | NewsletterSource;
  form: UntypedFormGroup;

  showMustache: boolean = false;
  showAddTrackingParamForm: boolean = false;

  paramNameControl = new UntypedFormControl('', Validators.required);
  paramValueControl = new UntypedFormControl('', Validators.required);
  paramEncodingControl = new UntypedFormControl('encoded', Validators.required);

  mustacheVariableSelector = new UntypedFormControl('');
  mustacheVariables: string[] = [];

  trackParamsColumns = ['select', 'paramName', 'paramValue', 'paramEncoded', 'actions'];
  trackParams: TrackingParameter[] = [];
  trackParamsForm: UntypedFormGroup = new UntypedFormGroup({});
  trackParamSelection = new SelectionModel<TrackingParameter>(true, []);

  // SENDING CONFIGURATION PARAMETERS
  sendingConfigForm: UntypedFormGroup;

  contactsMailers: Mailer[] = [];
  sendingMailers: Mailer[] = [];
  contactsListsForMailer: ContactList[] = [];
  senders: string[] = [];

  newContactList: string = '';

  get allTrackParamSelected(): boolean {
    return this.trackParamSelection?.selected?.length === this.trackParams?.length;
  }

  // shortcuts
  get selectedContactsMailer(): UntypedFormControl {
    return this.sendingConfigForm?.get('contactsMailer') as UntypedFormControl;
  }

  get selectedSendingMailer(): UntypedFormControl {
    return this.sendingConfigForm?.get('sendingMailer') as UntypedFormControl;
  }


  get selectedContactsLists(): UntypedFormControl {
    return this.sendingConfigForm?.get('contactsLists') as UntypedFormControl;
  }

  get senderName(): UntypedFormControl {
    return this.sendingConfigForm?.get('senderName') as UntypedFormControl;
  }

  get senderAddress(): UntypedFormControl {
    return this.sendingConfigForm?.get('senderAddress') as UntypedFormControl;
  }

  get replyToAddress(): UntypedFormControl {
    return this.sendingConfigForm?.get('replyToAddress') as UntypedFormControl;
  }

  get specificSendingMailerConf(): UntypedFormControl {
    return this.sendingConfigForm?.get('specificSendingMailerConfiguration') as UntypedFormControl;
  }

  get isEngineAdmin$(): Observable<boolean> {
    return this.appService.isEngineAdmin$();
  }

  get canConfigureSending$(): Observable<boolean> {
    return combineLatest([
      this.isAdmin$,
      this.isEngineAdmin$,
      this.appService.hasMailerPermissions$()
    ]).pipe(
      map(([isAdmin, isEngineAdmin, hasMailerPermissions]) => {
        return isAdmin || isEngineAdmin || hasMailerPermissions;
      })
    );
  }

  contactsMailerLoading: boolean = true;
  sendingMailerLoading: boolean = true;

  get trackParamsDataSource() {
    return new MatTableDataSource<TrackingParameter>(this.trackParams);
  }

  get isAdmin$(): Observable<boolean> {
    return this.authService.isAdmin$;
  }
  get displayName$(): Observable<string> {
    return this.appService.selectedSource$
      .pipe(
        filter((__) => !!__),
        map((__) => __.displayName)
      );
  }

  get isNewsletter$(): Observable<boolean> {
    return this.appService.selectedSource$
      .pipe(
        filter((__) => !!__),
        map((__) => __.sourceType === 'email')
      );
  }

  get apiURL$(): Observable<string> {
    return combineLatest([this.appService.selectedEngine$, this.appService.selectedSource$])
      .pipe(
        map(([engine, source]) => {
          if (!source) {
            return '';
          }

          if (source.sourceType === 'web') {
            return `${environment.apiUrl2}/1.0/recommendations/json?engine=${engine.id}&source=${source.id}`;
          } else {
            return `${environment.apiUrl2}/1.0/recommendations/html?engine=${engine.id}&source=${source.id}&campaign=[YYYYMMDD]`;
          }
        })
      );
  }

  get campaignParameterExample(): string {
    return dayjs().format('YYYYMMDD');
  }

  sourceSubscription: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private appService: AppService,
    private authService: AuthService,
    private sourceService: SourceService,
    private _fb: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private mailersProvider: MailersProvider,
    private translateService: TranslateService,
    private _vcr: ViewContainerRef
  ) { }

  async ngOnInit() {
    this.selectedEngine = await this.appService.getSelectedEngine();

    this.sourceSubscription = this.appService.selectedSource$.subscribe(async(__) => {

      // closing all current subscriptions
      this.unsubscribe$.next();

      if (__) {
        this.source = __;

        if (isNewsletter(this.source)) {
          const variables = TemplateModelUtils.getTemplateGlobalVariables({});
          this.mustacheVariables = variables ? [...Object.keys(variables)] : [];
        }

        this.initForm();

        /* Init trackParams and fill the table  */
        this.trackParams = [

          // normal params (will be encoded)
          ...(this.source.trackingParams ? Object
          .entries(this.source.trackingParams)
          .map(([name, value]) => ({ name, value, encoded: true })) : []),

          // raw params
          ...(this.source.rawTrackingParams ? Object
            .entries(this.source.rawTrackingParams)
            .map(([name, value]) => ({ name, value, encoded: false })) : [])
          ];

        this.syncTrackParamForm();

        if (isNewsletter(this.source)) {
          const newsletterSource: NewsletterSource = this.source;

          // setting up observables for selectedContactsMailer and selectedSendingMailer
          this.selectedContactsMailer.valueChanges.pipe(
              map(async(contactsMailerId) => {
                this.contactsMailerLoading = true;

                if (!contactsMailerId) {
                  this.contactsListsForMailer = [];
                } else {
                  if (this.isManualContactsMailer(this.selectedContactsMailer)) {
                    this.selectedContactsLists.setValue(newsletterSource.sendingConfiguration.contactsLists);
                  } else {
                    const contactsLists: ContactList[] = await this.mailersProvider.getContactsListsForMailer(this.selectedEngine.id, contactsMailerId)
                      .catch(() => []);

                  this.contactsListsForMailer = contactsLists;
                  const lists = newsletterSource.sendingConfiguration.contactsLists.map(listId =>
                    this.contactsListsForMailer.find((list) => list.id === listId)
                  ).filter((element) => element);
                  this.selectedContactsLists.setValue(lists);
                  }
                }

                this.contactsMailerLoading = false;
              }),
              takeUntil(this.unsubscribe$)
            ).subscribe();

          this.selectedSendingMailer.valueChanges.pipe(
              map((sendingMailerId) => {
                this.sendingMailerLoading = true;
                if (!sendingMailerId) {
                  this.senders = [];
                  this.sendingMailerLoading = false;
                } else {
                  const sendingMailer = this.sendingMailers.find((__) => __.id === sendingMailerId);
                  this.senders = sendingMailer.senders;

                  if (!hasSpecificSendingConfiguration(sendingMailer)) {
                    this.specificSendingMailerConf?.setValue(null);
                  }

                  // pushing sender address if not already available in senders list
                  if (newsletterSource.sendingConfiguration.senderAddress
                    && !this.senders?.includes(newsletterSource.sendingConfiguration.senderAddress)) {
                    this.senders = [
                      ...(this.senders?.length ? this.senders : []),
                      newsletterSource.sendingConfiguration.senderAddress
                    ];
                  }
                  this.sendingMailerLoading = false;
                }
              }),
              takeUntil(this.unsubscribe$)
            ).subscribe();

          this.contactsMailers = await this.mailersProvider.getContactsMailersForEngine(this.selectedEngine.id)
            .catch(() => []);

          this.sendingMailers = await this.mailersProvider.getSendingMailersForEngine(this.selectedEngine.id)
            .catch(() => []);

          if (newsletterSource?.sendingConfiguration) {
            this.selectedContactsMailer.setValue(newsletterSource.sendingConfiguration.contactsMailer);
            this.selectedSendingMailer.setValue(newsletterSource.sendingConfiguration.sendingMailer);

            this.senderName?.setValue(newsletterSource.sendingConfiguration.senderName);
            this.senderAddress?.setValue(newsletterSource.sendingConfiguration.senderAddress);
            this.replyToAddress?.setValue(newsletterSource.sendingConfiguration.replyToAddress);
            this.specificSendingMailerConf?.setValue(newsletterSource.sendingConfiguration.specificSendingMailerConfiguration);
          }

        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sourceSubscription) {
      this.sourceSubscription.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initForm() {
    const group = {
      displayName: [this.source.displayName, Validators.required],
      recommendationPipeline: new UntypedFormControl({ value: this.source.recommendationPipeline, disabled: true }),
      trackClicks: new UntypedFormControl(this.source.trackClicks || this.source.enableRecommendationTracking),
      recosSentTTL: new UntypedFormControl(this.source.recosSentTTL),
      coldstartNbLastVisitors: new UntypedFormControl(this.source.coldstartNbLastVisitors),
      openLinksInNewTab: this.source.headerTarget === '_blank',
      forceProtocol: new UntypedFormControl(this.source.forceProtocol),
      useHttps: this.source.useHttps ? 'https' : 'http',
      useSpecificAnchor: new UntypedFormControl(!!this.source.anchor),
      anchor: this.source.anchor ? this.source.anchor : '',
      makeRedirections: this.source.makeRedirections,
      enableMultiArmedBandit: new UntypedFormControl(this.source.enableMultiArmedBandit),
      multiArmedBanditEpsilon: new UntypedFormControl(this.source.multiArmedBanditEpsilon, MediegoValidators.requiredIfOtherEquals('enableMultiArmedBandit', true))
    };

    group['template'] = new UntypedFormControl({ value: this.source.template, disabled: true });
    group['enableTemplateEditor'] = new UntypedFormControl(this.source.enableTemplateEditor);

    if (isNewsletter(this.source)) {
      this.sendingConfigForm = this._fb.group({
        contactsMailer: '',
        contactsLists: [],

        sendingMailer: '',
        senderName: '',
        senderAddress: '',
        replyToAddress: '',
        specificSendingMailerConfiguration: null
      });
    }

    this.form = this._fb.group(group);

    this.mustacheVariableSelector.valueChanges.pipe(
      map(() => {
        this.paramValueControl.setValue(this.paramValueControl.value + '{{' + this.mustacheVariableSelector.value + '}}');
        this.mustacheVariableSelector.reset('', { emitEvent: false });
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  // can add given param or param available in form
  addTrackParam(param?: TrackingParameter): boolean {

    const name = param ? param.name : this.paramNameControl.value;
    const value = (param ? param.value : this.paramValueControl.value).trim();
    const encoded = param ? param.encoded : this.paramEncodingControl.value === 'encoded';


    if (this.trackParams.find(param => param?.name === name)) return false;

    this.trackParams.push({ name, value, encoded });

    if (!param) {
      this.paramNameControl.reset();
      this.paramValueControl.reset();
      this.paramEncodingControl.reset('encoded');
    }

    this.trackParamsForm.addControl(name, new UntypedFormGroup({
      name: new UntypedFormControl(name),
      value: new UntypedFormControl(value),
      encoded: new UntypedFormControl(encoded),
      added: new UntypedFormControl({ value: true, disabled: true })
    }));

    return true;
  }

  removeTrackParam(param: TrackingParameter): void {
    const index = this.trackParams.findIndex((__) => __.name === param.name);
    this.trackParams.splice(index, 1);

    this.trackParamsForm.removeControl(param.name);
  }

  syncTrackParamForm() {
    this.trackParamsForm = this._fb.group({});
    this.trackParams?.forEach(param => {
      this.trackParamsForm.addControl(param.name, new UntypedFormGroup({
        name: new UntypedFormControl(param.name),
        value: new UntypedFormControl(param.value),
        encoded: new UntypedFormControl(param.encoded)
      }));
    });
  }

  getParamChanged(name: string): boolean {
    return (this.trackParamsForm?.get(name) as UntypedFormGroup)?.get('added')?.value
        || !this.trackParams.find(param => param.name === (this.trackParamsForm?.get(name) as UntypedFormGroup)?.get('name').value)
        || this.trackParams.find(param => param.name === name).value !== (this.trackParamsForm?.get(name) as UntypedFormGroup)?.get('value').value
        || this.trackParams.find(param => param.name === name).encoded !== (this.trackParamsForm?.get(name) as UntypedFormGroup)?.get('encoded').value;
  }

  getTrackParamNameControl(name: string): UntypedFormControl {
    return (this.trackParamsForm?.get(name) as UntypedFormGroup)?.get('name') as UntypedFormControl;
  }

  getTrackParamValueControl(name: string): UntypedFormControl {
    return (this.trackParamsForm?.get(name) as UntypedFormGroup)?.get('value') as UntypedFormControl;
  }

  restoreParamName(name: string): void {
    const oldValue = this.trackParams.find(param => param?.name === name)?.name;
    if (oldValue) this.getTrackParamNameControl(name).setValue(oldValue);
  }

  restoreParamValue(name: string): void {
    const oldValue = this.trackParams.find(param => param?.name === name)?.value;
    if (oldValue) this.getTrackParamValueControl(name).setValue(oldValue);
  }

  getTrackParamEncodedControl(name: string): UntypedFormControl {
    return (this.trackParamsForm?.get(name) as UntypedFormGroup)?.get('encoded') as UntypedFormControl;
  }

  toggleAllTrackParam() {
    if (this.allTrackParamSelected) {
      this.trackParamSelection.clear();
      return;
    }

    this.trackParamSelection.select(...(this.trackParamsDataSource.data || []));
  }

  openDocumentationForUTM() {
    window.open('https://docs.mediego.com/fr/articles/5844649-ajout-des-utm-de-tracking', '_blank');
  }

  async exportTrackParams() {
    if (!this.trackParamSelection?.selected?.length) return;

    const permission = await navigator.permissions.query({ name: 'clipboard-write' as PermissionName });
    if (permission.state === 'granted' || permission.state === 'prompt') {

      const utms: number = this.trackParamSelection.selected.length;

      await navigator.clipboard.writeText(JSON.stringify(this.trackParamSelection.selected));

      this.trackParamSelection.clear();

      return this.snackbar.open(
        this.translateService.instant('COMMON.SOURCE_SETUP.LINK_PARAMS.EXPORT_PARAMETERS.SNACKBAR.SUCCESS', { utms }),
        null,
        { duration: 4000, panelClass: ['mediego-snack-bar', 'accent'] }
      );
    }

    return this.appService.raiseError(
      new Error(this.translateService.instant('COMMON.SOURCE_SETUP.LINK_PARAMS.EXPORT_PARAMETERS.SNACKBAR.ERROR')),
      { viewContainerRef: this._vcr }
    );
  }


  async importTrackParams() {

    const permission = await navigator.permissions.query({ name: 'clipboard-read' as PermissionName });
    if (permission.state === 'granted' || permission.state === 'prompt') {
      const clipboardContent = await navigator.clipboard.readText();

      if (clipboardContent) {
        try {
          const imported = JSON.parse(clipboardContent) as TrackingParameter[];
          let importedSuccessfully: number = 0;
          if (imported?.length) {
            imported.forEach((trackParam: TrackingParameter) => {
              if (trackParam.name !== undefined && trackParam.value !== undefined && trackParam.encoded !== undefined) {
                const added = this.addTrackParam(trackParam);
                if (added) importedSuccessfully++;
              }
            });
          }

          const snackbarContent = importedSuccessfully >= 1 ? this.translateService.instant('COMMON.SOURCE_SETUP.LINK_PARAMS.IMPORT_PARAMETERS.SNACKBAR.SUCCESS', { utms: importedSuccessfully })
            : this.translateService.instant('COMMON.SOURCE_SETUP.LINK_PARAMS.IMPORT_PARAMETERS.SNACKBAR.NEUTRAL')

          return this.snackbar.open(
            snackbarContent,
            null,
            { duration: 4000, panelClass: ['mediego-snack-bar', 'accent'] }
          );

        } catch (err) {
          console.error('impossible to parse clipboard content, probably not matching tracking parameters export');
          return this.appService.raiseError(
            new Error(this.translateService.instant('COMMON.SOURCE_SETUP.LINK_PARAMS.IMPORT_PARAMETERS.SNACKBAR.ERROR')),
            { viewContainerRef: this._vcr }
          );
        }
      }
    }
  }

  async save() {
    this.saving = true;

    const canConfigureSending = await firstValueFrom(this.canConfigureSending$);

    const newSource: Source | NewsletterSource = cloneDeep(this.source);

    newSource.displayName = this.form.get('displayName').value;
    newSource.recommendationPipeline = this.form.get('recommendationPipeline').value;
    newSource.trackClicks = this.form.get('trackClicks').value;
    newSource.recosSentTTL = this.form.get('recosSentTTL').value;
    newSource.coldstartNbLastVisitors = this.form.get('coldstartNbLastVisitors').value;
    newSource.headerTarget = this.form.get('openLinksInNewTab').value ? '_blank' : '';
    newSource.forceProtocol = this.form.get('forceProtocol').value;
    newSource.useHttps = this.form.get('useHttps').value === 'https';
    newSource.anchor = this.form.get('useSpecificAnchor').value === true ? this.form.get('anchor').value : undefined;
    newSource.makeRedirections = this.form.get('makeRedirections').value;
    newSource.enableMultiArmedBandit = this.form.get('enableMultiArmedBandit').value;
    newSource.multiArmedBanditEpsilon = this.form.get('multiArmedBanditEpsilon').value;

    newSource.trackingParams = (Object.values(this.trackParamsForm.value) as TrackingParameter[])
      .filter(param => param.encoded)
      .reduce((obj: { [param: string]: string; }, element: TrackingParameter) => {
        obj[element.name] = element.value.trim();
        return obj;
      }, {});

    newSource.rawTrackingParams = (Object.values(this.trackParamsForm.value) as TrackingParameter[])
      .filter(param => !param.encoded)
      .reduce((obj: { [param: string]: string; }, element: TrackingParameter) => {
        obj[element.name] = element.value.trim();
        return obj;
      }, {});

    newSource.template = this.form.get('template').value;
    newSource.enableTemplateEditor = this.form.get('enableTemplateEditor').value;

    if (isNewsletter(newSource)) {
      // only altering sending configuration if it is relevant
      if (canConfigureSending && this.sendingConfigForm?.touched && this.sendingConfigForm.valid && this.sendingConfigForm.enabled) {
        console.warn('saving sending configuration');
        const newSendingConf: SendingConfiguration = this.sendingConfigForm.value;
        // converting contactsLists to only keep their ids
        if (this.isManualContactsMailer(this.selectedContactsMailer)) {
          newSendingConf.contactsLists = newSendingConf.contactsLists;
        } else {
          newSendingConf.contactsLists = (newSendingConf.contactsLists as any || [])?.map((list: { id: string }) => list?.id);
        }

        newSource.sendingConfiguration = newSendingConf;
      } else console.warn('not saving sending configuration');
    }

    this.appService
      .getSelectedEngine()
      .then((engine) => {
        return this.sourceService.createOrUpdateSource(engine.id, newSource, 'updateSendingConf');
      })
      .then(() => {
        this.snackbar.open(this.translateService.instant('COMMON.SOURCE_SETUP.MESSAGE.SAVED'), null, {
          duration: 2000,
          panelClass: ['mediego-snack-bar', 'accent']
        });
        this.saving = false;
      })
      .catch((err) => {
        console.error(err);
        this.saving = false;
        this.appService.raiseError(err, { viewContainerRef: this._vcr });
      });
  }

  confirmDeleteSource() {
    const dialogRef = this.dialog.open(this.deleteDialogTemplateRef, {
      panelClass: 'mediego-dialog'
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        const source = { ...this.source };
        this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
        this.appService
          .getSelectedEngine()
          .then((engine) => {
            return this.sourceService.deleteSource(engine.id, source);
          }).catch((err) => {
            console.error(err);
            this.appService.raiseError(err, { viewContainerRef: this._vcr });
          });
      }
    });
  }

  async copyDebugRecosUrl(event) {

    event.preventDefault();

    const permission = await navigator.permissions.query({ name: 'clipboard-write' as PermissionName });
    if (permission.state === 'granted' || permission.state === 'prompt') {

      // parsing reco api for debug purpose
      const url = await firstValueFrom(this.apiURL$);
      const todayUrl = url.replace('[YYYYMMDD]', this.formatTodayDateToYYYYMMDD());
      const todayDebugUrl = todayUrl + '&debug=true';

      await navigator.clipboard.writeText(todayDebugUrl);

      this.snackbar.open(
        'URL de recommendations copiée dans le presse-papier',
        null,
        { duration: 1000, panelClass: ['mediego-snack-bar', 'accent'] }
        );
    }
  }


  private formatTodayDateToYYYYMMDD(): string {
    const d = new Date(Date.now()), year = d.getFullYear();
    let month = '' + (d.getMonth() + 1), day = '' + d.getDate();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('');
  }


  switchMoustacheSelector(e: any) {
    e ? e.preventDefault() : null;
    this.showMustache = !this.showMustache;
  }

  isManualContactsMailer(contactsMailer: UntypedFormControl): boolean {
    const selectedMailer = this.contactsMailers.find(m => m.id === contactsMailer.value);
    return selectedMailer && MailersProvider.MANUAL_CONTACTS_MAILER_TYPES.indexOf(selectedMailer.mailerType) != -1
  }

}
