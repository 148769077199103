import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable }             from '@angular/core';

import { firstValueFrom } from 'rxjs';

import { SourceService } from '../services/source.service';

import { NewsletterSource } from '../mediego-common-module/declarations/source';
import { RecommendationPipeline } from '../newsletters-module/declarations/pipeline/recommendation-pipeline';
import { RecommendationSource }   from '../newsletters-module/declarations/pipeline/recommendation-source';
import { Template }               from '../newsletters-module/declarations/template';
import { TemplateVariableFromAPI } from '../newsletters-module/declarations/template-variables';

import { environment } from '../../environments/environment';

import { RecoItem, RecosProvider, sanitizeReco } from './recos.provider';
export interface ItemMetadata { [metaName: string]: string }

export interface TemplateItem {
  itemId?: string;
  id?: string;
  metadata?: ItemMetadata;
  metadataOverride?: ItemMetadata;
  url?: string;
}

export interface TemplateItemFromAPI {
  creationDate?: string;
  url?: string;
  metadata?: ItemMetadata;
  itemSets?: string[];
  baseCategories?: string[];
  ancestorCategories?: string[];
  keywords?: string[];
  externalIds?: string[];
  sources?: string[];
  id?: string;
}

export const convertAPITemplateItemToFrontTemplateItem = (item: TemplateItemFromAPI): TemplateItem => {
  if (item) {
    return {
      id: item.id,
      url: item.url,
      metadata: item.metadata,
      metadataOverride: item.metadata
    };
  }
  return null;
};

export interface TemplateContent { [position: string]: TemplateItem }

export interface EditorialChoices {
  subject: string;
  recommendations: { [position: string]: EditorialRecommendationChoice };
}

export interface EditorialRecommendationChoice {
  recommendation: any;
  item: any;
}

@Injectable({
  providedIn: 'root'
})
export class TemplateProvider {

  constructor(private http: HttpClient, private recosProvider: RecosProvider, private sourceService: SourceService) { }

  // Returns templateId
  // Variables and subject query parameters are only used for API logging middleware
  createOrUpdateTemplate(engineId: number, sourceId: string, template: Template, variables: boolean, subject: boolean): Promise<string> {
    const template$ = this.http
      .put(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/template/?variables=${variables}&subject=${subject}`, {
        ...template,
        production: true
      },
      {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      });
    return firstValueFrom(template$);
  }

  getTemplateById(engineId: number, sourceId: string, templateId: string): Promise<Template> {
    const template$ = this.http.get(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/template/${templateId}`);

    return firstValueFrom(template$)
      .then((json: any) => {
        if (json) {
          return Template.fromJson(json)
        } else {
          console.error('no template', templateId);
          throw new Error('no json found for template');
        }
      });
  }

  async getPreview(engineId: number, sourceId: string, host: string, templateName: string, editorial: TemplateContent,
             editorialSubject: string, variables: TemplateVariableFromAPI[], recommendationPipeline: RecommendationPipeline, recos: RecoItem[]): Promise<string> {

    // We need to transpose map into an array but keeping order
    const editorialRecommendations = {};
    if (editorial) {
      Object.entries(editorial).forEach(([key, val]) => {
        if (val) {
          editorialRecommendations[key] = { ...val };
          editorialRecommendations[key].item = editorialRecommendations[key].itemId;
          delete editorialRecommendations[key].metadata;
          // delete editorialRecommendations[key].itemId; // TODO: clear this part of the code
        }
      });
    }

    const sourceFromServer = await this.sourceService.getSource(engineId, sourceId) as NewsletterSource;
    const templateFromServer = (await this.getTemplateById(engineId, sourceId, sourceFromServer?.template));
    const template = templateFromServer ? templateFromServer.compiled : null;

    // sanitizing recommendations to match backend signature
    let recommendations;

    if (recos) {
      recommendations = recos.map((reco: RecoItem) => {
        if (reco) {
          return {
            ...reco
          }
        } else {
          return null;
        }
      })
    } else {

      // recommendations are fetched if not provided
      const recommendations$ = this.recosProvider.getRecos(engineId, sourceId, host, recommendationPipeline, editorialRecommendations, variables);
      recommendations = (await firstValueFrom(recommendations$))
        .map((reco: RecoItem) => {
          if (reco) {
            return {
              ...reco,

              // erasing custom fields, used by dashboard
              origin: undefined,
              fromEditorial: undefined
            }
          } else {
            return null;
          }
        });
    }

    recommendations = recommendations.map((reco) => sanitizeReco(reco));

    const preview$ = this.http.post(
        `${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/recommendations/render`,
        { recommendations, editorialSubject, template, variables },
        { params: new HttpParams(),
          responseType: 'text'
        }
      );

    return firstValueFrom(preview$);
  }

  async getTemplateContent(engineId: number, source: string): Promise<TemplateContent> {
    const editorial$ = this.http.get<EditorialChoices>(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${source}/editorial`);
    const editorial = await firstValueFrom(editorial$);

    if (editorial) {
      const content = {};
      for (const index in editorial.recommendations) {
        const editorialRecommendation: EditorialRecommendationChoice = editorial.recommendations[index];
        content[index] = {
          ...editorialRecommendation.recommendation,
          ...editorialRecommendation.item
        }
      }
      return content;
    } else {
      return {};
    }
  }

  saveTemplateContent(engineId: number, source: string, items: TemplateContent): Promise<void> {
    const recommendations$ = this.http
      .post<void>(
        `${environment.apiUrl2}/2.0/engines/${engineId}/sources/${source}/editorial/recommendations`,
        items
      );
    return firstValueFrom(recommendations$);
  }

  scrapeItem(engineId: number, url: string): Promise<TemplateItemFromAPI> {
    const scrape$ = this.http
      .get<TemplateItemFromAPI>(`${environment.apiUrl2}/2.0/engines/${engineId}/scrape`, {
      params: new HttpParams()
        .set('url', url)
    });
    return firstValueFrom(scrape$);
  }

  getMostRecentItems(engineId: number, host: string, sourceId: string, recommendationSource: RecommendationSource): Promise<TemplateItemFromAPI[]> {
    const items$ = this.http
      .post<TemplateItemFromAPI[]>(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/most-recent-items`, {
        algorithm: recommendationSource.algorithm,
        constraints: recommendationSource.constraints,
        itemSets: recommendationSource?.itemSets?.length ? recommendationSource.itemSets : ['default'],
        invalidItemSets: recommendationSource.invalidItemSets,
        producerTimeout: recommendationSource.producerTimeout || null,
        optional: !!recommendationSource.optional
    },
    {
      params: new HttpParams()
        .set('nbOfRecentItems', '50')

    });
    return firstValueFrom(items$);
  }

  sendPreviewEmail(engineId: number, sourceId: string, subject: string, emails: string[], rendering: string): Promise<string> {
    const send$ = this.http
      .post(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/send-preview`, {
        subject,
        emails,
        rendering
      }, {
        responseType: 'text'
      });
    return firstValueFrom(send$);
  }

  async getEditorialSubject(engineId: number, sourceId: string): Promise<string> {
    const subject$ = this.http
      .get(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/editorial-subject`, {
        responseType: 'text'
      });
    return await firstValueFrom(subject$);
  }

  putEditorialSubject(engineId: number, sourceId: string, subject: string): Promise<void> {
    const subject$ = this.http
      .put<void>(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/editorial-subject`, subject);
    return firstValueFrom(subject$);
  }

  putTemplateVariables(engineId: number, sourceId: string, templateId: string, variables: TemplateVariableFromAPI[]): Promise<void> {
    const save$ = this.http
      .put<void>(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/template/${templateId}/variables`, variables);
    return firstValueFrom(save$);
  }

}
