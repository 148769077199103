import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, Input }                       from '@angular/core';

import { Observable }                             from 'rxjs';

import { TranslateService }                       from '@ngx-translate/core';

import { AppService }                             from '../../../../services/app.service';

import { LoadingStatus }                          from '../../../../main-module/declarations/loading-status';

@Component({
  selector: 'app-mediego-postit',
  templateUrl: './postit.component.html',
  styleUrls: ['./postit.component.scss']
})
export class PostitComponent {

  @Input() icon: string;
  @Input() cardTitle: string;
  @Input() description: string;
  @Input() value: number;
  @Input() valueType: string;

  get status(): Observable<LoadingStatus> {
    return this.appService.loadingStatus$;
  }

  constructor(
    private appService: AppService,
    private translate: TranslateService
  ) { }

  formatNumber(value: number): string {
    switch (this.valueType) {
      case 'number':
        return new DecimalPipe(this.translate.currentLang).transform(value, '1.0-0');

      case 'float':
        return new DecimalPipe(this.translate.currentLang).transform(value, '1.0-2');

      case 'percent':
        return new PercentPipe(this.translate.currentLang).transform(value, '1.0-2');

      case 'currency':
        return new CurrencyPipe(this.translate.currentLang).transform(value, 'EUR');

      default:
        return value.toString();
    }
  }

}
