import { Injectable }                                                                from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AppService }                                                                from 'app/services/app.service';

@Injectable()
export class HasEngines implements CanActivate {

  constructor(private router: Router, private appService: AppService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.appService.getEngines()
      .then(engines => {
        if (engines?.length) return true;
        else return this.router.parseUrl('/permissions-error');
      }).catch((err) => {
        console.error(err);
        return this.router.parseUrl('/platform-error');
      });
  }
}



@Injectable()
export class HasNoEngines implements CanActivate {

  constructor(private router: Router, private appService: AppService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const engines = await this.appService.getEngines();

    if (engines.length) {
      return this.router.parseUrl('/');
    } else {
      return true;
    }
  }
}
