import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { Observable }                                                            from 'rxjs';

import merge                                                                     from 'lodash/merge';

import { AppService }                                             from '../../../../services/app.service';

import { Language }                                               from '../../../../main-module/declarations/lang';
import { LoadingStatus }                                          from '../../../../main-module/declarations/loading-status';

import { RGB_ARRAY_WHITE, rgbArrayToHexString, rgbArrayToString } from '../../../utils/color.utils';

@Component({
  selector: 'app-mediego-advanced-pie-chart',
  templateUrl: './advanced-pie-chart.component.html',
  styleUrls: ['./advanced-pie-chart.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AdvancedPieChartComponent implements OnInit, OnChanges {

  @Input() chartTitle: string;
  @Input() datasets: any[];
  @Input() labels: any[];
  @Input() total: number;
  @Input() totalLabel: string = 'Total';
  @Input() colors: any[];
  @Input() options: any;
  @Input() nbOfCols: number;
  @Input() rowHeight: string;

  _dataPoints: Array<{ value: number; fraction: number; label: string; color: string }>;
  _total = 0;
  _options: any;
  _activeIndex: number;

  rgbColors = [
    [80, 192, 161], // teal
    [54, 162, 235], // blue
    [255, 99, 132], // red
    [153, 102, 255], // purple
    [255, 159, 64], // orange
    [151, 187, 205] // grey
  ];
  hexColors = this.rgbColors.map((__) => rgbArrayToHexString(__));
  defaultHexcolor = '#97BBCD';

  get status(): Observable<LoadingStatus> {
    return this.appService.loadingStatus$;
  }

  get locale$(): Observable<Language> {
    return this.appService.selectedLanguage$;
  }

  constructor(public appService: AppService) { }

  ngOnInit() {
    const _defaultOptions = { plugins: [] } as any;
    _defaultOptions['maintainAspectRatio'] = false;
    _defaultOptions['cutoutPercentage'] = 70;
    _defaultOptions['onHover'] = (e, activeElements) => {
      this._activeIndex = activeElements[0] && activeElements[0]._index;
    };

    // merge this.options into default options and put in this._options
    this._options = merge(_defaultOptions, this.options);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('datasets') && changes.datasets.currentValue.length > 0) {

      this.colors = Array(this.datasets.length).fill({
        backgroundColor: this.rgbColors.map((rgb) => rgbArrayToString(rgb, 0.8)),
        hoverBackgroundColor: this.rgbColors.map((rgb) => rgbArrayToString(rgb, 1)),
        hoverBorderColor: this.rgbColors.map((rgb) => rgbArrayToString(RGB_ARRAY_WHITE, 1))
      });

      this._total = this.total || this.datasets[0].data.reduce((acc, num) => acc + num, 0);
      this._dataPoints = this.datasets[0].data.map((value, index) => ({
        value,
        fraction: value / this._total,
        label: this.labels[index],
        color: index < this.hexColors.length ? this.hexColors[index] : this.defaultHexcolor
      }));
    }
  }

}
