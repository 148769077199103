import { createSelector }                          from '@ngrx/store';

import { SettingsState }                           from '../reducers/settings.reducer';

import { selectSettingsState } from './index';

export const selectSelectedLanguage = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.lang
);
