import { TemporalModifier } from './temporal-modifier';

export class LastNVisitsModifier extends TemporalModifier {


  readonly type: string = 'last-n-visits-filter';

  n: number;


  constructor(n: number = 30) {
    super();
    this.n = n;
  }

  parametersToJson(): any {
    return {
      n: this.n
    };
  }


  isValid(): boolean {
    return this.n > 0;
  }
}
