import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { firstValueFrom, Observable } from 'rxjs';
import { map }  from 'rxjs/operators';

import { AppState } from '../../main-module/state';
import { SLayout }             from '../state/reducers/layout.reducer';
import { STemplateModel }      from '../state/reducers/template-model.reducer';
import {
  selectModelChildrenIds,
  selectModelGlobalCss,
  selectModelRenderingProperties,
  selectModelSizeProperties,
  selectModelStructure,
  selectSTemplateModel,
  selectTemplateModel
}                              from '../state/selectors/template-model.selectors';
import { setContentHighlight } from '../state/actions/content.actions';

import { Article }                                           from '../declarations/article';
import { OuterRenderingProperties, SizeRenderingProperties } from '../declarations/rendering-properties';
import { TemplateModel }                                     from '../declarations/template-model';

@Injectable({
  providedIn: 'root'
})
export class TemplateModelService {

  get sTemplateModel$(): Observable<STemplateModel> {
    return this.store.pipe(select(selectSTemplateModel));
  }

  get templateModel$(): Observable<TemplateModel> {
    return this.store.pipe(select(selectTemplateModel));
  }
  get modelChildrenIds$(): Observable<string[]> {
    return this.store.pipe(select(selectModelChildrenIds));
  }

  get modelStructure$(): Observable<SLayout[]> {
    return this.store.pipe(select(selectModelStructure));
  }

  get isStructureEmpty$(): Observable<boolean> {
    return this.modelStructure$.pipe(map((__) => __.length === 0));
  }

  get modelGlobalCss$(): Observable<string> {
    return this.store.pipe(select(selectModelGlobalCss));
  }

  get modelSizeProperties$(): Observable<Partial<SizeRenderingProperties>> {
    return this.store.pipe(select(selectModelSizeProperties));
  }

  get modelRenderingProperties$(): Observable<Partial<OuterRenderingProperties>> {
    return this.store.pipe(select(selectModelRenderingProperties));
  }

  constructor(
    private store: Store<AppState>
  ) {}

  getSTemplateModel(): Promise<STemplateModel> {
    return firstValueFrom(this.sTemplateModel$);
  }

  getTemplateModel(): Promise<TemplateModel> {
    return firstValueFrom(this.templateModel$);
  }

  getModelStructure(): Promise<SLayout[]> {
    return firstValueFrom(this.modelStructure$);
  }

  highlightArticle(articleEntry: Article) {
    this.setArticleHighlight(articleEntry, true);
  }

  clearArticleHighlight(articleEntry: Article) {
    this.setArticleHighlight(articleEntry, false);
  }

  private setArticleHighlight(articleEntry: Article, highlighted: boolean) {
    this.store.dispatch(setContentHighlight({
      ids: articleEntry.elements,
      highlighted
    }));
  }

}
