import { SerializableCriterion } from './serializable-criterion';
import { VisitCriterion }        from './visit-criterion';

export abstract class SegmentCriterion extends SerializableCriterion {

  static fromJson(jsonCriterion: {type: string; parameters: any}): SegmentCriterion {
    switch (jsonCriterion.type) {
      case 'visit':
        return new VisitCriterion(jsonCriterion.parameters.useAndOperator);
      default:
        throw Error('Cannot parse jsonCriterion. Unknown criterionType: ' + jsonCriterion.type);
    }
  }


  readonly abstract displayName: string;
  readonly abstract icon: string;
  abstract isValid(): boolean;

}


