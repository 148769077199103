<div class="criteria-content">
  <mat-form-field>
    <mat-chip-grid #chipGrid>
      <mat-chip removable="true" (removed)="removeElement(i)"
                *ngFor="let elem of selectedElements; index as i">
        {{ elem}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <input matInput #chipCategoryInput placeholder="{{placeholder}}"
             [formControl]="selectedElement"
             [matAutocomplete]="autoComplete"
             [matChipInputFor]="chipGrid"
             [matChipInputAddOnBlur]="false" />
    </mat-chip-grid>

    <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="addElement($event)">
      <mat-option *ngFor="let elem of filteredElements" [value]="elem" >
        {{ elem }}
      </mat-option>
    </mat-autocomplete>

    <mat-hint *ngIf="max !== -1">Maximum de {{ max }} éléments.</mat-hint>
  </mat-form-field>
</div>
