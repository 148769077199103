import { Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router }         from '@angular/router';

import { MatLegacySnackBar as MatSnackBar }                    from '@angular/material/legacy-snack-bar';

import { TranslateService }                   from '@ngx-translate/core';

import { AppService }                         from '../../../services/app.service';
import { AuthService }                    from 'app/services/auth.service';

import { MediegoValidators }                  from '../../../mediego-common-module/validators/mediego-validators';

@Component({
  selector: 'app-mediego-accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.scss']
})
export class AcceptInviteComponent implements OnInit {
  @HostBinding('class') classes = 'mainContent fullScreenMobile';

  initStatus: 'loading' | 'done' | 'error' = 'loading';
  isLoading = false;

  token: string;

  passwordVisible = false;

  acceptInviteForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _fb: UntypedFormBuilder,
    private authService: AuthService,
    private appService: AppService,
    public snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    const token = this.route.snapshot.paramMap.get('token');
    this.token = token;
    this.authService.verifySignupInviteToken(token)
      .then((email: string) => {
        this.acceptInviteForm = this._fb.group({
          email: [{ value: email, disabled: true }, Validators.compose([
            Validators.required,
            Validators.email
          ])],
          password: ['', Validators.compose([
            Validators.required,
            MediegoValidators.sufficientlyStrongPassword()
          ])],
          confirmPassword: ['', Validators.compose([
            Validators.required,
            MediegoValidators.equalsControl('password')
          ])]
        });
        this.initStatus = 'done';
      })
      .catch(async(error) => {
        this.initStatus = 'error';
        let msg;

        try {
          console.error(error);
          const errorBody = JSON.parse(error.error);

          if (error.status === 400 && errorBody.errorId === 'InviteAlreadyUsed') {
            const errorBodyParts = errorBody.message.split(' ');
            const email = errorBodyParts[errorBodyParts.length - 1];

            msg = this.translate.instant('MAIN.ACCEPT_INVITE.WARNINGS.ALREADY_USED', { email });
            this.initStatus = 'done';
            this.showSnackBar(msg, false);

            if (window.hasOwnProperty('btoa') && window.hasOwnProperty('atob')) {
              const emailEncrypted = window.btoa(email);
              return this.router.navigate(['/login'], { queryParams: { email: emailEncrypted } });
            } else {

            }
          } else if (error.status === 404) {
            msg = this.translate.instant('MAIN.ACCEPT_INVITE.ERRORS.NOT_FOUND');
            this.showSnackBar(msg, true);
          } else {
            msg = this.translate.instant('MAIN.ACCEPT_INVITE.ERRORS.CANNOT_VERIFY');
            this.showSnackBar(msg, true);
          }

        } catch (e) {
          console.error('Cannot parse error body', e);
          msg = this.translate.instant('MAIN.ACCEPT_INVITE.ERRORS.CANNOT_VERIFY');
          this.showSnackBar(msg, true);
        }

      });
  }

  async onSubmit() {
    if (this.acceptInviteForm.valid) {
      this.isLoading = true;
      await this.authService.acceptInvite(this.token, this.acceptInviteForm.get('password').value).catch(async(err) => {
        console.error(err);
        this.showSnackBar(this.translate.instant('MAIN.ACCEPT_INVITE.ERRORS.CANNOT_ACCEPT'), true);
        this.isLoading = false;
        return 'accept';
      });

     await this.authService.login(
       this.acceptInviteForm.get('email').value,
       this.acceptInviteForm.get('password').value
     ).catch(async(err) => {
       console.error(err);
       this.showSnackBar(this.translate.instant('MAIN.ACCEPT_INVITE.ERRORS.CANNOT_LOGIN'), true);
       this.isLoading = false;
       return 'login';
     });

     await this.appService.loadEngines().catch(async(err) => {
       console.error(err);
       this.showSnackBar(this.translate.instant('MAIN.ACCEPT_INVITE.ERRORS.CANNOT_LOAD_ENGINES'), true);
       this.isLoading = false;
       return 'engines';
     });


      // Success. Redirect the user
      this.isLoading = false;
      return this.router.navigate(['/']);
    }
  }

  showSnackBar(message: string, error: boolean = true) {
    this.snackBar.open(
      message,
      undefined,
      {
        panelClass: error ? ['mediego-snack-bar', 'warn'] : ['mediego-snack-bar'],
        duration: error ? 10000 : 5000
      }
    );
  }

}
