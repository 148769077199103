<div id="container">
  <mat-card [ngSwitch]="status | async">
    <ng-container *ngSwitchCase="'ready'">
      <!-- INFO ICON -->
      <div id="info-icon" *ngIf="description" [matTooltip]="description" matTooltipPosition="left">
        <mat-icon>info</mat-icon>
      </div>

      <!-- BODY -->
      <div id="main" fxLayout="column" fxLayoutAlign="space-around stretch">
        <div id="value">{{ formatNumber(value, valueType) }}
          <span *ngIf="displayTrend && value && subValue" class="trend">{{ getTrendPercentage(value, subValue) }} <mat-icon *ngIf="value !== subValue" [class.up]="value > subValue" [class.down]="value < subValue">{{ value > subValue ? 'trending_up' : 'trending_down' }}</mat-icon></span>
        </div>
        <div id="title">{{ cardTitle }}</div>
        <div id="subValue" *ngIf="subValue !== undefined">
          {{ formatNumber(subValue, subValueType) }} <span *ngIf="subValueName !== undefined">{{ subValueName }}</span>
        </div>
      </div>


      <!-- FOOTER -->
      <div *ngIf="valueType === 'percent'; then percentFooter else normalFooter"></div>

      <ng-template #normalFooter>
        <div class="footer-fill normal-footer {{ color }}"></div>
      </ng-template>

      <ng-template #percentFooter>
        <div class="footer-outline percent-footer {{ color }}"></div>
        <div class="footer-fill percent-footer {{ color }}" [ngStyle]="{ 'width.%': (value <= 1 ? value * 100 : 100) }"></div>
      </ng-template>

    </ng-container>


    <ng-container *ngSwitchCase="'loading'">
      <div id="mainLoading" fxLayout="column" fxLayoutAlign="space-around stretch">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 250 90" preserveAspectRatio="xMidYMid slice">
          <rect x="0" y="0" width="187.5" height="35" rx="3" ry="3" fill="#e5ebee"></rect>
          <rect x="0" y="48" width="225" height="28" rx="3" ry="3" fill="#e5ebee"></rect>
        </svg>
      </div>
    </ng-container>



    <!-- ERROR PLACEHOLDER -->
    <ng-container *ngSwitchCase="'error'">
      <div id="errorContainer" fxLayout="row" fxLayoutAlign="center center" fxFill>
        <mat-icon>error</mat-icon>
      </div>
    </ng-container>
  </mat-card>
</div>
