import { Layout } from './layout';

export const isCustomBlock = (obj: any): obj is CustomBlock => {
  return obj && obj.hasOwnProperty('id') && obj.hasOwnProperty('engineId') && obj.hasOwnProperty('model');
};

export interface CustomBlock {
  id: string;
  engineId: number;
  name: string;
  description?: string;
  preview?: string;
  model: Layout[];
  compiled: string;
  collection?: string[];
}
