import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intervalTypeTranslationId'
})
export class IntervalTypePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    switch (value) {
      case 'hour':
        return 'COMMON.DATE_SELECTION.INTERVAL_SELECTION.HOUR';

      case 'day':
        return 'COMMON.DATE_SELECTION.INTERVAL_SELECTION.DAY';

      case 'week':
        return 'COMMON.DATE_SELECTION.INTERVAL_SELECTION.WEEK';

      case 'month':
        return 'COMMON.DATE_SELECTION.INTERVAL_SELECTION.MONTH';

      default:
        return value;
    }
  }

}
