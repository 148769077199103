import { createAction, props, union } from '@ngrx/store';

import { Content } from '../../declarations/content';
import {
  InnerRenderingProperties,
  OuterRenderingProperties,
  SizeRenderingProperties
}                  from '../../declarations/rendering-properties';

export const deleteContents = createAction(
  '[TEMPLATER][CONTENTS] Delete Many',
  props<{ ids: string[] }>()
);

export const updateContentProperties = createAction(
  '[TEMPLATER][CONTENTS] Update Properties',
  props<{ id: string; partial: Partial<Content> }>()
);

export const updateContentParameter = createAction(
  '[TEMPLATER][CONTENTS] Update Parameter',
  props<{ id: string; param: string; val: any }>()
);

export const updateContentStaticParameter = createAction(
  '[TEMPLATER][CONTENTS] Update Static Parameter',
  props<{ id: string; param: string; val: any }>()
);

export const updateContentDynamicParameter = createAction(
  '[TEMPLATER][CONTENTS] Update Dynamic Parameter',
  props<{ id: string; param: string; val: any }>()
);

export const updateContentDarkmodeParameter = createAction(
  '[TEMPLATER][CONTENTS] Update Darkmode Parameter',
  props<{ id: string; param: string; val: any }>()
);

export const updateContentSizeProps = createAction(
  '[TEMPLATER][CONTENTS] Update SIZE Properties',
  props<{ id: string; partial: Partial<SizeRenderingProperties> }>()
);

export const updateContentOuterRenderingProps = createAction(
  '[TEMPLATER][CONTENTS] Update OUTER Rendering Properties',
  props<{ id: string; partial: Partial<OuterRenderingProperties> }>()
);

export const updateContentInnerRenderingProps = createAction(
  '[TEMPLATER][CONTENTS] Update INNER Rendering Properties',
  props<{ id: string; partial: Partial<InnerRenderingProperties> }>()
);

export const setContentDynamic = createAction(
  '[TEMPLATER][CONTENTS] SET Article Ref',
  props<{ id: string; articleRef: string }>()
);

export const setContentStatic = createAction(
  '[TEMPLATER][CONTENTS] CLEAR Article Ref',
  props<{ id: string }>()
);

export const setContentHighlight = createAction(
  '[TEMPLATER][CONTENTS] Set Highlight',
  props<{ ids: string[]; highlighted: boolean }>()
);


export const undoContent = createAction('[TEMPLATER][CONTENTS] UNDO');
export const redoContent = createAction('[TEMPLATER][CONTENTS] REDO');

export const contentUserActionsCollection = {
  deleteContents,
  updateContentProperties,
  updateContentParameter,
  updateContentStaticParameter,
  updateContentDynamicParameter,
  updateContentDarkmodeParameter,
  updateContentSizeProps,
  updateContentOuterRenderingProps,
  updateContentInnerRenderingProps,
  setContentDynamic,
  setContentStatic
};

export const contentUserActions = union(contentUserActionsCollection);
const contentNonUserActions = union({
  setContentHighlight
});

export type ContentAction = typeof contentUserActions | typeof contentNonUserActions;
