import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../main-module/state';
import {
  selectEngines,
  selectSelectedEngine,
  selectSelectedEngineName
}                                 from '../main-module/state/selectors/engines-selectors';
import { selectSelectedSource }   from '../main-module/state/selectors/router-selectors';
import { selectSelectedLanguage } from '../main-module/state/selectors/settings-selectors';
import {
  AddEngineAction,
  ReplaceAllEnginesAction,
  ReplaceOneEngineAction
}                                 from '../main-module/state/actions/engines.actions';
import { SetLanguageAction }      from '../main-module/state/actions/settings.actions';

import { Engine, NewsletterPermissions } from 'app/main-module/declarations/engine';
import {
  GlobalError,
  GlobalErrorOptions
}                                                                         from 'app/main-module/declarations/global-error';
import { AVAILABLE_LANGUAGES, Language, LANGUAGE_STORAGE_KEY, setLocale } from '../main-module/declarations/lang';
import { LoadingStatus }                                                  from '../main-module/declarations/loading-status';
import { NewsletterSource, Source } from '../mediego-common-module/declarations/source';

import { environment } from '../../environments/environment';
import { MediegoError } from '../mediego-common-module/utils/errors.utils';


export type NewsletterSourcesMap = {[id: string]: string};

@Injectable({
  providedIn: 'root'
})
export class AppService {

  loadingEvents: string[] = [];
  private loadingStatus$$ = new BehaviorSubject<LoadingStatus>('standby');
  loadingStatus$ = this.loadingStatus$$.pipe(distinctUntilChanged());
  onGlobalError = new EventEmitter<GlobalError>(true);
  onClearGlobalError = new EventEmitter<void>(true);
  onSnackBarAction = new Subject();
  onSnackBarDismissed = new Subject();

  get engines$(): Observable<Engine[]> {
    return this.store.select(selectEngines);
  }

  get selectedEngine$(): Observable<Engine | null> {
    return this.store.select(selectSelectedEngine);
  }

  get selectedEngineName$(): Observable<string> {
    return this.store.select(selectSelectedEngineName);
  }

  get selectedSource$(): Observable<Source | undefined> {
    return this.store.select(selectSelectedSource);
  }

  get selectedLanguage$(): Observable<Language> {
    return this.store.select(selectSelectedLanguage);
  }

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private store: Store<AppState>,
    private router: Router
  ) {}

  async init(): Promise<void> {
    await this.loadEngines()
      .catch((err) => {
        console.error('error while trying to load engines', err);
        this.router.navigate(['/platform-error']);
      });
  }

  async initLanguage(): Promise<void> {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(AVAILABLE_LANGUAGES.fr);

    const storedLang: Language = localStorage.getItem(LANGUAGE_STORAGE_KEY) as Language;
    if (storedLang) {
      await this.setLanguage(storedLang); // Use stored language setting
    } else if (AVAILABLE_LANGUAGES[this.translate.getBrowserLang()]) {
      await this.setLanguage(this.translate.getBrowserLang() as Language); // Use browser language if available
    } else {
      await this.setLanguage(AVAILABLE_LANGUAGES.en); // Use internationally spoken language
    }
  }

  async setLanguage(lang: Language): Promise<void> {
    setLocale(lang);
    localStorage.setItem(LANGUAGE_STORAGE_KEY, lang);
    this.store.dispatch(new SetLanguageAction({ lang }));
    const language$ = this.translate.use(lang).pipe(take(1));
    return firstValueFrom(language$);
  }

  loadEngines(): Promise<void> {

    const engines$ = this.http.get<Engine[]>(`${environment.apiUrl2}/2.0/dashboard/engines`);

    return firstValueFrom(engines$)
      .then((engines: Engine[]) => {

        // TODO: remove once API v2 for permissions is deployed
        engines.map(engine => {

          const engineWithNewsletterAuthorization = engine;

          // authorizing access to global newsletters config if at least one newsletter is defined
          if (engine.userPermissions.newsletters && engine.userPermissions.newsletters.length) {
            engineWithNewsletterAuthorization.userPermissions.configurations.newsletters = true;
          }

          return engineWithNewsletterAuthorization;

        });

        this.store.dispatch(new ReplaceAllEnginesAction({ engines }));
      });
  }

  getEngines(): Promise<Engine[]> {
    const engines$ = this.engines$.pipe(filter((__) => !!__), take(1));
    return firstValueFrom(engines$);
  }

  getSelectedEngine(): Promise<Engine | undefined> {
    const selectedEngines$ = this.selectedEngine$.pipe(filter((__) => !!__), take(1));
    return firstValueFrom(selectedEngines$);
  }

  getSelectedSource(): Promise<Source | undefined> {
    const selectedSource$ = this.selectedSource$.pipe(filter((__) => !!__), take(1));
    return firstValueFrom(selectedSource$);
  }

  getSpecificNewsletterSourceOfEngine(allEngines: Engine[], engineId: number, sourceId: string): NewsletterSource | undefined {
    const specificEngine = allEngines.find(engine => engine.id === engineId);
    if (specificEngine) return specificEngine.sources.newsletters_all.find(newsletter => newsletter.id === sourceId);
    return undefined;
  }

  getAllNewsletterSourcesOfSelectedEngine(): Promise<NewsletterSourcesMap | undefined> {

    const allNewsletters$ = this.selectedEngine$.pipe(
      filter((__) => !!__),
      map((engine) => {
        const allSources: NewsletterSourcesMap = {};
        engine.sources.newsletters_all.forEach((source) => {
          if (source) allSources[source.id] = source.displayName;
        });
        return allSources;
      }),
      take(1));

    return firstValueFrom(allNewsletters$);
  }

  isEngineAdmin$(): Observable<boolean> {
    return combineLatest(
      [this.selectedEngine$]
    ).pipe(
      map((data: [Engine]) => {
        const engine = data[0];
        return !!engine?.userPermissions?.isEngineAdmin;
      })
    );
  }

  hasMailerPermissions$(): Observable<boolean> {
    return this.selectedEngine$.pipe(
      map((engine: Engine) => {
        return engine.userPermissions.configurations.mailers;
      })
    );
  }

  selectedPermissions$(): Observable<NewsletterPermissions> {
    return combineLatest(
      [
        this.selectedEngine$,
        this.selectedSource$
      ]
    ).pipe(
      map((data: [Engine, NewsletterSource]) => {
        const engine = data[0];
        const source = data[1];

        let foundPermissionsIndex = -1;
        if (engine && source && engine.userPermissions && engine.userPermissions.newsletters) {
          engine.userPermissions.newsletters.forEach((newsletter, index) => {
            if (newsletter.newsletterId === source.id) {
              foundPermissionsIndex = index;
            }
          });
        }

        if (foundPermissionsIndex > -1) {
          return engine.userPermissions.newsletters[foundPermissionsIndex];
        }
      })
    );
  }

  createEngine(engineName: string, domainName: string): Promise<void> {
    const engine$ = this.http.post<Engine>(`${environment.apiUrl2}/2.0/engines`, { engineName, domainName });

    return firstValueFrom(engine$)
      .then((engine: Engine) => {
        this.store.dispatch(new AddEngineAction({ engine }));
      });
  }

  editEngine(
    displayName: string,
    name: string,
    enabled: boolean,
    useKafkaScraper: boolean,
    timezone: string,
    expireRawFormat: string,
    hosts: string[],
    parametersToKeep: string[],
    blacklistedIps: string[]
  ): Promise<void> {
    return this.getSelectedEngine()
      .then((selectedEngine: Engine) => {

        const engine$ = this.http.put<Engine>(`${environment.apiUrl2}/2.0/dashboard/engines`, {
          id: selectedEngine.id,
          displayName,
          name,
          enabled,
          useKafkaScraper,
          timezone,
          expireRawFormat,
          hosts,
          parametersToKeep,
          blacklistedIps
        });

        return firstValueFrom(engine$);
      })
      .then((newEngine: Engine) => {
        this.store.dispatch(new ReplaceOneEngineAction({ newEngine }));
      });
  }

  addLoadingEvent(eventId: string) {
    this.onClearGlobalError.emit();
    if (!this.loadingEvents.includes(eventId)) {
      // preventing duplicated loading events
      this.loadingEvents.push(eventId);
      this.loadingStatus$$.next('loading');
    }
  }

  removeLoadingEvent(eventId: string) {
    this.loadingEvents = this.loadingEvents.filter((__) => __ !== eventId);

    if (this.loadingEvents.length === 0) {
      this.loadingStatus$$.next('ready');
    }
  }

  raiseError(error: Error | HttpErrorResponse, options: GlobalErrorOptions = {}) {
    if (options.snackBarDuration !== undefined && options.snackBarActionTitle !== undefined) {
      console.warn('AppService.raiseError(): options.snackBarDuration has no effect when options.snackBarActionTitle is defined');
    }

    let errorShown = error;
    if ((error as HttpErrorResponse)?.error?.errorId && (error as HttpErrorResponse)?.error?.message) {
      const mediegoError: MediegoError = (error as HttpErrorResponse).error;
      errorShown = new Error(`${mediegoError.message} [${mediegoError.errorId}]`);
    }

    // These instantiations are preventing multiple retry on snackbar action
    this.onSnackBarAction = new Subject();
    this.onSnackBarDismissed = new Subject();
    this.loadingEvents = [];
    this.loadingStatus$$.next('error');
    this.onGlobalError.emit({ error: errorShown, options });
  }

  resetScroll() {
    document.querySelector('mat-sidenav-content').scroll(0, 0);
  }

}
