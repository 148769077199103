<mat-card [ngSwitch]="status | async" fxFlex="100%" fxLayout="column">
  <ng-container *ngSwitchCase="'ready'">
    <div id="title">{{ cardTitle }}</div>

    <cdk-virtual-scroll-viewport [itemSize]="rowHeight + gutterSize"
                                 minBufferPx="0" maxBufferPx="0"
                                 [ngStyle]="{ height: rowHeight * 2 + gutterSize * 3 + 'px' }">
      <mat-grid-list *cdkVirtualFor="let row of rows | async; index as i"
                     [cols]="nbOfCols" [rowHeight]="rowHeight"
                     [ngStyle]="{ marginBottom: gutterSize + 'px' }">
        <mat-grid-tile *ngFor="let entry of row; index as j">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36">
            <circle cx="18" cy="18" r="16" class="base" [attr.stroke]="entry.color"></circle>
            <circle cx="18" cy="18" r="16" class="fill" [attr.stroke]="entry.color" [attr.stroke-dasharray]="entry.fraction * 100 + ', 100'"></circle>
          </svg>
          <div class="percentage">{{ entry.fraction | percent }}</div>
          <div class="label" [matTooltip]="entry.label">{{ entry.label }}</div>
          <span class="value">Total: {{ entry.value | number }}</span>
        </mat-grid-tile>
      </mat-grid-list>
    </cdk-virtual-scroll-viewport>
  </ng-container>


  <ng-container *ngSwitchCase="'loading'">
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 525 30" preserveAspectRatio="xMidYMid slice">
      <rect x="105" y="0" width="315" height="10" rx="3" ry="3" fill="#e5ebee"></rect>
    </svg>

    <div [ngStyle]="{ height: rowHeight + 'px' }">
      <mat-grid-list [cols]="nbOfCols" [rowHeight]="rowHeight" [gutterSize]="gutterSize + 'px'">
        <mat-grid-tile *ngFor="let entry of nbOfCols | times; index as i">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36">
            <circle cx="18" cy="18" r="16" class="base" stroke="#e5ebee"></circle>
            <circle cx="18" cy="18" r="16" class="fill" stroke="#e5ebee" [attr.stroke-dasharray]="(100 / nbOfCols) * (nbOfCols - i) + ', 100'"></circle>
            <rect x="12" y="14" width="12" height="3" rx="1" ry="1" fill="#e5ebee"></rect>
            <rect x="5.5" y="19" width="25" height="3" rx="1" ry="1" fill="#e5ebee"></rect>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 36 8.9">
            <rect x="4.5" y="2.95" width="27" height="3" rx="1" ry="1" fill="#e5ebee"></rect>
          </svg>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'error'">
    <div id="errorContainer" fxLayout="row" fxLayoutAlign="center center" fxFill>
      <mat-icon>error</mat-icon>
    </div>
  </ng-container>
</mat-card>
