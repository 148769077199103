import { Action }                        from '@ngrx/store';

import { NewsletterSource, Source }      from '../../../mediego-common-module/declarations/source';
import { Segment }                       from '../../../segments-module/declarations/segment';
import { Engine, NewsletterPermissions, SegmentPermissions } from '../../declarations/engine';

export enum EnginesActionTypes {
  ADD = 'ADD_ENGINE',
  REPLACE_ONE = 'REPLACE_ONE_ENGINE',
  REPLACE_ALL = 'REPLACE_ALL_ENGINES',
  SELECT_BY_ID = 'SELECT_ENGINE_BY_ID',
  DELETE_ALL = 'DELETE_ALL_ENGINES',

  USER_SELECTED_ENGINE = 'USER_SELECTED_ENGINE',

  ADD_EMAIL_SOURCE_FOLDER = 'ADD_EMAIL_SOURCE_FOLDER',

  UPSERT_SEGMENT_IN_SELECTED = 'UPSERT_SEGMENT_IN_SELECTED_ENGINE',
  DELETE_SEGMENT_FROM_SELECTED = 'DELETE_SEGMENT_FROM_SELECTED_ENGINE',
  INSERT_SEGMENT_PERMISSIONS_IN_SELECTED = 'INSERT_SEGMENT_PERMISSIONS_IN_SELECTED',

  UPSERT_WEB_SOURCE_IN_SELECTED = 'UPSERT_WEB_SOURCE_IN_SELECTED_ENGINE',
  DELETE_WEB_SOURCE_FROM_SELECTED = 'DELETE_WEB_SOURCE_FROM_SELECTED_ENGINE',

  UPSERT_EMAIL_SOURCE_IN_SELECTED = 'UPSERT_EMAIL_SOURCE_IN_SELECTED_ENGINE',
  DELETE_EMAIL_SOURCE_FROM_SELECTED = 'DELETE_EMAIL_SOURCE_FROM_SELECTED_ENGINE',

  INSERT_NEWSLETTER_PERMISSIONS_IN_SELECTED = 'INSERT_NEWSLETTER_PERMISSIONS_IN_SELECTED_ENGINE',
  DELETE_NEWSLETTER_PERMISSIONS_IN_SELECTED = 'DELETE_NEWSLETTER_PERMISSIONS_IN_SELECTED_ENGINE'
}

export class AddEngineAction implements Action {
  readonly type = EnginesActionTypes.ADD;

  constructor(public payload: { engine: Engine }) {}
}

export class ReplaceOneEngineAction implements Action {
  readonly type = EnginesActionTypes.REPLACE_ONE;

  constructor(public payload: { newEngine: Engine }) {}
}

export class ReplaceAllEnginesAction implements Action {
  readonly type = EnginesActionTypes.REPLACE_ALL;

  constructor(public payload: { engines: Engine[] }) {}
}

export class DeleteAllEnginesAction implements Action {
  readonly type = EnginesActionTypes.DELETE_ALL;
}

export class SelectEngineByIdAction implements Action {
  readonly type = EnginesActionTypes.SELECT_BY_ID;

  constructor(public payload: { engineId: number }) {}
}



export class AddEmailSourceFolder implements Action {
  readonly type = EnginesActionTypes.ADD_EMAIL_SOURCE_FOLDER;

  constructor(public payload: { folderPath: string[] }) {}
}



export class UserSelectedEngineAction implements Action {
  readonly type = EnginesActionTypes.USER_SELECTED_ENGINE;

  constructor(public payload: { engine: Engine }) {}
}






export class UpsertSegmentInSelectedEngineAction implements Action {
  readonly type = EnginesActionTypes.UPSERT_SEGMENT_IN_SELECTED;

  constructor(public payload: { segment: Segment }) {}
}

export class DeleteSegmentFromSelectedEngineAction implements Action {
  readonly type = EnginesActionTypes.DELETE_SEGMENT_FROM_SELECTED;

  constructor(public payload: { id: string }) {}
}

export class InsertSegmentPermissionsInSelectedEngineAction implements Action {
  readonly type = EnginesActionTypes.INSERT_SEGMENT_PERMISSIONS_IN_SELECTED;

  constructor(public payload: { segmentPermissions: SegmentPermissions }) {}
}





export class UpsertWebSourceInSelectedEngineAction implements Action {
  readonly type = EnginesActionTypes.UPSERT_WEB_SOURCE_IN_SELECTED;

  constructor(public payload: { source: Source }) {}
}

export class DeleteWebSourceFromSelectedEngineAction implements Action {
  readonly type = EnginesActionTypes.DELETE_WEB_SOURCE_FROM_SELECTED;

  constructor(public payload: { id: string }) {}
}





export class UpsertEmailSourceInSelectedEngineAction implements Action {
  readonly type = EnginesActionTypes.UPSERT_EMAIL_SOURCE_IN_SELECTED;

  constructor(public payload: { source: NewsletterSource }) {}
}

export class DeleteEmailSourceFromSelectedEngineAction implements Action {
  readonly type = EnginesActionTypes.DELETE_EMAIL_SOURCE_FROM_SELECTED;

  constructor(public payload: { id: string }) {}
}



export class InsertNewsletterPermissionsInSelectedEngineAction implements Action {
  readonly type = EnginesActionTypes.INSERT_NEWSLETTER_PERMISSIONS_IN_SELECTED;

  constructor(public payload: { newsletterPermissions: NewsletterPermissions }) {}
}

export class DeleteNewsletterPermissionsInSelectedEngineAction implements Action {
  readonly type = EnginesActionTypes.DELETE_NEWSLETTER_PERMISSIONS_IN_SELECTED;

  constructor(public payload: { id: string }) {}
}


export type EnginesAction =
  AddEngineAction |
  ReplaceOneEngineAction |
  ReplaceAllEnginesAction |
  DeleteAllEnginesAction |
  SelectEngineByIdAction |

  UserSelectedEngineAction |

  AddEmailSourceFolder |

  UpsertSegmentInSelectedEngineAction |
  DeleteSegmentFromSelectedEngineAction |
  InsertSegmentPermissionsInSelectedEngineAction |

  UpsertWebSourceInSelectedEngineAction |
  UpsertEmailSourceInSelectedEngineAction |

  DeleteWebSourceFromSelectedEngineAction |
  DeleteEmailSourceFromSelectedEngineAction |

  InsertNewsletterPermissionsInSelectedEngineAction |
  DeleteNewsletterPermissionsInSelectedEngineAction;
