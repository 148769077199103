import { createAction, props, union } from '@ngrx/store';

import { TemplateVariableFromAPI } from '../../declarations/template-variables';

export const loadTemplateVariables = createAction(
  '[NEWSLETTERS][VARIABLES] Load',
  props<{ templateVariables: TemplateVariableFromAPI[] }>()
);

export const createTemplateEditorialVariable = createAction(
  '[NEWSLETTERS][VARIABLES] Create Editorial',
  props<{ name: string; value: string; editorType: 'html' | 'text' }>()
);

export const setTemplateVariable = createAction(
  '[NEWSLETTERS][VARIABLES] Set One',
  props<{ name: string; value: string; enableReset?: boolean }>()
);

export const unsetTemplateVariable = createAction(
  '[NEWSLETTERS][VARIABLES] Unset One',
  props<{ name: string }>()
);

export const clearTemplateVariables = createAction(
  '[NEWSLETTERS][VARIABLES] Clear'
);

export const templateVariablesActions = union({
  loadTemplateVariables,
  createTemplateEditorialVariable,
  setTemplateVariable,
  unsetTemplateVariable,
  clearTemplateVariables
});

export type TemplateVariablesAction = typeof templateVariablesActions
