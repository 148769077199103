import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
}                             from '@angular/core';
import { UntypedFormControl }        from '@angular/forms';

import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-mediego-simple-datatable',
  templateUrl: './simple-datatable.component.html',
  styleUrls: ['./simple-datatable.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

/* A component to display a simple datatable with one column to display and one column to delete elements + a form to add elements */
export class SimpleDatatableComponent implements OnInit, OnChanges {

  @Input() elements: string[] = [];
  @Input() options: any;
  @Output() outputElements = new EventEmitter<string[]>();

  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['main', 'actions'];
  // elementToAdd: string = '';

  elementToAddForm: UntypedFormControl;

  ngOnInit() {
    this.elements.sort();
    this.dataSource.data = this.elements;
    this.elementToAddForm = new UntypedFormControl('', this.options.validators);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.elements = changes['elements'].currentValue;
    this.dataSource.data = this.elements;
  }

  removeElement(elem) {
    const index = this.elements.indexOf(elem);
    this.elements.splice(index, 1);
    this.elements.sort();
    this.dataSource.data = this.elements;
    this.outputElements.emit(this.elements);
  }

  addElement() {
    this.elements.push(this.elementToAddForm.value);
    this.elementToAddForm.reset();
    this.elements.sort();
    this.dataSource.data = this.elements;
    this.outputElements.emit(this.elements);
  }


}
