import { LastNVisitsModifier }    from './modifiers/last-n-visits-modifier';
import { NumberOfVisitsModifier } from './modifiers/number-of-visits-modifier';
import { QuantitativeModifier }   from './modifiers/quantitative-modifier';
import { TemporalModifier }       from './modifiers/temporal-modifier';
import { SegmentCriterion }       from './segment-criterion';
import { SingleVisitCriterion }   from './single-visit/single-visit-criterion';

export class VisitCriterion extends SegmentCriterion {

  readonly type: string = 'visit';
  readonly displayName: string = 'Pages vues';
  readonly icon: string = 'remove_red_eye';

  singleVisitCriteria: SingleVisitCriterion[];
  temporalModifier: TemporalModifier;
  quantitativeModifier: QuantitativeModifier;
  useAndOperator: boolean;

  constructor(
    singleVisitCriteria: SingleVisitCriterion[] = [],
    temporalModifier: TemporalModifier = new LastNVisitsModifier(),
    quantitativeModifier: QuantitativeModifier = new NumberOfVisitsModifier(),
    useAndOperator: boolean = true
  ) {
    super();
    this.singleVisitCriteria = singleVisitCriteria;
    this.temporalModifier = temporalModifier;
    this.quantitativeModifier = quantitativeModifier;
    this.useAndOperator = useAndOperator;
  }

  isValid(): boolean {
    return this.singleVisitCriteria?.length > 0 &&
      this.temporalModifier?.isValid() &&
      this.quantitativeModifier?.isValid() &&
      this.singleVisitCriteria?.every((criterion) => criterion?.isValid());
  }


  parametersToJson(): any {
    return {
      criteria: this.singleVisitCriteria?.map((criterion) => criterion?.toJson()),
      temporalModifier: this.temporalModifier?.toJson(),
      quantitativeModifier: this.quantitativeModifier?.toJson(),
      useAndOperator: this.useAndOperator
    };
  }
}

