import { Pipe, PipeTransform } from '@angular/core';

import { Folder } from '../../main-module/declarations/folder';

import { SearchPipe } from './search.pipe';

@Pipe({
  name: 'folderContains'
})
export class FolderContainsPipe implements PipeTransform {

  transform(array: Folder[], name: string, deep: number = 0): Folder[] {

    if (name) {

      const searchPipeForNewsletters = new SearchPipe();

      name = name.toLowerCase();
      return array.filter((folder) => {
        return folder.name.toLowerCase().includes(name)
          || searchPipeForNewsletters.transform(folder.items, name, 'displayName').length > 0
          || this.transform(folder.folders, name).length > 0;
      });
    } else {
      return array;
    }
  }

}
