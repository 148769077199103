import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'origin'
})
export class OriginPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'direct':
        return 'Direct';
      case 'mail':
        return 'Email';
      case 'search':
        return 'Recherche';
      case 'facebook':
        return 'Facebook';
      case 'twitter':
        return 'Twitter';
      case 'other':
        return 'Autre';
      default:
        return value;
    }
  }

}
