import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'times'
})
export class TimesPipe implements PipeTransform {

  transform(nbOfTimes: number): number[] {
    return Array(nbOfTimes).fill(null).map((__, i) => i);
  }

}
