import { HttpClient, HttpParams }     from '@angular/common/http';
import { Injectable }                 from '@angular/core';

import { Store }                      from '@ngrx/store';

import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment'

import { AppState }   from '../../main-module/state';
import { loadTemplatePipeline } from '../state/actions/template-pipeline.actions';

import { RecommendationPipeline }     from '../declarations/pipeline/recommendation-pipeline';

@Injectable({
  providedIn: 'root'
})
export class PipelineProvider {

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  async getAllPipelines(engineId: number): Promise<Record<string, RecommendationPipeline>> {
    const pipelines$ = this.http.get<Record<string, RecommendationPipeline>>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/pipelines`
    );

    return firstValueFrom(pipelines$);
  }

  async getPipeline(engineId: number, pipelineId: string): Promise<RecommendationPipeline> {
    return firstValueFrom(this.getPipeline$(engineId, pipelineId));
  }

  getPipeline$(engineId: number, pipelineId: string): Observable<RecommendationPipeline> {
    return this.http.get<RecommendationPipeline>(
      `${environment.apiUrl2}/2.0/engines/${engineId}/pipelines/${pipelineId}`
     )
      .pipe(
        map((pipeline) => {
          this.store.dispatch(loadTemplatePipeline({ pipeline }));
          return pipeline;
        })
      );
  }

  copyPipeline(engineId: number, fromId: string, toId: string): Promise<RecommendationPipeline> {
    const pipeline$ = this.http.post<RecommendationPipeline>(`${environment.apiUrl2}/2.0/engines/${engineId}/pipelines/${fromId}`, {}, {
      params: new HttpParams()
        .set('toId', toId)
    });
    return firstValueFrom(pipeline$);
  }

  updatePipeline(engineId: number, pipelineId: string, recommendationPipeline: RecommendationPipeline): Promise<void> {
    const pipeline$ = this.http.put<void>(`${environment.apiUrl2}/2.0/engines/${engineId}/pipelines/${pipelineId}`,
      recommendationPipeline
    );
    return firstValueFrom(pipeline$);
  }

}
