import { CommonModule }                     from '@angular/common';
import { NgModule }                         from '@angular/core';
import { FlexLayoutModule }                 from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule }                     from '@angular/router';

import { OverlayModule } from '@angular/cdk/overlay';
import { MatBadgeModule } from '@angular/material/badge';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { TranslateModule, TranslateService }         from '@ngx-translate/core';
import { NgChartsModule }                              from 'ng2-charts';
import { HighlightModule }                           from 'ngx-highlightjs';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { QuicklinkModule }                           from 'ngx-quicklink';

import { MaterialModule }        from '../material.module';

import { PaginatorI18n } from './services/PaginatorI18nFactory';

import { SetupSendingConfigComponent } from './views/source-setup/setup-sending-config/setup-sending-config.component';
import { SourceSetupComponent }  from './views/source-setup/source-setup.component';
import { SpecificSendingConfA7Component } from './views/specific-sending-conf/specific-sending-conf-a7/specific-sending-conf-a7.component';

import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { ButtonCardComponent }             from './components/button-card/button-card.component';
import { ButtonInputComponent } from './components/button-input/button-input.component';
import { ChipMultiselectComponent }        from './components/chip-multiselect/chip-multiselect.component';
import { ConcurrencyIndicatorComponent } from './components/concurrency-indicator/concurrency-indicator.component';
import { ConfirmationDialogComponent }     from './components/confirmation-dialog/confirmation-dialog.component';
import { ExitConfirmationDialogComponent } from './components/confirmation-dialog/exit-confirmation-dialog/exit-confirmation-dialog.component';
import { ConstraintReadonlyComponent } from './components/constraints-crud/constraint-readonly/constraint-readonly.component';
import { ConstraintComponent } from './components/constraints-crud/constraint/constraint.component';
import { ConstraintsCrudComponent } from './components/constraints-crud/constraints-crud.component';
import { AdvancedPieChartComponent }       from './components/data-display/advanced-pie-chart/advanced-pie-chart.component';
import { ChartBoxComponent }               from './components/data-display/chart-box/chart-box.component';
import { PieGridComponent }                from './components/data-display/pie-grid/pie-grid.component';
import { PostitComponent }                 from './components/data-display/postit/postit.component';
import { SimpleDatatableComponent }        from './components/data-display/simple-datatable/simple-datatable.component';
import { StatCardComponent }               from './components/data-display/stat-card/stat-card.component';
import { TableBoxComponent }               from './components/data-display/table-box/table-box.component';
import { TopDisplayComponent }             from './components/data-display/top-display/top-display.component';
import { DateSelectionComponent }          from './components/date-selection/date-selection.component';
import { FabCallToActionButtonComponent } from './components/fab-call-to-action-button/fab-call-to-action-button.component';
import { NewsletterItemComponent } from './components/list/newsletter-item.component';
import { MultiAutocompleteComponent }      from './components/multi-autocomplete/multi-autocomplete.component';
import { MultiSelectComponent }            from './components/multi-select-checkbox/multi-select.component';
import { PrettyIdComponent } from './components/pretty-id/pretty-id.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SectionHeadlineComponent } from './components/section-headline/section-headline.component';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { SectionTitleItemsetsComponent } from './components/section-title/section-title.itemsets.component';
import { TrendDisplayComponent } from './components/trend-display/trend-display.component';

import { AbbreviatedNumberPipe } from './pipes/abbreviated-number.pipe';
import { EnginePipe } from './pipes/engine.pipe';
import { FilterNotPipe }         from './pipes/filter-not.pipe';
import { FilterPipe }            from './pipes/filter.pipe';
import { FolderContainsPipe } from './pipes/folder-contains.pipe';
import { IntervalTypePipe }      from './pipes/interval-type.pipe';
import { LargeNumberPipe } from './pipes/large-number.pipe';
import { OrderByKeyPipe }        from './pipes/order-by-key.pipe';
import { OriginPipe }            from './pipes/origin.pipe';
import { SafeHtmlPipe }          from './pipes/safe-html.pipe';
import { SafeStylePipe }         from './pipes/safe-style.pipe';
import { SegmentActionTypePipe } from './pipes/segment-action-type.pipe';
import { SortFoldersPipe } from './pipes/sort-folders.pipe';
import { SortStringsPipe }       from './pipes/sort-strings.pipe';
import { StartcasePipe }         from './pipes/startcase.pipe';
import { TimesPipe }             from './pipes/times.pipe';
import { TimestampPipe } from './pipes/timestamp.pipe';
import { UserRolePipe }          from './pipes/user-role.pipe';

import { AutofocusDirective } from './directives/autofocus.directive';
import { CSSPropsDirective } from './directives/cssprops.directive';
import { IgnoreDirtyDirective } from './directives/ignoredirty.directive';
import { VarDirective } from './directives/ngvar.directive';

const COMPONENTS = [
  SourceSetupComponent, // common to web and newsletters modules
  SetupSendingConfigComponent,

  AdminPanelComponent,
  ConfirmationDialogComponent,
  ExitConfirmationDialogComponent,
  MultiAutocompleteComponent,
  ChipMultiselectComponent,
  DateSelectionComponent,
  ButtonCardComponent,
  ButtonInputComponent,
  MultiSelectComponent,
  FabCallToActionButtonComponent,
  PrettyIdComponent,

  SectionTitleComponent,
  SectionTitleItemsetsComponent,
  SectionHeadlineComponent,
  NewsletterItemComponent,
  SearchBarComponent,

  ConstraintsCrudComponent,
  ConstraintComponent,
  ConstraintReadonlyComponent,
  SpecificSendingConfA7Component,

  // DATA DISPLAY
  StatCardComponent,
  ChartBoxComponent,
  PieGridComponent,
  AdvancedPieChartComponent,
  TableBoxComponent,
  PostitComponent,
  SimpleDatatableComponent,
  TopDisplayComponent,
  TrendDisplayComponent
];

const PIPES = [
  IntervalTypePipe,
  UserRolePipe,
  StartcasePipe,
  OrderByKeyPipe,
  AbbreviatedNumberPipe,
  LargeNumberPipe,
  EnginePipe,
  FilterPipe,
  FilterNotPipe,
  FolderContainsPipe,
  OriginPipe,
  SafeHtmlPipe,
  SafeStylePipe,
  SegmentActionTypePipe,
  TimesPipe,
  TimestampPipe,
  SortStringsPipe,
  SortFoldersPipe
];

const MONACO_EDITOR_CONFIG: NgxMonacoEditorConfig = {
  // baseUrl: 'app-name/assets', // configure base path for monaco editor
  defaultOptions: {
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    }
  }
  /*onMonacoLoad: () => {
    // here monaco object will be available as window.monaco use this function to extend monaco editor functionality.
    // console.log((<any>window).monaco);
  }*/
};

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    QuicklinkModule,
    RouterModule,
    FormsModule,
    MatBadgeModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    NgChartsModule,
    HighlightModule,
    MonacoEditorModule.forRoot(MONACO_EDITOR_CONFIG),
    OverlayModule
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    AutofocusDirective,
    CSSPropsDirective,
    VarDirective,
    IgnoreDirtyDirective,
    ConcurrencyIndicatorComponent
  ],
  exports: [
    CommonModule,
    TranslateModule,
    QuicklinkModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    HighlightModule,
    MonacoEditorModule,
    ...COMPONENTS,
    ...PIPES,
    CSSPropsDirective,
    VarDirective,
    IgnoreDirtyDirective,
    ConcurrencyIndicatorComponent
  ],
  providers: [
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
    }
  ]
})
export class MediegoCommonModule { }
