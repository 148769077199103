import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
}                            from '@angular/core';

import { ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-mediego-chip-multiselect',
  templateUrl: './chip-multiselect.component.html',
  styleUrls: ['./chip-multiselect.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChipMultiselectComponent implements OnInit, OnChanges {

  @Input() preselectedPatterns: string[] = [];
  @Input() label: string;
  @Input() placeholder: string;
  @Input() appearance: MatFormFieldAppearance;
  @Input() multiple: boolean = true;
  @Output() outputPatterns = new EventEmitter<string[]>();

  separatorKeysCodes = [ENTER, SPACE, TAB];
  patterns: string[] = [];
  constructor(
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.patterns = this.preselectedPatterns;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['preselectedPatterns'] && changes['preselectedPatterns'].currentValue.length > 0) {
      this.patterns = changes['preselectedPatterns'].currentValue;
    }
  }

  clearPatterns() {
    this.patterns = [];
  }

  addPattern(event: MatChipInputEvent): void {
    const pattern = event.value;

    if (pattern) {
      if (!this.patterns.includes(pattern)) {
        this.patterns.push(pattern);
        this.outputPatterns.emit(this.patterns);
      } else {
        this.showSnackBarError('Élément déjà ajouté');
      }
    }
    event.chipInput?.clear();
  }

  removePattern(index: number) {
    this.patterns.splice(index, 1);
    this.outputPatterns.emit(this.patterns);
  }

  private showSnackBarError(message: string) {
    this.snackbar.open(
      message,
      undefined,
      {
        panelClass: ['mediego-snack-bar', 'warn'],
        duration: 2000
      }
    );
  }

}
