import { SettingsAction, SettingsActionTypes } from '../actions/settings.actions';

import { AVAILABLE_LANGUAGES, Language }       from '../../declarations/lang';

export interface SettingsState {
  lang: Language;
}

const INITIAL_STATE: SettingsState = {
  lang: AVAILABLE_LANGUAGES.fr
};

export function settingsReducer(state: SettingsState = INITIAL_STATE, action: SettingsAction): SettingsState {
  switch (action.type) {
    case SettingsActionTypes.SET_LANG:
      return {
        ...state,
        lang: action.payload.lang
      };

    default:
      return state;
  }
}
