import { Source } from '../../mediego-common-module/declarations/source';

export interface Folder<T = any> {
  name: string;

  folders: Array<Folder<T>>;
  items: T[];

  icon?: string;
  intercomTarget?: string;
  priority?: number;
}

export const deleteFromFolder = <T>(folder: Folder<T>, elem: T): Folder<T> => {

  const elemFound = elem && folder?.items?.find((item) => (item as any)?.id === (elem as any).id);

  if (elemFound) {
    folder.items = [...folder.items.filter((item) => (item as any)?.id !== (elem as any).id)];
  }

  if (folder?.folders && !elemFound) {
    folder.folders = [...folder.folders.map((subFolder) => deleteFromFolder(subFolder, elem))]
  }

  return folder;
};

export const addFolder = (rootFolder: Folder<Source>, folderPath: string[], items?: Source[]): Folder<Source> => {

  if (rootFolder && folderPath && folderPath.length) {
    const iterationsNeeded: number = folderPath.length - 1;

    let currentFolder = rootFolder;
    let nextFolderIndex: number = -1;


    for (let depth = 0; depth < iterationsNeeded; depth++) {
      nextFolderIndex = currentFolder.folders.findIndex((folder) => folder && folder.name === folderPath[depth]);
      currentFolder = currentFolder.folders[nextFolderIndex];
    }

    // once loop is finished, we can add folder
    currentFolder.folders.push({
      name: folderPath[folderPath.length - 1],
      folders: [],
      items: items || []
    });
  }

  return {
    ...rootFolder
  };
};

// rootFolder must contain elem inside, and next elem location
export const moveBetweenFolders = (rootFolder: Folder<Source>, elem: Source): Folder<Source> => {

  let elemIndex: number = -1;

  if (rootFolder && elem && elem.collection) {
    if (rootFolder.items) {
      elemIndex = rootFolder.items.findIndex((item) => (item as any).id === (elem as any).id);
      if (elemIndex !== -1) {
        // removing item from current folder (move = delete + add)
        return {
          ...rootFolder,
          folders: rootFolder.folders.reduce((res, folder) => [...res, moveBetweenFolders(folder, elem)], []),
          items: [...rootFolder.items.slice(0, elemIndex), ...rootFolder.items.slice(elemIndex + 1)]
        }
      }
    }

    if (elem.collection.length === 0 || (rootFolder.name === elem.collection[elem.collection.length - 1])) {
      // adding item as it's where we want it to be located
      return {
        ...rootFolder,
        folders: rootFolder.folders,
        items: [...rootFolder.items, elem]
      }
    }

    // default recursion if no element to add or remove has been found
    return {
      ...rootFolder,
      folders: rootFolder.folders.reduce((res, folder) => [...res, moveBetweenFolders(folder, elem)], [])
    }
  }

  return rootFolder;
};


export const pruneEmptyFolders = (rootFolder: Folder<Source>): Folder<Source> => {

  return {
    ...rootFolder,
    folders: rootFolder.folders.reduce((res, folder) => {
      if ((folder.items && folder.items.length) || (folder.folders && folder.folders.length)) {
        return [...res, pruneEmptyFolders(folder)];
      } else {
        // empty folder => not included in final folder
        return [...res];
      }
    }, [])
  };

};

export const deleteIdFromFolder = (rootFolder: Folder<Source>, id: string): Folder<Source> => {

  return {
    ...rootFolder,
    items: rootFolder.items.filter((item) => item.id !== id),
    folders: rootFolder.folders.reduce((res, folder) => {
      return [...res, {
        ...folder,
        items: folder.items.filter((item) => item.id !== id)
      }];
    }, [])
  };

};
