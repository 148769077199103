import dayjs      from 'dayjs';

import { NewsletterSource }              from '../../mediego-common-module/declarations/source';
import { Engine, NewsletterPermissions } from '../declarations/engine';

export class AuthUtils {

  static isDemoAccount(email: string): boolean {
    return email === 'demo@mediego.com';
  }

  static isDemoExpired(demoExpiryDate: number): boolean {
    return dayjs.unix(demoExpiryDate).isAfter(dayjs());
  }

  static getDefaultRouteOfNewsletter(source: NewsletterSource, perms: NewsletterPermissions): string {
    // let source = engine.sources.newsletters_all.find((__) => __.id === newsletterId);
    // let perms = engine.userPermissions.newsletters.find((__) => __.newsletterId === newsletterId);

    if (!source && !perms) {
      return '/newsletters/';
    }

    if (perms) {

      if (perms.editTemplate && source.enableTemplateEditor) {
        return `/newsletters/${perms.newsletterId}/edit-template`;
      } else if (perms.editContent) {
        return `/newsletters/${perms.newsletterId}/edit-content`;
      } else if (perms.editVariables) {
        return `/newsletters/${perms.newsletterId}/edit-variables`;
      } else if (perms.editCampaigns) {
        return `/newsletters/${perms.newsletterId}/edit-campaigns`;
      } else if (perms.statistics) {
        return `/newsletters/${perms.newsletterId}/stats`;
      } else if (perms.configuration) {
        return `/newsletters/${perms.newsletterId}/setup`;
      } else {
        return '/';
      }

    } else {
      return '/';
    }
  }

  static getDefaultRouteOfSegment(engine: Engine, segmentId: string): string {
    const segmentPerms = engine.userPermissions.segments.find((__) => __.segmentId === segmentId);

    if (segmentPerms.statistics) {
      return `/segments/${ segmentPerms.segmentId }/stats`;
    } else if (segmentPerms.createActions) {
      return `/segments/${ segmentPerms.segmentId }/actions`;
    } else if (segmentPerms.configuration) {
      return `/segments/${ segmentPerms.segmentId }/edit`;
    } else {
      return '/';
    }
  }
}
