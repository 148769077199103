import { RouterReducerState }                    from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CustomRouterState }                     from '../index';
import { AuthState }                             from '../reducers/auth.reducer';
import { CRMState } from '../reducers/crm.reducer';
import { EnginesState }                          from '../reducers/engines.reducer';
import { SettingsState }                         from '../reducers/settings.reducer';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectEnginesState = createFeatureSelector<EnginesState>('engines');

export const selectSettingsState = createFeatureSelector<SettingsState>('settings');

export const selectCRMState = createFeatureSelector<CRMState>('crm');

export const selectRouterState = createSelector(
  createFeatureSelector<RouterReducerState<CustomRouterState>>('router'),
  (router: RouterReducerState<CustomRouterState>) => router ? router.state : undefined
);
