import { Injectable }              from '@angular/core';

import { Store }                   from '@ngrx/store';

import { firstValueFrom, Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { AppState }                from '../../main-module/state';
import {
  selectNewslettersColorset,
  selectNewslettersConfig, selectNewslettersFonts,
  selectNewslettersPrimaryColor, selectNewslettersSecondaryColor
} from '../state/selectors/newsletters-config.selectors';
import { setNewslettersConfig } from '../state/actions/newsletters-config.actions';

import { AppService } from '../../services/app.service';

import { NewslettersConfigurationProvider } from '../providers/newsletters-configuration.provider';

import { NewslettersConfig } from '../declarations/newsletters-config';

@Injectable({
  providedIn: 'root'
})
export class NewslettersConfigurationService {

  get newslettersConfig$(): Observable<NewslettersConfig> {
    return this.store.select(selectNewslettersConfig);
  }

  get newslettersConfigColorset$(): Observable<string[]> {
    return this.store.select(selectNewslettersColorset);
  }

  get newslettersConfigPrimaryColor$(): Observable<string> {
    return this.store.select(selectNewslettersPrimaryColor);
  }

  get newslettersConfigSecondaryColor$(): Observable<string> {
    return this.store.select(selectNewslettersSecondaryColor);
  }

  get newslettersConfigFonts$(): Observable<string[]> {
    return this.store.select(selectNewslettersFonts);
  }

  constructor(private store: Store<AppState>,
              private appService: AppService,
              private translate: TranslateService,
              private newslettersConfigProvider: NewslettersConfigurationProvider) { }

  getCurrentNewslettersConfig(): Promise<NewslettersConfig> {
    return firstValueFrom(this.newslettersConfig$);
  }

  setNewslettersConfig(engineId: number, config: NewslettersConfig): Promise<boolean> {
    return this.newslettersConfigProvider.createOrUpdateConfigurationForEngine(engineId, config)
      .then(() => {

        // dispatching to keep a sync between server state and redux state
        this.store.dispatch(setNewslettersConfig({ config }));

        return true;

      });
  }

  async initNewslettersConfig(engineId: number | undefined): Promise<NewslettersConfig> {

    if (!engineId) {
      // fallback if no engineId provided
      const engine = await this.appService.getSelectedEngine();

      // recursion now we have an engineId
      if (engine && engine.id) {
        return await this.initNewslettersConfig(engine.id);
      } else {
        throw new Error(this.translate.instant('CONFIGURATION.NEWSLETTERS.ERROR.MISSING_ENGINE'));
      }
    } else {
      // if engineId is known
      const config = await this.newslettersConfigProvider.getOrInitConfigurationForEngine(engineId);

      if (config) {
        this.store.dispatch(setNewslettersConfig({ config }));
        return config;
      } else {
        throw new Error(this.translate.instant('CONFIGURATION.NEWSLETTERS.ERROR.UNKNOWN_CONFIG'));
      }
    }
  }

}
