import { Dictionary }                                from '@ngrx/entity';
import { createSelector, MemoizedSelectorWithProps } from '@ngrx/store';

import { AppState } from '../../../main-module/state';

import { Content, isContent }      from '../../declarations/content';
import { ContentType, LayoutType } from '../../declarations/enums';
import { CustomBlockRef, isLayout, Layout } from '../../declarations/layout';

import { TemplaterState }                       from '../index';
import { layoutsAdapter, LayoutState, SLayout } from '../reducers/layout.reducer';

import { selectTemplater }       from './';
import { selectContentEntities } from './contents.selectors';

const {
  // selectIds,
  selectEntities
  // selectAll
  // selectTotal
} = layoutsAdapter.getSelectors();

export const selectLayoutState = createSelector(
  selectTemplater,
  (state: TemplaterState) => state.layouts.present
);
export const selectLayoutEntities = createSelector(
  selectLayoutState,
  (state: LayoutState) => selectEntities(state)
);

export const selectLayoutCustomBlockRefs = createSelector(
  selectLayoutEntities,
  (layouts, { ids }) => {

    const customBlockRefs: CustomBlockRef[] = [];

    if (ids && ids.length) {
      ids.forEach((id) => {
        if (layouts[id] && layouts[id].customBlockRef) {
          customBlockRefs.push(layouts[id].customBlockRef);
        }
      });
    }

    return customBlockRefs
  }
);

export const selectLayout = createSelector(
  selectLayoutEntities,
  (entities: Dictionary<SLayout>, { id }) => entities[id]
);

export const selectFullLayout = (appState: AppState, props: any) => {
  return _selectFullLayout(appState, { ...props, appState })
};

const _selectFullLayout: MemoizedSelectorWithProps<object, { id: string; appState: AppState }, Layout> = createSelector(
  selectLayoutEntities,
  selectContentEntities,
  (layouts: Dictionary<SLayout>, contents: Dictionary<Content>, { id, appState }: { id: string; appState: AppState }) => {
    const sLayout = layouts[id];
    return {
      ...sLayout,
      children: sLayout?.children?.map((child: { id: string; type: LayoutType | ContentType }) => {
        if (isLayout(child)) {
          return _selectFullLayout(appState, { id: child.id, appState });
        } else if (isContent(child)) {
          return contents[child.id];
        } else {
          console.warn('selectArticleIdsInLayout: layout has child that is neither layout nor content');
          return null;
        }
      })
    };
  }
);

export const selectLayoutSizeProperties = createSelector(
  selectLayout,
  (sLayout: SLayout, { id }) => {
    if (sLayout && sLayout.size) {
      return sLayout.size;
    } else {
      return {};
    }
  }
);

export const selectLayoutRenderingProperties = createSelector(
  selectLayout,
  (sLayout: SLayout, { id }) => {
    return sLayout ? sLayout.renderingProperties : {};
  }
);


export const selectParentLayouts = createSelector(
  selectLayoutEntities,
  (layouts: Dictionary<SLayout>, { id }) => {

    let parents = [], parent, targetChildId = id;

    do {
      parent = Object.values(layouts)?.find(layout => layout.children?.find(__ => __?.id === targetChildId));
      targetChildId = parent?.id;
      if (parent) parents = [parent, ...parents];
    } while (!!parent)

    return parents;
  }
);
