import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on }                               from '@ngrx/store';

import { NewsletterGlobalAction, resetNewsletter } from '../../../newsletters-module/state/actions/newsletter.actions';
import {
  createArticle,
  deleteArticle,
  loadArticles,
  TemplateArticlesAction
} from '../actions/template-articles.actions';
import {
  addRowsToTemplateModel,
  addRowToTemplateModel,
  createNewTemplateModel,
  importTemplateModel,
  loadTemplateModel,
  resetTemplateModel, TemplateModelAction
} from '../actions/template-model.actions';

import { Article } from '../../declarations/article';

import { TemplateModelUtils } from '../../utils/template-model.utils';

export type ArticleState = EntityState<Article>;

export const articlesAdapter: EntityAdapter<Article> = createEntityAdapter<Article>();

const INITIAL_STATE: ArticleState = articlesAdapter.getInitialState();

const {
  // selectIds,
  // selectEntities,
  // selectAll
  // selectTotal
} = articlesAdapter.getSelectors();

const reducer = createReducer(
  INITIAL_STATE,
  on(resetNewsletter, (_) => INITIAL_STATE),
  on(
    loadTemplateModel,
    importTemplateModel,
    (state, { model }) =>
      articlesAdapter.setAll(TemplateModelUtils.parseParentElementArticleEntries(model), state)
  ),
  on(loadArticles, (state, { entries }) =>
    articlesAdapter.setAll(entries, state)
  ),
  on(deleteArticle, (state, { articleId }) =>
    articlesAdapter.removeOne(articleId, state)
  ),
  on(createArticle, (state, { articleEntry }) =>
    articlesAdapter.addOne(articleEntry, state)
  ),
  on(addRowToTemplateModel, (state, { row }) =>
    articlesAdapter.addMany(TemplateModelUtils.parseParentElementArticleEntries(row), state)
  ),
  on(addRowsToTemplateModel, (state, { rows }) =>
    articlesAdapter.addMany(
      rows.reduce((articles, row) => [...articles, ...TemplateModelUtils.parseParentElementArticleEntries(row)], []),
      //TemplateModelUtils.parseParentElementArticleEntries([...rows]),
      state
    )
  ),
  on(
    createNewTemplateModel,
    resetTemplateModel,
    (state) => articlesAdapter.removeAll(state)
  )
);

export function templateArticlesReducer(state: ArticleState, action: TemplateArticlesAction | TemplateModelAction | NewsletterGlobalAction): ArticleState {
  return reducer(state, action);
}
