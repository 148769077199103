import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByKey'
})
export class OrderByKeyPipe implements PipeTransform {

  transform<T>(array: T[], key?: string, order: 'asc' | 'desc' = 'asc'): T[] {
    if (key === undefined) {
      return array;
    }

    if (array.every((element) => element.hasOwnProperty(key))) {
      const sortedArray = array.sort((a, b) => {
        if (typeof a[key] === 'string') {
          return (a[key] as string).localeCompare(b[key] as string);
        } else {
          return +a[key] - +b[key];
        }
      });
      return order === 'asc' ? sortedArray : sortedArray.reverse();
    } else {
      console.warn(`__OrderByKeyPipe: key '${ key }' does not exist on one or more elements of array`, array);
      return array;
    }
  }
}
