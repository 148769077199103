import { BoxRule, SizeRule } from '../declarations/styles';

export const boxRuleToString = (values: BoxRule): string => `${values.top}px ${values.right}px ${values.bottom}px ${values.left}px`;

export const sizeRuleToString = (size: SizeRule): string => {

  if (size && size.value !== undefined && size.unit !== undefined) {
    return size.value + size.unit;
  } else {
    return '';
  }
}
