<div *ngIf="cardWrapper; else minimalCanvas" class="container">
  <mat-card [ngSwitch]="status | async" fxLayout="column" [class.admin-panel]="admin" [ngClass]="type">
    <ng-container *ngSwitchCase="'ready'">
      <div id="info-icon" *ngIf="description" [matTooltip]="description" matTooltipPosition="left">
        <mat-icon>info</mat-icon>
      </div>
      <div id="admin-indicator" *ngIf="admin">
        <mat-icon>lock</mat-icon>
      </div>

      <div class="title" *ngIf="chartTitle">{{ chartTitle }}</div>

      <div id="chart-container" class="chart-container">
        <canvas baseChart
                [type]="type"
                [datasets]="datasets"
                [labels]="labels"
                [options]="_options"
                [legend]="legend">
        </canvas>
      </div>
    </ng-container>


    <ng-container *ngSwitchCase="'loading'">
      <div [ngSwitch]="type" class="chart-container">
        <ng-container *ngSwitchDefault>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 525 290" preserveAspectRatio="xMidYMid slice">
            <path fill="#e8eef2" d="M523.4,288.47H3.36V288h520Zm0-37.43H3.36v-.5h520Zm0-37.43H3.36v-.5h520Zm0-37.43H3.36v-.5h520Zm0-37.43H3.36v-.5h520Zm0-37.43H3.36v-.5h520Z"></path>
            <polygon fill="#e5ebee" points="383.18 269.77 361.48 197.54 269.54 185.62 238.02 154.33 131.2 140.43 99.08 85.48 4.95 222.99 0 219.6 99.48 74.28 134.89 134.86 240.81 148.64 272.33 179.94 366.11 192.09 383.82 251.04 434.1 117.5 523.6 152.3 521.42 157.89 437.58 125.29 383.18 269.77"></polygon>
            <g>
              <rect x="105" y="20" width="315" height="10" rx="3" ry="3" fill="#e5ebee"></rect>
              <path d="M3.68,63.28H523.22" fill="none" stroke="#e8eef2" stroke-linecap="square" stroke-width="0.5"></path>
            </g>
          </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'bar'">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 525 290" preserveAspectRatio="xMidYMid slice">
            <rect x="105" y="20" width="315" height="10" rx="3" ry="3" fill="#e5ebee"></rect>
            <g transform="translate(0 63)">
              <path stroke="#E8EEF2" stroke-linecap="square" stroke-width=".5" d="M.135 224.847h519.543M.135 187.418h519.543M.135 149.99h519.543M.135 112.56h519.543M.135 75.132h519.543M.135 37.704h519.543M.135.275h519.543" data-reactid="217"></path>
              <rect width="8.578" height="148.967" x="22.483" y="75.604" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="52.84" x="33.205" y="171.731" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="119.992" x="54.65" y="104.58" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="26.42" x="97.539" y="198.151" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="96.874" x="129.706" y="127.697" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="71.555" x="172.596" y="153.017" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="136.626" x="247.652" y="87.945" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="41.832" x="322.709" y="182.739" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="41.832" x="429.932" y="182.739" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="52.84" x="194.04" y="171.731" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="155.218" x="365.598" y="69.353" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="83.664" x="376.32" y="140.908" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="184.941" x="97.539" y="39.63" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="52.84" x="108.262" y="171.731" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="119.992" x="129.706" y="104.58" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="26.42" x="172.596" y="198.151" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="96.874" x="204.763" y="127.697" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="71.555" x="247.652" y="153.017" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="96.874" x="322.709" y="127.697" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="41.832" x="397.765" y="182.739" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="41.832" x="504.989" y="182.739" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="52.84" x="269.097" y="171.731" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="119.992" x="440.655" y="104.58" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="136.626" x="451.377" y="87.945" fill="#E5EBEE" rx="2"></rect>
              <rect width="8.578" height="96.874" x="462.099" y="127.697" fill="#E5EBEE" rx="2"></rect>
            </g>
          </svg>
        </ng-container>
        <ng-container *ngSwitchCase="'horizontalBar'"> <!-- *ngIf bar and horizontal -->
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 525 290" preserveAspectRatio="xMidYMid slice">
            <rect x="105" y="20" width="315" height="10" rx="3" ry="3" fill="#e5ebee"></rect>
            <g transform="translate(0 63)">
              <path stroke="#E8EEF2" stroke-linecap="square" stroke-width=".5" d="M.135 224.847h519.543M.135 187.418h519.543M.135 149.99h519.543M.135 112.56h519.543M.135 75.132h519.543M.135 37.704h519.543M.135.275h519.543" data-reactid="217"></path>
              <rect height="8.578" width="298" y="22.483" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="106" y="33.205" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="240" y="54.65" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="52" y="97.539" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="194" y="129.706" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="144" y="172.596" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="274" y="247.652" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="106" y="194.04" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="370" y="97.539" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="106" y="108.262" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="240" y="129.706" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="52" y="172.596" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="194" y="204.763" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="144" y="247.652" x="20" fill="#E5EBEE" rx="2"></rect>
              <rect height="8.578" width="106" y="269.097" x="20" fill="#E5EBEE" rx="2"></rect>
            </g>
          </svg>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <div id="errorContainer" fxLayout="row" fxLayoutAlign="center center" fxFill>
        <mat-icon>error</mat-icon>
      </div>
    </ng-container>
  </mat-card>
</div>

<ng-template #minimalCanvas>
  <div class="chart-container">
    <canvas baseChart
            [type]="type"
            [datasets]="datasets"
            [labels]="labels"
            [options]="_options"
            [legend]="legend">
    </canvas>
  </div>
</ng-template>
