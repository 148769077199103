import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnInit, Output,
  ViewEncapsulation
} from '@angular/core';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../../../services/auth.service';

import {
  Constraint,
  CONSTRAINTS_GLOBAL,
  CONSTRAINTS_ITEMSET_ONLY,
  CONSTRAINTS_LOCAL,
  UnknownConstraint
} from './constraints';
import { deserializeConstraintForBack, serializeConstraintForDashboard } from './constraints-serdes';


export interface ConstraintChange {
  action: 'delete'|'add'|'update';
  constraint: Constraint;
  fromNegation?: boolean;
}

export interface ConstraintChangeEvent {
  change: ConstraintChange;
  state: Constraint[];
}

@Component({
  selector: 'app-mediego-constraints',
  templateUrl: './constraints-crud.component.html',
  styleUrls: ['./constraints-crud.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConstraintsCrudComponent implements OnInit, OnChanges {

  /** Whether constraints shown are global or not */
  @Input() constraintsFor: 'local' | 'global' | 'itemset' = 'local';
  @Input() readonly: boolean = false;
  @Input() limitShown: number = -1;

  @Input() constraints: any[] = [];
  constraintTypes: any[] = [];

  @Output() changeConstraints: EventEmitter<ConstraintChangeEvent> = new EventEmitter<ConstraintChangeEvent>();

  existingConstraints: Constraint[] = [];

  get existingConstraintsForBack(): any {
    return this.existingConstraints.map(constraint => deserializeConstraintForBack(constraint, this.constraintsFor === 'local'));
  }

  get unknownConstraints(): number {
    if (this.existingConstraints) {
      return this.existingConstraints.filter((constraint) => constraint && constraint.type === UnknownConstraint.type).length;
    } else {
      return 0;
    }
  }

  get isAdmin$(): Observable<boolean> {
    return this.authService.isAdmin$;
  }

  constructor(private authService: AuthService, private snackbar: MatSnackBar, private translate: TranslateService) { }

  async ngOnInit() {
    this.existingConstraints = [];

    this.refreshConstraints();
  }

  ngOnChanges(changes) {
    if (changes.itemset || changes.constraints) {
      this.refreshConstraints();
    }
  }

  // populate existingConstraints based on inputs
  private refreshConstraints() {
    // local or global, normal behavior
    if (this.constraints && this.constraints.length) {
      this.existingConstraints = [...this.constraints.map((constraint) => {
        return serializeConstraintForDashboard(constraint);
      })];
    } else {
      this.existingConstraints = [];
    }

    this.refreshConstraintsTypes();
  }

  private refreshConstraintsTypes() {
    let constraints = [];
    if (this.constraintsFor === 'global') {
      constraints = CONSTRAINTS_GLOBAL;
    } else if (this.constraintsFor === 'local') {
      constraints = CONSTRAINTS_LOCAL;
    } else if (this.constraintsFor === 'itemset') {
      constraints = CONSTRAINTS_ITEMSET_ONLY;
    }

    this.constraintTypes = constraints.reduce((acc, constraint) => {
      // preventing creation of multiple constraints of same type
      // forcing user to modify existing constraint (simplify logic)
      if (!constraint.supportsMultiple
        && this.existingConstraints.some((existingConstraint) => existingConstraint.type === constraint.type)) {
        return [...acc, { type: constraint.type, disabled: true }]
      } else {
        return [...acc, { type: constraint.type, disabled: constraint.disabled }]
      }

    }, []);
  }

  addConstraintHandler(constraint: Constraint) {
    if (constraint) {

      this.existingConstraints = [...this.existingConstraints, constraint];
      this.refreshConstraintsTypes();

      this.snackbar.open(this.translate.instant('COMMON.CONSTRAINTS.SNACKBAR.ADDED'), null, {
        duration: 1000,
        panelClass: ['mediego-snack-bar', 'accent']
      });

      this.notifyConstraintsChange({
        action: 'add',
        constraint
      });
    }
  }

  removeConstraintHandler(index: number) {
    if (index < this.existingConstraints.length && index >= 0) {

      const constraintRemoved: Constraint = this.existingConstraints[index];

      this.existingConstraints = [...this.existingConstraints.filter((_, k) => k !== index)];
      this.refreshConstraintsTypes();

      this.snackbar.open(this.translate.instant('COMMON.CONSTRAINTS.SNACKBAR.REMOVED'), null, {
        duration: 1000,
        panelClass: ['mediego-snack-bar', 'warn']
      });

      this.notifyConstraintsChange({
        action: 'delete',
        constraint: constraintRemoved
      });
    }
  }

  updateConstraintHandler(constraint: Constraint, index: number) {

    if (index >= 0 && index < this.existingConstraints.length) {

      const oldConstraint = this.existingConstraints[index];

      this.existingConstraints = [
        ...this.existingConstraints.slice(0, index),
        { ...constraint },
        ...this.existingConstraints.slice(index + 1)
      ];

      const isNegationChange = oldConstraint.negated === !constraint.negated;

      this.snackbar.open(this.translate.instant('COMMON.CONSTRAINTS.SNACKBAR.UPDATED'), null, {
        duration: 1000,
        panelClass: ['mediego-snack-bar', 'accent']
      });

      this.notifyConstraintsChange({
        action: 'update',
        constraint,
        fromNegation: isNegationChange
      });
    }
  }

  private notifyConstraintsChange(change: ConstraintChange) {
    this.changeConstraints.emit({
      change,
      state: this.existingConstraintsForBack
    });
  }


  logBack() {
    console.warn('front format', this.existingConstraints);
    console.warn('api format', this.existingConstraintsForBack);
  }

}
