import { Dictionary }                                from '@ngrx/entity';
import { createSelector, MemoizedSelectorWithProps } from '@ngrx/store';

import { AppState }                               from '../../../main-module/state';

import { Content, isContent } from '../../declarations/content';
import { isLayout } from '../../declarations/layout';
import { TemplateModel } from '../../declarations/template-model';

import { TemplaterState }                         from '../index';
import { SLayout }                                from '../reducers/layout.reducer';
import { STemplateModel }                         from '../reducers/template-model.reducer';

import { selectContentEntities }                  from './contents.selectors';
import { selectTemplater }                        from './index';
import { selectFullLayout, selectLayoutEntities } from './layouts.selectors';

export const selectSTemplateModel = createSelector(
  selectTemplater,
  (state: TemplaterState) => state.model.present
);

export const selectTemplateModel = (appState: AppState) => {
  return _selectTemplateModel(appState, { appState })
};

const _selectTemplateModel = createSelector(
  selectSTemplateModel,
  (sModel: STemplateModel | undefined, { appState }: { appState: AppState }) => {
    if (sModel) {
      return {
        ...sModel,
        children: sModel.children.map((id) =>
          selectFullLayout(appState, { id })
        )
      }
    } else {
      return undefined;
    }
  }
);

export const selectTemplateModelPast = createSelector(
  selectTemplater,
  (state: TemplaterState) => state.model.past
);

export const selectTemplateModelFuture = createSelector(
  selectTemplater,
  (state: TemplaterState) => state.model.future
);

export const selectModelChildrenIds = createSelector(
  selectSTemplateModel,
  (state: STemplateModel | undefined) => state ? state.children : []
);

export const selectModelStructure = createSelector(
  selectModelChildrenIds,
  selectLayoutEntities,
  (ids: string[], layouts: Dictionary<SLayout>) =>
    ids.map((id) => layouts[id])
);

export const selectModelGlobalCss = createSelector(
  selectSTemplateModel,
  (state: STemplateModel) => state ? state.globalCss : ''
);

export const selectModelSizeProperties = createSelector(
  selectSTemplateModel,
  (state: STemplateModel) => state ? state.size : {}
);

export const selectModelRenderingProperties = createSelector(
  selectSTemplateModel,
  (state: STemplateModel) => state ? state.renderingProperties : {}
);

export const selectModelFavoriteColors = createSelector(
  selectSTemplateModel,
  (state: STemplateModel) => state ? state.favoriteColors : []
);

export const selectModelHeaders = createSelector(
  selectSTemplateModel,
  (state: STemplateModel) => state ? state.headers : null
);

export const selectModelCustomFonts = createSelector(
  selectSTemplateModel,
  (state: STemplateModel) => state ? state.customFonts : []
);

export const selectModelConstraints = createSelector(
  selectSTemplateModel,
    (state: STemplateModel) => state ? state.constraints : []
);

export const selectModelMeta = createSelector(
  selectSTemplateModel,
  (state: STemplateModel) => state ? state.metaModel : { articles: [], allVariables: [] }
);

export const selectElementById: MemoizedSelectorWithProps<object, { id: string }, SLayout | Content | undefined> = createSelector(
  selectLayoutEntities,
  selectContentEntities,
  (layouts: Dictionary<SLayout>, contents: Dictionary<Content>, { id }: { id: string }) => {
    return layouts[id] || contents[id]
  }
);

const layoutHasChildContent = (layout: SLayout, childContentId: string): boolean => {
  return layout?.children?.reduce((res, childLayoutOrContent) => {
    if (isContent(childLayoutOrContent)) return res || childContentId === childLayoutOrContent.id;
    else if (isLayout(childLayoutOrContent)) {
      return res || layoutHasChildContent(childLayoutOrContent, childContentId);
    }
  }, false);
}

export const selectTopLayoutOfContent = createSelector(
  selectTemplateModel,
  (template: TemplateModel, { id }: { id: string }) => template?.children?.find(layout => layoutHasChildContent(layout, id))
);
