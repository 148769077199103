import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-mediego-section-headline',
  template: `
    <div fxLayout="row" fxFlex="0 0 100%" fxLayoutAlign="center">
        <h1>{{ text }}<br *ngIf="breaklineEmphasis" /><span *ngIf="emphasis" class="emphasis"> {{ emphasis }}</span>.</h1>
    </div>
  `,
  styleUrls: ['./section-headline.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SectionHeadlineComponent {
  @Input() text: string;
  @Input() emphasis: string;
  @Input() breaklineEmphasis?: boolean = false;
}
