<div class="mediego-toolbar-container" [class.mat-elevation-z4]="applyShadow">
  <mat-toolbar fxLayoutAlign="start center" fxLayoutGap="16px" color="primary">
    <div class="toolbar-logo-section" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button (click)="onMenuButtonClick()" *ngIf="authService.isLoggedIn$ | async">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="logo" *ngIf="(authService.isLoggedIn$ | async) === false || (layoutService.isTablet | async) || (layoutService.isDesktop | async)" fxFlex="0 0 150px">
        <a href="/" fxFlex="0 0 100%" fxLayoutAlign="center center">
          <img src="/assets/img/logo/logo-full-white.svg" fxFlex="0 0 100%">
        </a>
      </div>
      <div class="logo" *ngIf="(layoutService.isMobile | async) && (authService.isLoggedIn$ | async)" fxFlex="0 0 25px">
        <a href="/" fxFlex="0 0 100%" fxLayoutAlign="center center">
          <img src="/assets/img/logo/logo-compact-white.svg" fxFlex="0 0 100%">
        </a>
      </div>
      <!-- <a class="title" href="/" *ngIf="(layoutService.isDesktop | async) || ((layoutService.isDesktop | async) === false && (authService.isLoggedIn$ | async) === false)">
        {{ 'SHARED.MAIN_TITLE' | translate }}
      </a> -->
    </div>

    <div class="toolbar-main-section" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="row" fxFlex="0 0 100%" fxFlex.gt-lg="1 1 1280px">
        <h3>
          <ng-container *ngFor="let crumb of (displayedBreadcrumbs$ | async); last as last">

            <ng-container *ngIf="crumb.engineRedirect || crumb.newslettersRedirect; else defaultCrumb">
              <a class="clickable" [routerLink]="['/']"
                 queryParamsHandling="merge"
                 [queryParams]="crumb.newslettersRedirectFilter ? { filter: crumb.newslettersRedirectFilter } : {}">
                {{ crumb.label | translate }}
              </a>
            </ng-container>

            <ng-template #defaultCrumb>
              {{ crumb.label | translate }}
            </ng-template>

            <ng-container *ngIf="!last">&nbsp;&nbsp;>&nbsp;&nbsp;</ng-container>
          </ng-container>

          <span *ngIf="selectedContactList$ | async as contactsList">
             &nbsp;>&nbsp; {{ contactsList.name }}
          </span>
        </h3>
      </div>
    </div>

    <div class="toolbar-end-section">

      <!-- <app-mediego-concurrency-indicator></app-mediego-concurrency-indicator>-->

      <button mat-icon-button [class.shaked]="pendingNotifications$ | async"
              [color]="(pendingNotifications$ | async) === true ? 'warn' : undefined"
              (click)="notificationsOpened = !notificationsOpened" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <mat-icon>{{ (pendingNotifications$ | async) === true ? 'notifications_active' : 'notifications' }}</mat-icon>
      </button>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="notificationsOpened"
        [cdkConnectedOverlayWidth]="600"
        (overlayOutsideClick)="notificationsOpened = false"
      >
        <app-mediego-notifications-card></app-mediego-notifications-card>
      </ng-template>


      <button mat-icon-button [matTooltip]="'MAIN.APP.TOOLBAR.LANGUAGE' | translate" [matMenuTriggerFor]="langMenu">
        <mat-icon>translate</mat-icon>
      </button>
      <mat-menu #langMenu="matMenu" class="language-selection-menu">
        <button mat-menu-item
                *ngFor="let lang of availableLanguages"
                [class.active]="(selectedLanguage$ | async) === lang"
                [disabled]="(selectedLanguage$ | async) === lang"
                (click)="setLanguage(lang)">
          {{ langDisplayName(lang) }}
        </button>
      </mat-menu>

      <a mat-icon-button [matTooltip]="'MAIN.APP.TOOLBAR.DOCS' | translate"
         [href]="docHref$ | async" target="_blank"
         data-intercom-target="documentation_link">
        <mat-icon>import_contacts</mat-icon>
      </a>

      <app-mediego-intercom *ngIf="(authService.isLoggedIn$ | async)"
                            data-intercom-target="intercom_link">
      </app-mediego-intercom>
    </div>
  </mat-toolbar>

  <nav mat-tab-nav-bar *ngIf="tabRoutes?.length" [hidden]="tabRoutes.length === 0">
    <ng-container *ngFor="let route of tabRoutes; trackBy: trackRouteFn">
      <a mat-tab-link fxFlex="auto"
         *ngIf="route.visible"
         [routerLink]="route.path"
         queryParamsHandling="preserve"
         routerLinkActive="active"
         #rla="routerLinkActive"
         [active]="rla.isActive"
         [attr.data-intercom-target]="route.intercomTag">

        <mat-icon>{{route.icon}}</mat-icon>
        <span *ngIf="(layoutService.isDesktop | async) && (layoutService.isLandscape | async)">
          {{ route.label | translate }}
        </span>
      </a>
    </ng-container>
  </nav>

</div>
