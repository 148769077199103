<button *ngIf="!showInput; else inputLayout" (click)="showInput = true" mat-icon-button color="primary" [matTooltip]="buttonTooltip">
  <mat-icon>{{ icon }}</mat-icon>
</button>

<ng-template #inputLayout>
  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
    <mat-form-field fxFlex appearance="outline">
      <mat-label>{{ inputLabel }}</mat-label>
      <input [autofocus] matInput type="text" [(ngModel)]="inputValue">
    </mat-form-field>
    <button (click)="reset()" fxFlex="nogrow" mat-icon-button color="warn">
      <mat-icon>close</mat-icon>
    </button>
    <button (click)="submit()" [disabled]="!inputValue" fxFlex="nogrow" mat-icon-button color="primary">
      <mat-icon>check</mat-icon>
    </button>
  </div>
</ng-template>
