import { Dictionary }     from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Content }     from '../../declarations/content';
import { ContentType } from '../../declarations/enums';
import { isLayout } from '../../declarations/layout';

import { TemplaterState }   from '../index';
import { SLayout }          from '../reducers/layout.reducer';
import { TemplaterUIState } from '../reducers/templater-ui.reducer';

import { selectTemplater }       from './';
import { selectContentEntities } from './contents.selectors';
import { selectLayoutEntities }  from './layouts.selectors';

export const selectTemplateUIState = createSelector(
  selectTemplater,
  (state: TemplaterState) => state.ui
);

export const selectTemplaterReadonlyMode = createSelector(
  selectTemplateUIState,
  (state: TemplaterUIState) => state.readonlyMode
);

export const selectTemplaterSelectedArticleIndex = createSelector(
  selectTemplateUIState,
  (state: TemplaterUIState) => state.selectedArticleIndex
);

export const selectTemplaterSelectedContents = createSelector(
  selectTemplateUIState,
  (state: TemplaterUIState) => state.selectedContents
);

export const selectTemplaterSelectedLayouts = createSelector(
  selectTemplateUIState,
  (state: TemplaterUIState) => state.selectedLayouts
);

export const selectContentSelectionModeIsActive = createSelector(
  selectTemplaterSelectedContents,
  (selectedElements: string[]) =>
    selectedElements.length > 0
);

export const selectLayoutSelectionModeIsActive = createSelector(
  selectTemplaterSelectedLayouts,
  (selectedElements: string[]) =>
    selectedElements.length > 0
);

export const selectIsContentInSelection = createSelector(
  selectTemplaterSelectedContents,
  (selectedContents: string[], { id }) => {
    return selectedContents.includes(id)
  }
);

export const selectIsLayoutInSelection = createSelector(
  selectTemplaterSelectedLayouts,
  (selectedLayouts: string[], { id }) => {
    return selectedLayouts.includes(id)
  }
);

export const selectFocusedElementId = createSelector(
  selectTemplateUIState,
  (state: TemplaterUIState) => state.focusedElementId
);

export const selectFocusedElement = createSelector(
  selectFocusedElementId,
  selectLayoutEntities,
  selectContentEntities,
  (id: string, layouts: Dictionary<SLayout>, contents: Dictionary<Content>) =>
    layouts[id] || contents[id]
);

export const selectFocusedCustomBlockRef = createSelector(
  selectFocusedElementId,
  selectLayoutEntities,
  selectContentEntities,
  (id: string, layouts: Dictionary<SLayout>, contents: Dictionary<Content>) => {
    const focused = layouts[id] || contents[id];
    if (isLayout(focused)) {
      return focused.customBlockRef;
    }
  }
);

export const selectActiveCustomBlocks = createSelector(
  selectTemplateUIState,
  (state: TemplaterUIState) => state.activeCustomBlocks
);

export const selectIsElementFocused = createSelector(
  selectFocusedElementId,
  (focusedId: string, { id }) =>
    focusedId === id
);

export const selectConfiguredElementId = createSelector(
  selectTemplateUIState,
  (state: TemplaterUIState) => state.configuredElementId
);

export const selectConfiguredElement = createSelector(
  selectConfiguredElementId,
  selectLayoutEntities,
  selectContentEntities,
  (id: string, layouts: Dictionary<SLayout>, contents: Dictionary<Content>) =>
    layouts[id] || contents[id]
);

export const selectTextEditionModeIsActive = createSelector(
  selectFocusedElement,
  (focused: SLayout | Content) => {
    if (focused && focused.type) {
      return focused.type === ContentType.Text || focused.type === ContentType.Title;
    } else {
      return false;
    }
  }
);

