import { Dictionary }     from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Content } from '../../declarations/content';

import { TemplaterState }                from '../index';
import { contentsAdapter, ContentState } from '../reducers/content.reducer';

import { selectTemplater } from './';

const {
  // selectIds
  selectEntities
  // selectAll
  // selectTotal
} = contentsAdapter.getSelectors();

export const selectContentState = createSelector(
  selectTemplater,
  (state: TemplaterState) => state.contents.present
);
export const selectContentEntities = createSelector(
  selectContentState,
  (state: ContentState) => selectEntities(state)
);

export const selectContent = createSelector(
  selectContentEntities,
  (entities: Dictionary<Content>, { id }: { id: string }) =>
    entities[id]
);

export const selectContents = createSelector(
  selectContentEntities,
  (entities: Dictionary<Content>, { ids }: { ids: string[] }) =>
    ids.map((id) => entities[id])
);

export const selectContentSizeProperties = createSelector(
  selectContent,
  (content: Content, { id }) =>
    content && content.size ? content.size : {}
);

export const selectContentOuterRenderingProperties = createSelector(
  selectContent,
  (content: Content, { id }) => {
    return content ? content.outerRenderingProperties : {};
  }
);
