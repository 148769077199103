import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable }             from '@angular/core';

import { firstValueFrom, Observable, throwError } from 'rxjs';
import { map }                           from 'rxjs/operators';

import { environment } from 'environments/environment';

import { TemplateVariableFromAPI } from '../../newsletters-module/declarations/template-variables';
import { TemplateModel } from '../declarations/template-model';

@Injectable({
  providedIn: 'root'
})
export class TemplateModelProvider {

  constructor(private http: HttpClient) { }

  getModel(engineId: number, sourceId: string, templateId: string): Observable<TemplateModel> {
    return this.http
      .get<TemplateModel>(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/template/${templateId}/model`, {
      params: new HttpParams()
    });
  }

  getVariablesOfModel(engineId: number, sourceId: string, templateId: string): Observable<TemplateVariableFromAPI[]> {
    return this.http
      .get<TemplateVariableFromAPI[]>(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/template/${templateId}/variables`).pipe(
      map(res => {
        if (res) {
          return res;
        } else {
          throwError(new Error('Impossible to access variables'));
        }
      })
    );
  }

  createOrUpdateModel(engineId: number, sourceId: string, templateId: string, model: string, compiled: string): Promise<void> {
    const model$ = this.http
      .put<void>(`${environment.apiUrl2}/2.0/engines/${engineId}/sources/${sourceId}/template/${templateId}/model`, {
        model,
        compiled
      });

    return firstValueFrom(model$);
  }

}
