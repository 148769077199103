import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import _ from 'lodash/fp';

import { EnginesAction, EnginesActionTypes } from '../actions/engines.actions';

import { Engine } from '../../declarations/engine';
import {
  addFolder,
  deleteFromFolder,
  deleteIdFromFolder,
  moveBetweenFolders,
  pruneEmptyFolders
} from '../../declarations/folder';

export interface EnginesState extends EntityState<Engine> {
  selectedEngineId: number | null; // id
}

export const enginesAdapter: EntityAdapter<Engine> = createEntityAdapter<Engine>({
  sortComparer: (a: Engine, b: Engine) => a.displayName.localeCompare(b.displayName)
});

const INITIAL_STATE: EnginesState = enginesAdapter.getInitialState({
  selectedEngineId: null
});

const {
  selectIds,
  selectEntities
  // selectAll,
  // selectTotal
} = enginesAdapter.getSelectors();

export function enginesReducer(state: EnginesState = INITIAL_STATE, action: EnginesAction): EnginesState {
  switch (action.type) {
    case EnginesActionTypes.ADD:
      return enginesAdapter.addOne(action.payload.engine, {
        ...state,
        selectedEngineId: action.payload.engine.id
      });

    case EnginesActionTypes.REPLACE_ONE:
      return enginesAdapter.upsertOne(action.payload.newEngine, state);

    case EnginesActionTypes.REPLACE_ALL:
      const newState = enginesAdapter.setAll(action.payload.engines, state);

      const mediegoEngineIndex = (selectIds(newState) as number[])?.indexOf(42);
      const hasMediegoEngineAvailable: boolean = mediegoEngineIndex > -1;

      return {
        ...newState,
        // we select ID like this, because selectIds() sorts IDs first
        selectedEngineId: hasMediegoEngineAvailable ? 42 : selectIds(newState)[0] as number
      };

    case EnginesActionTypes.DELETE_ALL:
      return INITIAL_STATE;

    case EnginesActionTypes.SELECT_BY_ID: {
      const ids: number[] = selectIds(state) as number[];
      const id = action.payload.engineId;

      if (ids.indexOf(id) > -1) {
        return {
          ...state,
          selectedEngineId: id
        };
      } else {
        console.error('No engine found with id', id);
        return {
          ...state,
          selectedEngineId: ids[0]
        };
      }
    }



    case EnginesActionTypes.UPSERT_SEGMENT_IN_SELECTED: {
      const selectedEngine: Engine = selectEntities(state)[state.selectedEngineId];
      const segment = action.payload.segment;
      const index = selectedEngine.segments.findIndex((__) => __.id === segment.id);

      if (index > -1) {
        const updatedSegmentList = selectedEngine.segments.slice();
        updatedSegmentList.splice(index, 1, segment);

        return enginesAdapter.updateOne({
          id: state.selectedEngineId,
          changes: {
            segments: updatedSegmentList
          }
        }, state);
      } else {
        return enginesAdapter.updateOne({
          id: state.selectedEngineId,
          changes: {
            segments: [...selectedEngine.segments, segment]
          }
        }, state);
      }

    }

    case EnginesActionTypes.INSERT_SEGMENT_PERMISSIONS_IN_SELECTED: {
      const selectedEngine: Engine = selectEntities(state)[state.selectedEngineId];
      const segmentsPermissions = action.payload.segmentPermissions;

      return enginesAdapter.updateOne({
        id: state.selectedEngineId,
        changes: {
          userPermissions: {
            ...selectedEngine.userPermissions,
            segments: [...selectedEngine.userPermissions.segments, segmentsPermissions]
          }
        }
      }, state);
    }

    case EnginesActionTypes.DELETE_SEGMENT_FROM_SELECTED: {
      const selectedEngine: Engine = selectEntities(state)[state.selectedEngineId];
      const segmentId = action.payload.id;

      const index = selectedEngine.segments.findIndex((__) => __.id === segmentId);
      if (index === -1) {
        return state;
      }

      const updatedSegmentList = selectedEngine.segments.slice();
      updatedSegmentList.splice(index, 1);

      return enginesAdapter.updateOne({
        id: state.selectedEngineId,
        changes: {
          segments: updatedSegmentList
        }
      }, state);
    }



    case EnginesActionTypes.UPSERT_WEB_SOURCE_IN_SELECTED: {
      const selectedEngine: Engine = selectEntities(state)[state.selectedEngineId];
      const source = action.payload.source;
      const index = selectedEngine.sources.web_all.findIndex((__) => __.id === source.id);

      if (index > -1) {
        const updatedSourceList = selectedEngine.sources.web_all.slice();
        updatedSourceList.splice(index, 1, source);

        return enginesAdapter.updateOne({
          id: state.selectedEngineId,
          changes: {
            sources: {
              ...selectedEngine.sources,
              web_all: updatedSourceList
            }
          }
        }, state);
      } else {
        return enginesAdapter.updateOne({
          id: state.selectedEngineId,
          changes: {
            sources: {
              ...selectedEngine.sources,
              web_all: [...selectedEngine.sources.web_all, source]
            }
          }
        }, state);
      }
    }

    case EnginesActionTypes.DELETE_WEB_SOURCE_FROM_SELECTED: {
      const selectedEngine: Engine = selectEntities(state)[state.selectedEngineId];
      const sourceId = action.payload.id;

      const index = selectedEngine.sources.web_all.findIndex((__) => __.id === sourceId);
      if (index === -1) {
        return state;
      }

      const updatedSourceList = selectedEngine.sources.web_all.slice();
      const deletedSource = updatedSourceList.splice(index, 1)[0];

      const updatedFolder = deleteFromFolder(selectedEngine.sources.web, deletedSource);

      return enginesAdapter.updateOne({
        id: state.selectedEngineId,
        changes: {
          sources: {
            ...selectedEngine.sources,
            web_all: {
              ...updatedSourceList
            },
            web: {
              ...updatedFolder
            }
          }
        }
      }, state);
    }


    case EnginesActionTypes.ADD_EMAIL_SOURCE_FOLDER: {
      const selectedEngine: Engine = selectEntities(state)[state.selectedEngineId];
      const folderPath = action.payload.folderPath;

      if (folderPath && folderPath.length) {

        const updatedTreeStructure = addFolder(selectedEngine.sources.newsletters, folderPath);

        const sources = selectedEngine.sources;
        sources.newsletters = { ...updatedTreeStructure };

        return enginesAdapter.updateOne({
          id: state.selectedEngineId,
          changes: {
            sources
          }
        }, state);
      }

      return state;
    }



    case EnginesActionTypes.UPSERT_EMAIL_SOURCE_IN_SELECTED: {
      const selectedEngine: Engine = selectEntities(state)[state.selectedEngineId];
      const source = action.payload.source;

      if (selectedEngine?.sources?.newsletters_all && selectedEngine?.sources?.newsletters) {

        const index = selectedEngine.sources.newsletters_all.findIndex((__) => __.id === source.id);

        if (index > -1) {

          const oldSource = selectedEngine.sources.newsletters_all[index];

          const updatedSourceList = selectedEngine.sources.newsletters_all.slice();
          updatedSourceList.splice(index, 1, {
            ...oldSource,
            ...source
          }); // updating source, keeping unmodified fields

          const needsToMoveNewsletter = !_.isEqual(source.collection, oldSource.collection);

          // modification of tree structure (based on collection field)
          let treeStructureUpdated = null;
          if (needsToMoveNewsletter) {

            if (source.collection) {

              let moveInsideNewFolder: boolean = false;

              // might create a new folder
              if (source.collection.length) {
                const lastFolderName = source.collection[source.collection.length - 1];
                moveInsideNewFolder = selectedEngine.sources.newsletters_all.every((newsletter) =>
                  newsletter && newsletter.collection && newsletter.collection.includes(lastFolderName) === false
                );

                if (moveInsideNewFolder) {

                  // removing newsletter
                  selectedEngine.sources.newsletters = deleteIdFromFolder(
                    selectedEngine.sources.newsletters,
                    source.id
                  );

                  treeStructureUpdated = addFolder(
                    selectedEngine.sources.newsletters,
                    source.collection,
                    [{
                      ...oldSource,
                      ...source
                    }]
                  );
                }
              }

              // else may move between folders
              if (!moveInsideNewFolder && !treeStructureUpdated) {

                // moving source, need to impact not only newsletters_all but also newsletters
                treeStructureUpdated = moveBetweenFolders(selectedEngine.sources.newsletters, {
                  ...oldSource,
                  ...source
                });

              }
            }

            // if tree structure changed, we need to prune empty folders which may appear
            if (treeStructureUpdated) treeStructureUpdated = pruneEmptyFolders(treeStructureUpdated);
          }

          const sources = selectedEngine.sources;
          sources.newsletters_all = [...updatedSourceList];
          sources.newsletters = treeStructureUpdated ? { ...treeStructureUpdated } : sources.newsletters;

          return enginesAdapter.updateOne({
            id: state.selectedEngineId,
            changes: {
              sources
            }
          }, state);
        } else {
          return enginesAdapter.updateOne({
            id: state.selectedEngineId,
            changes: {
              sources: {
                ...selectedEngine.sources,
                newsletters_all: [...selectedEngine.sources.newsletters_all, source],
                newsletters: {
                  ...selectedEngine.sources.newsletters,
                  items: [...selectedEngine.sources.newsletters.items, source]
                }
              }
            }
          }, state);
        }
      } else {
        return state;
      }
    }

    case EnginesActionTypes.DELETE_EMAIL_SOURCE_FROM_SELECTED: {
      const selectedEngine: Engine = selectEntities(state)[state.selectedEngineId];
      const sourceId = action.payload.id;

      const index = selectedEngine?.sources?.newsletters_all?.findIndex((__) => __?.id === sourceId) || -1;
      if (index === -1) {
        console.error('newsletter to delete not found');
        return state;
      }

      const updatedSourceList = selectedEngine.sources.newsletters_all.slice();
      const deletedSource = updatedSourceList.splice(index, 1)[0];

      console.warn('deletion', deletedSource);
      const updatedFolder = deleteFromFolder(selectedEngine.sources.newsletters, deletedSource);

      return enginesAdapter.updateOne({
        id: state.selectedEngineId,
        changes: {
          sources: {
            ...selectedEngine.sources,
            newsletters_all: [
              ...updatedSourceList
            ],
            newsletters: {
              ...updatedFolder
            }
          }
        }
      }, state);
    }

    case EnginesActionTypes.INSERT_NEWSLETTER_PERMISSIONS_IN_SELECTED: {
      const selectedEngine: Engine = selectEntities(state)[state.selectedEngineId];
      const newsletterPermissions = action.payload.newsletterPermissions;

      return enginesAdapter.updateOne({
        id: state.selectedEngineId,
        changes: {
          userPermissions: {
            ...selectedEngine.userPermissions,
            newsletters: [...selectedEngine.userPermissions.newsletters, newsletterPermissions]
          }
        }
      }, state);
    }

    case EnginesActionTypes.DELETE_NEWSLETTER_PERMISSIONS_IN_SELECTED: {
      const selectedEngine: Engine = selectEntities(state)[state.selectedEngineId];
      const newsletterId = action.payload.id;

      const index = selectedEngine.userPermissions.newsletters.findIndex((__) => __.newsletterId === newsletterId);
      if (index === -1) {
        return state;
      }

      const updatedNewsletterPermissionsList = selectedEngine.userPermissions.newsletters.slice();
      updatedNewsletterPermissionsList.splice(index, 1);

      return enginesAdapter.updateOne({
        id: state.selectedEngineId,
        changes: {
          userPermissions: {
            ...selectedEngine.userPermissions,
            newsletters: updatedNewsletterPermissionsList
          }
        }
      }, state);
    }

    default:
      return state;
  }
}
