import { TemplateModelUtils } from '../../utils/template-model.utils';
import { TemplateTrackingUtils } from '../../utils/template-tracking.utils';
import { CompilationContext, CompilationResult } from '../compilation';
import { ConstraintPattern, ContentType, SizeUnit, TextAlignment } from '../enums';
import { applyBorderRadius, applyStyles, SizeRenderingProperties } from '../rendering-properties';

import { Content, ContentParametersInfo, DEFAULT_ARTICLE_LINK } from './index';

export interface ImageContentParams {
  imageUrl: string;
  imageLink: string;
  imageAlt: string;
  imageTitle: string;
  customTracking: string;
  disableEncoding: boolean;
}

export const IMAGE_PARAMETERS_INFO: ContentParametersInfo<ImageContentParams> = {
  imageUrl: {
    valueType: 'url',
    debounced: true
  },
  imageLink: {
    valueType: 'url',
    debounced: true
  },
  imageAlt: {
    valueType: 'string'
  },
  imageTitle: {
    valueType: 'string'
  },
  customTracking: {
    valueType: 'string',
    enabledWithConstraints: [ConstraintPattern.HeatmapUTM, ConstraintPattern.SelligentTracking]
  },
  disableEncoding: {
    valueType: 'boolean',
    hint: true
  }
};

export const DEFAULT_IMAGE_PARAMETERS: Content<ImageContentParams>['params'] = {
  static: {
    imageUrl: '',
    imageLink: '',
    imageAlt: '',
    imageTitle: '',
    customTracking: '',
    disableEncoding: false
  },
  dynamic: {
    imageUrl: '{{{ metadata.image }}}',
    imageLink: DEFAULT_ARTICLE_LINK,
    imageAlt: '',
    imageTitle: '',
    customTracking: '',
    disableEncoding: false
  },
  darkmode: {
    imageUrl: ''
  }
};

export type ImageContent = Content<ImageContentParams>;

export const deduceAbsoluteImageWidth = (content: ImageContent, parentSizes: SizeRenderingProperties[]): number => {

  // We try to iterate on Template children until reaching our current Image
  // Storing relative sizes of parent elements
  // IDEA: use margins and paddings to get a precise width ? (doesn't look like a real issue on Email on acid)

  let deducedWidth: number = content.innerRenderingProperties.imageWidth.value;

  if (content.innerRenderingProperties.imageWidth.unit == SizeUnit.PX) {
    return deducedWidth;
  } else {
    let parentElement: SizeRenderingProperties;

    let lastUnitProcessed: SizeUnit = content.innerRenderingProperties.imageWidth.unit;

    for (let k = parentSizes.length - 1; k >= 0; k--) {
      parentElement = parentSizes[k];

      if (parentElement && parentElement.width) {

        // Excluding the scenario of absolute size over absolute size (no-op)
        if (!(lastUnitProcessed === SizeUnit.PX && parentElement.width.unit === SizeUnit.PX)) {
          deducedWidth = Math.floor((parentElement.width.value * deducedWidth) / 100);
          lastUnitProcessed = parentElement.width.unit;
        }

      }
    }

    // we will necessarily get a PX size as template width is PX

    return deducedWidth;
  }
};


// The code handles two main possibilities : static or not static (article-related) content
export const compileToHtmlNodes = (content: ImageContent, articleIndex: number, context: CompilationContext): CompilationResult => {

  const result: CompilationResult = {
    nodes: [],
    context
  };

  let mainNodes: Node[];

  const img_tag = document.createElement('img') as HTMLImageElement;

  img_tag.classList.add('mdg-img');
  if (content.innerRenderingProperties.imageWidth.responsive) {
    img_tag.classList.add('r');
  }
  applyStyles(img_tag, content.innerRenderingProperties);
  applyBorderRadius(content.outerRenderingProperties.borderRadius, img_tag);

  // Fixing image width (outlook issue)
  if (context.parentSizes?.length) {
    const absoluteWidth = deduceAbsoluteImageWidth(content, context.parentSizes);
    if (absoluteWidth) {
      img_tag.width = absoluteWidth;
    }
  }

  // Fixing image issues (block is better but block image can't be aligned)
  if (content.outerRenderingProperties.textAlign == TextAlignment.Left
      || (content.innerRenderingProperties.imageWidth.unit == SizeUnit.PERCENT
          && content.innerRenderingProperties.imageWidth.value === 100)) {
    img_tag.style.display = 'block';
  } else if (content.outerRenderingProperties.textAlign == TextAlignment.Center
      || (content.innerRenderingProperties.imageWidth.unit == SizeUnit.PERCENT
      && content.innerRenderingProperties.imageWidth.value <= 100)) {

    // can use block and emulate (text-align: center) with (margin: auto)
    // img_tag.style.display = 'block';
    // img_tag.style.margin = 'auto';

  } else {
    img_tag.style.display = 'inline-block';
  }

  let imageSrc: string, imageAlt: string, imageTitle: string;

  if (content.unlockedForEditorial) {
    imageSrc = `{{variables.${TemplateModelUtils.sanitizeContentIdForMustache(content.id)}}}`;
  } else if (content.articleRef) {
    imageSrc = content.params.dynamic.imageUrl; // manipulating innerHTML because text may contain links/tags
    imageAlt = content.params.dynamic.imageAlt;
    imageTitle = content.params.dynamic.imageTitle;
  } else {
    imageSrc = content.params.static.imageUrl; // manipulating innerHTML because text may contain links/tags
    imageAlt = content.params.static.imageAlt;
    imageTitle = content.params.static.imageTitle;
  }

  if (imageSrc) img_tag.src = imageSrc;
  if (imageAlt) img_tag.alt = imageAlt;
  if (imageTitle) img_tag.title = imageTitle;

  // Wrap with a_tag if we have a URL
  let imageLink: string, linkWithoutBraces: string, startIfLink: string, endIfLink: string;
  let needsDarkmode: boolean = false;

  const a_tag: HTMLAnchorElement = document.createElement('a');
  a_tag.target = '_blank';
  a_tag.style.textDecoration = 'none';

  const relevantParams = content.articleRef ? content.params.dynamic : content.params.static;
  TemplateTrackingUtils.setSelligentTrackingParameter(a_tag, context, relevantParams, !!content.articleRef, articleIndex);

  if (relevantParams.disableEncoding) a_tag.setAttribute('data-disable-tracking', 'true');

  if (content.unlockedForEditorial) {
    imageLink = `{{variables.${TemplateModelUtils.sanitizeContentIdForMustacheLink(content.id)}}}`
  } else if (content.articleRef) {
    imageLink = content.params.dynamic.imageLink;
  } else {
    imageLink = content.params.static.imageLink;

    const darkmodeUrl = content.params.darkmode?.imageUrl;
    needsDarkmode = darkmodeUrl && darkmodeUrl !== content.params.static.imageUrl;
  }

  a_tag.href = imageLink + TemplateTrackingUtils.getMediegoTrackingParameter(context, relevantParams, ContentType.Image, !!content.articleRef, articleIndex);

  mainNodes = [img_tag];

  // in case of darkmode image, generating darkmode classes + second image
  if (needsDarkmode) {
    const darkmode_image_tag = img_tag.cloneNode(true) as HTMLImageElement;
    darkmode_image_tag.classList.add('mdg-dark');
    darkmode_image_tag.src = content.params.darkmode.imageUrl;
    darkmode_image_tag.style.display = 'none';
    if (imageAlt) darkmode_image_tag.alt = imageAlt;

    img_tag.classList.add('mdg-light');

    const msoIf = document.createComment('[if !mso]><!');
    const msoEndIf = document.createComment('<![endif]');

    mainNodes = imageSrc ? [img_tag, msoIf, darkmode_image_tag, msoEndIf] : [msoIf, darkmode_image_tag, msoEndIf];
  }

  // wrapping within a link tag if relevant
  if (imageLink) {
    linkWithoutBraces = imageLink.replace('{{', '').replace('}}', '');
    startIfLink = `{{ #${linkWithoutBraces} }}`;
    endIfLink = `{{ /${linkWithoutBraces} }}`;

    if (content.unlockedForEditorial) a_tag.appendChild(document.createTextNode(endIfLink));
    mainNodes.forEach((node) => a_tag.appendChild(node));
    if (content.unlockedForEditorial) a_tag.appendChild(document.createTextNode(startIfLink));

    mainNodes = [a_tag];
  }

  const startIfArticle = `{{ #reco_${articleIndex} }}{{ #metadata }}`;
  const endIfArticle = `{{ /metadata }}{{ /reco_${articleIndex} }}`;


  // returning nodes with correct if / end if brackets
  if (content.articleRef && content.unlockedForEditorial) {
    result.nodes = [
      document.createTextNode(startIfArticle),
      document.createTextNode(startIfLink),
      ...mainNodes,
      document.createTextNode(endIfLink),
      document.createTextNode(endIfArticle)];
  } else if (content.unlockedForEditorial) {
    result.nodes = [
      document.createTextNode(startIfLink),
      ...mainNodes,
      document.createTextNode(endIfLink)
    ];
  } else if (content.articleRef) {
    result.nodes = [
      document.createTextNode(startIfArticle),
      ...mainNodes,
      document.createTextNode(endIfArticle)];
  } else {
    result.nodes = [...mainNodes];
  }

  if (needsDarkmode) result.context.darkmodeContents?.push({ content, compiled: [...result.nodes] });
  return result;
};
