import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'largeNumber'
})
export class LargeNumberPipe implements PipeTransform {

  /**
   * Inserts spaces if necessary
   * For example 103489304 will become 103 489 304 after pipe transformation
   * @param {number} input
   * @returns {string}
   */
  transform(input: number): string {
    return input?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

}
