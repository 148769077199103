import { Constraint } from '../constraints';

export const METADATA_TYPES = ['hasMetadata', 'metadataContains', 'metadataEquals']

export class HasSourcesConstraintForItemset implements Constraint {
  static type = 'hasSources';
  static disabled = false;
  static supportsMultiple = false;
  type = 'hasSources';
  disabled = false;
  configurable = true;
  mandatory = false;
  supportsNegation = false;
  negated = false;
  priority = 3;
  parameters = {
    'withSource': {
      type: 'custom',
      description: true,
      value: [],
      hint: 'flux1, flux2'
    },
    'all-mandatory': {
      type: 'boolean',
      description: true,
      value: true
    }
  }
}

export class PublicationDateConstraintForItemset implements Constraint {
  static type = 'isRecentItemset';
  static disabled = false;
  static supportsMultiple = false;
  type = 'isRecent';
  disabled = false;
  configurable = true;
  mandatory = true;
  supportsNegation = false;
  negated = false;
  priority = 2;
  parameters = {
    'hours': {
      type: 'number',
      description: true,
      value: 24
    }
  };
}

export class PublicationDateConstraint implements Constraint {
  static type = 'isRecent';
  static disabled = false;
  static supportsMultiple = false;
  type = 'isRecent';
  disabled = false;
  configurable = true;
  mandatory = false;
  supportsNegation = true;
  negated = false;
  priority = 2;
  parameters = {
    'hours': {
      type: 'number',
      description: true,
      value: 24
    }
  };
}

export class HasCategoriesConstraint implements Constraint {
  static type = 'hasCategories';
  static disabled = false;
  static supportsMultiple = true;
  type = 'hasCategories';
  disabled = false;
  configurable = true;
  supportsNegation = true;
  negated = false;
  priority = 3;
  parameters = {
    'withCategory': {
      type: 'custom',
      description: true,
      value: []
    },
    'all-mandatory': {
      type: 'boolean',
      description: true,
      value: true
    }
  };
}

export class HasMetadataConstraintForItemset implements Constraint {
  static type = 'hasMetadataItemset';
  static disabled = false;
  static supportsMultiple = false;
  type = 'hasMetadataItemset';
  disabled = false;
  configurable = true;
  supportsNegation = true;
  negated = false;
  priority = 3;
  parameters = {
    'withMetadata': {
      type: 'custom',
      description: true,
      value: []
    }
  };
  description: true
}

// Version compatible with current system
export class HasMetadataConstraint implements Constraint {
  static type = 'hasMetadata';
  static disabled = false;
  static supportsMultiple = true;
  type = 'hasMetadata';
  disabled = false;
  configurable = true;
  supportsNegation = true;
  negated = false;
  priority = 3;
  parameters = {
    'withName': {
      type: 'string',
      description: true,
      value: '',
      required: true,
      hint: true
    }
  };
  description: true
}

export class MetadataValueConstraint implements Constraint {
  static type = 'metadataValue';
  static disabled = false;
  static supportsMultiple = true;
  type = 'metadataValue';
  disabled = false;
  configurable = true;
  supportsNegation = false;
  negated = false;
  priority = 3;
  description = true;
  parameters = {
    'withCheck': {
      type: 'select',
      description: true,
      viewChoices: ['CONTAINS', 'EQUALS'],
      viewChoicesNegated: ['CONTAINS_NOT', 'EQUALS_NOT'],
      choices: ['metadataContains', 'metadataEquals'],
      value: 'metadataEquals'
    },
    'withValue': {
      type: 'custom',
      description: true,
      value: {} // map
    }
  }
}

export class MetadataInsecableSpaceConstraint implements Constraint {
  static type = 'metadataValue-nbsp';
  static disabled = false;
  static supportsMultiple = true;
  static unbreakableSpace = ' '
  type = 'metadataValue-nbsp';
  disabled = false;
  configurable = true;
  supportsNegation = false;
  negated = true;
  priority = 3;
  description = true;
  parameters = {
    'withName': {
      type: 'string',
      value: 'title',
      required: true
    }
  };
}

export class URLValueConstraint implements Constraint {
  static type = 'urlValue';
  static disabled = false;
  static supportsMultiple = true;
  type = 'urlValue';
  disabled = false;
  configurable = true;
  supportsNegation = false;
  negated = false;
  priority = 3;
  description = true;
  parameters = {
    'withCheck': {
      type: 'select',
      description: true,
      viewChoices: ['CONTAINS', 'STARTS', 'ENDS', 'EQUALS'],
      viewChoicesNegated: ['CONTAINS_NOT', 'STARTS_NOT', 'ENDS_NOT', 'EQUALS_NOT'],
      choices: ['urlContains', 'urlStartsWith', 'urlEndsWith', 'urlEquals'],
      value: 'urlStartsWith'
    },
    'withURL': {
      type: 'custom',
      description: 'URL',
      value: []
    }
  }
}
