export abstract class SerializableCriterion {
  abstract readonly type: string;
  abstract parametersToJson(): any;

  toJson(): any {
    return {
      type: this.type,
      parameters: this.parametersToJson()
    };
  }
}
