import {
  Component,
  forwardRef,
  OnInit,
  ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { A7SpecificSendingMailerConfiguration } from 'app/mediego-common-module/declarations/source';

@Component({
  selector: 'app-mediego-specific-sending-conf-a7',
  templateUrl: './specific-sending-conf-a7.component.html',
  styleUrls: ['./specific-sending-conf-a7.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpecificSendingConfA7Component),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class SpecificSendingConfA7Component implements ControlValueAccessor, OnInit {

  a7Form: UntypedFormGroup;
  onTouched: any;

  defaultConf: A7SpecificSendingMailerConfiguration = {
    type: 'a7',
    autoStart: false,
    htmlProcess: null,
    interests: []
  }

  get interests(): UntypedFormControl {
    return this.a7Form?.get('interests') as UntypedFormControl;
  }

  constructor(
    private _fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.a7Form = this._fb.group({
      type: this.defaultConf.type,
      autoStart: this.defaultConf.autoStart,
      htmlProcess: this.defaultConf.htmlProcess,
      interests: [this.defaultConf.interests]
    });

    this.a7Form.valueChanges.subscribe((value) => {
      this.onTouched?.();
    });
  }

  writeValue(obj: A7SpecificSendingMailerConfiguration): void {
    if (obj) {
      this.a7Form.setValue(obj);
    } else {
      this.a7Form.setValue(this.defaultConf);
    }
  }

  registerOnChange(fn: any): void {
    this.a7Form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.a7Form.disable() : this.a7Form.enable();
  }

  updateInterests(newInterests: string[]): void {
    this.interests.setValue(newInterests);
  }

}
