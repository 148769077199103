import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'trend-display',
  templateUrl: './trend-display.component.html',
  styleUrls: ['./trend-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrendDisplayComponent implements OnChanges {
  @Input() value: number;
  prettyValue: string;
  isPositive: boolean;
  hasValueToDisplay: boolean;

  ngOnChanges(changes): void {
    if (changes?.value) {
      const rawValue = changes.value.currentValue;

      if (rawValue === undefined) {
        this.hasValueToDisplay = false;
        return;
      }

      this.hasValueToDisplay = true;

      if (rawValue > 0) {
        this.prettyValue = '+' + changes.value.currentValue;
        this.isPositive = true;
      } else if (rawValue < 0) {
        this.prettyValue = changes.value.currentValue;
        this.isPositive = false;
      } else if (rawValue === 0) {
        this.prettyValue = '';
      }
    }
  }
}
