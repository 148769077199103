import { createAction, props, union } from '@ngrx/store';

import { RGBA } from 'ngx-color';

import { NewslettersConfig } from '../../../configuration-module/declarations/newsletters-config';
import { NewsletterSource }                                  from '../../../mediego-common-module/declarations/source';
import { Layout }                                            from '../../declarations/layout';
import { OuterRenderingProperties, SizeRenderingProperties } from '../../declarations/rendering-properties';
import { TemplateHeaders, TemplateModel } from '../../declarations/template-model';

export const loadTemplateModel = createAction(
  '[TEMPLATER][MODEL] Load',
  props<{ model: TemplateModel }>()
);
export const importTemplateModel = createAction(
  '[TEMPLATER][MODEL] Import',
  props<{ model: TemplateModel; source: NewsletterSource }>()
);

export const createNewTemplateModel = createAction(
  '[TEMPLATER][MODEL] Create New',
  props<{ config: NewslettersConfig }>()
);

export const resetTemplateModel = createAction('[TEMPLATER][MODEL] Reset');
export const clearTemplateModel = createAction('[TEMPLATER][MODEL] Clear');




export const addRowToTemplateModel = createAction(
  '[TEMPLATER][MODEL] Add Row',
  props<{ row: Layout; position: number }>()
);
export const addRowsToTemplateModel = createAction(
  '[TEMPLATER][MODEL] Add Rows',
  props<{ rows: Layout[]; position: number }>()
);
export const deleteRowFromTemplateModel = createAction(
  '[TEMPLATER][MODEL] Delete Row',
  props<{ id: string }>()
);
export const moveRowInTemplateModel = createAction(
  '[TEMPLATER][MODEL] Move Row',
  props<{ sourceIndex: number; targetIndex: number }>()
);
export const moveRowsInTemplateModel = createAction(
  '[TEMPLATER][MODEL] Move Rows',
  props<{ firstRow: string; numberOfRows: number; targetIndex: number }>()
);
export const duplicateRowInTemplateModel = createAction(
  '[TEMPLATER][MODEL] Duplicate Row',
  props<{ id: string }>()
);

export const duplicateRowsInTemplateModel = createAction(
  '[TEMPLATER][MODEL] Duplicate Rows',
  props<{ ids: string[] }>()
);




/*export const importBlockBlueprint = createAction(
  '[TEMPLATER][MODEL] Import Block Blueprint',
  props<{ row: Layout; position: number }>()
);*/
/*export const importCustomBlock = createAction(
  '[TEMPLATER][MODEL] Import Custom Block',
  props<{ block: CustomBlock; position: number }>()
);*/

export const updateTemplateModelGlobalCss = createAction(
  '[TEMPLATER][MODEL] Update Global CSS',
  props<{ css: string }>()
);

export const updateTemplateModelSizeProps = createAction(
  '[TEMPLATER][MODEL] Update SIZE Properties',
  props<{ partial: Partial<SizeRenderingProperties> }>()
);
export const updateTemplateModelRenderingProps = createAction(
  '[TEMPLATER][MODEL] Update RENDERING Properties',
  props<{ partial: Partial<OuterRenderingProperties> }>()
);

export const updateTemplateModelPrimaryColor = createAction(
  '[TEMPLATER][MODEL] Update PRIMARY COLOR Property',
  props<{ previousPrimaryColor: RGBA; nextPrimaryColor: RGBA }>()
);

export const updateTemplateModelFavoriteColor = createAction(
  '[TEMPLATER][MODEL] Update FAVORITE COLOR',
  props<{ color: string; index: number }>()
);

export const updateTemplateModelCustomFont = createAction(
  '[TEMPLATER][MODEL] Update CUSTOM FONT',
  props<{ rule: string; index: number }>()
);

export const updateTemplateModelHeaders = createAction(
  '[TEMPLATER][MODEL] Update HEADERS',
  props<{ headers: TemplateHeaders }>()
);


export const createArticleFromSelection = createAction(
  '[TEMPLATER][MODEL] Create Article From Selection'
);



export const undoTemplateModel = createAction('[TEMPLATER][MODEL] UNDO');
export const redoTemplateModel = createAction('[TEMPLATER][MODEL] REDO');

export const templateModelUserActionsCollection = {
  importTemplateModel,
  addRowToTemplateModel,
  addRowsToTemplateModel,
  deleteRowFromTemplateModel,
  moveRowInTemplateModel,
  moveRowsInTemplateModel,
  duplicateRowInTemplateModel,
  duplicateRowsInTemplateModel,
  // importBlockBlueprint,
  // importCustomBlock,
  updateTemplateModelGlobalCss,
  updateTemplateModelSizeProps,
  updateTemplateModelRenderingProps,
  updateTemplateModelPrimaryColor,
  updateTemplateModelFavoriteColor,
  createArticleFromSelection,
  resetTemplateModel
};

export const templateModelUserActions = union(templateModelUserActionsCollection);
const templateModelNonUserActions = union({
  loadTemplateModel,
  createNewTemplateModel,
  clearTemplateModel
});

export type TemplateModelAction = typeof templateModelUserActions | typeof templateModelNonUserActions;
