import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MediegoMessage, MiscProvider } from '../providers/misc.provider';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private DISMISSED_NOTIFICATIONS_STORAGE_KEY: string = 'mediego_notifications';

  private notifications$$: BehaviorSubject<MediegoMessage[]> = new BehaviorSubject<MediegoMessage[]>([]);
  notifications$: Observable<MediegoMessage[]> = this.notifications$$.asObservable();

  static getImageForMediegoMessageType(type: MediegoMessage['tag']): string {
    switch (type) {
      case 'error':
        return '/assets/img/error.jpg';
      case 'info':
        return '/assets/img/shouting.jpg';
      default:
        return '/assets/img/shouting.jpg';
    }
  }


  constructor(private miscProvider: MiscProvider) {}


  private dismissedNotifications(): string[] {
    const dismissedRaw = localStorage.getItem(this.DISMISSED_NOTIFICATIONS_STORAGE_KEY);
    if (dismissedRaw) {
      return JSON.parse(dismissedRaw);
    } else {
      return [];
    }
  }

  hasPendingNotifications$(): Observable<boolean> {
    return this.notifications$.pipe(
      map(notifications => !!notifications.find(notification => notification.pending))
    );
  }

  dismissNotification(id: string): void {
    const dismissed = this.dismissedNotifications();
    if (!dismissed.includes(id)) {
      localStorage.setItem(this.DISMISSED_NOTIFICATIONS_STORAGE_KEY, JSON.stringify([...dismissed, id]));
    }

    const notificationsUpdated = this.notifications$$.getValue().map(notification => {
      if (notification.id !== id) return notification;
      else return {
        ...notification,
        pending: false
      };
    });

    this.notifications$$.next([...notificationsUpdated]);
  }

  dismissAllNotifications(): void {
    const notificationsUpdated = this.notifications$$.getValue().map(notification => {
      return {
        ...notification,
        pending: false
      };
    });

    localStorage.setItem(this.DISMISSED_NOTIFICATIONS_STORAGE_KEY, JSON.stringify([...notificationsUpdated.map(notification => notification.id)]));

    this.notifications$$.next([...notificationsUpdated]);
  }

  async getMediegoMessages() {

    const dismissedIds = this.dismissedNotifications();

    const messages = await this.miscProvider.getLatestMediegoMessages().then((messages) =>
      messages.map(message => message && {
        ...message,
        // adding here image src
        imgSrc: NotificationsService.getImageForMediegoMessageType(message.tag),
        pending: !dismissedIds.includes(message.id)
      })
    ).catch((err) => {
      console.error('error while trying to get latest mediego message');
      console.error(err);
      return [];
    });

    this.notifications$$.next([...messages]);
  }


}
