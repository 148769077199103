import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NewslettersState }                      from '../';


export const selectNewsletters = createFeatureSelector<NewslettersState>('newsletters');

export const selectTemplateVariables = createSelector(
  selectNewsletters,
  (state: NewslettersState) => state.variables
);
export const selectCampaignsState = createSelector(
  selectNewsletters,
  (state: NewslettersState) => state.campaigns
);

export const selectTemplateSubject = createSelector(
  selectNewsletters,
  (state: NewslettersState) => state.subject
);

export const selectTemplatePipeline = createSelector(
  selectNewsletters,
  (state: NewslettersState) => state.pipeline
);

export const selectTemplateRecos = createSelector(
  selectNewsletters,
  (state: NewslettersState) => state.recos
);
