<h2 mat-dialog-title fxLayoutAlign="start center" fxLayoutGap="10px">
  <mat-icon *ngIf="icon && icon !== 'mediego'; else mediegoLogo" [color]="danger ? 'warn' : 'primary'">{{ icon }}</mat-icon>
  <ng-template #mediegoLogo>
    <img src="/assets/img/logo/logo-compact-color.svg" alt="" title="" width="32" />
  </ng-template>
  <span>{{ dialogTitle }}</span>
</h2>

<mat-dialog-content>
  <ng-content></ng-content>
</mat-dialog-content>

<mat-dialog-actions align="end" fxLayoutGap="10px">
  <button mat-button [mat-dialog-close]="false" class="button-decline">{{ declineLabel }}</button>
  <button mat-button *ngIf="deleteLabel" color="warn" [mat-dialog-close]="'delete'">{{ deleteLabel }}</button>
  <button *ngIf="acceptLabel" #submitButton
          mat-stroked-button
          [disabled]="!formValidation || dangerCountdown > 0"
          [mat-dialog-close]="resultData ? resultData : true"
          [color]="danger ? 'warn' : 'accent'"
          class="button-confirm">
    {{ acceptLabel }} <ng-container *ngIf="dangerCountdown > 0">({{ dangerCountdown }})</ng-container>
  </button>
</mat-dialog-actions>
