import { CompilationContext, CompilationResult } from '../compilation';
import { SizeUnit, TextAlignment } from '../enums';
import { applyStyles } from '../rendering-properties';

import { deduceAbsoluteImageWidth } from './image.content';
import { Content, ContentParametersInfo } from './index';

export interface PowerspaceContentParams {
  powerspaceId?: string;
  customDomain?: string;
  user?: string;
  gender?: string;
  age?: string;
  postCode?: string;
  nativeWidth?: boolean;
}

export type PowerspaceContent = Content & PowerspaceContentParams;

export const POWERSPACE_PARAMETERS_INFO: ContentParametersInfo<PowerspaceContentParams> = {
  powerspaceId: {
    valueType: 'string',
    debounced: true,
    required: true
  },
  customDomain: {
    valueType: 'url',
    debounced: true,
    hiddenInSidebar: false,
    hint: '<a href="https://docs.powerspace.com/docs/tag-publicitaire-mise-en-place#sous-domaines-personnalis%C3%A9s" target="_blank">En savoir plus</a>'
  },
  user: {
    valueType: 'string',
    hint: 'email hash'
  },
  gender: {
    valueType: 'string'
  },
  age: {
    valueType: 'string'
  },
  postCode: {
    valueType: 'string'
  },
  nativeWidth: {
    valueType: 'boolean',
    hint: 'Utiliser la largeur réelle de l\'image'
  }
};

const user_email_md5 = '{{#user.email_md5}}ps_ee={{user.email_md5}}&{{/user.email_md5}}';

const DEFAULT_POWERSPACE_DOMAIN = 'https://a.pwspace.com';

export const DEFAULT_POWERSPACE_PARAMETERS: Content<PowerspaceContentParams>['params'] = {
  static: {
    powerspaceId: '',
    customDomain: '',
    user: 'email_md5',
    gender: 'gender',
    age: 'age',
    postCode: '',
    nativeWidth: false
  },
  dynamic: { }
};

export const getSimplePowerspaceUrlForTemplater = (content: PowerspaceContent, type: 'image' | 'link', adPosition: number = 1): string => {
  const customDomain = content.params.static.customDomain || DEFAULT_POWERSPACE_DOMAIN;
  const powerspaceId = content.params.static.powerspaceId;

  if (powerspaceId && customDomain) {
    const subdomain = type === 'image' ? 'ndi' : 'ndc';
    return `${customDomain}/${subdomain}/${powerspaceId}?ps_ce=mediego@powerspace.com&z=${adPosition}`;
  }

  return '';
};

export const compileToHtmlNodes = (content: PowerspaceContent, articleIndex: number, adPosition: number = 1, withMobileVersion: boolean, context: CompilationContext): CompilationResult => {

  const result: CompilationResult = {
    nodes: [],
    context
  };

  const img_tag = document.createElement('img') as HTMLImageElement;
  img_tag.classList.add('mdg-img');
  img_tag.classList.add('pws-content');
  if (content.innerRenderingProperties.imageWidth.responsive) {
    img_tag.classList.add('r');
  }

  applyStyles(img_tag, content.innerRenderingProperties);

  const allowingWidthOverride = !content.params.static.nativeWidth;
  if (!allowingWidthOverride) {
    img_tag.style.width = null;
    img_tag.style.minWidth = null;
    img_tag.style.maxWidth = null;
    // if (!img_tag.style.cssText) img_tag.removeAttribute('style');
  }

  // Fixing image width (outlook issue) if relevant
  if (allowingWidthOverride && context.parentSizes?.length) {
    const absoluteWidth = deduceAbsoluteImageWidth(content, context.parentSizes);
    if (absoluteWidth) {
      img_tag.width = absoluteWidth;
    }
  }

  // Fixing image issues (block is better but block image can't be aligned)
  if (content.outerRenderingProperties.textAlign == TextAlignment.Left
    || (content.innerRenderingProperties.imageWidth.unit == SizeUnit.PERCENT
      && content.innerRenderingProperties.imageWidth.value === 100)) {
    img_tag.style.display = 'block';
  } else if (content.outerRenderingProperties.textAlign == TextAlignment.Center
    || (content.innerRenderingProperties.imageWidth.unit == SizeUnit.PERCENT
      && content.innerRenderingProperties.imageWidth.value <= 100)) {

    // can use block and emulate (text-align: center) with (margin: auto)
    // img_tag.style.display = 'block';
    // img_tag.style.margin = 'auto';

  } else {
    img_tag.style.display = 'inline-block';
  }

  if (!allowingWidthOverride) img_tag.style.display = 'inline-block'; // forcing display for handling text align

  // TODO check custom domain
  const customDomain = content.params.static.customDomain || DEFAULT_POWERSPACE_DOMAIN;
  const powerspaceId = content.params.static.powerspaceId;

  const relevantParams = content.params.static;
  let all_user_variables = user_email_md5;

  // For each query parameter we try to determine if we'll use Mediego user parameter
  // Or an outside user parameter provided by external routing entity

  const specialChars = ['#', '~', '%'];

  const paramContainsSpecialChar: (param: string) => boolean = (param) => !!specialChars.find(specialChar => param.includes(specialChar))

  if (relevantParams.user) {
    if (paramContainsSpecialChar(relevantParams.user)) {
      all_user_variables = `ps_ee=${relevantParams.user.trim()}&`;
    } else {
      all_user_variables = `{{#user.email_md5}}ps_ee={{user.email_md5}}&{{/user.email_md5}}`.replace(/email_md5/g, relevantParams.user.trim());
    }
  }

  if (relevantParams.gender) {
    if (paramContainsSpecialChar(relevantParams.gender)) {
      all_user_variables += `ps_g=${relevantParams.gender.trim()}&`;
    } else {
      all_user_variables += '{{#user.gender}}ps_g={{user.gender}}&{{/user.gender}}'.replace(/gender/g, relevantParams.gender.trim());
    }
  }

  if (relevantParams.age) {
    if (paramContainsSpecialChar(relevantParams.age)) {
      all_user_variables += `ps_a=${relevantParams.age.trim()}&`;
    } else {
      all_user_variables += '{{#user.age}}ps_a={{user.age}}&{{/user.age}}'.replace(/age/g, relevantParams.age.trim());
    }
  }

  if (relevantParams.postCode) {
    if (paramContainsSpecialChar(relevantParams.postCode)) {
      all_user_variables += `ps_pc=${relevantParams.postCode.trim()}&`;
    } else {
      all_user_variables += '{{#user.zip}}ps_pc={{user.zip}}&{{/user.zip}}'.replace(/zip/g, relevantParams.postCode.trim());
    }
  }

  let img_src = `${customDomain}/ndi/${powerspaceId}?${all_user_variables}z=${adPosition}`;
  if (withMobileVersion) img_src += '&mv=1';
  img_tag.src = img_src;
  img_tag.alt = '';

  let img_link = `${customDomain}/ndc/${powerspaceId}?${all_user_variables}z=${adPosition}`;
  if (withMobileVersion) img_link += '&mv=1';
  const a_tag: HTMLAnchorElement = document.createElement('a');
  a_tag.setAttribute('data-disable-tracking', 'true'); // Avoid encoding for external variables
  a_tag.href = img_link;
  a_tag.target = '_blank';
  a_tag.style.textDecoration = 'none';
  a_tag.appendChild(img_tag);


  const responsive = content.innerRenderingProperties.imageWidth?.responsive;

  if (responsive) {
    if (!withMobileVersion) {
      const div_tag: HTMLDivElement = document.createElement('div');
      div_tag.classList.add('hide');
      div_tag.classList.add('hidden');
      div_tag.appendChild(a_tag);
      result.nodes = [div_tag, ...(compileToHtmlNodes(content, articleIndex, adPosition, true, context).nodes)];
    } else {
      const div_tag: HTMLDivElement = document.createElement('div');
      div_tag.classList.add('show');
      div_tag.style.overflow = 'hidden';
      div_tag.style.display = 'none';
      div_tag.style.lineHeight = '0';
      div_tag.style.fontSize = '0';
      div_tag.style.maxHeight = '0';

      div_tag.appendChild(a_tag);

      const msoStartingComment = document.createComment('[if !mso]><!');
      const msoEndingComment = document.createComment('<![endif]');
      result.nodes = [msoStartingComment, div_tag, msoEndingComment];
    }
  } else {
    result.nodes = [a_tag];
  }

  return result;

};
