import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-mediego-no-permissions',
  templateUrl: './no-permissions.component.html',
  styleUrls: ['./no-permissions.component.scss']
})
export class NoPermissionsComponent {
  @HostBinding('class') classes = 'mainContent';

}
