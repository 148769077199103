import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-mediego-snackbar-loading',
  templateUrl: './snackbar-loading.component.html',
  styleUrls: ['./snackbar-loading.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SnackbarLoadingComponent {

}
