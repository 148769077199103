import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, Input }                       from '@angular/core';

import { Observable }                             from 'rxjs';

import { TranslateService }                       from '@ngx-translate/core';

import { AppService }                             from '../../../../services/app.service';

import { LoadingStatus }                          from '../../../../main-module/declarations/loading-status';

@Component({
  selector: 'app-mediego-stat-card',
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss']
})
export class StatCardComponent {

  @Input() cardTitle: string;
  @Input() cardTitlePlural: string;
  @Input() description: string;
  @Input() value: number;
  @Input() valueType: string;
  @Input() subValue: number;
  @Input() subValueType: string;
  @Input() subValueName: string;
  @Input() color: 'accent' | 'warn';
  @Input() displayTrend: boolean;

  get status(): Observable<LoadingStatus> {
    return this.appService.loadingStatus$;
  }

  constructor(
    private appService: AppService,
    private translate: TranslateService
  ) { }

  getTrendPercentage(a: number, b: number): string {
    if (a === 0 || b === 0) return '';
    const ratio = a / b - 1
    const res = new PercentPipe(this.translate.currentLang).transform(ratio, '1.2');
    if (ratio > 0) return `+${res}`;
    else return res;
  }

  formatNumber(value: number, type: string): string {
    switch (type) {
      case 'number':
        return new DecimalPipe(this.translate.currentLang).transform(value, '1.0-0');

      case 'float':
        return new DecimalPipe(this.translate.currentLang).transform(value, '1.0-2');

      case 'percent':
        return new PercentPipe(this.translate.currentLang).transform(value, '1.0-2');

      case 'currency':
        return new CurrencyPipe(this.translate.currentLang).transform(value, 'EUR');

      default:
        return value.toString();
    }
  }

}
