import { Location }                        from '@angular/common';
import { Injectable }                      from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';

import { Actions, createEffect, ofType }                                                 from '@ngrx/effects';
import { ROUTER_NAVIGATED, ROUTER_REQUEST, RouterNavigationAction, RouterRequestAction } from '@ngrx/router-store';
import { Store }                                                                         from '@ngrx/store';

import { of }                                                        from 'rxjs';
import { concatMap, debounceTime, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { EnginesActionTypes, SelectEngineByIdAction, UserSelectedEngineAction } from '../actions/engines.actions';

import { AppState, CustomRouterState }  from '../index';
import { enginesAdapter, EnginesState } from '../reducers/engines.reducer';
import { selectEnginesState }           from '../selectors';

const {
  // selectIds,
  // selectEntities,
  // selectAll,
  selectTotal
} = enginesAdapter.getSelectors();

@Injectable()
export class EnginesEffects {

  navigationIncludingEngineId$ = createEffect(() =>
    this.actions.pipe(
      ofType(ROUTER_REQUEST),
      map((__: RouterRequestAction<CustomRouterState>) =>
        this.router.parseUrl(__.payload.event.url)
      ),
      filter((__: UrlTree) =>
        __.queryParams.hasOwnProperty('engineId')
      ),
      map((__: UrlTree) => +__.queryParams['engineId']), concatMap(action => of(action).pipe(
        withLatestFrom(this.store.select(selectEnginesState)))),
      filter((data: [number, EnginesState]) => {
        const engineId = data[0];
        const enginesState = data[1];
        return selectTotal(enginesState) > 0 && enginesState.selectedEngineId !== engineId;
      }),
      // tap((data) => console.debug('selecting engine from URL', data[0])),
      map((data: [number, EnginesState]) =>
        new SelectEngineByIdAction({ engineId: data[0] })
      )
    ));

  navigationMissingEngineId$ = createEffect(() =>
    this.actions.pipe(
      ofType(ROUTER_NAVIGATED),
      debounceTime(1000), // not in a hurry, and don't want to handle intermediate redirects
      filter((__: RouterNavigationAction<CustomRouterState>) =>
        !__.payload.routerState.queryParams.hasOwnProperty('engineId')
      ), concatMap(action => of(action).pipe(
        withLatestFrom(this.store.select(selectEnginesState)))),
      filter((__: [RouterNavigationAction<CustomRouterState>, EnginesState]) => __[1].selectedEngineId !== null),
      tap((latest: [RouterNavigationAction<CustomRouterState>, EnginesState]) => {
      // console.debug('adding engineId to url', latest[1].selectedEngineId);

        this.location.replaceState(
          this.router
            .createUrlTree([], {
              queryParams: {
                engineId: latest[1].selectedEngineId
              },
              queryParamsHandling: 'merge',
              relativeTo: this.route
            }).toString()
        );

      })
    ),
  { dispatch: false }
  );

  userSelectedEngine$ = createEffect(() =>
    this.actions.pipe(
      ofType(EnginesActionTypes.USER_SELECTED_ENGINE),
      tap((action: UserSelectedEngineAction) => {
      // console.debug('user chose engine', action.payload.engine.id);
        this.router.navigate(['/'], {
          queryParams: { engineId: action.payload.engine.id },
          queryParamsHandling: ''
        });
      })
    ),
  { dispatch: false }
  );

  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { }

}
