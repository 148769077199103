import { Inject, Injectable, Optional }                 from '@angular/core';

import { DateAdapter, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';

import dayjs, { Dayjs }                                 from 'dayjs';

const WEEK_STARTS_ON = 1; // 0 sunday, 1 monday...

export const MAT_DAYJS_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'L'
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Injectable()
export class CustomDateAdapter extends DateAdapter<Dayjs> {

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super();
  }

  addCalendarDays(date: Dayjs, days: number): Dayjs {
    return date.add(days, 'day');
  }

  addCalendarMonths(date: Dayjs, months: number): Dayjs {
    return date.add(months, 'month');
  }

  addCalendarYears(date: Dayjs, years: number): Dayjs {
    return date.add(years, 'year');
  }

  clone(date: Dayjs): Dayjs {
    return date.clone();
  }

  createDate(year: number, month: number, date: number): Dayjs {
    return dayjs(new Date(year, month, date));
  }

  format(date: Dayjs, displayFormat: string): string {
    // console.log(date);
    return date.format(displayFormat);
  }

  getDate(date: Dayjs): number {
    return date.date();
  }

  getDateNames(): string[] {
    return Array(31)
      .fill(null)
      .map((__, i) => String(i + 1));
  }

  getDayOfWeek(date: Dayjs): number {
    return date.day();
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const formats = {
      long: 'dddd',
      short: 'ddd',
      narrow: 'dd'
    };

    const formatStr = formats[style];

    return Array(7)
      .fill(null)
      .map((__, i) =>
        dayjs().set('day', i).format(formatStr)
      );
  }

  getFirstDayOfWeek(): number {
    return WEEK_STARTS_ON;
  }

  getMonth(date: Dayjs): number {
    return date.month();
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const formats = {
      long: 'MMMM',
      short: 'MMM',
      narrow: 'MM'
    };

    const formatStr = formats[style];

    return Array(12)
      .fill(null)
      .map((__, i) =>
        dayjs().set('month', i).format(formatStr)
      );
  }

  getNumDaysInMonth(date: Dayjs): number {
    return date.daysInMonth();
  }

  getYear(date: Dayjs): number {
    return date.year();
  }

  getYearName(date: Dayjs): string {
    return date.format('YYYY');
  }

  invalid(): Dayjs {
    return dayjs(new Date(NaN));
  }

  isDateInstance(obj: any): boolean {
    return dayjs.isDayjs(obj);
  }

  isValid(date: Dayjs): boolean {
    return date.isValid();
  }

  parse(value: any, parseFormat: any): Dayjs | null {
    return dayjs(value, parseFormat);
  }

  toIso8601(date: Dayjs): string {
    return date.toISOString();
  }

  today(): Dayjs {
    return dayjs();
  }

}
