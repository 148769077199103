import { AtLeastNItems, AtMostNItems, AtMostNItemsInSameCategory } from './constraints/global-constraints';
import {
  HasCategoriesConstraint,
  HasMetadataConstraint,
  HasMetadataConstraintForItemset,
  HasSourcesConstraintForItemset,
  MetadataInsecableSpaceConstraint,
  MetadataValueConstraint,
  PublicationDateConstraint,
  PublicationDateConstraintForItemset,
  URLValueConstraint
} from './constraints/item-constraints';
import {
  AlreadyRecommendedConstraint,
  CategoryInUserPreferencesConstraint,
  MandatoryArticleConstraint
} from './constraints/local-constraints';

// FOR CREATING A NEW CONSTRAINT :
// Create class (constraints/*****-constraints.ts)
// Add it to Constraint Factory (constraints.ts)
// Register it for display in select (constraints.ts)
// Implement serialize/unserialize logic (constraints-serdes.ts)
// If constraint has custom parameters, set it in populateForm (constraint/constraint.component.ts)
// Develop the matching interface (constraint/constraint.component.html)
// If not custom (ie string, boolean, number),
// it will be automatically generated
// Augment constraintToText() (constraint/constraint.component.ts) for pretty display
// Alter also validNewConstraint to bind UX with model (for front) (constraint/constraint.component.ts)

// For an alias example, consider MetadataInsecableSpaceConstraint

export interface Constraint {
  type: string;
  disabled: boolean;
  configurable: boolean;
  mandatory?: boolean;
  supportsMultiple?: boolean;
  supportsNegation: boolean;
  negated: boolean;
  priority: number;
  parameters: ConstraintParameter[] | any;
  parametersInline?: boolean;
  description?: boolean;
}

export interface ConstraintParameter {
  name: string;
  type: 'string' | 'boolean' | 'number' | 'toggle' | 'select' | 'custom';
  description?: boolean;
  disabled?: boolean;
  required?: boolean;
  hint?: boolean;
  value: any;
  choices?: any[];
  viewChoices?: any[];
  viewChoicesNegated?: any[];
}

export class UnknownConstraint implements Constraint {
  static type = 'unknown';
  static disabled = true;
  type = 'unknown';
  disabled = true;
  supportsNegation = false;
  negated = false;
  configurable = false;
  priority = 5;
  description = true;
  parameters = {
    'unknown': {
      type: 'custom',
      description: true,
      value: ''
    }
  };
}

// PATTERN FACTORY
// Instanciation of Constraints is centralized
export class ConstraintFactory {
  static buildFromType = (type: string, parameterValues?: { [key: string]: any }, negated?: boolean) => {
    switch (type) {
      case HasCategoriesConstraint.type:
        return new HasCategoriesConstraint();

      case AlreadyRecommendedConstraint.type:
        return new AlreadyRecommendedConstraint();

      case MetadataValueConstraint.type:
        // Alias
        if (parameterValues && parameterValues.withName) {
          return new MetadataInsecableSpaceConstraint();
        } else {
          return new MetadataValueConstraint();
        }

      case MetadataInsecableSpaceConstraint.type:
        return new MetadataInsecableSpaceConstraint();

      case HasMetadataConstraint.type:
        return new HasMetadataConstraint();

      case HasMetadataConstraintForItemset.type:
        return new HasMetadataConstraintForItemset();

      case PublicationDateConstraint.type:
        return new PublicationDateConstraint();

      case PublicationDateConstraintForItemset.type:
        return new PublicationDateConstraintForItemset();

      case HasSourcesConstraintForItemset.type:
        return new HasSourcesConstraintForItemset();

      case CategoryInUserPreferencesConstraint.type:
        return new CategoryInUserPreferencesConstraint();

      case MandatoryArticleConstraint.type:
        return new MandatoryArticleConstraint();

      case AtMostNItemsInSameCategory.type:
        return new AtMostNItemsInSameCategory();

      case AtLeastNItems.type:
        return new AtLeastNItems();

      case AtMostNItems.type:
        return new AtMostNItems();

      case URLValueConstraint.type:
        return new URLValueConstraint();

      default:
        return new UnknownConstraint();
    }
  }
}

export const CONSTRAINTS_ITEMSET = [
  URLValueConstraint,
  HasCategoriesConstraint,
  MetadataValueConstraint,
  HasMetadataConstraint,
  MetadataInsecableSpaceConstraint // alias for MetadataValue
];

export const CONSTRAINTS_ITEMSET_ONLY = [
  URLValueConstraint,
  PublicationDateConstraintForItemset,
  HasSourcesConstraintForItemset,
  HasCategoriesConstraint,
  MetadataValueConstraint,
  MetadataInsecableSpaceConstraint,
  HasMetadataConstraintForItemset
];


export const CONSTRAINTS_LOCAL = [
  ...CONSTRAINTS_ITEMSET,
  PublicationDateConstraint,
  AlreadyRecommendedConstraint,
  CategoryInUserPreferencesConstraint
];


export const CONSTRAINTS_GLOBAL = [
  AtMostNItemsInSameCategory,
  AtLeastNItems,
  AtMostNItems
];
