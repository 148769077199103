import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { AppState } from '../../main-module/state';
import { selectContactsList } from '../../main-module/state/selectors/crm-selectors';

import { ContactList } from '../../configuration-module/providers/mailers.provider';
import { Contact, ContactsProvider } from '../providers/contacts.provider';


@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  get selectedContactsList$(): Observable<ContactList> {
    return this.store.select(selectContactsList);
  }

  constructor(
    private store: Store<AppState>,
    private contactsProvider: ContactsProvider
  ) { }

  subscribeManyContactsFromLists(engineId: number, contacts: Contact[], lists: ContactList[]) {
    const contactsWithLists = contacts.map((contact) => {
      const artificialConsent = { timestamp: Date.now() };
      lists.forEach(({ id }) => { contact.listsWithConsents[id] = artificialConsent });
      return contact;
    });

    return this.contactsProvider.createOrUpdateManyContacts(engineId, contactsWithLists);
  }

  unsubscribeManyContactsFromLists(engineId: number, contacts: Contact[], lists: ContactList[]) {
    const contactsWithoutLists = contacts.map((contact) => {
      lists.forEach(({ id }) => { delete contact.listsWithConsents[id] });
      return contact;
    });

    return this.contactsProvider.createOrUpdateManyContacts(engineId, contactsWithoutLists);
  }

}
