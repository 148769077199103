import { createSelector } from '@ngrx/store';

import { NewslettersConfigState } from '../../declarations/newsletters-config';

import { ConfigurationState }  from '../index';

import { selectConfigurationState } from './';

export const selectNewslettersConfig = createSelector(
  selectConfigurationState,
  (state: ConfigurationState) => state && state.newslettersConfig
);

export const selectNewslettersColorset = createSelector(
  selectNewslettersConfig,
  (state: NewslettersConfigState) => (state && state.colorset) || []
);

export const selectNewslettersFonts = createSelector(
  selectNewslettersConfig,
  (state: NewslettersConfigState) => (state && state.fonts) || []
);

export const selectNewslettersPrimaryColor = createSelector(
  selectNewslettersConfig,
  (state: NewslettersConfigState) => state && state.primaryColor
);

export const selectNewslettersSecondaryColor = createSelector(
  selectNewslettersConfig,
  (state: NewslettersConfigState) => state && state.secondaryColor
);
