import { Pipe, PipeTransform } from '@angular/core';

import { Engine } from '../../main-module/declarations/engine';

@Pipe({
  name: 'engine'
})
export class EnginePipe implements PipeTransform {

  transform(engineId: number, engines: Engine[]): any {
    return engines.find(e => e.id === engineId)?.displayName;
  }

}
