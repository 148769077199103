import { Injectable }                                                                from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';

import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppState } from '../state';
import { selectSelectedEngine } from '../state/selectors/engines-selectors';

import { Engine } from '../declarations/engine';

@Injectable()
export class HasWebSources implements CanActivate {

  constructor(private router: Router, private store: Store<AppState>) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const engine: Engine = await firstValueFrom(this.store.select(selectSelectedEngine));

    if (engine && engine.sources.web_all.length) {
      return true;
    } else {
      return this.router.parseUrl('/');
    }
  }

}


@Injectable()
export class HasNewsletterSources implements CanActivate {

  constructor(private router: Router, private store: Store<AppState>) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const engine$ = this.store.select(selectSelectedEngine).pipe(filter((__) => !!__));
    const engine: Engine = await firstValueFrom(engine$);

    if (engine?.sources?.newsletters_all) {
      return true;
    } else {
      return this.router.parseUrl('/');
    }
  }

}
