import { createSelector }  from '@ngrx/store';

import { selectAuthState } from './index';

export const selectIsLoggedIn = createSelector(
  selectAuthState,
  (state) => state.isLoggedIn
);

export const selectEmail = createSelector(
  selectAuthState,
  (state) => state.email
);

export const select__IsAdmin = createSelector(
  selectAuthState,
  (state) => state.__isAdmin
);

export const selectIsAdmin = createSelector(
  selectAuthState,
  (state) => state.isAdmin
);

export const selectIsDemoUser = createSelector(
  selectAuthState,
  (state) => state.isDemoUser
);
