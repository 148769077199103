<mat-card [ngSwitch]="status$ | async">
  <mat-card-content *ngSwitchCase="'ready'">
    <mat-tab-group>
      <mat-tab *ngFor="let tab of tabs" [label]="tab.tabTitle | translate">
        <ng-template matTabContent>
          <div class="no-data-placeholder" fxFill fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" *ngIf="!tab.tabData.length">
            <mat-icon>notification_important</mat-icon>
            <h2>{{ 'SHARED.STATISTICS.TOP_ITEMS.NO_DATA_ERROR' | translate }}</h2>
          </div>
          <cdk-virtual-scroll-viewport *ngIf="tab.tabData.length" itemSize="170" class="scroll-viewport">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" *cdkVirtualFor="let item of tab.tabData" class="scroll-item">
              <div fxFlex="0 0 200px" class="article-image" *ngIf="data.hasImages" >
                <a [href]="item.url.startsWith('http') ? item.url : 'http://' + item.url" target="_blank">
                  <img src="{{ item.image }}" [alt]="item.title">
                </a>
              </div>
              <div fxFlex="auto" class="article-title">
                <a [href]="item.url.startsWith('http') ? item.url : 'http://' + item.url" target="_blank">
                  {{ item.title }}
                </a>
              </div>
              <div fxFlex.xs="0 0 75px" fxFlex="0 0 150px" class="article-clicks">{{ item.nbOfClicks | number:'1.0-0':(locale$ | async) }} clics</div>
              <div fxFlex.xs="0 0 75px" fxFlex="0 0 150px" class="article-displays">{{ item.nbOfDisplays | number:'1.0-0':(locale$ | async) }} affich.</div>
              <div fxFlex.xs="0 0 30px" fxFlex="0 0 60px" class="article-click-rate">{{ item.clickRate | percent:'1.2-2' }}</div>
            </div>
          </cdk-virtual-scroll-viewport>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>

  <mat-card-content *ngSwitchCase="'loading'">
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="50px" preserveAspectRatio="xMidYMid slice">
            <rect x="0" y="10" width="100" height="30" fill="#e5ebee"></rect>
          </svg>
        </ng-template>

        <div class="scroll-viewport loading">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" *ngFor="let i of 2 | times" class="scroll-item">
            <div fxFlex="0 0 200px" class="article-image">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="xMidYMid slice">
                <rect x="10" y="10" width="180" height="130" fill="#e5ebee"></rect>
              </svg>
            </div>
            <div fxFlex="auto" class="article-title">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="50px" preserveAspectRatio="xMidYMid slice">
                <rect x="0" y="10" width="100%" height="30" fill="#e5ebee"></rect>
              </svg>
            </div>
            <div fxFlex.xs="0 0 75px" fxFlex="0 0 150px" class="article-clicks">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="50px" preserveAspectRatio="xMidYMid slice">
                <rect x="0" y="10" width="100%" height="30" fill="#e5ebee"></rect>
              </svg>
            </div>
            <div fxFlex.xs="0 0 75px" fxFlex="0 0 150px" class="article-displays">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="50px" preserveAspectRatio="xMidYMid slice">
                <rect x="0" y="10" width="100%" height="30" fill="#e5ebee"></rect>
              </svg>
            </div>
            <div fxFlex.xs="0 0 30px" fxFlex="0 0 60px" class="article-click-rate">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="50px" preserveAspectRatio="xMidYMid slice">
                <rect x="0" y="10" width="100%" height="30" fill="#e5ebee"></rect>
              </svg>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab [disabled]="true">
        <ng-template mat-tab-label>
          <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="50px" preserveAspectRatio="xMidYMid slice">
            <rect x="0" y="10" width="100" height="30" fill="#e5ebee"></rect>
          </svg>
        </ng-template>
      </mat-tab>
      <mat-tab [disabled]="true">
        <ng-template mat-tab-label>
          <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="50px" preserveAspectRatio="xMidYMid slice">
            <rect x="0" y="10" width="100" height="30" fill="#e5ebee"></rect>
          </svg>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>

  <mat-card-content *ngSwitchCase="'error'">
    <div id="errorContainer" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon>error</mat-icon>
    </div>
  </mat-card-content>
</mat-card>
