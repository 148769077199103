<ng-template #addEngineDialogTemplateRef>
  <app-mediego-confirmation-dialog [dialogTitle]="'MAIN.APP.DIALOGS.ADD_ENGINE.TITLE' | translate"
                                   icon="add_circle_outline"
                                   [acceptLabel]="'SHARED.BUTTONS.ADD' | translate"
                                   [declineLabel]="'SHARED.BUTTONS.CANCEL' | translate"
                                   [resultData]="{ name: nameInput.value, domainName: domainInput.value }">
    <mat-form-field appearance="outline" mat-dialog-content>
      <mat-label>{{ 'MAIN.APP.DIALOGS.ADD_ENGINE.INPUT.NAME' | translate }}</mat-label>
      <input matInput type="text" #nameInput>
    </mat-form-field>
    <mat-form-field appearance="outline" mat-dialog-content>
      <mat-label>{{ 'MAIN.APP.DIALOGS.ADD_ENGINE.INPUT.DOMAIN' | translate }}</mat-label>
      <input matInput type="text" #domainInput>
    </mat-form-field>
  </app-mediego-confirmation-dialog>
</ng-template>


<mat-sidenav-container>
  <mat-sidenav id="main-sidenav" #sidenav mode="over">

    <div id="sideNavHeader" fxFlex="0 0 100%" fxLayoutAlign="center center">
      <div class="logo" fxFlex="0 0 150px" fxLayout="row">
        <a href="/" fxFlex="0 0 100%" fxLayoutAlign="center center"><img src="/assets/img/logo/logo-full-color.svg" fxFlex="0 0 100%"></a>
      </div>
    </div>

    <div id="view-mode-section" [fxFlex]="'0 0 100%'" *ngIf="authService.__isAdmin$ | async" [fxLayoutAlign]="'center'">
      <mat-slide-toggle color="accent" labelPosition="before" [checked]="authService.isAdmin$ | async" (change)="toggleAdminDisplay($event.checked)">
        {{ 'MAIN.APP.SIDEBAR.ADMIN_VIEW' | translate }}
      </mat-slide-toggle>
    </div>

    <div id="adminEngineSection" [fxFlex]="'0 0 100%'" *ngIf="(authService.isAdmin$ | async) && (engines$ | async).length"
         matAutocompleteOrigin #origin="matAutocompleteOrigin">
      <mat-form-field appearance="outline" id="engineSearch" [fxFlex]="'0 0 calc(100% - 40px - 20px)'" subscriptSizing="dynamic">
        <mat-label>{{ 'MAIN.APP.SIDEBAR.DOMAIN_NAME_INPUT' | translate }}</mat-label>
        <input matInput tabindex="-1" onClick="this.select();" name="engineChoice"
               [matAutocomplete]="domainAutoComplete" [matAutocompleteConnectedTo]="origin"
               [formControl]="selectedEngineFormControl">
        <mat-hint *ngIf="templaterService.modified$$.value">
          {{ 'MAIN.APP.SIDEBAR.DOMAIN_NAME_INPUT.DISABLED' | translate }}
        </mat-hint>
      </mat-form-field>
      <mat-autocomplete #domainAutoComplete="matAutocomplete" [displayWith]="getEngineName"
                        (optionSelected)="changeSelectedEngine($event.option.value);">
        <mat-option *ngFor="let engine of filteredEngines$ | async" [value]="engine">
          {{ engine.displayName || engine.id }}
        </mat-option>
      </mat-autocomplete>

      <button mat-icon-button color="primary" tabindex="-1" id="addEngineButton" (click)="openAddEngineDialog()" >
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div id="user-engine-section" [fxFlex]="'0 0 100%'" *ngIf="(authService.isAdmin$ | async) === false && (engines$ | async)?.length > 0">
      <mat-form-field appearance="outline" id="engineSelect" [fxFlex]="'0 0 100%'">
        <mat-label>{{ 'MAIN.APP.SIDEBAR.DOMAIN_NAME_INPUT' | translate }}</mat-label>
        <mat-select tabindex="-1" [formControl]="selectedEngineFormControl" (selectionChange)="changeSelectedEngine($event.value)">
          <mat-option *ngFor="let engine of engines$ | async" [value]="engine">{{ engine.displayName || engine.id }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-mediego-sidenav-tabs [fxFlex]="'0 0 100%'" (shouldCloseSidebar)="closeSidenavIfApplicable()">
    </app-mediego-sidenav-tabs>

  </mat-sidenav>

  <mat-sidenav-content fxLayout="row wrap" fxLayoutAlign="center start">
    <app-mediego-toolbar (menuButtonClick)="sidenav.toggle()" fxLayout="column"></app-mediego-toolbar>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-mediego-backboard></app-mediego-backboard>
