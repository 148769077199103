import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';


@Component({
  selector: 'app-mediego-button-input',
  templateUrl: './button-input.component.html',
  styleUrls: ['./button-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonInputComponent {

  @Input() icon: string;
  @Input() buttonTooltip: string;
  @Input() inputLabel: string;

  showInput: boolean = false;
  inputValue: string = '';

  @Output() valueSubmit: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _cdr: ChangeDetectorRef) {
  }

  reset() {
    this.showInput = false;
    this.inputValue = '';
    this._cdr.markForCheck();
  }

  submit() {
    this.valueSubmit.emit(this.inputValue);
    this.reset();
  }

}
