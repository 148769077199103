import { HttpClient } from '@angular/common/http';
import { Injectable }             from '@angular/core';

import { firstValueFrom } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment'
import { v4 as uuidv4 } from 'uuid';

import { INITIAL_STATE as defaultConfiguration } from '../state/reducers/newsletters-config.reducer';

import { NewslettersConfig } from '../declarations/newsletters-config';

@Injectable({
  providedIn: 'root'
})
export class NewslettersConfigurationProvider {

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  getConfigurationForEngine(engineId: number): Promise<NewslettersConfig> {
    const config$ = this.http.get<any>(`${environment.apiUrl2}/2.0/engines/${engineId}/newsletters-configuration`);
    return firstValueFrom(config$);
  }

  createOrUpdateConfigurationForEngine(engineId: number, configuration: NewslettersConfig): Promise<string> {
    const config$ = this.http.put(`${environment.apiUrl2}/2.0/engines/${engineId}/newsletters-configuration`, {
      ...configuration
    }, {
      responseType: 'text'
    });
    return firstValueFrom(config$);
  }

  getOrInitConfigurationForEngine(engineId: number): Promise<NewslettersConfig> {
    return this.getConfigurationForEngine(engineId).then((config) => {
      // existing config found
      return config;
    }).catch((err) => {

      if (err.status !== 400) {
        const errorWhileFetching = new Error(this.translate.instant('CONFIGURATION.NEWSLETTERS.ERROR.FETCHING_CONFIG'));
        errorWhileFetching.name = 'while fetching';
        throw errorWhileFetching;
      }

      // if error 400, normal flow, initializing config

      const id = uuidv4();
      const configuration: NewslettersConfig = {
        id,
        engineId,
        colorset: [...defaultConfiguration.colorset],
        primaryColor: defaultConfiguration.primaryColor,
        secondaryColor: defaultConfiguration.secondaryColor,
        fonts: [...defaultConfiguration.fonts]
      };

      return this.createOrUpdateConfigurationForEngine(engineId, configuration).then(() => {
        return configuration;
      }).catch((err) => {

        if (err.name === 'while fetching') {
          // passing error
          throw err;
        } else {
          // error when creating (should not happen unless server down)
          const errorWhileCreating = new Error(this.translate.instant('CONFIGURATION.NEWSLETTERS.ERROR.CREATING_CONFIG'));
          errorWhileCreating.name = 'while creating';
          throw errorWhileCreating;
        }
      })
    });
  }

}
