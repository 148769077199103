import { registerLocaleData } from '@angular/common';
import localeFR               from '@angular/common/locales/fr';

import dayjs                  from 'dayjs';

import 'dayjs/locale/fr';

export const LANGUAGE_STORAGE_KEY = 'mediego_dashboard_lang';

export enum AVAILABLE_LANGUAGES {
  en = 'en',
  fr = 'fr'
}

export const LANGUAGE_DISPLAY_NAMES = {
  en: 'English',
  fr: 'Français'
};

export type Language = keyof typeof AVAILABLE_LANGUAGES;

export const setLocale = (lang: Language): void => {
  // DayJS
  dayjs.locale(lang);

  // Angular
  switch (lang) {
    case 'en':
      break; // angular already registers en-US
    case 'fr':
      registerLocaleData(localeFR);
      break;
  }
};
