import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';

import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';

@Component({
  selector: 'app-mediego-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  @Input() danger: boolean = false;
  @Input() dangerCountdown: number = 0;
  @Input() dialogTitle = 'Confirmation';
  @Input() icon: string;
  @Input() acceptLabel: string = 'CONFIRMER';
  @Input() declineLabel: string = 'ANNULER';
  @Input() deleteLabel?: string;
  @Input() formValidation: boolean = true;
  @Input() resultData: any;
  @Input() enableEnterKeyValidation: 'button' | 'submit' | 'reset' = 'submit';

  @ViewChild('submitButton', { static: true }) submitButton: MatButton;

  ngOnInit() {
    if (!this.icon) {
      this.icon = this.danger ? 'warning' : 'info';
    }

    if (this.dangerCountdown > 0) {
      const countdownDecrease = setInterval(() => {
        this.dangerCountdown--;
        if (!this.dangerCountdown) clearInterval(countdownDecrease)
      }, 1000);
    }
  }

  @HostListener('keydown', ['$event']) onClick(e) {
    if (e.which === 13) {
      // ease of form validation => ENTER key triggers validation
      if (this.submitButton && this.submitButton._elementRef && this.submitButton._elementRef.nativeElement) {
        this.submitButton._elementRef.nativeElement.click();
      }
    }
  }

}
