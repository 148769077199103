import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-mediego-pretty-id',
  templateUrl: './pretty-id.component.html',
  styleUrls: ['./pretty-id.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrettyIdComponent implements OnChanges {

  @Input() id: string = '';
  @Input() bold: boolean = true;
  @Input() maxLength: number = 10;
  @Input() allowCopy: boolean = true;

  prettyId: string = '';

  constructor(private snackbar: MatSnackBar) {}

  private isAutoGeneratedId(id: string): boolean {
    return id?.length > this.maxLength && (
      /^[A-Za-z0-9]+$/.test(id)
      || /^[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}$/.test(id));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.id) {
      if (this.isAutoGeneratedId(changes.id.currentValue)) {
        this.prettyId = changes.id.currentValue.slice(0, this.maxLength);
      } else {
        this.prettyId = changes.id.currentValue;
      }
    }
  }

  async copyId(e) {
    e.stopPropagation();
    e.preventDefault();

    const permission = await navigator.permissions.query({ name: 'clipboard-write' as PermissionName });
    if (permission.state === 'granted' || permission.state === 'prompt') {

      await navigator.clipboard.writeText(this.id);

      this.snackbar.open(
        'ID copié dans le presse-papier',
        null,
        { duration: 1000, panelClass: ['mediego-snack-bar', 'accent'] }
      );
    }
  }


}
