<ng-container *ngFor="let constraint of existingConstraints | orderByKey: 'priority' : 'asc'; let k = index">

  <ng-container *ngIf="readonly; else interactiveConstraint">
    <app-mediego-constraint-readonly *ngIf="constraint.type !== 'unknown' || (isAdmin$ | async)"
                                      [constraint]="constraint">
    </app-mediego-constraint-readonly>
  </ng-container>

  <ng-template #interactiveConstraint>
    <app-mediego-constraint *ngIf="constraint.type !== 'unknown' || (isAdmin$ | async)"
                            (removeConstraint)="removeConstraintHandler(k)"
                            (updateConstraint)="updateConstraintHandler($event, k)"
                            [existingConstraint]="constraint" [index]="k"
                            [constraintTypes]="constraintTypes">
    </app-mediego-constraint>
  </ng-template>
</ng-container>

<p class="no-constraints" *ngIf="existingConstraints && existingConstraints.length === 0">
  {{ 'COMMON.CONSTRAINTS.EMPTY' | translate }}
</p>

<p *ngIf="unknownConstraints && (isAdmin$ | async) === false">
  {{ unknownConstraints }} {{ (unknownConstraints > 1 ? 'COMMON.CONSTRAINTS.UNKNOWN_NOT_DISPLAYED.PLURAL' : 'COMMON.CONSTRAINTS.UNKNOWN_NOT_DISPLAYED') | translate }}
</p>

<!-- CREATE NEW CONSTRAINT -->
<app-mediego-constraint *ngIf="readonly === false" (addConstraint)="addConstraintHandler($event)"
                        [constraintsFor]="constraintsFor"
                        [constraintTypes]="constraintTypes">
</app-mediego-constraint>

<button *ngIf="(isAdmin$ | async) && !readonly" class="debug-btn" mat-flat-button color="primary" (click)="logBack()">
  Logger le modèle généré pour l'API <mat-icon>bug_report</mat-icon>
</button>

