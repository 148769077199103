<!--Confirm delete -->
<ng-template #deleteDialogTemplateRef>
  <app-mediego-confirmation-dialog [acceptLabel]="'SHARED.BUTTONS.DELETE' | translate"
                                   [declineLabel]="'SHARED.BUTTONS.CANCEL' | translate"
                                   [danger]="true" [dangerCountdown]="5">
    <p [innerHTML]="'COMMON.SOURCE_SETUP.CONFIRM_REMOVE' | translate:{ name: displayName$ | async }"></p>
  </app-mediego-confirmation-dialog>
</ng-template>

<form *ngIf="source && form" fxFlex="100" [formGroup]="form" autocomplete="off">

  <div class="row row-newsletter-name" fxLayoutAlign="center">
    <div class="section" fxFlex="1 1 100%">
      <app-mediego-section-title [title]="'COMMON.SOURCE_SETUP.TITLE' | translate:{ name: displayName$ | async }"
                                 icon="settings" iconColor="primary">
      </app-mediego-section-title>


      <div class="section-content-row">
        <mat-card fxFlex="1 1 100%" fxFlex.gt-sm="1 1 85%" fxFlex.gt-md="1 1 70%">
          <mat-card-content>
            <div fxLayout="row wrap" fxLayoutAlign="space-between baseline">
              <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 10px)">
                <mat-label>{{ 'COMMON.SOURCE_SETUP.NAME' | translate }}</mat-label>
                <input matInput type="text" formControlName="displayName">
              </mat-form-field>
            </div>

            <hr/>

            <h3>{{ 'COMMON.SOURCE_SETUP.API_REQUEST.TITLE' | translate }}</h3>
            <p>{{ 'COMMON.SOURCE_SETUP.API_REQUEST.HELP' | translate }}</p>
            <pre><code [highlight]="apiURL$ | async" [languages]="['xml']"></code></pre>

            <button mat-flat-button color="accent"
                    *ngIf="isAdmin$ | async"
                    (click)="copyDebugRecosUrl($event)"
                    class="copy-reco-url-btn"
                    matTooltip="Copier l'URL">
              <mat-icon>content_paste</mat-icon>
            </button>
            <p *ngIf="isNewsletter$ | async" [innerHTML]="'COMMON.SOURCE_SETUP.API_REQUEST.HELP_CAMPAIGN' | translate:{ date: campaignParameterExample }"></p>


          </mat-card-content>
        </mat-card>
      </div>


    </div>
  </div>

  <div class="row row-link-params" fxLayoutAlign="center">
    <div class="section" fxFlex="1 1 100%">
      <app-mediego-section-title [title]="'COMMON.SOURCE_SETUP.LINK_PARAMS.TITLE' | translate"
                                 icon="public" iconColor="primary">
      </app-mediego-section-title>

      <div class="section-content-row">
        <mat-card fxFlex="1 1 100%" fxFlex.gt-sm="1 1 85%" fxFlex.gt-md="1 1 70%">
          <mat-card-content>
            <div class="row-link-params--checkbox" fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px">
              <mat-checkbox formControlName="trackClicks" fxFlex="1 1 100%">
                {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.ENABLE_MEDIEGO_TRACKING' | translate }}
              </mat-checkbox>
              <mat-checkbox formControlName="openLinksInNewTab" fxFlex="1 1 100%">
                {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.OPEN_LINKS_IN_NEW_TAB' | translate }}
              </mat-checkbox>
              <mat-checkbox formControlName="forceProtocol" fxFlex="1 1 100%">
                {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.FORCE_PROTOCOL' | translate }}
              </mat-checkbox>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between baseline">
              <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(33% - 13.33px)" *ngIf="form.get('forceProtocol')?.value === true">
                <mat-label>{{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.CHOOSE_PROTOCOL' | translate }}</mat-label>
                <mat-select formControlName="useHttps">
                  <mat-option value="https">https</mat-option>
                  <mat-option value="http">http</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px">
              <mat-checkbox formControlName="useSpecificAnchor" fxFlex="1 1 100%">
                {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.ANCHOR' | translate }}
              </mat-checkbox>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between baseline">
              <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlexOffset.gt-xs="15%" fxFlex.gt-xs="0 0 70%" *ngIf="form.get('useSpecificAnchor')?.value === true">
                <mat-label>{{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.ANCHOR.VALUE' | translate }}</mat-label>
                <input matInput formControlName="anchor">
              </mat-form-field>
            </div>

            <hr/>

            <h3 class="tracking-params-title">{{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.TRACKING_PARAMS_TITLE' | translate }}</h3>



            <div *ngIf="trackParams.length; else noTrackParams" fxLayout="row wrap" fxLayoutAlign="space-between center">
              <table mat-table fxFlex="1 1 100%" class="table-tracking-params" [dataSource]="trackParamsDataSource">

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? toggleAllTrackParam() : null"
                                  [checked]="trackParamSelection.hasValue() && allTrackParamSelected"
                                  [indeterminate]="trackParamSelection.hasValue() && !allTrackParamSelected">
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let param">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? trackParamSelection.toggle(param) : null"
                                  [checked]="trackParamSelection.isSelected(param)">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="paramName">
                  <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.NAME' | translate }}</th>
                  <td mat-cell *matCellDef="let param">
                    <mat-form-field *ngIf="getTrackParamNameControl(param.name) as nameControl" class="table-tracking-params--name" appearance="outline">
                      <input matInput [formControl]="nameControl" spellcheck="false">
                      <button (click)="restoreParamName(param.name)" matSuffix mat-icon-button color="accent" type="button"
                              aria-label="revert" [matTooltip]="'COMMON.WORDS.REVERT' | translate" class="revert-change">
                        <mat-icon>update</mat-icon>
                      </button>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="paramValue">
                  <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.VALUE' | translate }}</th>
                  <td mat-cell *matCellDef="let param">
                    <mat-form-field *ngIf="getTrackParamValueControl(param.name) as valueControl" class="table-tracking-params--value" appearance="outline">
                      <input matInput [formControl]="valueControl" spellcheck="false">
                      <button (click)="restoreParamValue(param.name)" matSuffix mat-icon-button color="accent" type="button"
                              aria-label="revert" [matTooltip]="'COMMON.WORDS.REVERT' | translate" class="revert-change">
                        <mat-icon>update</mat-icon>
                      </button>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="paramEncoded">
                  <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.ENCODED' | translate }}</th>
                  <td mat-cell *matCellDef="let param">
                    <mat-slide-toggle *ngIf="getTrackParamEncodedControl(param.name) as encodedControl"
                                      [formControl]="encodedControl">
                    </mat-slide-toggle>
                  </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef style="width: 90px;">{{ 'COMMON.ENTITY.ACTIONS' | translate }}</th>
                  <td mat-cell *matCellDef="let param" style="width: 90px;">
                    <!--<button mat-icon-button color="accent" (click)="copyTrackParam(param.name)"
                            [matTooltip]="'COMMON.SOURCE_SETUP.LINK_PARAMS.ACTIONS.COPY' | translate"
                            [disabled]="!param?.value || !param?.name">
                      <mat-icon>content_copy</mat-icon>
                    </button>-->
                    <button mat-icon-button color="warn" (click)="removeTrackParam(param)"
                            [matTooltip]="'COMMON.SOURCE_SETUP.LINK_PARAMS.ACTIONS.DELETE' | translate">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="trackParamsColumns"></tr>
                <tr mat-row *matRowDef="let param; columns: trackParamsColumns;" [class.changed]="getParamChanged(param.name)"></tr>
              </table>
            </div>

            <ng-template #noTrackParams>
              <p class="no-tracking-params">{{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.NO_TRACKING_PARAMS' | translate }}</p>
              <hr class="half">
            </ng-template>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">

              <a href="https://docs.mediego.com/fr/articles/5844649-ajout-des-utm-de-tracking" target="_blank">
                <button mat-icon-button color="primary" fxFlex="nogrow" (click)="openDocumentationForUTM()">
                    <mat-icon>help</mat-icon>
                </button>
              </a>

              <button (click)="exportTrackParams()" [disabled]="!trackParamSelection?.selected?.length"
                      fxFlex="nogrow"
                      mat-flat-button color="accent" class="export-tracking-param" type="button">
                <mat-icon>file_upload</mat-icon> {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.EXPORT_PARAMETERS' | translate }}
              </button>

              <button (click)="importTrackParams()"
                      fxFlex="nogrow"
                      mat-flat-button color="accent" class="export-tracking-param" type="button">
                <mat-icon>file_download</mat-icon> {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.IMPORT_PARAMETERS' | translate}}
              </button>

              <button *ngIf="!showAddTrackingParamForm" (click)="showAddTrackingParamForm = true"
                      fxFlex="nogrow"
                      mat-flat-button color="primary" class="add-tracking-param--show" type="button">
                <mat-icon>add</mat-icon> {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.ADD_PARAMETER' | translate }}
              </button>

            </div>


            <ng-container *ngIf="showAddTrackingParamForm">
              <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="add-tracking-param">

                <mat-form-field appearance="outline" fxFlex="1 1 100%">
                  <mat-label>{{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.PARAMETER_NAME' | translate }}</mat-label>
                  <input matInput type="text" placeholder="" [formControl]="paramNameControl">
                  <mat-hint *ngIf="trackParamsForm.get(paramNameControl?.value)">
                    {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.PARAMETER_NAME.ALREADY_EXISTING' | translate }}
                  </mat-hint>
                </mat-form-field>

                <mat-button-toggle-group [formControl]="paramEncodingControl" fxFlex="1 1 100%">
                  <mat-button-toggle value="encoded">
                    {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.ENCODED' | translate }}
                  </mat-button-toggle>
                  <mat-button-toggle value="raw">
                    {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.RAW' | translate }}
                  </mat-button-toggle>
                </mat-button-toggle-group>

                <mat-form-field appearance="outline" fxFlex="1 1 100%">
                  <mat-label>{{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.PARAMETER_VALUE' | translate }}</mat-label>
                  <input matInput type="text" placeholder="" [formControl]="paramValueControl">
                </mat-form-field>

              </div>

              <button (click)="addTrackParam()" [disabled]="!!trackParamsForm.get(paramNameControl?.value) || paramNameControl.invalid || paramValueControl.invalid"
                      mat-flat-button color="primary" class="add-tracking-param-btn">
                {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.VALIDATE_PARAMETER' | translate }}
              </button>

              <div fxLayout="row wrap" fxLayoutAlign="center center"
                   class="add-tracking-param add-tracking-param--mustache"
                   *ngIf="paramEncodingControl.value === 'encoded'">

                <button *ngIf="!showMustache" fxFlex="nogrow" mat-button color="accent"
                        (click)="switchMoustacheSelector($event)">
                  {{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.ADD_MUSTACHE_VARIABLE' | translate }}
                </button>

                <mat-form-field *ngIf="showMustache"
                                appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="nogrow">
                  <mat-label>{{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.INSERT_MUSTACHE_VARIABLE' | translate }}</mat-label>
                  <mat-select [formControl]="mustacheVariableSelector">
                    <mat-option value="">{{ 'COMMON.SOURCE_SETUP.LINK_PARAMS.VARIABLE_CHOICE' | translate }}</mat-option>
                    <mat-option *ngFor="let variable of mustacheVariables" [value]="variable">{{ variable }}</mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
            </ng-container>


          </mat-card-content>
        </mat-card>
      </div>


    </div>
  </div>

  <div *ngIf="this.sendingConfigForm && (canConfigureSending$ | async) === true" class="row row-sending-configuration" fxLayoutAlign="center">
    <div class="section" fxFlex="1 1 100%">
      <app-mediego-section-title [title]="'COMMON.SOURCE_SETUP.SENDING_CONF.TITLE' | translate"
                                 icon="sending" iconColor="primary">
      </app-mediego-section-title>

      <div class="section-content-row">
        <mat-card fxFlex="1 1 100%" fxFlex.gt-sm="1 1 85%" fxFlex.gt-md="1 1 70%">
          <mat-card-content>
            <app-mediego-setup-sending-config [sendingConfigForm]="sendingConfigForm"
                                              [contactsMailers]="contactsMailers"
                                              [contactsMailerLoading]="contactsMailerLoading"
                                              [contactsListsForMailer]="contactsListsForMailer"
                                              [sendingMailers]="sendingMailers"
                                              [senders]="senders"
                                              [isManualContactsMailer]="isManualContactsMailer(selectedContactsMailer)"></app-mediego-setup-sending-config>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <div *ngIf="isAdmin$ | async" class="row row-for-admins" fxLayoutAlign="center">
    <div class="section" fxFlex="1 1 100%">
      <app-mediego-section-title [title]="'COMMON.SOURCE_SETUP.ADMIN.TITLE' | translate"
                                 icon="manage_accounts" iconColor="warn" color="warn">
      </app-mediego-section-title>

      <div class="section-content-row">
        <mat-card fxFlex="1 1 100%" fxFlex.gt-sm="1 1 85%" fxFlex.gt-md="1 1 70%" class="admin">
          <mat-card-content>
            <div fxLayout="row wrap" fxLayoutAlign="space-between baseline" style="margin-bottom: 15px;">
              <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 15px)">
                <mat-label>{{ 'COMMON.SOURCE_SETUP.ADMIN.PIPELINE_ID' | translate }}</mat-label>
                <input type="text" matInput formControlName="recommendationPipeline">
                <mat-hint align="end">recommendationPipeline</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 15px)" *ngIf="form.get('template')">
                <mat-label>{{ 'COMMON.SOURCE_SETUP.ADMIN.TEMPLATE_ID' | translate }}</mat-label>
                <input type="text" matInput placeholder="Template" formControlName="template">
                <mat-hint align="end">template</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(33% - 11.5px)">
                <mat-label>{{ 'COMMON.SOURCE_SETUP.ADMIN.COLDSTART_NB_LAST_VISITORS' | translate }}</mat-label>
                <input matInput type="number" placeholder="" formControlName="coldstartNbLastVisitors">
                <mat-hint align="end">coldstartNbLastVisitors</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(33% - 11.5px)">
                <mat-label>{{ 'COMMON.SOURCE_SETUP.ADMIN.RECO_SENT_TTL' | translate }}</mat-label>
                <input matInput type="number" formControlName="recosSentTTL">
                <mat-hint align="end">recosSentTTL</mat-hint>
              </mat-form-field>

              <mat-checkbox formControlName="makeRedirections" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(33% - 11.5px)">
                {{ 'COMMON.SOURCE_SETUP.ADMIN.MAKE_REDIRECTIONS' | translate }}
              </mat-checkbox>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="space-between">
              <div fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(33% - 11.5px)">
                <h3>{{ 'COMMON.SOURCE_SETUP.ADMIN.TEMPLATE_EDITOR.TITLE' | translate }}</h3>

                <mat-slide-toggle formControlName="enableTemplateEditor">{{ 'COMMON.SOURCE_SETUP.ADMIN.TEMPLATE_EDITOR.ENABLE' | translate }}</mat-slide-toggle>
              </div>

              <div fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(66% - 11.5px)">
                <h3>{{ 'COMMON.SOURCE_SETUP.ADMIN.MULTI_ARMED_BANDIT.TITLE' | translate }}</h3>

                <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start baseline">
                  <mat-slide-toggle formControlName="enableMultiArmedBandit">{{ 'COMMON.SOURCE_SETUP.ADMIN.MULTI_ARMED_BANDIT.ENABLE' | translate }}</mat-slide-toggle>

                  <mat-form-field appearance="outline" *ngIf="form.get('enableMultiArmedBandit').value === true">
                    <mat-label>{{ 'COMMON.SOURCE_SETUP.ADMIN.MULTI_ARMED_BANDIT.EPSILON' | translate }}</mat-label>
                    <input type="number" matInput formControlName="multiArmedBanditEpsilon">
                    <mat-hint align="end">multiArmedBanditEpsilon</mat-hint>
                  </mat-form-field>
                </div>
              </div>

              <div fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(33% - 11.5px)"></div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>


    </div>
  </div>

</form>

<app-mediego-admin-panel fxLayout="row" fxLayoutAlign="center"
                         (delete)="confirmDeleteSource()"
                         [deleteText]="source?.sourceType === 'web' ? 'COMMON.SOURCE_SETUP.REMOVE.WEB' : 'COMMON.SOURCE_SETUP.REMOVE.EMAIL'">
</app-mediego-admin-panel>

<app-mediego-fab-cta-button (click)="save()" icon="save" color="accent" [loading]="saving"
                            [disabled]="form?.invalid || saving || !source"
                            [tooltip]="'SHARED.BUTTONS.SAVE' | translate">
</app-mediego-fab-cta-button>
