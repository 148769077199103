export const JWT_TOKEN_STORAGE_KEY = 'mediego_jwt';

export function tokenGetter() {
  return localStorage.getItem(JWT_TOKEN_STORAGE_KEY);
}

export const JWT_MODULE_CONFIG = {
  config: {
    tokenGetter,
    allowedDomains: [
      'localhost',
      'localhost:9001',
      'mediego.pro',
      'mediego.com',
      'api.mediego.com',
      'api-branch.mediego.com',
      'api-dev.mediego.com',
      'api-qual.mediego.com'
    ],
    disallowedRoutes: [
      RegExp('.*\/api\/login'),
      RegExp('.*\/api\/signup'),
      RegExp('.*\/api\/accept-invite')
    ]
  }
};

export interface MediegoJwt {
  email: string;
  superAdmin: boolean;
}
