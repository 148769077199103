<ng-template #dialogRef>
  <app-mediego-confirmation-dialog [dialogTitle]="'MAIN.APP.MEDIEGO_MESSAGE.TITLE' | translate"
                                   icon="mediego"
                                   [acceptLabel]="'SHARED.BUTTONS.DO_NOT_SHOW' | translate"
                                   [declineLabel]="'SHARED.BUTTONS.CLOSE' | translate">

    <div class="mediego-content">
      <pre class="mediego-content--message">{{ latestMediegoMessage?.content }}</pre>
      <b class="mediego-content--publication">{{ latestMediegoMessage?.publicationDate | date:'EEEE d MMMM, HH:mm' }}</b>
    </div>


    <img [src]="latestMediegoMessage?.imgSrc" class="illustration" [alt]="'MAIN.APP.MEDIEGO_MESSAGE.IMAGE.ALT' | translate" title="" />

    <div class="copyright-quote">
      <a href="https://fr.freepik.com/auteur/pch-vector" target="_blank">{{ 'MAIN.APP.MEDIEGO_MESSAGE.IMAGE.QUOTE' | translate }}</a>
    </div>

  </app-mediego-confirmation-dialog>
</ng-template>
