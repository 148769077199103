export enum ConstraintType {
  Global = 'global',
  Local = 'local'
}

export enum ConstraintPattern {
  LayoutFullOrNothing = 1,
  SpecificArticlesDependance = 2,
  HeatmapUTM = 3,
  SelligentTracking = 4
}

export enum Origin {
  User = 'user',
  Mediego = 'mediego'
}

export enum LayoutType {
  Row = 'row',
  Column = 'column'
}

export enum ContentType {
  Empty = 'no-content',
  Image = 'image',
  Text = 'text',
  Title = 'title',
  Button = 'button',
  Code = 'code',
  Powerspace = 'pws',
  Spacer = 'spacer'
}

export enum TextAlignment {
  Center = 'center',
  Left = 'left',
  Right = 'right',
  Justify = 'justify'
}

export enum VerticalAlignment {
  Middle = 'middle',
  Top = 'top',
  Bottom = 'bottom',
  Inherit = 'inherit'
}

export enum SizeUnit {
  PX = 'px',
  EM = 'em',
  PERCENT = '%'
}

export enum LinkTargetMode {
  NewTab = '_blank',
  CurrentTab = '_self'
}
