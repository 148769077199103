import { v4 as uuidv4 } from 'uuid';

import { CompilationContext, CompilationResult } from './compilation';
import { ConstraintPattern, ConstraintType, Origin } from './enums';
import { compileLayoutToHtmlNodes, isLayout, isSuperLayout, Layout } from './layout';


export interface Constraint {
  id: string;
  type: ConstraintType;
  createdBy: Origin;
  pattern: ConstraintPattern;
  active: boolean;
}


export function compileConstraint(constraint: Constraint,
                                  layout: Layout,
                                  context: CompilationContext): CompilationResult {

  const result: CompilationResult = {
    nodes: [],
    context
  };

  if (!isLayout(layout)) {
    console.warn('compileConstraint function not suited for non-layout elements');
  }

  if (constraint.active) {

    switch (constraint.pattern) {
      // LayoutFullOrNothing will put {{conditions}} at top level for a better design
      case ConstraintPattern.LayoutFullOrNothing:

        const compilationUnsafe: CompilationResult = compileLayoutToHtmlNodes(layout, context);

        // super layout means there is a starting and ending outlook conditional formatting
        // we trim them
        if (isSuperLayout(layout) && compilationUnsafe.nodes.length === 3) {
          compilationUnsafe.nodes = Array.from(compilationUnsafe.nodes[1].childNodes);
        }

        let innerNodeText = '', innerNodeHtml = '', foundArticles = [], foundVariables = [], foundUserEncryptedMails = [], startingWrapper = '', endingWrapper = '';

        if (compilationUnsafe) {

          let compiledSafe: Node[] = [];

          for (const node of compilationUnsafe.nodes) {
            innerNodeText = node.textContent;
            innerNodeHtml = (node as Element).innerHTML || '';

            foundArticles = innerNodeText.match(/#reco_[0-9]+/gi) || [];
            foundVariables = innerNodeHtml.match(/variables\.mdg_[a-z0-9_]+/gi) || [];
            // omitting links as they are not to be considered as mandatory
            foundVariables = [...foundVariables.filter(variable => !variable.endsWith('_url'))];
            foundUserEncryptedMails = innerNodeHtml.match(/#user\.email_md5/gi) || [];

            let toEscape: string[] = [...foundArticles, ...foundVariables, ...foundUserEncryptedMails];
            if (toEscape.length) {

              // removing # character
              toEscape = toEscape.map(_ => _?.startsWith('#') ? _?.slice(1) : _);

              // asserting articles are unique
              toEscape = toEscape.filter((_, index) =>
                _ && toEscape.indexOf(_) === index);

              [startingWrapper, endingWrapper] = toEscape.reduce((finalTags, current) => {
                // generating at the same time starting and ending wrappers
                return [finalTags[0] + '{{#' + current + '}}', '{{/' + current + '}}' + finalTags[1]];
              }, ['', '']);

              compiledSafe = [
                ...compiledSafe,
                document.createTextNode(startingWrapper),
                node,
                document.createTextNode(endingWrapper)
              ];

            } else {

              compiledSafe = [...compiledSafe, node];

            }
          }

          // end of loop => result is ready
          result.nodes = compiledSafe;
          return result;
        }

        return compilationUnsafe;
      case ConstraintPattern.SpecificArticlesDependance:
        return compileLayoutToHtmlNodes(layout, context);
      default:
        return compileLayoutToHtmlNodes(layout, context);
    }

  } else {
    return compileLayoutToHtmlNodes(layout, context);
  }

}


export const DEFAULT_GLOBAL_CONSTRAINTS: Constraint[] = [
  {
    id: uuidv4(),
    type: ConstraintType.Global,
    createdBy: Origin.Mediego,
    pattern: ConstraintPattern.LayoutFullOrNothing,

    // Name & Description implemented in i18n
    active: true
  },
  {
    id: uuidv4(),
    type: ConstraintType.Global,
    createdBy: Origin.Mediego,
    pattern: ConstraintPattern.HeatmapUTM,

    // Name & Description implemented in i18n
    active: false
  },
  {
    id: uuidv4(),
    type: ConstraintType.Global,
    createdBy: Origin.Mediego,
    pattern: ConstraintPattern.SelligentTracking,

    // Name & Description implemented in i18n
    active: false
  }
];
