import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
}                      from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-mediego-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectComponent<T = object> implements OnInit, OnChanges {
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() withSelectAll: boolean;

  @Input() availableElements: T[] = [];
  @Input() displayWith: (item: T, index: number) => string;
  @Input() sortKey?: string;

  @Input() selectedElements: T[] = [];
  @Output() selectedElementsChange = new EventEmitter<T[]>();

  selectElement = new UntypedFormControl([]);

  get isChecked(): boolean {
    return this.selectedElements.length > 0
      && this.selectedElements.length === this.availableElements.length;
  }

  get isIndeterminate(): boolean {
    return this.selectedElements.length > 0
      && this.selectedElements.length < this.availableElements.length;
  }

  ngOnInit(): void {
    this.selectElement.valueChanges.subscribe((selectedElements) => {
      this.selectedElementsChange.emit(selectedElements);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selectedElements')) {
      this.selectElement.setValue(
        changes['selectedElements'].currentValue,
        { emitEvent: false }
      );
    }

    if (changes.hasOwnProperty('disabled')) {
      if (changes.disabled.currentValue) {
        this.selectElement.disable();
      } else {
        this.selectElement.enable();
      }
    }
  }

  toggleAllElements(checked: boolean) {
    if (checked) {
      this.selectedElementsChange.emit(this.availableElements);
    } else {
      this.selectedElementsChange.emit([]);
    }
  }

}
