import { Component, Input, ViewEncapsulation } from '@angular/core';

import { Itemset } from '../../../configuration-module/declarations/itemset';

@Component({
  selector: 'app-mediego-section-title-for-itemsets',
  template: `
    <h2 [class.title--primary]="color === 'primary'"
        [class.title--accent]="color === 'accent'"
        [class.title--warn]="color === 'warn'">
      <mat-icon *ngIf="icon" [color]="iconColor">{{ icon }}</mat-icon>
      &nbsp;<b [innerHTML]="title | safeHtml"></b>
      <span>
        <ul>

          <ng-container *ngIf="!withLinks; else links">
            <li *ngFor="let itemset of itemsets">
              {{ itemset.displayName }}
            </li>
          </ng-container>

          <ng-template #links>
            <a *ngFor="let itemset of itemsets"
               target="_blank"
               routerLink="/contents/itemsets" [queryParams]="{ itemset: itemset.name }" queryParamsHandling="merge"
               [matTooltip]="'Ouvrir les règles du groupe dans un nouvel onglet'">
              <li>
                {{ itemset.displayName }}
              </li>
            </a>
          </ng-template>

        </ul>
      </span>
    </h2>
  `,
  styleUrls: ['./section-title.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SectionTitleItemsetsComponent {
  @Input() title: string;
  @Input() itemsets: Itemset[];
  @Input() withLinks: boolean;
  @Input() icon?: string;
  @Input() iconColor?: 'primary' | 'accent' | 'warn';
  @Input() color?: 'primary' | 'accent' | 'warn';
}
