// Separate file as template-model.utils.ts is large enough

import { Article } from '../declarations/article';
import { Content } from '../declarations/content';
import { ContentType } from '../declarations/enums';
import { isLayout, Layout } from '../declarations/layout';
import { TemplateMetaModel } from '../declarations/template-meta-model';
import { TemplateModel } from '../declarations/template-model';

export class TemplateMetaModelUtils {

  static extractMetaModel(articles: Article[], model: TemplateModel): TemplateMetaModel {

    const metaModel: TemplateMetaModel = {
      articles: [],
      allVariables: []
    };

    const getPositionFromArticleId = (articles: Article[], articleId: string): number => {
      let position: number = -1;

      if (articles && articleId) {
        articles.forEach((article, index) => {
          if (position === -1 && article.id === articleId) {
            position = index;
          }
        });
      }

      return position;
    };

    // mutate argument metaModel
    const createOrUpdateMetaArticle = (articles: Article[], articleId: string, metadata: string[], metaModel: TemplateMetaModel) => {
      const position: number = getPositionFromArticleId(articles, articleId);

      // valid article => UPDATE
      if (metaModel && metaModel.articles
        && position > -1 && position < metaModel.articles.length) {

        // ensuring metadata is available (for using spread operator)
        if (!metaModel.articles[position].metadata) {
          metaModel.articles[position].metadata = [];
        }
        metaModel.articles[position].metadata = [...metaModel.articles[position].metadata, ...metadata];

        // ...
      } else {
        // non-existing article => CREATE in last position
        metaModel.articles.push({
          metadata: [...metadata],
          variables: []
        });

        // ...
      }
    };

    const readMetadataFromString = (textToSearch: string): string[] => {

      const regex = /metadata\.([a-z0-9_-]+)/gi; // isolates the XXXX part in metadata.XXXX
      let regexExecutionResult: any[];

      const metadataFound: string[] = [];

      while ((regexExecutionResult = regex.exec(textToSearch)) !== null) {
        metadataFound.push(regexExecutionResult[1]);
      }

      return Array.from(new Set(metadataFound));
    };


    // mutate argument metaModel
    const extractMetaArticle = (articles: Article[], target: Layout | Content, metaModel: TemplateMetaModel): void => {
      if (isLayout(target)) {
        target.children.forEach((child) => extractMetaArticle(articles, child, metaModel));
      } else {
        // safety check before target manipulation
        if (target && target.articleRef && target.type && target.params && target.params.dynamic) {

          const params = Object.keys(target.params.dynamic);

          const metadata = Array.from(new Set(
            params.reduce((res, paramName) => {
              if (paramName && target.params.dynamic[paramName]) {
                return [...res, ...readMetadataFromString(target.params.dynamic[paramName])];
              } else {
                return [...res];
              }
            }, [])
          ));

          if (target.type === ContentType.Code) {
            metadata.push(...readMetadataFromString(target.params.codeContent));
          }

          createOrUpdateMetaArticle(articles, target.articleRef, metadata, metaModel);
        }
      }
    };

    if (model && model.children) {
      model.children.forEach((child) => extractMetaArticle(articles, child, metaModel));
    }

    // clean meta model (eliminating duplicated metadata)
    metaModel.articles.forEach((metaArticle, index) => {
      metaArticle.metadata = Array.from(new Set(metaArticle.metadata));
    });

    return metaModel;
  }


}
