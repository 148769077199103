<form [formGroup]="sendingConfigForm" fxLayout="row wrap" fxLayoutAlign="space-around baseline" style="margin-bottom: 15px;">

  <p fxFlex="100" [innerHTML]="'COMMON.SOURCE_SETUP.SENDING_CONF.CONTACTS_EXPLANATION' | translate"></p>

  <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 15px)">
    <mat-label>{{ 'COMMON.SOURCE_SETUP.SENDING_CONF.CONTACTS_MAILER' | translate }}</mat-label>
    <mat-select formControlName="contactsMailer">
      <mat-option *ngFor="let contactsMailer of contactsMailers" [value]="contactsMailer.id">
        {{contactsMailer.displayName}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <!-- Empty div for replacing space taken by contact list selector -->
  <div fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 15px)" *ngIf="isManualContactsMailer"></div>


  <app-mediego-multi-select *ngIf="!isManualContactsMailer; else manualContactsLists"
                            [label]="'COMMON.SOURCE_SETUP.SENDING_CONF.CONTACTS_LISTS' | translate"
                            [availableElements]="contactsListsForMailer"
                            [displayWith]="displayContactsListsName"
                            sortKey="name"
                            [selectedElements]="sendingConfigForm.get('contactsLists').value"
                            (selectedElementsChange)="updateSelectedContactsLists($event)"
                            fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 15px)"
                            [disabled]="contactsMailerLoading || sendingConfigForm?.disabled || !sendingConfigForm.get('contactsMailer').value"
                            [withSelectAll]="false">
  </app-mediego-multi-select>


  <ng-template #manualContactsLists>
    <app-mediego-chip-multiselect
      fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(100% - 15px)"
      label="{{ 'COMMON.SOURCE_SETUP.SENDING_CONF.MANUAL_CONTACTS_LIST' | translate }}"
      appearance="outline"
      [multiple]="false"
      (outputPatterns)="updateManualContactLists($event)"
      [preselectedPatterns]="sendingConfigForm.get('contactsLists').value"
      style="margin-top: 15px;"
    ></app-mediego-chip-multiselect>

  </ng-template>
</form>

<hr/>

<form [formGroup]="sendingConfigForm" fxLayout="row wrap" fxLayoutAlign="space-around baseline" style="margin-bottom: 15px;">

  <p fxFlex="100" [innerHTML]="'COMMON.SOURCE_SETUP.SENDING_CONF.SENDER_EXPLANATION' | translate"></p>

  <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 15px)">
    <mat-label>{{ 'COMMON.SOURCE_SETUP.SENDING_CONF.SENDING_MAILER' | translate }}</mat-label>
    <mat-select formControlName="sendingMailer">
      <mat-option *ngFor="let sendingMailer of sendingMailers" [value]="sendingMailer.id">
        {{sendingMailer.displayName}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline"  fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 15px)">
    <mat-label>{{ 'COMMON.SOURCE_SETUP.SENDING_CONF.SENDER_ADDRESS' | translate }}</mat-label>
    <mat-select formControlName="senderAddress">
      <mat-option *ngFor="let sender of senders" [value]="sender">
        {{sender}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</form>
<form [formGroup]="sendingConfigForm" fxLayout="row wrap" fxLayoutAlign="space-around baseline" style="margin-bottom: 15px;">

  <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 15px)" subscriptSizing="dynamic">
    <mat-label>{{ 'COMMON.SOURCE_SETUP.SENDING_CONF.SENDER_NAME' | translate }}</mat-label>
    <input type="text" matInput formControlName="senderName">
    <button mat-icon-button matSuffix color="accent">
      <mat-icon>account_circle</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 15px)" subscriptSizing="dynamic">
    <mat-label>{{ 'COMMON.SOURCE_SETUP.SENDING_CONF.REPLY_TO' | translate }}</mat-label>
    <input type="text" matInput placeholder="Reply to address" formControlName="replyToAddress">
    <button mat-icon-button matSuffix color="accent">
      <mat-icon>reply</mat-icon>
    </button>
  </mat-form-field>
</form>

<form *ngIf="specificSendingConf" fxLayout="row wrap" fxLayoutAlign="space-around baseline" style="margin-bottom: 15px;">
  <ng-container [ngSwitch]="selectedSendingMailerType">
    <app-mediego-specific-sending-conf-a7 *ngSwitchCase="'a7'" [formControl]="specificSendingConf" fxFlex="1 1 100%">
    </app-mediego-specific-sending-conf-a7>
  </ng-container>
</form>

<div fxLayout="row" fxLayoutAlign="end end">
  <button fxFlex="nogrow" mat-button color="warn" (click)="restoreConfig()" matTooltip="Nécessitera une sauvegarde pour être pris en compte">Réinitialiser la configuration</button>
</div>
