import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, flatMap, map, switchMap, take } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash/fp';

import { AppState }                    from '../../main-module/state';
import { SLayout }                     from '../state/reducers/layout.reducer';
import { selectCustomBlock } from '../state/selectors/custom-blocks.selectors';
import {
  selectActiveCustomBlocks,
  selectConfiguredElement,
  selectContentSelectionModeIsActive, selectFocusedCustomBlockRef,
  selectFocusedElement,
  selectLayoutSelectionModeIsActive
} from '../state/selectors/templater-ui.selectors';
import {
  loadTemplatePipeline
} from '../../newsletters-module/state/actions/template-pipeline.actions';
import { loadSubject } from '../../newsletters-module/state/actions/template-subject.actions';
import {
  loadTemplateVariables
} from '../../newsletters-module/state/actions/template-variables.actions';
import { addContentToLayout, resetContentOfLayout } from '../state/actions/layout.actions';
import {
  clearTemplateModel,
  createNewTemplateModel,
  deleteRowFromTemplateModel,
  duplicateRowInTemplateModel,
  duplicateRowsInTemplateModel,
  importTemplateModel,
  loadTemplateModel,
  resetTemplateModel
}                                      from '../state/actions/template-model.actions';
import { setConfiguredElementId, setFocusedElementId } from '../state/actions/templater-ui.actions';

import { NewslettersConfigurationService } from '../../configuration-module/services/newsletters-configuration.service';
import { AppService }      from '../../services/app.service';
import { PipelineService } from '../../services/pipeline.service';

import { PipelineProvider }      from '../../newsletters-module/providers/pipeline.provider';
import { TemplateProvider } from '../../providers/template.provider';
import { TemplateModelProvider } from '../providers/template-model-provider';

import { Engine }                 from '../../main-module/declarations/engine';
import { NewsletterSource } from '../../mediego-common-module/declarations/source';
import { RecommendationPipeline } from '../../newsletters-module/declarations/pipeline/recommendation-pipeline';
import {
  subjectToTemplateHeader,
  Template,
  templateHeaderToSubject
} from '../../newsletters-module/declarations/template';
import { TemplateVariableFromAPI } from '../../newsletters-module/declarations/template-variables';
import { Article }                from '../declarations/article';
import { ContentBlueprint }       from '../declarations/blueprints';
import { Content }                from '../declarations/content';
import { CustomBlock } from '../declarations/custom-block';
import { TemplateHeader, TemplateModel } from '../declarations/template-model';

import { TemplateMetaModelUtils } from '../utils/template-meta-model.utils';
import { TemplateMigrationUtils } from '../utils/template-migration.utils';
import { TemplateModelUtils } from '../utils/template-model.utils';

import { ArticlesService }      from './articles.service';
import { TemplateModelService } from './template-model.service';

/*
 *  TemplaterService
 *
 *  Service manipulating a template instance (one at a time)
 *  And containing export logic (to preview or get html code)
 */
@Injectable({
  providedIn: 'root'
})
export class TemplaterService {

  modified$$ = new BehaviorSubject<boolean>(false);
  waitingSubject$ = new BehaviorSubject<boolean>(true);
  saving$$ = new BehaviorSubject<boolean>(false);
  savingFailed$$ = new BehaviorSubject<boolean>(false);
  mustacheEnabled$$ = new BehaviorSubject<boolean>(true);
  mustacheEnabled$ = this.mustacheEnabled$$.asObservable();
  private editorialEnabled$$ = new BehaviorSubject<boolean>(true);
  editorialEnabled$ = this.editorialEnabled$$.asObservable();
  err$$ = new Subject<string>();

  get focusedElement$(): Observable<SLayout | Content | undefined> {
    return this.store.select(selectFocusedElement);
  }

  get focusedElementCustomBlockName$(): Observable<string | undefined> {
    return this.store.select(selectFocusedCustomBlockRef).pipe(
      flatMap((ref) => {

        // retrieving custom block if existing
        if (ref) {
          return this.store.select(selectCustomBlock, { id: ref.id }).pipe(
            map((block) => {
              return block && block.name
            })
          );
        } else {
          return of(undefined);
        }

      })
    );
  }

  get activeCustomBlock$(): Observable<CustomBlock | undefined> {
    return this.store.select(selectActiveCustomBlocks).pipe(
      flatMap((refs) => {

        // retrieving custom block if existing
        // if more than 1 ref, we guess user wants to create a new custom block
        // (it's hard to guess his intentions, how to chose modified block, etc.
        if (refs && refs.length === 1) {
          // TODO: consider all refs (how ?), not only the first
          return this.store.select(selectCustomBlock, { id: refs[0] }).pipe(
            map((block) => {
              return block;
            })
          );
        } else {
          return of(undefined);
        }

      })
    );
  }

  get elementInConfig$(): Observable<SLayout | Content | undefined> {
    return this.store.select(selectConfiguredElement);
  }

  get inContentSelectionMode$(): Observable<boolean> {
    return this.store.select(selectContentSelectionModeIsActive);
  }

  get inLayoutSelectionMode$(): Observable<boolean> {
    return this.store.select(selectLayoutSelectionModeIsActive);
  }

  constructor(
    private store: Store<AppState>,
    private appService: AppService,
    private translate: TranslateService,
    private newslettersConfigurationService: NewslettersConfigurationService,
    private templateModelService: TemplateModelService,
    private templateModelProvider: TemplateModelProvider,
    private articlesService: ArticlesService,
    private pipelineService: PipelineService,
    private pipelineProvider: PipelineProvider,
    private templateProvider: TemplateProvider
  ) {
  }

  addContent(contentElement: ContentBlueprint, parentId: string, position: number, placeholderId: string): Content {
    const content: Content = TemplateModelUtils.parseContentBlueprint(contentElement, parentId, this.translate);
    this.store.dispatch(addContentToLayout({ layoutId: parentId, placeholderId, content }));

    return content;
  }

  duplicateRow(id: string): void {
    this.store.dispatch(duplicateRowInTemplateModel({ id }));
  }

  duplicateRows(ids: string[]): void {
    this.store.dispatch(duplicateRowsInTemplateModel({ ids }));
  }

  deleteRowFromStructure(id: string): void {
    this.store.dispatch(setConfiguredElementId({ id: null }));
    this.store.dispatch(deleteRowFromTemplateModel({ id }));
  }

  deleteContentFromStructure(layoutId: string, contentId: string): void {
    this.store.dispatch(setConfiguredElementId({ id: null }));
    this.store.dispatch(setFocusedElementId({ id: null }));
    this.store.dispatch(resetContentOfLayout({ layoutId, contentId }));
  }

  async importTemplateModel(source: NewsletterSource): Promise<void> {
    const engine = await this.appService.getSelectedEngine();
    const sourceModel$ = this.templateModelProvider
      .getModel(engine.id, source.id, source.template)
      .pipe(
        catchError((e) => {
          // if templateModel not found, create a brand new model
          if (e.status === 404) {
            throw new Error(`La newsletter ${source.displayName} n'a pas de template`);
          } else {
            return throwError(e);
          }
        })
      );

    const sourceModel = await firstValueFrom(sourceModel$);

    if (TemplateModelUtils.modelVersionIsSupported(sourceModel)) {
      const targetPipeline = await this.pipelineService.getCurrentPipeline();
      const sourcePipeline = await this.pipelineProvider.copyPipeline(
        engine.id,
        source.recommendationPipeline,
        targetPipeline.id
      );

      this.store.dispatch(loadTemplatePipeline({ pipeline: sourcePipeline }));
      this.store.dispatch(importTemplateModel({ model: sourceModel, source }));

      // await this.recosService.getEnoughRecosWithRetry(Object.entries(sourcePipeline.recommendationsSources).length, true)
    } else {
      throw new Error('Template version unsupported');
    }
  }

  clearTemplateModel(): void {
    this.store.dispatch(clearTemplateModel());
  }

  resetTemplateModel(): void {
    this.store.dispatch(resetTemplateModel());
  }

  loadOrCreateTemplateModel(): Observable<void> {
    // clear any model that was still in state
    this.clearTemplateModel();

    return this.loadTemplateModel()
      .pipe(
        catchError((e) => {
          // if templateModel not found, create a brand new model
          if (e.status === 404) {
            return this.createTemplateModel().then((withConfig) => {
              // ... template model created
            });
          } else {
            return throwError(e);
          }
        })
      );
  }

  loadTemplateModel(): Observable<void> {

    return combineLatest(
      [
        this.appService.selectedEngine$,
        this.appService.selectedSource$
      ]
    ).pipe(
      take(1),
      switchMap((data: [Engine, NewsletterSource]) => {
        const engine = data[0];
        const selectedSource: NewsletterSource = data[1];

        if (!selectedSource) {
          // No template model to load as NO SOURCE selected
          return of(undefined);
        }

        if (selectedSource && !selectedSource.template) {
          return throwError(new Error('Template missing!'));
        }

        return this.templateModelProvider.getModel(engine?.id, selectedSource?.id, selectedSource?.template);
      }),
      map((model: TemplateModel) => {
        if (model) {
          if (TemplateModelUtils.modelVersionIsSupported(model)) {
            model = TemplateMigrationUtils.migrate(model);
            this.store.dispatch(loadTemplateModel({ model }));
          } else {
            throw new Error('Template version unsupported!');
          }
        }
      })
    );
  }

  loadTemplateVariables(): Observable<void> {

    return combineLatest(
      [
        this.appService.selectedEngine$,
        this.appService.selectedSource$
      ]
    ).pipe(
      take(1),
      switchMap((data: [Engine, NewsletterSource]) => {
        const engine = data[0];
        const selectedSource: NewsletterSource = data[1];

        if (!selectedSource) {
          return of(undefined);
        }

        if (selectedSource && !selectedSource.template) {
          return throwError(new Error('Template missing!'));
        }

        return this.templateModelProvider.getVariablesOfModel(engine.id, selectedSource.id, selectedSource.template);
      }),
      map((variables: TemplateVariableFromAPI[]) => {

        if (variables) {
          const loadVariablesAction = loadTemplateVariables({ templateVariables: variables });

          // putting variables in store
          this.store.dispatch(loadVariablesAction);
        }
      })
    );
  }

  // Boolean returned represents whether created template uses global newsletters config or not
  createTemplateModel(): Promise<boolean> {

    return this.newslettersConfigurationService.initNewslettersConfig(undefined).then((config) => {

      // new template will benefit of global newsletters config
      this.store.dispatch(createNewTemplateModel({ config }));
      return true;

    }).catch((err) => {
      console.error('Unable to fetch global newsletters config, proceeding without');
      console.error(err);

      this.store.dispatch(createNewTemplateModel({ config: null }));
      return false;
    });
  }

  async saveTemplateModel(): Promise<boolean> {
    try {
      // 1°) updating pipeline
      const selectedEngine = await this.appService.getSelectedEngine();
      const selectedSource: NewsletterSource = await this.appService.getSelectedSource();
      const pipeline: RecommendationPipeline = await this.pipelineService.getCurrentPipeline();
      await this.pipelineProvider.updatePipeline(selectedEngine.id, selectedSource.recommendationPipeline, pipeline);

      const model = await this.templateModelService.getTemplateModel();
      const articles: Article[] = await this.articlesService.getSortedArticles();

      // generating meta model at the same time
      model.metaModel = TemplateMetaModelUtils.extractMetaModel(articles, model);


      const subject = await this.loadTemplateSubject(selectedEngine.id, selectedSource.id, selectedSource.template).catch((e) => {
        console.error('error while fetching subject');
        console.error(e);

        return null;
      });

      const mustacheTemplate: string = TemplateModelUtils.compileModelToMustacheTemplate(model, articles, subject);

      // 2°) updating model
      await this.templateModelProvider.createOrUpdateModel(
        selectedEngine.id,
        selectedSource.id,
        selectedSource.template,
        JSON.stringify(model),
        mustacheTemplate
      );

      // no error thrown: templater saved !
      this.savingFailed$$.next(false);
      this.modified$$.next(false);

      return true;

    } catch (e) {

      // On error, emitting a human-readable message of error
      console.error('Error while saving', e);

      let errorMessage: string = this.translate.instant('NEWSLETTERS.TEMPLATER.MESSAGES.ERROR.ON_SAVE');

      if (e.status) {
        switch (e.status) {
          // request entity too large
          case 413:
            errorMessage = this.translate.instant('NEWSLETTERS.TEMPLATER.MESSAGES.ERROR.TOO_LARGE');
            break;
          case 500:
            errorMessage = this.translate.instant('NEWSLETTERS.TEMPLATER.MESSAGES.ERROR.SERVER_UNAVAILABLE');
            break;
          default:
            errorMessage = this.translate.instant('NEWSLETTERS.TEMPLATER.MESSAGES.ERROR.GENERIC');
            break;
        }
      }

      this.err$$.next(errorMessage);
      this.savingFailed$$.next(true);
      return false;
    }
  }


  createTemplateSubject(specificSource: NewsletterSource = undefined): Promise<TemplateHeader> {
    const subject$ = combineLatest(
      [
        this.appService.selectedEngine$,
        this.appService.selectedSource$
      ]
    ).pipe(
      switchMap((data: [Engine, NewsletterSource]) => {
        const engine = data[0];
        const selectedSource: NewsletterSource = data[1];

        if (!selectedSource.template) {
          return throwError(new Error('Template missing!'));
        }

        return this.templateProvider.getTemplateById(
          engine.id,
          (specificSource || selectedSource).id,
          (specificSource || selectedSource).template
        );

      }),
      map((template: Template) => {

        // parsing subject as server side subject is not equivalent to frontend one
        // (altered for manipulation such as display operation)
        const subject = subjectToTemplateHeader(template?.subject);

        if (!subject.valueEditorialized) {
          subject.valueEditorialized = '';
          subject.allowEditorialization = true;
        }

        // putting subject in store
        this.store.dispatch(loadSubject({ subject }));

        return subject;
      })
    );
    return firstValueFrom(subject$);
  }

  // Subject API
  // heavy distinction between a SUBJECT (which is found under template.subject for example)
  // and a HEADER (which represents the model used by frontend in order to display/manipulate SUBJECT)
  async loadTemplateSubject(engineId: number, sourceId: string, templateId: string): Promise<TemplateHeader> {

    if (!engineId || !sourceId || !templateId) {
      throw new Error('Missing parameter(s) for loading subject: engineId/sourceId/templateId');
    }

    this.waitingSubject$.next(true);

    // first fetching subject from template
    const template = await this.templateProvider.getTemplateById(engineId, sourceId, templateId);
    const templateSubjectFromAPI = template.subject;

    const templateHeader = subjectToTemplateHeader(templateSubjectFromAPI);
    templateHeader.valueEditorialized = '';
    templateHeader.allowEditorialization = true;

    // putting it in store
    this.store.dispatch(loadSubject({ subject: templateHeader }));
    this.waitingSubject$.next(false);


    // then requesting editorialized subject
    const templateHeaderWithEditorialSubject = subjectToTemplateHeader(templateSubjectFromAPI);

    const editorialSubjectFromAPI = await this.templateProvider.getEditorialSubject(engineId, sourceId)
      .then((subject) => subject || '')
      .catch(
        (err) => {
          if (err?.status === 404 && err?.error) {
            const apiError = JSON.parse(err.error);
            if (apiError?.errorId === 'NoEditorialSubjectError') {
              return '';
            }
          }
          throw err;
        });

    if (!templateHeaderWithEditorialSubject.valueEditorialized) {
      templateHeaderWithEditorialSubject.valueEditorialized = '';
      templateHeaderWithEditorialSubject.allowEditorialization = true;
    }

    // appending editorialized subject if possible
    if (templateHeaderWithEditorialSubject) templateHeaderWithEditorialSubject.valueEditorialized = editorialSubjectFromAPI;

    // putting it in store with updated editorial subject if necessary
    if (!_.isEqual(templateHeader, templateHeaderWithEditorialSubject)) {
      this.store.dispatch(loadSubject({ subject: templateHeaderWithEditorialSubject }));
    }

    return templateHeaderWithEditorialSubject;
  }

  async saveTemplateProperties(engine: Engine, source: NewsletterSource, variables: TemplateVariableFromAPI[], subject?: TemplateHeader): Promise<void> {
    const subjectNormalized = subject ? templateHeaderToSubject(subject) : null;

    if (!source.template) {
      throw new Error('Template missing!');
    }

    if (!variables) {
      throw new Error('Variables missing!');
    }

    const template = await this.templateProvider.getTemplateById(engine.id, source.id, source.template);
    const subjectChange = !!subject && !_.isEqual(template.subject, subjectNormalized);
    const variablesChange = !!variables && !_.isEqual(template.variables, variables);

    if (subjectChange) template.subject = subjectNormalized;
    if (variablesChange) template.variables = variables;

    // avoiding unnecessary HTTP POST
    if (subjectChange || variablesChange) {
      await this.templateProvider.createOrUpdateTemplate(engine.id, source.id, template, variablesChange, subjectChange);
    }
  }

  switchMustacheCompilation() {
    if (this.mustacheEnabled$$) this.mustacheEnabled$$.next(!this.mustacheEnabled$$.getValue());
  }

  resetEditorial() {
    this.editorialEnabled$$?.next(true);
  }

  toggleEditorial() {
    if (this.editorialEnabled$$) this.editorialEnabled$$.next(!this.editorialEnabled$$.getValue());
  }
}
