<hr/>

<form [formGroup]="a7Form">
    <div fxLayout="row wrap" fxLayoutAlign="space-around baseline">

      <p fxFlex="100" [innerHTML]="'COMMON.SOURCE_SETUP.SENDING_CONF.SENDER_EXPLANATION.A7' | translate"></p>


        <input type="hidden" formControlName="type">

        <mat-form-field appearance="outline" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 15px)">
          <mat-label>{{ 'COMMON.SOURCE_SETUP.SENDING_CONF.A7.HTML_PROCESS' | translate }}</mat-label>
          <input type="text" matInput placeholder="{{ 'COMMON.SOURCE_SETUP.SENDING_CONF.A7.HTML_PROCESS_PLACEHOLDER' | translate }}" formControlName="htmlProcess">
          <button mat-icon-button matSuffix color="accent">
            <mat-icon>settings</mat-icon>
          </button>
        </mat-form-field>

        <mat-checkbox formControlName="autoStart" fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(50% - 15px)">{{ 'COMMON.SOURCE_SETUP.SENDING_CONF.A7.AUTOSTART' | translate }}</mat-checkbox>

        <app-mediego-chip-multiselect
          fxFlex="1 1 100%" fxFlex.gt-xs="0 0 calc(100% - 15px)"
          label="{{ 'COMMON.SOURCE_SETUP.SENDING_CONF.A7.INTERESTS' | translate }}"
          appearance="outline"
          (outputPatterns)="updateInterests($event)"
          [preselectedPatterns]="interests.value"
        ></app-mediego-chip-multiselect>

      </div>
</form>
