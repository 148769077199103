import { createAction, props, union } from '@ngrx/store';

import { Article } from '../../declarations/article';

export const loadArticles = createAction(
  '[TEMPLATER][ARTICLES] Load All',
  props<{ entries: Article[] }>()
);

export const createArticle = createAction(
  '[TEMPLATER][ARTICLES] Create One',
  props<{ articleEntry: Article }>()
);

export const deleteArticle = createAction(
  '[TEMPLATER][ARTICLES] Delete One',
  props<{ articleId: string }>()
);

const articleActions = union({
  loadArticles,
  deleteArticle
});

export type TemplateArticlesAction = typeof articleActions
