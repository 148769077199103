import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class MediegoValidators {
  static equalsControl(otherControlId: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const form = control.parent;
      if (!form) {
        return null;
      } else {
        const otherControl = form.get(otherControlId);
        return control.value !== otherControl.value ? { NotEqualTo: otherControlId } : null;
      }
    };
  }

  static sufficientlyStrongPassword(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const value = control.value;
      if (!value) {
        return null;
      } else {

        // Length of at least 10 characters
        const NotLongEnough = value.length < 10;

        // Contains at least one lowercase letter
        const NoLowerCase = /[a-z]/.test(value) === false;

        // Contains at least one uppercase letter
        const NoUpperCase = /[A-Z]/.test(value) === false;

        // Contains at least one digit
        const NoDigit = /\d/.test(value) === false;

        // Contains at least one special character (non-alphanumeric, non-digit)
        const NoSpecialChar = /[\[\$\&\+\,\:\;\=\?\@\#\|\'\<\>\.\^\*\(\)\%\!\-\]\€]/.test(value) === false;

        if (!NotLongEnough && !NoLowerCase && !NoUpperCase && !NoDigit && !NoSpecialChar) {
          return null;
        } else {
          const errors = {};
          if (NotLongEnough) errors['NotLongEnough'] = true;
          if (NoLowerCase) errors['NoLowerCase'] = true;
          if (NoUpperCase) errors['NoUpperCase'] = true;
          if (NoDigit) errors['NoDigit'] = true;
          if (NoSpecialChar) errors['NoSpecialChar'] = true;

          return errors;
        }
      }
    }
  }


  static requiredIfOtherEquals(otherControlId: string, value: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const form = control.parent;
      if (!form) {
        return null;
      } else {
        const otherControl = form.get(otherControlId);
        if (otherControl.value === value) {
          return Validators.required(control);
        } else {
          return null;
        }
      }
    };
  }

  static requiredIfOtherNotEquals(otherControlId: string, value: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const form = control.parent;
      if (!form) {
        return null;
      } else {
        const otherControl = form.get(otherControlId);
        if (otherControl.value !== value) {
          return Validators.required(control);
        } else {
          return null;
        }
      }
    };
  }
}
