import { CompilationContext, CompilationResult } from '../compilation';

import { Content } from './';

export type SpacerContent = Content;

export const compileToHtmlNodes = (content: SpacerContent, context: CompilationContext): CompilationResult => {
  const result: CompilationResult = {
    nodes: [],
    context
  };
  return result;
};
