import { createAction, props, union } from '@ngrx/store';

import { CustomBlockRef } from '../../declarations/layout';

export const setTemplaterReadonlyMode = createAction(
  '[TEMPLATER][UI] Set Templater Readonly TRUE',
  props<{ readonly: boolean }>()
);

export const setSelectedArticleIndex = createAction(
  '[TEMPLATER][UI] Set Selected Article Index',
  props<{ index: number }>()
);

export const setFocusedElementId = createAction(
  '[TEMPLATER][UI] Set Focused Element Id',
  props<{ id: string | null }>()
);

export const setConfiguredElementId = createAction(
  '[TEMPLATER][UI] Set Configured Element Id',
  props<{ id: string | null}>()
);

export const setActiveCustomBlockRefs = createAction(
  '[TEMPLATER][UI] Set Active Custom Block Refs',
  props<{ refs: CustomBlockRef[] }>()
);

export const addContentToViewportSelection = createAction(
  '[TEMPLATER][UI] Add Content To Selection',
  props<{ id: string }>()
);

export const removeContentFromViewportSelection = createAction(
  '[TEMPLATER][UI] Remove Content From Selection',
  props<{ id: string }>()
);

export const addLayoutToViewportSelection = createAction(
  '[TEMPLATER][UI] Add Layout To Selection',
  props<{ id: string }>()
);

export const removeLayoutFromViewportSelection = createAction(
  '[TEMPLATER][UI] Remove Layout From Selection',
  props<{ id: string }>()
);

export const cancelViewportSelection = createAction(
  '[TEMPLATER][UI] Cancel Selection'
);

export const templateUIActions = union({
  addContentToViewportSelection,
  removeContentFromViewportSelection,
  addLayoutToViewportSelection,
  removeLayoutFromViewportSelection,
  cancelViewportSelection
});

export type TemplaterUIAction = typeof templateUIActions;
